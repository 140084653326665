import React, {useEffect, useState} from "react";

//components
import Summary from "./Summary";
import EndPresentationPrompt from "./EndPresentation";
import {resetPresentationServices} from "utils/activePresentationUtils";

export default function EndPresentations({endPresentation, closeModal, isEvent, eventDetails}) {
    //usestate
    const [isPresentationOver, setisPresentationOver] = useState(false);

    useEffect(() => {
        setisPresentationOver(false);
    }, []);

    //function
    const showSummary = () => {
        endPresentation();
        closeModal();

        //stop and store timer
        localStorage.setItem("isActive", JSON.stringify(false));

        const savedStartTime = localStorage.getItem("startTime");
        const startTime = JSON.parse(savedStartTime);
        const totalDurationInSeconds = Math.floor((Date.now() - startTime) / 1000);

        localStorage.setItem("duration", totalDurationInSeconds);

        resetPresentationServices();
    };

    return (
        <section>
            {!isPresentationOver ? (
                <EndPresentationPrompt
                    isEvent={isEvent}
                    eventDetails={eventDetails}
                    showSummary={showSummary}
                    closeModal={closeModal}
                />
            ) : (
                <Summary/>
            )}
        </section>
    );
}
