import Axios from 'lib/axiosInterceptor';
import { getConnectionId, getPresentationId } from 'utils/localStorageServices';

//get

export const _getPolls = (presentationId: string) => {
  return Axios.get(`/Poll/GetPollList?presentationId=${presentationId}`);
};

export const _getPollDetails = (presentationId: string, pollId: string) => {
  return Axios.get(
    `/Poll/GetPoll?presentationId=${presentationId}&PollId=${pollId}&presentaConnectionId=${getConnectionId()}`
  );
};

export const _getPollTemplates = () => {
  return Axios.get(`/Poll/Templates`);
};

//post
export const _pollCreation = (data: any, presentationId?: string) => {
  return Axios.post(`/Poll/CreatePoll`, data);
};

export const _takePoll = (data: any) => {
  return Axios.post(
    `/Poll/TakePoll?presentationId=${getPresentationId()}&presentaConnectionId=${getConnectionId()}`,
    data
  );
};

export const _deletePollQuestionType = (
  presentationId: string,
  pollId: string
) => {
  return Axios.post(
    `/Poll/DeletePoll?presentationId=${presentationId}&rowKey=${pollId}`
  );
};
