import EventsPreview from 'components/Admin/Events/EventsPreview';
import ProtectedRoute from 'routes/ProtectedRoute';
import BriefcasePreview from 'views/Admin/Briefcase/BriefcasePreview/BriefcasePreview';

const previewRoutes = [
  {
    path: 'briefcase-preview/:spaceId',
    element: (
      <ProtectedRoute>
        <BriefcasePreview />
      </ProtectedRoute>
    ),
  },

  {
    path: 'document-preview/:documentId',
    element: (
      <ProtectedRoute>
        <BriefcasePreview />
      </ProtectedRoute>
    ),
  },

  {
    path: 'events/preview/:eventId',
    element: (
      <ProtectedRoute>
        <EventsPreview />
      </ProtectedRoute>
    ),
  },
  {
    path: 'events/preview/:eventId/:sessionId',
    element: (
      <ProtectedRoute>
        <EventsPreview />
      </ProtectedRoute>
    ),
  },
  {
    path: 'events/preview/:eventId/:documentId',
    element: (
      <ProtectedRoute>
        <EventsPreview />
      </ProtectedRoute>
    ),
  },
];

export default previewRoutes;
