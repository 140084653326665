import ellipsisVertical from 'assets/svg/ellipsis-vertical.svg';
import play from 'assets/svg/play2.svg';
import calender from 'assets/svg/calendar-2.svg';
import bell from 'assets/svg/bell.svg';
import chevronDownWhite from 'assets/svg/chevron-down-white.svg';
import eyeI from 'assets/svg/eye-2.svg';

import { Dropdown, Menu } from 'antd';
import { Dispatch, SetStateAction } from 'react';
interface Props {
  documentId: string;
  setDocumentId: Dispatch<SetStateAction<string | null>>;
  setPresentationType: Dispatch<SetStateAction<number>>;
  setSchedulePresentation: Dispatch<SetStateAction<boolean>>;
  setShareBriefCaseDrawer: Dispatch<SetStateAction<boolean>>;
  openInNewTab: (documentId: string) => void;
}
export default function DocumentPreviewComponent({
  documentId,
  setDocumentId,
  setPresentationType,
  setSchedulePresentation,
  setShareBriefCaseDrawer,
  openInNewTab,
}: Props) {
  const items_ = [
    {
      key: '1',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => {
            if (documentId) {
              setDocumentId(documentId);
              setPresentationType(1);
              setSchedulePresentation(true);
              setShareBriefCaseDrawer(true);
            }
          }}
        >
          <img src={play} alt='rename file' className='w-4' />
          <p>Create presentation</p>
        </div>
      ),
    },

    {
      key: '2',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => {
            if (documentId) {
              setDocumentId(documentId);
              setPresentationType(0);
              setShareBriefCaseDrawer(true);
            }
          }}
        >
          <img src={calender} alt='metrics file' className='w-4' />
          <p>Share file</p>
        </div>
      ),
    },
  ];
  return (
    <section className='gap-x-2 lg:flex hidden items-center justify-between'>
      {/* btn */}
      <div className='h-8 rounded-md w-8 border border-[#E2E2E2] cursor-pointer flex items-center justify-center'>
        <img src={ellipsisVertical} alt='ellipsis icon' />
      </div>
      <div
        className='h-8 flex items-center gap-x-2 px-5 rounded-md border border-[#AFAFAF] text-sm text-[#545454] cursor-pointer'
        onClick={() => {
          openInNewTab(`/document-preview/${documentId}`);
        }}
      >
        <p>Preview</p>
        <img src={eyeI} alt='eye' className='w-4' />
      </div>
      <section onClick={(e) => e.preventDefault()} className='cursor-pointer'>
        <Dropdown
          trigger={['click']}
          dropdownRender={(menu) => (
            <Menu>
              {items_.map((item) => {
                return <Menu.Item key={item.key}>{item.label}</Menu.Item>;
              })}
            </Menu>
          )}
        >
          <div className='h-8 bg-black text-white flex items-center gap-x-2 px-5 rounded-md text-sm'>
            <p>Create link</p>
            <img src={chevronDownWhite} alt='eye' className='' />
          </div>
        </Dropdown>
      </section>

      <div className='px-2 py-2 border rounded-md cursor-pointer bg-gray20'>
        <img src={bell} alt='notification' className='w-4' />
      </div>
    </section>
  );
}
