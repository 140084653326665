// import { toast } from "react-toastify";

export const GetQuestionDetails = (
    toast,
    connection,
    presentationId,
    questionId
) => {
    const state = connection?.state;

    if (state === "Connected") {
        console.log(presentationId, questionId, "Trying to get Question")
        connection
            .invoke("GetQuestion", presentationId, questionId)
            .catch((err) => {
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: "An error occurred invoking 'GetQuestion' on the server",
                });
                // toast.error("An error occurred invoking 'GetQuestions' on the server");
            });

    }
};
