import {ViewerMenuWrapper} from "./ViewerMenuWrapper";
import {ViewerMenuHeader} from "./ViewMenuHeader";
import {ViewerInnerWrapper} from "./ViewerInnerWrapper";
import userIcon from "assets/svg/avatar.svg"
import {UserInterface} from "../../../../interfaces/interfaces-data";

export const ViewerUsers = ({users}: { users: UserInterface[] }) => {
    return <ViewerMenuWrapper>
        <ViewerMenuHeader text="Attendees" sideText={String(users.length)}/>
        <ViewerInnerWrapper>
            {users.length ? users.map(item => {
                return <ViewerUserWrapper name={item.name}/>
            }) : <div className="w-full justify-center py-4">
                No Active Viewer
            </div>}


        </ViewerInnerWrapper>
    </ViewerMenuWrapper>
}

const ViewerUserWrapper = ({name}: { name: string }) => {
    return <div className={"flex items-center gap-3 py-3 border-b"}>
            <span>
                <img className={"w-[30px] h-[30px]"} src={userIcon} alt={""}/>
            </span>

        <p className="text-[#111827] text-sm font-semibold">
            {name}
        </p>
    </div>
}