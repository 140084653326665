import { useEffect, useState } from 'react';

// Assets
import clock from 'assets/svg/clock-icon.svg';
import briefcaseIMG from 'assets/svg/briefcase-img.svg';
import plusWhite from 'assets/svg/plus-white.svg';
import lines from 'assets/image/briefcaselayerlines.png';
import divider from 'assets/svg/divider.svg';
import camera from 'assets/svg/cameraIcon.svg';
import eventBanner from 'assets/image/events-banner.png';
import eyeI from 'assets/svg/eye-2.svg';
import sendIcon from 'assets/svg/send-icon.svg';
import playOrange from 'assets/svg/play-orange.svg';
import eventThumbnail from 'assets/svg/eventThumbnail.svg';
import sms from 'assets/svg/sms-tracking.svg';
import arrowRight from 'assets/svg/arrow-right-white.svg';
import calender_ from 'assets/image/calendar.png';
import linkSquare from 'assets/svg/link-square.svg';
import copyIcon from 'assets/svg/copy-1.svg';
import previewIcon from 'assets/svg/preview-icon.svg';
import previewImg from 'assets/image/previewFile.png';

// Libraries
import {
  Link,
  useSearchParams,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { Drawer, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'store/store';
import AllAgendas from './Tables/AllAgendas';
import { getTeamsState } from 'store/Teams/teamsSlice';
import {
  addEventThumbnail,
  addSession,
  getEvent,
  shareEvent,
} from 'store/Events/EventsSlice';
import NavbarNew from 'components/UI/AdminLayout/NavbarNew';
import BulkUpload from 'components/UI/BulkUpload/BulkUpload';
import {
  AddSessionRequestPayload,
  GetEventResponsePayload,
  ShareEventLinkRequestPayload,
} from 'interfaces/Events/EventsInterfaces';
import { ClipLoader } from 'react-spinners';
import Loader from 'components/UI/Loader';
// import InviteCoPresenters from 'components/Admin/Events/Modals/InviteCoPresenters';
import CreateAgenda from 'components/Admin/Events/EventSetup/CreateAgenda';
import dayjs from 'dayjs';
import moment from 'moment';
import Axios from 'lib/axiosInterceptor';
import { getEmail } from 'utils/localStorageServices';
import { toast } from 'sonner';
import copyLink from 'utils/copyLink';

export default function EventDetails() {
  //url param
  let [searchParams] = useSearchParams();
  const { eventId } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  // StatesE
  const [tab, setTab] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState<null | string>('');
  const [openAddSessionModal, setOpenAddSessionModal] = useState(false);

  // Store

  const eventDetails = useSelector(
    (state: RootState) => state.events.event.data
  );
  const { teamInfo } = useSelector(getTeamsState);

  // useEffect
  useEffect(() => {
    const teamId = teamInfo?.data?.id;
    if (teamId && eventId) {
      dispatch(getEvent({ eventId, teamId }));
      const fetchProfileImage = async () => {
        try {
          const response = await Axios.get(`/Events/Thumbnail/${eventId}`, {
            responseType: 'blob',
          });

          if (response) {
            const image =
              response.data?.size > 0
                ? URL.createObjectURL(new Blob([response.data]))
                : null;
            setBackgroundImage(image);
          } else {
            setBackgroundImage(null);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchProfileImage();
    }
  }, [teamInfo, dispatch, eventId]);

  //useeffects
  useEffect(() => {
    const tabParam = searchParams.get('tab')?.toLowerCase();
    !tabParam && setTab(0);
    tabParam === 'all-files' && setTab(0);
    tabParam === 'links' && setTab(1);
    tabParam === 'presentation-history' && setTab(2);
    tabParam === 'analytics' && setTab(3);
  }, [searchParams]);

  //variables
  const items_ = [
    {
      key: '1',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => setOpenAddSessionModal(true)}
        >
          <img src={divider} alt='rename file' className='w-4' />
          <p>Add session</p>
        </div>
      ),
    },
  ];

  const handleAddSession = (
    title: string,
    emailList: string[],
    emailName: string,
    eventHost: 'You' | 'Guest'
  ) => {
    console.log(title, emailList, emailName);
    const teamId = teamInfo?.data?.id;
    if (eventId && teamId) {
      let position = 0;
      position = eventDetails?.agenda.length ?? 0;

      let updatedEmailList = [...emailList];
      if (emailName.trim().length > 0) {
        updatedEmailList = [...updatedEmailList, emailName];
      }

      const data: AddSessionRequestPayload = {
        eventId: eventId,
        teamId: teamId,
        sessions: [
          {
            position: position,
            title: title,
            time: '2024-12-20T07:32:13.403Z',
            duration: 0,
            collaborators:
              eventHost === 'You' ? [getEmail()] : updatedEmailList,
          },
        ],
      };
      dispatch(addSession(data)).then(({ type }) => {
        if (type.includes('fulfilled')) {
          dispatch(getEvent({ eventId, teamId }));
          setOpenAddSessionModal(false);
        }
      });
    }
  };

  const updateThumbnail = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files ? e.currentTarget.files[0] : null;
    if (!file) {
      return;
    }

    // Store the current thumbnail before updating
    const previousThumbnailImg = backgroundImage;

    // Optimistic UI update
    const objectUrl = URL.createObjectURL(file);
    setBackgroundImage(objectUrl);

    const formData = new FormData();

    if (file) {
      formData.append('banner', file);
    }

    // Clear the file input value to allow selecting the same file again
    if (e.currentTarget?.value) {
      e.currentTarget.value = '';
    }

    const teamId = teamInfo?.data?.id;

    teamId &&
      eventId &&
      dispatch(addEventThumbnail({ eventId, teamId, data: formData })).then(
        ({ type }) => {
          if (type.includes('fulfilled')) {
          } else {
            setBackgroundImage(previousThumbnailImg);
          }
        }
      );
  };

  return (
    <section>
      {!eventDetails ? (
        <Loader />
      ) : (
        <section className='relative'>
          <NavbarNew
            hasBreadCrumb
            leftContent={
              eventDetails.role === 'Collaborator' ? (
                <LeftContentCollaborator eventId={eventDetails.id} />
              ) : (
                <LeftContentEventOwner
                  eventId={eventDetails.id}
                  eventDetails={eventDetails}
                />
              )
            }
            subText={[
              { text: 'Events', link: '/admin/events' },
              { text: 'Event details', link: '' },
            ]}
            mainText={'Events'}
            mainBigger
          />
          <section>
            {/* Details banner */}
            <section className='mt-5 relative'>
              {backgroundImage ? (
                <div className='h-[130px] w-full rounded-xl overflow-hidden bg-blue400'>
                  <img
                    src={backgroundImage}
                    alt='background frame'
                    className='w-full h-full object-cover'
                    role='presentation'
                  />
                </div>
              ) : (
                <div className='h-[130px] w-full rounded-xl overflow-hidden bg-white'>
                  <img
                    src={eventBanner}
                    alt='background banner'
                    className='w-full h-full object-cover'
                    role='presentation'
                  />
                </div>
              )}

              {/* banner */}
              <div className='h-[144px] w-full bg-transparent absolute -bottom-[70%] left-0 px-5'>
                <div className='bg-[#FEFEFD] w-full h-full rounded-xl p-5 border border-[#F6F3EF] relative'>
                  <div className='w-full'>
                    <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                      {eventDetails ? eventDetails.title : ''}
                    </h1>
                    <section className='flex gap-x-5 items-center text-sm relative'>
                      <div className='flex gap-x-5 items-center'>
                        <div className='flex items-center gap-x-2'>
                          <img src={calender_} alt='date' />
                          <p>
                            {dayjs(eventDetails.startDate).format(
                              'dddd, MMMM D'
                            )}
                          </p>
                        </div>
                        <div className='flex items-center gap-x-2'>
                          <img src={clock} alt='time' />
                          <p>
                            {dayjs(eventDetails.startDate).format('h:mm A')}
                          </p>
                        </div>
                      </div>

                      <div className='flex gap-x-2 cursor-pointer absolute right-[220px] top-0 pointer-events-none'>
                        <div className='flex items-center gap-x-2'>
                          <img src={clock} alt='time' />
                          <p>
                            Updated{' '}
                            {moment.utc(eventDetails.dateModified).fromNow()}
                          </p>
                        </div>
                      </div>
                    </section>
                  </div>
                  <img
                    src={briefcaseIMG}
                    alt='briefcase'
                    className='absolute right-20 top-0 pointer-events-none'
                  />
                  <img
                    src={lines}
                    alt='briefcase'
                    className=' absolute right-0 top-0 pointer-events-none'
                  />
                </div>
              </div>

              {/* upload banner */}
              {eventDetails.role === 'EventOwner' && (
                <div className='absolute top-4 left-6 cursor-pointer'>
                  <label htmlFor='upload-banner'>
                    <img
                      src={camera}
                      alt='uplaod banner'
                      className='cursor-pointer'
                    />
                    <input
                      id='upload-banner'
                      type='file'
                      className='border cursor-pointer opacity-0 w-[40px] hidden'
                      onChange={updateThumbnail}
                    />
                  </label>
                </div>
              )}
            </section>

            {/* tabs */}
            <section className='my-20 mt-[110px]'>
              <section className='flex gap-x-4 items-center justify-between'>
                <section className='flex gap-x-2 border-b'>
                  <Link to={`/admin/events/details/${eventId}?tab=all-files`}>
                    <button
                      onClick={() => setTab(0)}
                      className={`px-4 pb-2 cursor-pointer text-sm  ${
                        tab === 0 && 'border-b-2  text-primary border-b-primary'
                      }`}
                    >
                      Agenda
                    </button>
                  </Link>

                  <Link to={`/admin/events/details/${eventId}?tab=links`}>
                    <button
                      onClick={() => setTab(1)}
                      className={`px-4 pb-2 cursor-pointer text-sm  ${
                        tab === 1 && 'border-b-2  text-primary border-b-primary'
                      }`}
                    >
                      Analytics
                    </button>
                  </Link>

                  <Link
                    to={`/admin/events/details/${eventId}?tab=presentation-history`}
                  >
                    <button
                      onClick={() => setTab(2)}
                      className={`px-4 pb-2 cursor-pointer text-sm  ${
                        tab === 2 && 'border-b-2  text-primary border-b-primary'
                      }`}
                    >
                      Audit trail
                    </button>
                  </Link>
                </section>
                <section className='flex items-center gap-x-2'>
                  {tab === 0 && eventDetails.role === 'EventOwner' && (
                    <section
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenAddSessionModal(true);
                      }}
                    >
                      <div className='bg-black text-white text-sm h-8 rounded-md flex gap-x-1 items-center px-3 cursor-pointer'>
                        <img src={plusWhite} alt='ellipsis icon' />
                        <p>Add session</p>
                        {/* <img src={ChevronDownWhite} alt='ellipsis icon' /> */}
                      </div>
                    </section>
                  )}
                </section>
              </section>

              <section className='mt-1 mb-8 pb-20'>
                {tab === 0 && <AllAgendas />}
              </section>
            </section>
          </section>

          <Modal
            footer={null}
            centered={false}
            open={openAddSessionModal}
            width={452}
            closeIcon={false}
            style={{
              top: 20,
            }}
            destroyOnClose
            onCancel={() => setOpenAddSessionModal(false)}
          >
            <CreateAgenda
              addSessionFromEventDetailsPage={handleAddSession}
              from={'eventDetailsPage'}
            />
          </Modal>
        </section>
      )}
    </section>
  );
}

const LeftContentEventOwner = ({
  eventId,
  eventDetails,
}: {
  eventId: string;
  eventDetails: GetEventResponsePayload;
}) => {
  const [openShareModal, setOpenShareModal] = useState(false);
  const navigate = useNavigate();
  const openInNewTab = (url: string) => {
    window.open(url, '_blank'); // Opens in a new tab
  };
  const { teamInfo } = useSelector(getTeamsState);

  return (
    <div className='flex items-center flex-col lg:flex-row gap-y-4 gap-x-2 text-sm font-medium'>
      <div
        className='h-8 flex items-center gap-x-2 px-5 rounded-md border border-[#AFAFAF] text-sm text-[#545454] cursor-pointer'
        onClick={() => {
          openInNewTab(`/events/preview/${eventId}`);
        }}
      >
        <p>Preview</p>
        <img src={eyeI} alt='eye' className='w-4' />
      </div>
      <div
        className='px-3 border border-[#AFAFAF] rounded-md text-[#545454] flex gap-x-2 items-center h-8 cursor-pointer'
        onClick={() => {
          setOpenShareModal(true);
        }}
      >
        <p>Share event</p>
        <img src={sendIcon} alt='share briefcase' />
      </div>

      <section onClick={(e) => e.preventDefault()}>
        <div
          onClick={() =>
            navigate(
              `/active-event/${eventId}/${teamInfo?.data?.id}?isEvent=${true}`
            )
          }
          className='px-3 text-[#FF6929] border border-[#D45722] rounded-md flex gap-x-2 items-center h-8 cursor-pointer'
        >
          <p>Start event</p>
          <img src={playOrange} alt='drop down' className='w-6' />
        </div>
      </section>

      <Drawer
        placement='right'
        closable={false}
        onClose={() => {
          setOpenShareModal(false);
        }}
        open={openShareModal}
        width={468}
        destroyOnClose
      >
        <ShareEvent eventDetails={eventDetails} />
      </Drawer>
    </div>
  );
};
const LeftContentCollaborator = ({ eventId }: { eventId: string }) => {
  const openInNewTab = (url: string) => {
    window.open(url, '_blank'); // Opens in a new tab
  };

  return (
    <div className='flex items-center flex-col lg:flex-row gap-y-4 gap-x-2 text-sm font-medium'>
      <div
        className='h-8 flex items-center gap-x-2 px-5 rounded-md border border-[#AFAFAF] text-sm text-[#545454] cursor-pointer'
        onClick={() => {
          openInNewTab(`/events/preview/${eventId}`);
        }}
      >
        <p>Preview</p>
        <img src={eyeI} alt='eye' className='w-4' />
      </div>

      <section onClick={(e) => e.preventDefault()}>
        <div className='px-3 text-[#FF6929] border border-[#D45722] rounded-md flex gap-x-2 items-center h-8 cursor-pointer'>
          <p>Present now</p>
          <img src={playOrange} alt='drop down' className='w-6' />
        </div>
      </section>
    </div>
  );
};

const ShareEvent = ({
  eventDetails,
}: // eventId,
{
  eventDetails: GetEventResponsePayload;
  // eventId: string;
}) => {
  const [email, setEmail] = useState('');
  const [emailList, setEmailList] = useState<string[]>([]);
  const [shareLinkWIthAnyone, setShareLinkWIthAnyone] = useState(false);
  const [restrictDomains, setRestrictDomains] = useState(false);
  const [emailSecurity, setEmailSecurity] = useState('');
  const [emailListSecurity, setEmailListSecurity] = useState<string[]>([]);
  // const [security, setSecurity] = useState(false);
  const [usePasscode, setUsePasscode] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [allowDownloads, setAllowDownloads] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();

  const { teamInfo } = useSelector(getTeamsState);
  let routeOrigin = window.location.origin;

  const { eventId } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const handleRemoveEmail = (index: number) => {
    const updatedEmails = emailList.filter((_, i) => i !== index);
    setEmailList([...updatedEmails]);
  };
  const handleRemoveEmailSecurity = (index: number) => {
    const updatedEmails = emailListSecurity.filter((_, i) => i !== index);
    setEmailListSecurity([...updatedEmails]);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value.trim();
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      if (email === ',') {
        setEmail('');
        return;
      }
      if (email) {
        setEmailList([...emailList, email]);
      }
      setEmail('');
    }
  };
  const handleKeyDownDomain = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value.trim();
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      if (email === ',') {
        setEmailSecurity('');
        return;
      }
      if (email) {
        setEmailListSecurity([...emailListSecurity, email]);
      }
      setEmailSecurity('');
    }
  };
  useEffect(() => {
    if (emailList.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [emailList]);

  const handleShareEventLink = () => {
    const data: ShareEventLinkRequestPayload = {
      openLink: usePasscode,
      allowQuestion: true,
      enableWaitingRoom: true,
      emails: emailList,
      restriction: 0,
      domains: emailListSecurity,
      watermark: '',
      authMode: 0,
      canDownload: allowDownloads ? 1 : 0,
    };

    const teamId = teamInfo.data?.id;

    eventId && dispatch(shareEvent({ eventId, teamId, data }));
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank'); // Opens in a new tab
  };
  return (
    <section>
      {tab === 0 && (
        <section>
          <div className='flex gap-2 py-2 border-b'>
            <h3>Share event link</h3>
          </div>
          <section className='flex flex-col gap-2'>
            <div className='mt-5 bg-[#FAFAFA] rounded-xl p-2'>
              <div className='rounded-full w-fit flex gap-x-2 px-2 items-center border border-[#D3D3D3] bg-[#F6F6F6]'>
                <img src={eventThumbnail} alt='' />
                <p className='font-medium'>{eventDetails.title}</p>
              </div>
              <p className='font-medium mt-1'>
                {<p>{dayjs(eventDetails.startDate).format('dddd, MMMM D')}</p>}
              </p>
            </div>
            <BulkUpload
              item={email}
              itemList={emailList}
              handleKeyDown={handleKeyDown}
              removeItem={handleRemoveEmail}
              setItem={setEmail}
              toggleInput={true}
              title='Share link with anyone'
              checked={shareLinkWIthAnyone}
              setChecked={setShareLinkWIthAnyone}
            />
            <section className='border rounded-xl border-[#EEEEEE] p-3'>
              <div className='w-full flex justify-between items-center mt-3'>
                <p className='text-base'>Use passcode</p>
                <label className='inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    checked={usePasscode}
                    onChange={() => {
                      if (usePasscode) {
                        setPasscode('');
                        setUsePasscode(false);
                      } else {
                        setUsePasscode(true);
                      }
                    }}
                    className='sr-only peer'
                  />
                  <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                </label>
              </div>
              {/* enter password */}
              <div className={`${usePasscode ? ' ' : 'hidden'} w-full mt-3`}>
                <label
                  htmlFor='add-email'
                  className='w-full rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
                >
                  <img src={sms} alt='email' className='w-4 h-4' />
                  <input
                    type='text'
                    id='add-email'
                    placeholder='Enter password'
                    className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                    name='passcode'
                    value={passcode || ''}
                    onChange={(e) => {
                      setPasscode(e.target.value);
                    }}
                  />
                </label>
              </div>
            </section>
            <section className='border rounded-xl border-[#EEEEEE] p-3'>
              <div className='w-full flex justify-between items-center'>
                <p className='text-base'>Allow downloads</p>
                <label className='inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    onChange={() => {
                      setAllowDownloads(!allowDownloads);
                    }}
                    className='sr-only peer'
                  />

                  <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                </label>
              </div>
            </section>
            <BulkUpload
              item={emailSecurity}
              itemList={emailListSecurity}
              handleKeyDown={handleKeyDownDomain}
              removeItem={handleRemoveEmailSecurity}
              setItem={setEmailSecurity}
              toggleInput={true}
              title='Restrict domains'
              checked={restrictDomains}
              setChecked={setRestrictDomains}
              header='More security'
            />
          </section>
          <div className='mt-5 py-5 w-full flex justify-end border-y'>
            <button
              className={`text-white h-10 px-5 flex gap-x-2 items-center justify-center rounded-md min-w-[100px] 
             ${disableButton ? 'bg-orange-300' : 'bg-primary'}
             `}
              onClick={(e) => {
                e.preventDefault();
                handleShareEventLink();
                setTab(1);
              }}
              disabled={disableButton}
            >
              {!isLoading ? (
                <span className='flex items-center gap-x-2'>
                  <p className='font-semibold'>Share link</p>
                  <img src={arrowRight} alt='next' />
                </span>
              ) : (
                <ClipLoader size={12} color='white' />
              )}
            </button>
          </div>
        </section>
      )}

      {tab === 1 && (
        <section>
          <div className='w-full border-b pb-2'>
            <h1 className='text-xl font-medium'>Share link</h1>
          </div>
          <section className='p-3 bg-[#E6F2ED] min-h-20 flex gap-x-4 rounded-xl items-center mt-5 '>
            <img src={linkSquare} alt='review!' className='w-6' />
            <div className=''>
              <p className='text-sm text-black font-semibold'>
                Link created successfully
              </p>
              <p
                className='cursor-pointer'
                onClick={() => {
                  copyLink(`join-event/${eventDetails.id}`);
                }}
              >
                {`${routeOrigin}/join-event/${eventDetails.id}`}
                <span className='mr-1'>
                  <img src={copyIcon} alt='copy' className='cursor-pointer' />
                </span>
              </p>
            </div>
          </section>

          <section className='rounded-md border p-3 flex flex-col gap-y-2 mt-5'>
            <img src={previewImg} alt='preview file' className='w-[83px]' />
            <p>See how your file will look like to the public</p>
            <button
              className='flex gap-x-2 bg-[#F5F5F5] py-2 px-5 rounded-md w-fit'
              onClick={() => {
                openInNewTab(`/events/preview/${eventId}`);
              }}
            >
              <img src={previewIcon} alt='preveiw botton' />
              <p className='text-black'>Preview file</p>
            </button>
          </section>
        </section>
      )}
    </section>
  );
};
