//Assets
import arrowLeft from 'assets/svg/arrow-left-circle.svg';
import arrowLeftGray from 'assets/svg/arrow-left-circle-gray.svg';
import arrowRight from 'assets/svg/arrow-right-circle.svg';
import arrowRightGray from 'assets/svg/arrow-right-circle-gray.svg';
import { MutableRefObject } from 'react';

interface ImageCache {
  id: string[];
}
interface Props {
  screenWidth: number;
  isSlides: boolean;
  slides: string[];
  slideDocumentIndex: number;
  handlePrevSlideDoc: () => void;
  handleNextSlideDoc: () => void;
  displayDocumentIndex: number;
  handlePrevDoc: () => void;
  flattedDisplayDocuments: any;
  handleNextDoc: () => void;
  imageCache: MutableRefObject<ImageCache[]>;
}

export default function Controller({
  screenWidth,
  isSlides,
  slides,
  slideDocumentIndex,
  handlePrevSlideDoc,
  displayDocumentIndex,
  flattedDisplayDocuments,
  handleNextSlideDoc,
  handlePrevDoc,
  handleNextDoc,
  imageCache,
}: Props) {
  return (
    <section>
      {isSlides && slides.length > 0 ? (
        <section className='flex items-center gap-x-5'>
          <button
            className={`bg-[#F5F5F5] flex gap-x-3 px-5 h-9 items-center rounded-lg select-none ${
              slideDocumentIndex === 0
                ? 'cursor-default text-[#E2E2E2]'
                : 'cursor-pointer text-black'
            }`}
            onClick={handlePrevSlideDoc}
          >
            <img
              src={slideDocumentIndex === 0 ? arrowLeftGray : arrowLeft}
              alt='previous slide'
            />
            <p className=''>Previous slide</p>
          </button>

          <div className='bg-[#FFD1BD] rounded-[20px] text-black px-2 h-[44px] flex items-center select-none'>
            <p className='font-medium'>{`${slideDocumentIndex + 1} of ${
              slides?.length ?? 1
            }`}</p>
          </div>

          <button
            className='bg-[#F5F5F5] flex gap-x-3 px-5 h-9 items-center rounded-lg cursor-pointer select-none'
            onClick={handleNextSlideDoc}
          >
            <p>Next slide</p>
            <img
              src={
                slideDocumentIndex === slides.length - 1
                  ? arrowRightGray
                  : arrowRight
              }
              alt='next slide'
            />
          </button>
        </section>
      ) : (
        <section className='flex items-center gap-x-5'>
          <button
            className={`bg-[#F5F5F5] flex gap-x-3 px-5 h-9 items-center rounded-lg select-none ${
              displayDocumentIndex === 0
                ? 'cursor-default text-[#E2E2E2]'
                : 'cursor-pointer text-black'
            }`}
            onClick={handlePrevDoc}
          >
            <img
              src={displayDocumentIndex === 0 ? arrowLeftGray : arrowLeft}
              alt='previous slide'
            />
            <p className='pointer-events-none'>Previous slide</p>
          </button>

          <div className='bg-[#FFD1BD] rounded-[20px] px-2 h-[44px] flex items-center select-none'>
            <p className='font-medium'>{`1 of 1`}</p>
          </div>

          <button
            className={`bg-[#F5F5F5] flex gap-x-3 px-5 h-9 items-center rounded-lg cursor-pointer select-none ${
              displayDocumentIndex === flattedDisplayDocuments.length - 1
                ? 'cursor-default text-[#E2E2E2]'
                : 'cursor-pointer text-black'
            }
                    `}
            onClick={handleNextDoc}
          >
            <p>Next slide</p>
            <img
              src={
                displayDocumentIndex === flattedDisplayDocuments.length - 1
                  ? arrowRightGray
                  : arrowRight
              }
              alt='next slide'
            />
          </button>
        </section>
      )}
    </section>
  );
}

// ${
//   imageCache.current[displayDocumentIndex]?.id.length ?? 1
// }
