import BlackBackground from 'components/Website/About/BlackBackground';
import Banner from 'components/Website/Support/Banner';
import Contact from 'components/Website/Support/Contact/Contact';
import FAQ from 'components/Website/Support/FAQ/FAQ';

export default function Support() {
  return (
    <section>
      <Banner />
      <Contact />
      <FAQ />
      <BlackBackground />
    </section>
  );
}
