import {ViewerMenuWrapper} from "./ViewerMenuWrapper";
import {ViewerMenuHeader} from "./ViewMenuHeader";
import {ViewerInnerWrapper} from "./ViewerInnerWrapper";
import {HubConnection} from "@microsoft/signalr";
import React, {useEffect, useRef, useState} from "react";
import {GetQuestions} from "../../../../hooks/SignalR/Invokers/Presenter/GetQuestions";
import {useGetQuestions} from "../../../../hooks/SignalR/Listeners/Presenter/useGetQuestions";
import {ClipLoader} from "react-spinners";
import QuestionCard from "../../../Admin/ActivePresentation/QuestionsDrawer/QuestionCard";
import empty from "../../../../assets/svg/emptyTemplate.svg";
import {Modal} from "antd";
import AskQuestion from "../../../../views/User/ViewerMode/Modal/AskQuestion";
import {EventsInterface, SlideInterface} from "../../../../interfaces/interfaces-data";

export const DesktopViewerQuestion = ({
                                          connection,
                                          connected,
                                          presentationId,
                                          activeSlideId,
                                          activeDocument,
                                          activeImage,
                                          activeSlideIndex,
                                          isEvent,
                                          eventDetails

                                      }: {
    connection?: HubConnection | null,
    connected: boolean,
    presentationId: string,
    activeSlideId: string,
    activeDocument?: SlideInterface,
    activeImage: string,
    activeSlideIndex: number,
    isEvent?: boolean,
    eventDetails?: EventsInterface
}) => {
    const [showQuestionModal, setShowQuestionModal] = useState(false)
    const [tab, setTab] = useState(0);
    const [questions, setQuestions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [originalQuestion, setOriginalQuestion] = useState<any[]>([])

    useGetQuestions(connection, presentationId, (questionList: any) => {
        console.log(questionList, "Question list hereee")
        const questions = questionList.sort((a: any, b: any) =>
            a?.askTime < b?.askTime ? 1 : -1
        );

        setOriginalQuestion([...questions])
        setQuestions(
            questions.filter((question: any) => {
                if (tab === 1) {
                    return !question?.isAnswered;
                }
                if (tab === 2) {
                    return question?.isAnswered;
                }

                return question;
            })
        );
        setIsLoading(false);
    });
    useEffect(() => {
        if (connected && connection) {
            GetQuestions(connection, presentationId, "", eventDetails?.id, isEvent)
        }
    }, [connected]);

    const askQuestionRef = useRef()
    const handleCancel = () => {
        setShowQuestionModal(false)
        // askQuestionRef.current.resetModal();
    };
    useEffect(() => {
        if (tab === 0) {
            setQuestions([...originalQuestion]);
        } else if (tab === 1) {
            const filteredQuestions = originalQuestion.filter(item => {
                return !item?.isAnswered
            })
            setQuestions(filteredQuestions);
        } else if (tab === 2) {
            const filteredQuestions = originalQuestion.filter(item => {
                return item?.isAnswered
            })
            setQuestions(filteredQuestions);
        }
    }, [tab]);
    return <>
        <Modal
            style={{
                top: window.innerWidth > 900 ? "10%" : "5%",
            }}
            open={showQuestionModal}
            onCancel={handleCancel}
            footer={null}
            destroyOnClose={true}
        >
            <AskQuestion
                ref={askQuestionRef}
                isOpen={showQuestionModal}
                connection={connection}
                slideId={activeSlideId}
                slideNumber={activeSlideIndex}
                //Active document seem quite redundant heree
                activeDocument={activeDocument}
                presentationId={presentationId}
                activeImage={activeImage}
                closeModal={handleCancel}
                eventId={eventDetails?.id}
                isEvent={isEvent}
            />
        </Modal>
        <ViewerMenuWrapper>
            <ViewerMenuHeader handleQuestion={setShowQuestionModal} questionType={connected}
                              text={"Questions"} sideText={String(questions.length)}/>
            <ViewerInnerWrapper>
                <div>
                    <section className="relative">


                        <section className=" bg-white w-[27%] ">
                            <div className="flex items-center text-xs font-medium gap-x-4 text-gray60">
                                <p
                                    className={` cursor-pointer ${
                                        tab === 0 && " font-bold text-primary"
                                    } `}
                                    onClick={() => setTab(0)}
                                >
                                    ALL
                                </p>
                                <p
                                    className={` cursor-pointer ${
                                        tab === 1 && " font-bold text-primary"
                                    } `}
                                    onClick={() => setTab(1)}
                                >
                                    UNANSWERED
                                </p>
                                <p
                                    className={` cursor-pointer ${
                                        tab === 2 && " font-bold text-primary"
                                    } `}
                                    onClick={() => setTab(2)}
                                >
                                    {" "}
                                    ANSWERED
                                </p>
                            </div>
                        </section>

                        <section className="mt-4">
                            {isLoading && (
                                <div className="flex items-center justify-center w-full mt-20">
                                    <ClipLoader size={20} color="#FF6929"/>
                                </div>
                            )}

                            {!isLoading && (
                                <div>
                                    {questions?.length > 0 &&
                                        questions?.map((question, index) => {
                                            return (
                                                //@ts-ignore
                                                <QuestionCard
                                                    isViewer={true}
                                                    connection={connection}
                                                    question={question}
                                                    key={index}
                                                    presentationId={presentationId}

                                                />
                                            );
                                        })}

                                    {questions.length < 1 && (
                                        <div className="h-[60vh] flex flex-col items-center justify-center">
                                            <img src={empty} alt="no viewer"/>
                                            <p className="text-lg font-medium">No question on the list</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </section>
                    </section>
                </div>
            </ViewerInnerWrapper>
        </ViewerMenuWrapper>
    </>
}