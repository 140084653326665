import React, {useState} from 'react';

//assets
import add from 'assets/svg/addImg.svg';
// import globe from 'assets/svg/globe2.svg';
import folder from 'assets/svg/folder3.svg';
import googleDrive from 'assets/svg/google-drive.svg';
import closeIcon from 'assets/svg/close-icon.svg';
import briefcaseIcon from 'assets/svg/briefcase-icon.svg';

//libraries
import {Modal} from 'antd';

//components
// import UploadFromComputer from './UploadFromComputer';
import UploadFromWorkspace from './UploadFromWorkspace';

import UploadDocuments from './UploadDocuments';

interface Props {
    closeAddFileModal: () => void;
    spaceId?: string;
    groupId?: string;
    presentationId?: string;
  fromEvent?: boolean;
  eventFunc?: (upload: () => void) => void;
    fromPresentation?: boolean;
}

export default function AddFile({
                                    closeAddFileModal,
                                    spaceId,
                                    groupId,
                                    presentationId,
  eventFunc,
  fromEvent,
                                    fromPresentation
                                }: Props) {
    // const fromComputerRef = useRef();

    //usestate
    const [openViewersDrawer, setOpenViewersDrawer] = useState(false);
    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);

    //functions

    const handleFileUploadCancel = () => {
        setIsFileUploadModalOpen(false);
        // if (fromComputerRef.current) {
        //   fromComputerRef.current.resetModal();
        // }
    };
    const onClose = () => {
        setOpenViewersDrawer(false);
        closeAddFileModal();
    };

    return (
        <section>
            <div className=' flex justify-between items-center'>
                <img src={briefcaseIcon} alt=''/>
                <img
                    src={closeIcon}
                    alt='close'
                    className='cursor-pointer'
                    onClick={closeAddFileModal}
                />
            </div>
            <div className=''>
                <h5 className='mt-4 font-semibold text-2xl '>Add files</h5>
                <p className='mt=2'>
                    You can upload from your device, web or existing files in the folder
                </p>
            </div>
            <section className=' mx-auto py-10 '>
                <section className='grid grid-cols-1 gap-x-5 gap-y-4'>
                    <div
                        onClick={() => setOpenViewersDrawer(true)}
                        className='flex px-5 text-sm text-center border-[#E2E2E2] border rounded-lg cursor-pointer gap-x-4 bg-[#F6F3EF] items-center h-[88px]'
                    >
                        <div className=''>
                            <img src={folder} alt='upload from folder' className='w-9'/>
                        </div>
                        <p>Upload from folder</p>
                    </div>

                    <div
                        onClick={() => setIsFileUploadModalOpen(true)}
                        className='flex px-5 text-sm text-center border-[#E2E2E2] border rounded-lg cursor-pointer gap-x-4 bg-[#F6F3EF] items-center h-[88px]'
                    >
                        <div className=''>
                            <img src={add} alt='upload from folder' className='w-8'/>
                        </div>
                        <p>Upload from computer</p>
                    </div>

                    <div
                        className='flex px-5 text-sm text-center border-[#E2E2E2] border rounded-lg cursor-pointer gap-x-4 bg-[#F6F3EF] items-center h-[88px]'>
                        <div className=''>
                            <img src={googleDrive} alt='upload from folder' className='w-8'/>
                        </div>
                        <p>Google drive</p>
                    </div>
                </section>
            </section>

            <>
                {/* Modal */}

        <Modal
          open={isFileUploadModalOpen}
          onCancel={handleFileUploadCancel}
          footer={null}
          closeIcon={<img src={closeIcon} alt='close' />}
        >
          <UploadDocuments
            closeModal={handleFileUploadCancel}
            spaceId={spaceId}
            groupId={groupId}
            fromEvent={true}
            eventFunc={eventFunc}
          />
        </Modal>
        {/*  */}

                {/* Drawer  */}
                <Modal
                    closable={false}
                    onCancel={onClose}
                    width='85%'
                    open={openViewersDrawer}
                    // open={true}
                    footer={null}
                    centered
                    // onCancel={closeAddFileModal}
                >
                    <UploadFromWorkspace
                        fromPresentation={fromPresentation}
                        closeAddFileModal={closeAddFileModal}
                        closeUploadFromWorkspaceModal={onClose}
                        groupId={groupId}
                        presentationId={presentationId}
            fromEvent={true}
            eventFunc={eventFunc}
                    />
                </Modal>

                {/*  */}
            </>
        </section>
    );
}
