import { PollQuestion } from 'interfaces/PollSetup';
import React, { Dispatch, SetStateAction } from 'react';

interface Props {
  activeQuestion: PollQuestion;
  setActiveQuestion: Dispatch<SetStateAction<PollQuestion>>;
}

export default function QuestionInput({
  activeQuestion,
  setActiveQuestion,
}: Props) {
  return (
    <div>
      <p className='mb-2'>QUESTION</p>
      <textarea
        name='question'
        id='question'
        className='h-[110px] w-full resize-none bg-[#F6F3EF] rounded-md p-3'
        placeholder='Type your questions'
        value={activeQuestion.question}
        onChange={(e) =>
          setActiveQuestion((prevQuestion) => {
            return {
              ...prevQuestion,
              question: e.target.value,
            };
          })
        }
      ></textarea>
    </div>
  );
}
