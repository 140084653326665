import {localStorageSetter} from "utils/localStorageServices";

export const joinPresentationUseCase = (
    presentationId,
    eventName,
    status,
    message,
    presenterConnectionId,
    navigate,
    setIsLoading,
    setNextStage,
    setReference,
    setLoadPage,
    isEvent
) => {
    eventName = eventName?.toLowerCase();
    console.log(
        presentationId,
        eventName,
        status,
        message,
        "join presentation argument"
    );

    const reference = message?.message?.toLowerCase();
    const isInvited = message?.isInvited;
    const confirmedUser = message?.confirmedUser;
    const waitingToBeLetIn =
        "the presenter has been notified and will let you in";

    const hasntStarted =
        "the meeting starts soon and the presenter will let you in";
    const allowedIn = "meeting joined";

    if (status) {
        if (reference === "the presentation has ended") {
            navigate("/presentation-ended");
            setIsLoading(false);
            return;
        }
        console.log(presenterConnectionId, "Presenter Connection Id")
        localStorage.setItem("connectionId", presenterConnectionId ?? "");

        //attend event-response
        if (eventName === "attend") {
            setLoadPage(true);
            setIsLoading(false);
            localStorageSetter("gottenOTP", true);
            if (reference === hasntStarted || reference === waitingToBeLetIn) {
                setNextStage("waiting");
                return;
            }

            if (reference === allowedIn) {
                navigate(isEvent ? `/viewer-mode-new/${presentationId}?isEvent=${isEvent}` : `/viewer-mode-new/${presentationId}`);
                return;
            }

            if (reference?.length <= 6) {
                setLoadPage(true);
                setNextStage(true);
                setReference(reference);
                return;
            }
        }

        //confirm-otp event-response
        if (eventName === "confirmaccessotp") {
            if (!isInvited) {
                setNextStage("waiting");
            }

            if (
                message === "The meeting starts soon and the presenter will let you in"
            ) {
                setNextStage("waiting");
                setIsLoading(false);
            }

            if (message?.toLowerCase() === "meeting joined") {
                navigate(isEvent ? `/viewer-mode-new/${presentationId}?isEvent=${isEvent}` : `/viewer-mode-new/${presentationId}`);
            }

            if (message === "The presenter has been notified and will let you in") {
                setNextStage("waiting");
                setIsLoading(false);
            }
        }
    }

    //status is false
    if (!status) {
        if (reference === "the presentation has ended") {
            navigate("/presentation-ended");
            return;
        }

        // if (reference === "presentation not started") {
        //   setNextStage("waiting");
        //   return;
        // }
    }
    setIsLoading(false);
};
