import {ButtonInterface} from "interfaces/interfaces-ui";
import React from "react";
import {useNavigate} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import arrowLeft from "assets/svg/arrowLeftPlain.svg";

export const CButton = ({
                            loading,
                            text,
                            icon,
                            iconPosition = "right",
                            variant = "plain",
                            action,
                            fullWidth,
                            isSubmit,
                            longer
                        }: ButtonInterface) => {
    return (
        <button
            disabled={loading}
            onClick={action}
            type={isSubmit ? "submit" : "button"}
            className={`${
                variant === "plain"
                    ? "border border-[#AFAFAF]  bg-transparent text-[#545454]"
                    : variant === "grey"
                        ? "bg-[#F5F5F5] border border-[#EEEEEE]"
                        : variant === "dark"
                            ? "bg-[#000000] text-white"
                            : variant === "orangePlain"
                                ? "border border-[#FF6929] text-[#FF6929]"
                                : variant === "orangeBg"
                                    ? "border bg-[#FF6929] text-white"
                                    : ""
            }  px-4 ${longer ? 'py-3' : 'py-2'}  ${
                fullWidth && "w-full "
            }  rounded-lg font-grotesk font-semibold lg:text-sm text-xs flex justify-center items-center gap-2 `}
        >
            {loading ? (
                <ClipLoader
                    size={20}
                    color={
                        variant === "dark"
                            ? "#fff"
                            : variant === "grey" || variant === "plain"
                                ? "#ED6E33"
                                : "white"
                    }
                />
            ) : (
                <>
                    {icon && iconPosition === "left" && (
                        <span>
              <img alt={""} src={icon}/>
            </span>
                    )}
                    {text}
                    {icon && iconPosition === "right" && (
                        <span>
              <img alt={""} src={icon}/>
            </span>
                    )}
                </>
            )}
        </button>
    );
};

export const BackButton = () => {
    const navigate = useNavigate();
    return (
        <>
            <button
                onClick={() => navigate(-1)}
                className="flex items-center gap-1 py-2 px-4 border rounded-xl  "
            >
        <span>
          <img alt={""} src={arrowLeft}/>
        </span>
                <p className="text-xs text-[#0E0E0E] font-semibold">Go back</p>
            </button>
        </>
    );
};
