import { Poll, PollQuestion } from 'interfaces/PollSetup';
import { Dispatch, SetStateAction } from 'react';
import chevronDown from 'assets/svg/chevron-down.svg';
import { VariableQuestionTypes } from './MainContent';
import MultiQuestion from './QuestionType/MultiQuestions/MultiQuestion';
import quiz1 from 'assets/svg/quiz1.svg';
import worldCloud from 'assets/svg/wordCloud.svg';
import quizzes from 'assets/svg/quiz3.svg';

interface Props {
  setPoll: Dispatch<SetStateAction<Poll>>;
  activeQuestion: PollQuestion;
  setActiveQuestion: Dispatch<SetStateAction<PollQuestion>>;
  handleSave: () => void;
}

export default function QuestionType({
  setPoll,
  activeQuestion,
  setActiveQuestion,
  handleSave,
}: Props) {
  const questionTypes: VariableQuestionTypes[] = [
    {
      title: 'Multi question',
      image: quiz1,
      type: 'Multi Choice',
    },
    {
      title: 'World cloud',
      image: worldCloud,
      type: 'Word Cloud',
    },
    {
      title: 'Quizzes',
      image: quizzes,
      type: 'Quiz',
    },
    {
      title: 'True or False',
      image: quizzes,
      type: 'True Or False',
    },
  ];

  return (
    <section className='col-span-3 h-full overflow-hidden pb-[90px]'>
      <div className='w-full bg-white p-5 rounded-md max-h-full overflow-hidden overflow-y-scroll hide-scrollbar'>
        <p className='text-[##667084] font-semibold text-sm'>
          SELECT QUESTION TYPE
        </p>
        {/* Questions */}
        <section className='mt-3 grid grid-cols-1 text-sm font-semibold text-[##333333] gap-y-3'>
          {questionTypes.map((item, index) => (
            <div key={index}>
              {(activeQuestion.questionType === 'default' ||
                activeQuestion.questionType === item.type) && (
                <div
                  key={item.title}
                  className={`rounded-md border py-2 px-3 flex justify-between items-center cursor-pointer ${
                    activeQuestion.questionType === item.type
                      ? 'bg-[#F2F2F2]'
                      : ''
                  }`}
                  onClick={() =>
                    setActiveQuestion((prevQuestion) => {
                      return {
                        ...prevQuestion,
                        questionType:
                          prevQuestion.questionType === 'default'
                            ? item.type
                            : 'default',
                      };
                    })
                  }
                >
                  <div className='flex items-center gap-x-3'>
                    <img
                      src={item.image}
                      alt='multi question'
                      className='w-[26px] h-[26px]'
                    />
                    <p>{item.title}</p>
                  </div>

                  <img
                    src={chevronDown}
                    alt='multi question'
                    className={`
                          ${
                            activeQuestion.questionType === item.type
                              ? ''
                              : 'hidden'
                          }
                          `}
                  />
                </div>
              )}
            </div>
          ))}

          {activeQuestion.questionType === 'Multi Choice' && (
            <MultiQuestion
              activeQuestion={activeQuestion}
              setActiveQuestion={setActiveQuestion}
              handleSave={handleSave}
            />
          )}

          {/* {activeQuestion.questionType === 'Word Cloud' && (
            <WordCloud
              setQuestion={setQuestion}
              question={question}
              questionsList={questionsList}
              setQuestionsList={setQuestionsList}
            />
          )} */}
        </section>
      </div>
    </section>
  );
}
