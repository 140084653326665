import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//assets
import trash from 'assets/svg/trash.svg';
import search from 'assets/svg/search.svg';
import rename from 'assets/svg/rename.svg';
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import sortIcon from 'assets/svg/sortIcon.svg';
import addGray from 'assets/svg/add-circle-gray.svg';
import addWhite from 'assets/svg/add-circle-white.svg';
import presentNow from 'assets/svg/presentNow.svg';
import addCircle from 'assets/svg/add-circle2.svg';
import arrowDown from 'assets/svg/arrow-down-white.svg';
import folderIcon from 'assets/svg/folder-icon2.svg';
import presentIcon from 'assets/svg/presention-chart-icon.svg';
import archiveIcon from 'assets/svg/archive-icon.svg';
import downloadIcon from 'assets/svg/download-icon.svg';
import {toast} from 'sonner';
// import avatar from "assets/svg/avatar.svg";
//components
import EmptyState from './EmptyState';
import Loader from 'components/UI/Loader';
// import FileThumbnail from './FileThumbnail';
import ConfirmationModal from 'components/UI/Modal/ConfirmationModal';
// import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import SetPresentationTime from 'views/Admin/Presentation/Modals/SetTime';

//libraries
import {Column} from 'primereact/column';
import {Dropdown, Menu, Modal, Tooltip} from 'antd';
import {DataTable} from 'primereact/datatable';
import {FilterMatchMode} from 'primereact/api';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

//slices
import {
    deleteFolder,
    getFolderFiles,
    setAddToBriefcaseModalOpen,
    setSelectedFiles,
    updateDocument,
} from 'store/Workspace/workspaceSlice';
import {deleteDocument} from 'store/Presentations/presentationSlice';
import {getFileStructureState, pushOpenedFolder, setMostActiveFolder,} from 'store/FileStructure/fileStructureSlice';
import UpgradeAccount from 'components/Admin/Team/UpgradeAccount';
import {DateFromNow} from 'utils/formatDate';
import FileThumbnail from 'components/Admin/BriefCase/FileThumbnail';
import 'store/Briefcase/briefcaseSlice';
import RenameModal from 'components/UI/Modal/RenameModal';
import AddToBriefcase from 'views/Admin/Workspace/Modal/AddToBriefcase';
import Axios from 'lib/axiosInterceptor';
import instance from 'lib/axiosInterceptor';
import {addPresentationDocument} from 'store/Events/EventsSlice';
import {CButton} from '../../../UI/Button/Buttons';

export default function ContentPage({
                                        setUploadSelectedFile,
                                        handleBulkDocumentUpload,
                                        fromEvent,
                                        eventFunc,
                                        // selectedFiles,
                                        // setSelectedFiles,
                                        fromPresentation,
                                        presentationId,
                                    }) {
    console.log('FromPresentation ', fromPresentation);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {pathname} = useLocation();
    const {spaceId, eventId} = useParams();

    //useref
    const setPresentationTimeRef = useRef();
    const selectedFile = useRef(null);

    const selectedFiles = useSelector((state) => state.workspace.selectedFiles);
    const addToBriefcaseModalOpen = useSelector(
        (state) => state.workspace.addToBriefcaseModalOpen
    );

    const {mostActiveFolder} = useSelector(getFileStructureState);
    const data = useSelector(
        (state) =>
            state.workspace.folderFiles.data[mostActiveFolder?.id]?.files ??
            state.workspace.workspaceFolders.tableData?.[mostActiveFolder?.id]?.files
    );

    const isLoading = useSelector(
        (state) => state.workspace.folderFiles?.isLoading
    );

    //usestates
    const [isUpgraded] = useState(true);
    const [documentList, setDocumentList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [presentationDoc, setPresentationDoc] = useState(null);
    const [selectedDocumentId, setSelectedDocumentId] = useState(null); // for some reason, this holds the selected file and not the ID
    const [confirmRenameDocument, setConfirmRenameDocument] = useState(false);
    const [confirmRenameFolder, setConfirmRenameFolder] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isRenameLoading, setIsRenameLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [confirmFolderDelete, setConfirmFolderDelete] = useState(false);
    const [isFolderDeleteLoading, setIsFolderDeleteLoading] = useState(false);
    const [filters, setFilters] = useState({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    });
    const [disableBTN, setDisableBTN] = useState(true);
    const [sortBy, setSortBy] = useState('All files');
    const [addLoading, setAddLoading] = useState(false);

    // const [addToBriefcaseModalOpen, setAddToBriefcaseModalOpen] = useState(false);
    // const [selectedFiles, setSelectedFiles] = useState([]);

    //useffect
    useEffect(() => {
        const {subFolders, documents} = data ?? {};
        if (subFolders || documents) {
            const folders = data?.subFolders
                ?.map((folder) => {
                    const {id, title, dateModified, access = null} = folder ?? {};
                    console.log('folder', folder);
                    return {
                        id,
                        title,
                        lastUpdated: dateModified,
                        access,
                        isFolder: true,
                    };
                })
                ?.filter(
                    (doc) => !doc?.title?.includes('vrecord') //hide audio
                );

            const documents = data?.documents
                ?.map((doc) => {
                    const {
                        id,
                        title,
                        dateModified,
                        access = null,
                        folderId,
                        url,
                    } = doc ?? {};
                    return {
                        id,
                        url,
                        title,
                        access,
                        isFolder: false,
                        folderId: folderId,
                        lastUpdated: dateModified,
                    };
                })
                ?.filter((doc) => !doc?.title?.includes('vrecord'));
            //hide audio

            setDocumentList([...folders, ...documents]);
        } else if (data?.length > 0) {
            const list = data?.map((doc) => {
                const {id, title, dateModified, access = null} = doc ?? {};
                return {
                    id,
                    title,
                    access,
                    isFolder: true,
                    lastUpdated: dateModified,
                };
            });
            setDocumentList(list);
        }
    }, [data]);

    function sortAphabetically(items) {
        return [...items].sort((a, b) => a.title.localeCompare(b.title));
    }

    const handleDownload = async () => {
        try {
            const doc = selectedFile.current;
            const match = doc.url.match(/\.([a-zA-Z0-9]+)$/);
            const fileExtension = match ? match[1] : null;
            const response = await Axios.get(`Document/Download/${doc.id}`, {
                responseType: 'arraybuffer',
            });

            if (response) {
                const url = URL.createObjectURL(new Blob([response.data]));
                console.log(url);
                // Create a link element
                const link = document.createElement('a');
                link.href = url;
                link.download = `${doc.title}.${fileExtension}`; // Set the filename (adjust the extension accordingly)
                link.rel = 'noopener noreferrer';
                document.body.appendChild(link);
                // Trigger the download
                link.click();
                // Clean up
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const filterItems = [
        {
            key: '1',
            action: 'All files',
            label: (
                <div
                    className='flex gap-x-2 py2 pl-2 pr-5'
                    onClick={() => {
                        const sortedItems = documentList.sort((a, b) => {
                            const unixTimeA = Date.parse(a.lastUpdated); // Convert to milliseconds since the UNIX epoch
                            const unixTimeB = Date.parse(b.lastUpdated);

                            return unixTimeB - unixTimeA; // Sort in ascending order
                        });
                        setDocumentList(sortedItems);
                        setSortBy('All files');
                    }}
                >
                    <p>All files</p>
                </div>
            ),
        },
        {
            key: '2',
            action: 'aphabetically',
            label: (
                <div
                    className='flex gap-x-2 py2 pl-2 pr-5'
                    onClick={() => {
                        setDocumentList(sortAphabetically(documentList));
                        setSortBy('A -  Z');
                    }}
                >
                    <p>A - Z</p>
                </div>
            ),
        },
    ];

    const items = [
        {
            key: '1',
            label: (
                <div
                    className='flex gap-x-2'
                    onClick={() => {
                        setPresentationDoc(selectedFile.current?.id);
                        setIsModalOpen(true);
                    }}
                >
                    <img src={presentNow} alt='create new folder' className='w-4'/>
                    <p>Prepare Presentation</p>
                </div>
            ),
        },

        {
            key: '3',
            label: (
                <div
                    className='flex gap-x-2'
                    onClick={() =>
                        selectedDocumentId?.isFolder
                            ? setConfirmRenameFolder(true)
                            : setConfirmRenameDocument(true)
                    }
                >
                    <img src={rename} alt='rename file' className='w-4'/>
                    <p>Rename file</p>
                </div>
            ),
        },

        {
            key: '4',
            label: (
                <div
                    className='flex gap-x-2'
                    onClick={() => setAddToBriefcaseModalOpen(true)}
                >
                    <img src={addCircle} alt='access control' className='w-4'/>
                    <p>Add to Briefcase</p>
                </div>
            ),
        },

        {
            key: '5',
            action: 'download',
            label: (
                <div className='flex gap-x-2' onClick={handleDownload}>
                    <img src={downloadIcon} alt='download file' className='w-4'/>
                    <p>Download</p>
                </div>
            ),
        },

        {
            key: '6',
            label: (
                <div
                    className='flex gap-x-2'
                    onClick={() =>
                        selectedDocumentId?.isFolder
                            ? setConfirmFolderDelete(true)
                            : setConfirmDelete(true)
                    }
                >
                    <img src={trash} alt='delete file' className='w-4'/>
                    <p>Delete</p>
                </div>
            ),
        },
    ];

    const more_action_items = [
        {
            key: '1',
            label: (
                <div
                    className='flex gap-x-2'
                    onClick={() => dispatch(setAddToBriefcaseModalOpen(true))}
                >
                    <img src={folderIcon} alt='create new folder' className='w-4'/>
                    <p>Add to briefcase</p>
                </div>
            ),
        },

        {
            key: '2',
            label: (
                <div className='flex gap-x-2'>
                    <img src={presentIcon} alt='move file' className='w-4'/>
                    <p>Present files</p>
                </div>
            ),
        },

        {
            key: '3',
            label: (
                <div className='flex gap-x-2'>
                    <img src={archiveIcon} alt='rename file' className='w-4'/>
                    <p>Archive</p>
                </div>
            ),
        },

        {
            key: '4',
            label: (
                <div className='flex gap-x-2'>
                    <img src={trash} alt='access control' className='w-4'/>
                    <p>Delete</p>
                </div>
            ),
        },
    ];

    const columns = [
        {
            sortable: true,
            field: 'title',
            header: 'File name',
            body: (file) => (
                <div>
                    <div className='h-[32px] flex gap-x-4 cursor-pointer items-center'>
                        <FileThumbnail
                            file={file}
                            width={32}
                            height={32}
                            imgTagheight={32}
                            imgTagwidth={32}
                        />
                        {file?.title?.length > 50 ? (
                            <Tooltip
                                title={<p className='text-black capitalize'>{file.title}</p>}
                                color='white'
                                placement='top'
                            >
                                <p className='text-xs'>{`${file.title.slice(0, 50)}${
                                    file.title.length > 50 ? '...' : ''
                                }`}</p>
                            </Tooltip>
                        ) : (
                            <p className='text-xs'>{file.title}</p>
                        )}
                        {/* <p>{file.title}</p> */}
                    </div>
                </div>
            ),
        },
        {
            field: 'dateAdded',
            sortable: true,
            header: 'Last updated',
            body: (rowData) => {
                return (
                    <div className=''>
                        <p>{DateFromNow(rowData?.lastUpdated ?? new Date())}</p>
                    </div>
                );
            },
        },

        {
            field: '',
            header: '',
            body: (file) => {
                return (
                    <section
                        onClick={(e) => {
                            e.stopPropagation();
                            selectedFile.current = file;
                        }}
                        className='w-[100px] flex justify-end cursor-pointer'
                    >
                        <Dropdown
                            trigger={['click']}
                            dropdownRender={(menu) => (
                                <Menu>
                                    {items.map((item, index) => (
                                        <Menu.Item
                                            key={item.key}
                                            onClick={() => {
                                                setSelectedDocumentId(file);
                                                // item?.onClick(file);
                                            }}
                                        >
                                            {file.isFolder && item.key === 1 ? '' : item.label}
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            )}
                        >
                            <div className='cursor-pointer py-1'>
                                <img src={ellipsis} alt='options' className=''/>
                            </div>
                        </Dropdown>
                    </section>
                );
            },
        },
    ];

    const handleCancel = () => {
        setIsModalOpen(false);
        setPresentationTimeRef.current.resetModal();
    };
    const closeConfirmDelete = () => {
        setConfirmDelete(false);
    };
    const closeFolderConfirmDelete = () => {
        setConfirmFolderDelete(false);
    };

    const deleteWorkspaceFolder = () => {
        setIsFolderDeleteLoading(true);
        dispatch(deleteFolder(selectedDocumentId?.id)).then(({type}) => {
            setIsFolderDeleteLoading(false);

            if (type.includes('fulfilled')) {
                closeFolderConfirmDelete();
                toast.success('Folder deleted successfully');
                window.location.reload();
                // to be looked at
                dispatch(getFolderFiles(mostActiveFolder?.id));
            }
        });
    };

    const trashPresentation = () => {
        setIsDeleteLoading(true);
        let isArchive = false;
        const selectedDocument = selectedDocumentId?.id;


        //highlight

        dispatch(deleteDocument({selectedDocument, isArchive})).then(
            ({type}) => {
                setIsDeleteLoading(false);


                if (type.includes('fulfilled')) {
                    closeConfirmDelete();
                    toast.success('Document deleted successfully');

                    dispatch(getFolderFiles(mostActiveFolder?.id));
                }
            }
        );
    };

    const openFolder = ({data}) => {
        // console.log(data);
        const {id, isFolder, title} = data ?? {};

        //highlight
        if (fromPresentation) {
            return
        }

        if (isFolder) {
            dispatch(pushOpenedFolder({id, title}));
            dispatch(setMostActiveFolder(data));
            dispatch(getFolderFiles(id));
            return;
        }

        // if a file has been selected, disable individual file uplaod
        if (spaceId) {
            if (selectedFiles.length > 0) {
                return;
            } else {
                pathname?.includes('mybriefcase/details') &&
                setUploadSelectedFile(data);
            }
        } else if (
            pathname?.includes('presentation-details') ||
            pathname?.includes('mybriefcase/details') ||
            pathname?.includes('events/details')
        ) {
            // if adding from presentation detail or mybriefcase details
            setUploadSelectedFile(data);
        } else {
            navigate(`/admin/workspace/details/${id}`);
        }
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;

        let _filters = {...filters};

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const [titleName, setTitleName] = useState('');

    const closeRenameModal = () => {
        setConfirmRenameDocument(false);
    };

    const handleRenameDocument = () => {
        setIsRenameLoading(true);

        if (selectedFile.current) {
            const {id, folderId} = selectedFile.current;

            const formData = new FormData();
            formData.append('Title', titleName);
            formData.append('DocumentId', id);
            formData.append('FolderId', folderId);
            dispatch(updateDocument(formData)).then(({type}) => {
                if (type.includes('fulfilled')) {
                    toast.success('Document renamed successfully');
                    // to be looked at
                    dispatch(getFolderFiles(mostActiveFolder?.id));
                }
                setIsRenameLoading(false);
                closeRenameModal();
            });
        }
    };

    const handleCloseAddToBriefcaseModal = () => {
        dispatch(setAddToBriefcaseModalOpen(false));
    };
    const DataTableConfig = {
        value: documentList,
        paginator: true,
        rows: 30,
        className: 'text-xs',
        selectionMode: 'checkbox',
        selection: selectedFiles,
        tableStyle: {minWidth: '40rem'},
        onRowClick: openFolder,
        onSelectionChange: (e) => {
            console.log('setSelectedFiles:', setSelectedFiles);
            dispatch(setSelectedFiles?.(e.value));
            e.value.length < 1 ? setDisableBTN(true) : setDisableBTN(false);
        },
        filters: filters,
        globalFilterFields: ['title'],
    };

    const handleAddFileToPresentation = async () => {
        setAddLoading(true);
        if (!selectedFiles.length) {
            setAddLoading(false);
            return;
        }
        // Now, curate the data and then work with it
        const documentPayload = selectedFiles.map((item, index) => {
            return {
                title: item.title,
                position: index,
                documentId: item.id,
            };
        });
        try {
            const result = await instance.post('/Presentation/AddDocument/Multiple', {
                publicationId: presentationId,
                documents: documentPayload,
            });
            toast.success('Documents added to presentation');
            navigate(`/presentation-details/${presentationId}`);
        } catch (e) {
        } finally {
            setAddLoading(false);
        }
    };
    return (
        <section>
            {/* search & filter */}
            {/* Add selected files to briefcase */}
            {spaceId || eventId ? (
                <section className='flex w-full justify-between'>
                    <section className='flex gap-x-3 items-center'>
                        <div
                            className='flex justify-center items-center w-full h-8 rounded-md gap-x-2 bg-gray30 cursor-pointer px-3'>
                            <img src={sortIcon} alt='sort'/>
                            <p className='text-[#545454] text-sm font-medium'>All files</p>
                        </div>

                        <section className={`w-full justify-end flex`}>
                            {mostActiveFolder !== 'team' && (
                                <div className='flex items-center w-56 px-4 text-xs rounded-md gap-x-2 bg-gray30'>
                                    <img src={search} alt='sort'/>
                                    <input
                                        placeholder=' search folders'
                                        value={globalFilterValue}
                                        onChange={onGlobalFilterChange}
                                        className='bg-transparent h-8 outline-none w-full'
                                    />
                                </div>
                            )}
                        </section>
                    </section>

                    <section className=''>
                        <button
                            className={`h-8 flex items-center gap-x-2 ${
                                disableBTN
                                    ? 'bg-[#E2E2E2] text-[#AFAFAF] cursor-not-allowed'
                                    : 'bg-black text-white'
                            } px-5 rounded-md  font-medium`}
                            disabled={disableBTN}
                            onClick={async () => {
                                if (fromEvent && eventFunc) {
                                    const uploadToEvent = async (presentationId) => {
                                        // Upload each file in parallel
                                        const uploadPromises = selectedFiles.map(
                                            async (fileData, index) => {
                                                const formData = new FormData();

                                                // Dispatch the file upload

                                                if (fromEvent) {
                                                    // formData.append('file', fileData.file);
                                                    formData.append(
                                                        'presentationId',
                                                        presentationId ?? ''
                                                    );
                                                    formData.append('documentTitle', fileData.title);
                                                    formData.append('DocumentId', fileData.id);
                                                    formData.append('position', index.toString());

                                                    // formData.append('DocumentId', document.id);
                                                    // formData.append('Title', document.title);

                                                    return dispatch(addPresentationDocument(formData));
                                                }
                                            }
                                        );

                                        // Wait for all uploads to complete
                                        await Promise.all(uploadPromises);
                                    };

                                    eventFunc(uploadToEvent);
                                } else {
                                    handleBulkDocumentUpload(selectedFiles);
                                }
                            }}
                        >
                            <img src={disableBTN ? addGray : addWhite} alt=''/>
                            Add selected files
                        </button>
                    </section>
                </section>
            ) : (
                <section className='w-full flex justify-between'>
                    {/* sort by */}
                    <Dropdown
                        trigger={['click']}
                        dropdownRender={(menu) => (
                            <Menu>
                                {filterItems.map((item) => {
                                    return <Menu.Item key={item.key}>{item.label}</Menu.Item>;
                                })}
                            </Menu>
                        )}
                    >
                        <div
                            className='flex items-center w-28 h-8 rounded-md gap-x-2 bg-gray30 py-1 cursor-pointer px-3'>
                            <img src={sortIcon} alt='sort'/>
                            <p className='text-[#545454] text-sm font-medium'>{sortBy}</p>
                        </div>
                    </Dropdown>

                    <section className={`w-ful flex`}>
                        {/* More action */}
                        {selectedFiles.length > 0 && (
                            <section className='mr-2'>
                                <Dropdown
                                    trigger={['click']}
                                    dropdownRender={(menu) => (
                                        <Menu>
                                            {more_action_items.map((item) => {
                                                return (
                                                    <Menu.Item
                                                        key={item.key}
                                                        // onClick={() => {
                                                        //   // setFileIndex(options.rowIndex);
                                                        //   // handleOptionsClick(options.rowIndex, item.action);
                                                        // }}
                                                    >
                                                        {item.label}
                                                    </Menu.Item>
                                                );
                                            })}
                                        </Menu>
                                    )}
                                >
                                    {fromPresentation ? (
                                        <CButton
                                            text={'Add Files to presentation'}
                                            variant={'dark'}
                                            action={handleAddFileToPresentation}
                                            loading={addLoading}
                                        />
                                    ) : (
                                        <section
                                            className='w-36 text-white bg-black flex items-center gap-x-2 justify-center rounded-md py-2 cursor-pointer'>
                                            <p className='text-sm font-medium'>More action</p>

                                            <img src={arrowDown} alt='more options'/>
                                        </section>
                                    )}
                                </Dropdown>
                            </section>
                        )}

                        {mostActiveFolder !== 'team' && (
                            <div
                                className='flex items-center self-end justify-end w-56 px-4 text-xs rounded-md gap-x-2 bg-gray30 py-2'>
                                <img src={search} alt='sort'/>
                                <input
                                    placeholder=' search folders'
                                    value={globalFilterValue}
                                    onChange={onGlobalFilterChange}
                                    className='bg-transparent w-[90%] py-1 outline-none'
                                />
                            </div>
                        )}
                    </section>
                </section>
            )}
            {/*  */}

            {/* content */}
            {mostActiveFolder === 'team' && <UpgradeAccount/>}

            {mostActiveFolder !== 'team' && (
                <section>
                    {isLoading && <Loader/>}

                    {!isLoading && (
                        <section>
                            {documentList?.length > 0 && (
                                <div className='my-4'>
                                    <DataTable
                                        value={documentList}
                                        paginator
                                        rows={30}
                                        className='text-xs'
                                        selectionMode='checkbox'
                                        selection={selectedFiles}
                                        tableStyle={{minWidth: '40rem'}}
                                        onRowClick={openFolder}
                                        onSelectionChange={(e) => {
                                            console.log('setSelectedFiles', setSelectedFiles);
                                            dispatch(setSelectedFiles(e.value));
                                            e.value.length < 1
                                                ? setDisableBTN(true)
                                                : setDisableBTN(false);
                                        }}
                                        filters={filters}
                                        globalFilterFields={['title']}
                                    >
                                        <Column
                                            selectionMode='multiple'
                                            headerStyle={{width: '3rem'}}
                                        ></Column>
                                        {columns?.map((col, index) => {
                                            if (
                                                pathname
                                                    ?.toLowerCase()
                                                    ?.includes('/presentation-details') &&
                                                col.field === 'options'
                                            ) {
                                                // eslint-disable-next-line array-callback-return
                                                return;
                                            }

                                            if (spaceId && col.header === '') {
                                                // eslint-disable-next-line array-callback-return
                                                return;
                                            }

                                            return (
                                                <Column
                                                    key={index}
                                                    body={col?.body}
                                                    field={col.field}
                                                    header={col.header}
                                                    sortable={col?.sortable}
                                                    className='cursor-pointer'
                                                />
                                            );
                                        })}
                                    </DataTable>
                                </div>
                            )}

                            {documentList?.length < 1 && (
                                <div className='h-[70vh] flex items-center'>
                                    <EmptyState type={'file'}/>
                                </div>
                            )}
                        </section>
                    )}
                </section>
            )}

            {/*  */}

            <ConfirmationModal
                open={confirmDelete}
                isLoading={isDeleteLoading}
                onClose={closeConfirmDelete}
                func={trashPresentation}
                text='Are you sure you want to delete this document?'
            />

            <ConfirmationModal
                open={confirmFolderDelete}
                isLoading={isFolderDeleteLoading}
                onClose={closeFolderConfirmDelete}
                func={deleteWorkspaceFolder}
                text='Are you sure you want to delete this folder?'
            />

            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                // maskClosable={false}
            >
                <SetPresentationTime
                    docId={presentationDoc}
                    closeModal={handleCancel}
                    ref={setPresentationTimeRef}
                />
            </Modal>
            {!selectedFile?.current?.isFolder && (
                <Modal
                    footer={null}
                    centered={false}
                    open={confirmRenameDocument}
                    onCancel={closeRenameModal}
                    style={{
                        top: 20,
                    }}
                    destroyOnClose
                    closeIcon={false}
                >
                    <RenameModal
                        closeModal={closeRenameModal}
                        setTitleName={setTitleName}
                        func={handleRenameDocument}
                        isLoading={isRenameLoading}
                        previousTitle={selectedFile?.current?.title}
                        titleName={titleName}
                        heading='Document title'
                        subHeading='Rename your document'
                        buttonText='Update title'
                    />
                </Modal>
            )}

            {!spaceId && (
                <AddToBriefcase
                    isModalOpen={addToBriefcaseModalOpen}
                    setMododalOpen={handleCloseAddToBriefcaseModal}
                />
            )}
        </section>
    );
}
