import pptxFile from "assets/svg/pptActive.svg"
import pptxFileInactive from "assets/svg/pptxInactive.svg"
import pdfIcon from "assets/svg/pdfInactive.svg"

export const resolveImageType = (active: boolean, fileType: string) => {
    if (fileType.toLowerCase().includes("ppt")) {
        return active ? pptxFile : pptxFileInactive
    } else if (fileType.toLowerCase().includes("pdf")) {
        return active ? pdfIcon : pdfIcon
    }

    return active ? pptxFile : pptxFileInactive
}