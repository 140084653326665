export const localStorageSetter = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const localStorageGetter = (key) => {
    const storedValue = localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key))
        : null;
    return storedValue;
};

export const getUserDetails = () => {
    const user = localStorage.getItem('user');
    if (!user) {
        return null;
    }

    return JSON.parse(user);
};

export const resetTimer = () => {
    localStorage.removeItem('startTime');
    localStorage.removeItem('isActive');
};

export const getToken = () => {
    const user = localStorage.getItem('user');
    if (!user) {
        return null;
    }
    return JSON.parse(user)?.token;
};
export const getEmail = () => {
    const user = localStorage.getItem('user');
    if (!user) {
        return null;
    }
    return JSON.parse(user)?.email;
};

export const getConnectionId = () => {
    const connectionId = localStorage.getItem('connectionId');
    return connectionId ?? '';
};

export const getProjectorConnectionId = () => {
    const connectionId = localStorage.getItem('projectorConnectionId');
    return connectionId ?? ""
}

export const getPresentationId = () => {
    const presentationId = localStorage.getItem('presentationId');
    return presentationId ?? '';
};

export const beginViewerJourney = () => {
    localStorage.setItem('hasViewerJourney', true);
};

export const stopViewerJourney = () => {
    localStorage.setItem('hasViewerJourney', false);
};

export const hasViewerJourneyBegan = () => {
    return JSON.parse(localStorage.getItem('hasViewerJourney', true) ?? false);
};

export const getUnlockedSlides = () => {
    return JSON.parse(localStorage.getItem('unlockedSlides')) ?? [];
};
export const getViewersJourney = () => {
    return JSON.parse(localStorage.getItem('viewersJourney')) ?? [];
};

export const setUnlockedSlides = (id, docId, slideNumber) => {
    //NAVIGATING ACCORDING TO PRESENTER'S MOVEMENT

    // let slides = getUnlockedSlides();
    // let lastEntry = slides?.[slides?.length - 1];
    // if (lastEntry?.docId === docId && lastEntry?.slideNumber === slideNumber) {
    //   return;
    // }
    // slides.push({ id, docId, slideNumber: slideNumber ?? 0 });
    // let newSlides = [...new Set(slides)];
    // localStorage.setItem("unlockedSlides", JSON.stringify(newSlides));
    // if (!hasViewerJourneyBegan()) {
    //   setViewersJourney({ docId, slideNumber: slideNumber ?? 0 });
    // }

    ////////////////////

    //SORT NAVIGATION ACCORDING TO HERACHY OF PLAYLIST

    let slides = getUnlockedSlides();
    const isExist = slides.filter((slide) => {
        return slide?.docId === docId && slide?.slideNumber === slideNumber;
    });

    if (isExist?.length > 0) return;
    slides.push({id, docId, slideNumber: slideNumber ?? 0});

    let newSlides = slides?.sort((a, b) => {
        if (a.docId < b.docId) return -1;
        if (a.docId > b.docId) return 1;
        if (a.slideNumber < b.slideNumber) return -1;
        if (a.slideNumber > b.slideNumber) return 1;
        return 0;
    });
    localStorage.setItem('unlockedSlides', JSON.stringify(newSlides));
    if (!hasViewerJourneyBegan()) {
        setViewersJourney({docId, slideNumber: slideNumber ?? 0});
    }

    /////
};

export const setViewersJourney = (slide) => {
    let slides = getViewersJourney();
    slides.push(slide);
    slides = Array.from(new Set(slides.map((item) => JSON.stringify(item)))).map(
        (str) => JSON.parse(str)
    );
    localStorage.setItem('viewersJourney', JSON.stringify(slides));
};

export const resetUnlockedSlides = () => {
    localStorage.setItem('unlockedSlides', JSON.stringify([]));

    localStorage.setItem('viewersJourney', JSON.stringify([]));
    stopViewerJourney();
};

export const setPresenterActiveDocumentId = (id) => {
    localStorage.setItem('presenterActiveDocId', id);
};

export const getPresenterActiveDocumentId = () => {
    return localStorage.getItem('presenterActiveDocId');
};
