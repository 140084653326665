import {ViewerMenuWrapper} from "./ViewerMenuWrapper";
import {ViewerMenuHeader} from "./ViewMenuHeader";
import {ViewerInnerWrapper} from "./ViewerInnerWrapper";
import {
    EventsInterface,
    InnerPresentationInterface,
    PresentationDisplayInterface,
    SlideInterface
} from "../../../../interfaces/interfaces-data";
import slideTypeActive from "assets/svg/slideTypeActive.svg";
import slideTypeInactive from "assets/svg/slideTypeInactive.svg";
import lineActive from "assets/svg/lineActive.svg";
import lineInActive from "assets/svg/lineInactive.svg"
import {MutableRefObject, useEffect, useState} from "react";
import groupIcon from "assets/svg/groupIcon.svg";
import arrowDown from "assets/svg/arrow-down.svg"
import {resolveImageType} from "../../../../utils/resolveImageType";
import {getFileExtension} from "../../../../utils/getFileExtension";
import groupIconWhite from "assets/svg/agendaIconWhite.svg"
import instance from "../../../../lib/axiosInterceptor";
import {getConnectionId} from "../../../../utils/localStorageServices";
import {documentHasSlides} from "../../../../utils/documentHasSlides";
import {imageStream} from "../../../../utils/imageStream";


export const DesktopViewerPlaylist = ({
                                          presentationDetails,
                                          activeDocumentId,
                                          presentationDisplayDetails,
                                          userOutOfSyncState,
                                          setUserOutOfSyncState,
                                          setActiveIndex,
                                          setActiveDocumentId,
                                          userOutOfSyncRef,
                                          isEvent,
                                          eventDetails,
                                          agendaPlaylist,
                                          agendaPresentationDisplayDetails,
                                          setAgendaPresentationDisplayDetails,
                                          setAgendaPlaylist,
                                          anotherAgendaDocLoading,
                                          setAnotherAgendaDocLoading,
                                          setOutOfSyncPresentationDetails,
                                          outOfSyncPresentationDetails,
                                          outOfSyncDetailsRef,
                                          setOutOfCurrentAgenda,
                                          setOutOfSyncDocId,
                                          setOutOfSyncActiveIndex

                                      }: {
    presentationDetails: InnerPresentationInterface,
    activeDocumentId: string,
    presentationDisplayDetails?: PresentationDisplayInterface,
    userOutOfSyncState: boolean,
    setUserOutOfSyncState: (val: boolean) => void
    setActiveIndex: (val: number) => void
    setActiveDocumentId: (val: string) => void
    userOutOfSyncRef: MutableRefObject<boolean>
    isEvent?: boolean,
    eventDetails?: EventsInterface,
    agendaPlaylist?: InnerPresentationInterface[],
    setAgendaPlaylist: (val: InnerPresentationInterface[]) => void,
    agendaPresentationDisplayDetails?: PresentationDisplayInterface,
    setAgendaPresentationDisplayDetails: (val: PresentationDisplayInterface) => void,
    anotherAgendaDocLoading: boolean,
    setAnotherAgendaDocLoading: (val: boolean) => void,
    outOfSyncPresentationDetails?: PresentationDisplayInterface,
    setOutOfSyncPresentationDetails: (val: PresentationDisplayInterface) => void,
    outOfSyncDetailsRef?: MutableRefObject<PresentationDisplayInterface>,
    setOutOfCurrentAgenda: (val: boolean) => void,
    setOutOfSyncDocId: (val: string) => void,
    setOutOfSyncActiveIndex: (val: number) => void

}) => {
    return <>
        <ViewerMenuWrapper>
            <ViewerMenuHeader text={isEvent ? "Agenda" : "Playlist"}
                              sideText={(isEvent && eventDetails) ? String(eventDetails.agenda.length) : String(presentationDetails.doc.length)}/>
            {isEvent ? <ViewerInnerWrapper>
                <EventPlaylistWrapper
                    presentationDisplayDetails={presentationDisplayDetails}
                    userOutOfSyncRef={userOutOfSyncRef}
                    userOutOfSyncState={userOutOfSyncState}
                    setActiveIndex={setActiveIndex}
                    setActiveDocumentId={setActiveDocumentId}
                    presentationDetails={presentationDetails}
                    eventDetails={eventDetails}
                    activeDocumentId={activeDocumentId}
                    setUserOutOfSyncState={setUserOutOfSyncState}
                    agendaPlaylist={agendaPlaylist}
                    setAgendaPlaylist={setAgendaPlaylist}
                    agendaPresentationDisplayDetails={agendaPresentationDisplayDetails}
                    setAgendaPresentationDisplayDetails={setAgendaPresentationDisplayDetails}
                    setAnotherAgendaDocLoading={setAnotherAgendaDocLoading}
                    anotherAgendaDocLoading={anotherAgendaDocLoading}
                    outOfSyncPresentationDetails={outOfSyncPresentationDetails}
                    outOfSyncDetailsRef={outOfSyncDetailsRef}
                    setOutOfSyncPresentationDetails={setOutOfSyncPresentationDetails}
                    setOutOfCurrentAgenda={setOutOfCurrentAgenda}
                    setOutOfSyncDocId={setOutOfSyncDocId}
                    setOutOfSyncActiveIndex={setOutOfSyncActiveIndex}
                />
            </ViewerInnerWrapper> : <ViewerInnerWrapper>
                {presentationDisplayDetails && Object.keys(presentationDisplayDetails).length && presentationDetails.doc.map(item => {
                    return <div key={item.id}>
                        <PlaylistWrapper
                            presentationDisplayDetails={presentationDisplayDetails}
                            title={item.title}
                            key={item.id}
                            documentId={item.documentId}
                            active={item.documentId === activeDocumentId}
                            userOutOfSyncState={userOutOfSyncState}
                            setUserOutOfSyncState={setUserOutOfSyncState}
                            setActiveIndex={setActiveIndex}
                            setActiveDocumentId={setActiveDocumentId}
                            userOutOfSyncRef={userOutOfSyncRef}

                        />
                    </div>
                })}
            </ViewerInnerWrapper>}

        </ViewerMenuWrapper>
    </>
}

const PlaylistWrapper = ({
                             active,
                             title,
                             presentationDisplayDetails,
                             documentId,
                             userOutOfSyncState,
                             setUserOutOfSyncState,
                             setActiveIndex,
                             setActiveDocumentId,
                             userOutOfSyncRef
                         }: {
    active?: boolean,
    title: string,
    presentationDisplayDetails?: PresentationDisplayInterface,
    documentId: string,
    userOutOfSyncState: boolean,
    setUserOutOfSyncState: (val: boolean) => void
    setActiveIndex: (val: number) => void
    setActiveDocumentId: (val: string) => void
    userOutOfSyncRef: MutableRefObject<boolean>
}) => {
    // Now , the idea here is that when the document is clicked , i expect that the document clicked should automatically go into the first document that has been unlocked
    return <>
        {presentationDisplayDetails && Object.keys(presentationDisplayDetails).length && presentationDisplayDetails[documentId] && presentationDisplayDetails[documentId].find(item => item.unlocked) &&
            <button onClick={() => {
                const firstUnlockedObject = presentationDisplayDetails[documentId].find(item => item.unlocked)
                userOutOfSyncRef.current = true;
                setUserOutOfSyncState(true);
                setActiveDocumentId(documentId)
                // find the index of the first unlocked slide
                const newIndex = presentationDisplayDetails[documentId].findIndex(item => item.unlocked)
                setActiveIndex(newIndex);
            }} className={"py-3 flex justify-start items-start gap-2 w-full"}>
                <div>
                    <span>
                        <img className="w-[28px] h-[28px]" src={active ? slideTypeActive : slideTypeInactive} alt={""}/>
                    </span> <span className="flex items-center justify-center">
                    <img src={active ? lineActive : lineInActive} alt={""}/>
                </span>
                </div>
                <span className={"w-full"}>
                    <p className={`${active ? 'text-[#FF6929]' : 'text-[#000000]'} min-w-[200px] w-[250px] max-w-full font-semibold text-sm text-ellipsis overflow-hidden whitespace-nowrap text-left `}>{title}</p>
                </span>
            </button>}


    </>
}

const EventPlaylistWrapper = ({
                                  eventDetails,
                                  presentationDisplayDetails,
                                  userOutOfSyncState,
                                  setActiveIndex,
                                  setActiveDocumentId,
                                  userOutOfSyncRef,
                                  activeDocumentId,
                                  presentationDetails,
                                  setUserOutOfSyncState,
                                  agendaPlaylist,
                                  agendaPresentationDisplayDetails,
                                  setAgendaPresentationDisplayDetails,
                                  setAgendaPlaylist,
                                  setAnotherAgendaDocLoading,
                                  anotherAgendaDocLoading,
                                  outOfSyncPresentationDetails,
                                  outOfSyncDetailsRef,
                                  setOutOfSyncPresentationDetails,
                                  setOutOfCurrentAgenda,
                                  setOutOfSyncDocId,
                                  setOutOfSyncActiveIndex
                              }: {
    eventDetails?: EventsInterface,
    presentationDisplayDetails?: PresentationDisplayInterface,
    userOutOfSyncState: boolean,
    setActiveIndex: (val: number) => void,
    setActiveDocumentId: (val: string) => void,
    userOutOfSyncRef: MutableRefObject<boolean>,
    activeDocumentId: string,
    presentationDetails: InnerPresentationInterface,
    setUserOutOfSyncState: (val: boolean) => void,
    agendaPlaylist?: InnerPresentationInterface[],
    setAgendaPlaylist: (val: InnerPresentationInterface[]) => void,
    agendaPresentationDisplayDetails?: PresentationDisplayInterface,
    setAgendaPresentationDisplayDetails: (val: PresentationDisplayInterface) => void,
    setAnotherAgendaDocLoading: (val: boolean) => void,
    anotherAgendaDocLoading: boolean,
    outOfSyncPresentationDetails?: PresentationDisplayInterface,
    setOutOfSyncPresentationDetails?: (val: PresentationDisplayInterface) => void,
    outOfSyncDetailsRef?: MutableRefObject<PresentationDisplayInterface>,
    setOutOfCurrentAgenda: (val: boolean) => void,
    setOutOfSyncDocId: (val: string) => void,
    setOutOfSyncActiveIndex: (val: number) => void


}) => {
    const [showContent, setShowContent] = useState(true);
    const [loadingAgendaDetails, setLoadingAgendaDetails] = useState(false)
    const [selectedAgendaIndex, setSelectedAgendaIndex] = useState(-1)
    const [presentationArrayDetails, setPresentationArrayDetails] = useState<InnerPresentationInterface[]>([]);
    const [activeIds, setActiveIds] = useState<string[]>([])

    const getPresentationDetails = async (presentationId: string) => {
        try {
            const result = await instance.get(`/presentation/${presentationId}?presentaConnectionId=${getConnectionId()}`)

            return result.data
        } catch (e) {
            return null
        }
    }

    useEffect(() => {
        (async function handlePresentations() {
            const arrayResult = []
            if (eventDetails) {
                for (const data of eventDetails.agenda) {
                    const result = await getPresentationDetails(data.presentationId ?? "")
                    arrayResult.push(result);
                }
                setPresentationArrayDetails(arrayResult)
            }
        })()
    }, [eventDetails]);

    const getPresentationFromArray = (id: string) => {
        const presentationDetails = presentationArrayDetails.find((item_) => item_.presentation.id === id);
        console.log(presentationDetails, "Presentation Details value here")
        return presentationDetails
    }

    const getOutOfSyncDocDetail = async (documentId: string, presentationId: string) => {
        try {
            setOutOfSyncDocId(documentId);
            setAnotherAgendaDocLoading(true)
            const result = await instance.get(`/Document/${documentId}`);
            if (result.data.slides.length && documentHasSlides(result.data.url)) {
                const reArrangedData = result.data.slides.sort(
                    (a: SlideInterface, b: SlideInterface) => {
                        if (a.position > b.position) {
                            return 1;
                        } else if (a.position < b.position) {
                            return -1;
                        }
                        return 0;
                    }
                );
                // Now Only get the ones that have been unlocked,
                const docPresentationDetails = getPresentationFromArray(presentationId);
                const unlockedDocIds = docPresentationDetails?.presentation.unlocked?.[documentId];

                setOutOfSyncActiveIndex(unlockedDocIds ? unlockedDocIds[0] : 0);
                if (outOfSyncDetailsRef?.current) {
                    // But ideally there would always be an OutOfSyncRef here
                    outOfSyncDetailsRef.current[documentId] = reArrangedData;
                    console.log(unlockedDocIds, "Unlocked Ids here")
                    if (unlockedDocIds && unlockedDocIds.length) {
                        let loadedItem = 0
                        for (const unlockedId of unlockedDocIds) {
                            const docUrl = await imageStream(reArrangedData[unlockedId].id);
                            outOfSyncDetailsRef.current[documentId][unlockedId].imgUrl = docUrl ?? ""
                            outOfSyncDetailsRef.current[documentId][unlockedId].unlocked = true
                            loadedItem += 1
                            if (loadedItem === 4) {
                                if (setOutOfSyncPresentationDetails) {
                                    setOutOfSyncPresentationDetails({...outOfSyncDetailsRef.current})
                                    setAnotherAgendaDocLoading(false);
                                }
                            }
                        }
                        setOutOfSyncPresentationDetails && setOutOfSyncPresentationDetails({...outOfSyncDetailsRef.current});

                    }
                }
            } else {
                const slideObject: SlideInterface[] = [
                    {
                        id: result.data.id,
                        url: result.data.url,
                        position: 1,
                        title: result.data.title,
                        note: result.data.note ?? "",
                        imgUrl: "",
                    }
                ]
                try {
                    slideObject[0]["imgUrl"] = (await imageStream(result.data.id)) ?? "";
                    if (outOfSyncDetailsRef && setOutOfSyncPresentationDetails) {
                        outOfSyncDetailsRef.current[documentId] = slideObject
                        setOutOfSyncPresentationDetails({...outOfSyncDetailsRef.current})
                    }
                    setAnotherAgendaDocLoading(false);
                } catch (e) {

                }
            }
        } catch (e) {

        }
    }
    return <>
        {eventDetails && eventDetails?.agenda.length && eventDetails?.agenda.map((item, index) => {
            return <div key={item.id}>
                <div
                    onClick={() => {
                        if (eventDetails.currentSession && eventDetails.currentSession === item.id) {
                            setShowContent(prev => !prev)
                        } else if (presentationArrayDetails.length && presentationArrayDetails.find(item_ => item_.presentation.id === item.presentationId)?.presentation?.status === 4) {
                            //Now , i need to just show the content of the agenda based on the details
                            if (activeIds.includes(item.presentationId)) {
                                const clonedActiveIds = [...activeIds];
                                const idIndex = clonedActiveIds.indexOf(item.presentationId);
                                clonedActiveIds.splice(idIndex, 1);
                                setActiveIds([...clonedActiveIds])
                            } else {

                                setActiveIds(prev => ([...prev, item.presentationId]))
                            }

                        }
                    }}
                    className={`flex justify-between cursor-pointer p-3 rounded-lg items-center border border-[#D7D7D7]  ${eventDetails.currentSession && eventDetails.currentSession === item.id ? "bg-[#000000] text-white" : "bg-[#F6F3EF]"}`}>
                    <span className={"flex items-center gap-3"}>
                        <img
                            src={eventDetails.currentSession && eventDetails.currentSession === item.id ? groupIconWhite : groupIcon}
                            alt={""}/>
                        <p>{item.title}</p>
                    </span>
                    {eventDetails.currentSession && eventDetails.currentSession === item.id ? <span>
                        <img src={arrowDown} alt={""}/>
                    </span> : presentationArrayDetails.length ? (getPresentationFromArray(item.presentationId)?.presentation.status === 4 &&
                        <span>
                        <img src={arrowDown} alt={""}/>
                    </span>) : ""}
                </div>
                {showContent && eventDetails.currentSession === item.id && <div className={"py-2 mx-4  px-3 border-l"}>
                    {eventDetails.currentSession && eventDetails.currentSession === item.id && presentationDisplayDetails && Object.keys(presentationDisplayDetails).length && presentationDetails.doc.map(item => {
                        return <div key={item.id}>
                            {presentationDisplayDetails[item.documentId] && presentationDisplayDetails[item.documentId].find(item => item.unlocked) &&
                                <button onClick={() => {
                                    const firstUnlockedObject = presentationDisplayDetails[item.documentId].find(item => item.unlocked)
                                    userOutOfSyncRef.current = true;
                                    setUserOutOfSyncState(true);
                                    setActiveDocumentId(item.documentId)
                                    // find the index of the first unlocked slide
                                    const newIndex = presentationDisplayDetails[item.documentId].findIndex(item => item.unlocked)
                                    setActiveIndex(newIndex);
                                }} className={"pb-3 flex justify-start items-start gap-2 w-full"}>
                                    <div>
                                        <img
                                            alt={""}
                                            src={resolveImageType(item.documentId === activeDocumentId, getFileExtension(item.url))}/>
                                    </div>
                                    <span className={"w-full"}>
                                                    <p className={`${item.documentId === activeDocumentId ? 'text-[#FF6929]' : 'text-[#000000]'} min-w-[200px] w-[250px] max-w-full font-semibold text-sm text-ellipsis overflow-hidden whitespace-nowrap text-left `}>{item.title}</p>
                                                </span>
                                </button>}
                        </div>
                    })
                    }
                </div>}
                {/*This is for when the agenda clicked is not the same as what is currently been worked on */}
                {activeIds.includes(item.presentationId) ? <div className={"py-2 mx-4  px-3 border-l"}>
                    {
                        getPresentationFromArray(item.presentationId)?.doc.map(docItem => {
                            const presentationSnapShot = getPresentationFromArray(item.presentationId)
                            return <div key={docItem.id}>
                                {presentationSnapShot?.presentation.unlocked && presentationSnapShot?.presentation?.unlocked?.[docItem.documentId] && <>
                                    <button onClick={() => {
                                        setOutOfCurrentAgenda(true)
                                        userOutOfSyncRef.current = true;
                                        setUserOutOfSyncState(true);
                                        getOutOfSyncDocDetail(docItem.documentId, item.presentationId)
                                    }} className={"pb-3 flex justify-start items-start gap-2 w-full"}>
                                        <div>
                                            <img
                                                alt={""}
                                                src={resolveImageType(true, getFileExtension(docItem.url))}/>
                                        </div>
                                        <span className={"w-full"}>
                                                        <p className={`${false ? 'text-[#FF6929]' : 'text-[#000000]'} min-w-[200px] w-[250px] max-w-full  text-sm text-ellipsis overflow-hidden whitespace-nowrap text-left `}>{docItem.title}</p>
                                                    </span>
                                    </button>
                                </>
                                }
                            </div>


                        })
                    }

                </div> : ""
                }


            </div>
        })}

    </>
}