import React from "react";

//assets
import zap from "assets/svg/zap.svg";
import cards from "assets/svg/blackCards.svg";

export default function BlackBackground() {
  const boxShadowStyle = {
    boxShadow: "0px 0px 0px 3px #333333",
  };

  return (
    <section className=" bg-black20  black-background [ lg:py-20 lg:my-10  py-10 my-8] [  lg:rounded-b-[5rem] rounded-b-[3rem] ]">
      <section className=" mx-auto [  lg:w-[90%] w-[95%] ]">
        {/* first */}
        <section className=" mx-auto [ flex  justify-between ] [ lg:flex-row flex-col ] [ gap-y-8 lg:gap-y-0 ] [  lg:w-[90%] w-full ]">
          <div className=" text-white mt-2 [ lg:w-[50%] w-full ] [ text-center lg:text-left ] ">
            <h1 className=" leading-tight [ lg:text-[3.5rem] text-2xl ] [  lg:w-[80%] w-full ]">
              Comprehensive Analytics
            </h1>

            <p className="  text-gray90 mt-4 [ text-xs lg:text-sm ]  [ lg:w-[85%] w-full ] ">
              Empower yourself with actionable insights into your presentation's
              performance using SlideSync's comprehensive analytics dashboard.
              Track key metrics such as engagement levels, slide views, and
              participant interactions with precision and granularity.
              Understand how your audience interacts with your content and
              identify areas for improvement. With data-driven insights at your
              fingertips, you can refine your delivery, adjust your content
              strategy, and ultimately elevate the effectiveness of your
              presentations.
            </p>
          </div>

          <section
            style={boxShadowStyle}
            className=" [ flex items-center ] border border-gray150 gap-x-2 py-4 px-4 bg-black rounded-3xl [ lg:w-[392px] w-[90%] mx-auto ]"
          >
            <section>
              <div>
                <img src={zap} alt="" />
              </div>
              <div className="my-4">
                <h4 className="[ lg:text-lg font-medium text-white ]">
                  Participant Interaction
                </h4>
                <p className="mt-2 text-sm text-gray80">
                  Your presentation participants can quickly navigate to any
                  previous slide to clarify points they might have missed,
                  ensuring everyone stays on the same page and maximizes
                  understanding.
                </p>
              </div>
              <div className="flex justify-center w-full ">
                <img src={cards} alt="present preview" />
              </div>
            </section>
          </section>
        </section>
        {/*  */}

        {/* second */}
        <section
          style={boxShadowStyle}
          className="mx-auto rounded-lg  text-center bg-gray800 [ lg:my-32 lg:py-12 my-10 py-8 ]  [  lg:w-[80%] w-11/12 ]"
        >
          <h1 className="text-orange50 [ lg:text-[4rem] text-2xl ]  [ mb-4 lg:mb-8 ] ">
            Get Started for <span className="text-primary">free</span>
          </h1>

          <p className=" mx-auto text-gray300 [ lg:w-[70%] w-11/12 ] [ lg:text-lg text-xs ] [ ]">
            Whether you're a seasoned professional or a first-time presenter,
            TeamDocs empowers you to deliver impactful presentations with ease.
            Be the first in your clique to use TeamDocs to revolutionize your
            presentations .
          </p>

          <div className="flex justify-center mt-4">
            <button className="text-sm text-white bg-primary px-4 py-2 rounded-md [ flex items-center justify-center gap-x-2 ]">
              <span> Sign up now</span>
              <i className="font-bold cursor-pointer pi pi-chevron-circle-right"></i>
            </button>
          </div>
        </section>
        {/*  */}
      </section>
    </section>
  );
}
