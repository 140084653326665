import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';

//slices
import sidebarSlice from './UI';
// import pollSlice from './Poll/pollSlice';
import slideSlice from './Slides/slidesSlice';
import signalRSlice from './SignalR/signalRSlice';
import workspaceSlice from './Workspace/workspaceSlice';
import presentationSlice from './Presentations/presentationSlice';
import fileStructureSlice from './FileStructure/fileStructureSlice';
import paymentSlice from './Payment/paymentSlice';
import settingSlice from './Settings/settingSlice';
import teamsSlice from './Teams/teamsSlice';
import briefcaseSlice from './Briefcase/briefcaseSlice';
import briefcaseTabSlice from './Briefcase/briefcaseTabSlice';
import presentationDSlice from './generics/presenationDSlice';
import sharedBriefcaseSlice from './sharedBriefcase/sharedBriefcaseSlice';
import eventsSlice from './Events/EventsSlice';
import pollSlice from './Poll/pollSlice';
import pollCopySlice from './PollCopy/pollCopySlice';
import onGoingActionSlice from "./OnGoing/onGoingAction";

const store = configureStore({
    reducer: {
        poll: pollSlice,
        teams: teamsSlice,
        slides: slideSlice,
        signalR: signalRSlice,
        settings: settingSlice,
        briefcase: briefcaseSlice,
        workspace: workspaceSlice,
        sidebarState: sidebarSlice,
        paymentState: paymentSlice,
        presentations: presentationSlice,
        fileStructure: fileStructureSlice,
        briefcaseTab: briefcaseTabSlice,
        presentationDSlice: presentationDSlice,
        sharedBriefcase: sharedBriefcaseSlice,
        events: eventsSlice,
        pollcopy: pollCopySlice,
        onGoingActionSlice: onGoingActionSlice
    },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
