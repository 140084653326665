import {PresentationHeader} from "components/UI/mobile/headers/presentation-header";
import {PresentationMobileWrapper} from "components/UI/wrapper/presentation-mobile-wrapper";
import fullScreenIcon from "assets/svg/fullScreenIcon.svg";
import React, {useEffect, useRef, useState} from "react";
import exclain from "assets/svg/exclaim.svg";
import fileBlack from "assets/svg/fileBlack.svg";
import arrowSquareLeft from "assets/svg/arrowLeftSquare.svg";
import arrowSquareRight from "assets/svg/arrowRightSquare.svg";
import instance from "lib/axiosInterceptor";
import {
    AgendaInterface,
    DocDetailsInterface,
    EventsInterface,
    InnerPresentationInterface,
    PresentationDisplayInterface,
    PresentationDocInterface,
    SlideInterface,
} from "interfaces/interfaces-data";
import {getConnectionId, getUserDetails,} from "utils/localStorageServices";
import {usePresentationConnectSignalR} from "hooks/SignalR/usePresentationConnectSignalR";
import {toast} from "sonner";
import {imageStream} from "utils/imageStream";
import {BottomDrawer} from "components/UI/Drawers/bottom-drawer";
import modalIcon from "assets/svg/briefCaseModalIcon.svg";
import {Col, Row, Switch} from "antd";
import pptActive from "assets/svg/pptActive.svg";
import settingsColored from "assets/svg/settingsColored.svg";
import {MoveSlide} from "hooks/SignalR/Invokers/Presenter/MoveSlide";
import {documentHasSlides} from "utils/documentHasSlides";
import {DesktopPresentationHeader} from "components/UI/Presentation/desktop/desktop-presentationHeader";
import {DesktopPrentationScreen} from "components/UI/Presentation/desktop/desktop-presentation-screen";
import {DesktopSlider} from "components/UI/Presentation/desktop/desktop-slider";
import {useNavigate, useParams} from "react-router-dom";
import {DesktopMenu} from "components/UI/Presentation/desktop/desktop-menu"
import {DesktopControl} from "../Presentation/desktop/desktop-control";

export const MobilePresentation = () => {
    const {urlPresentationId, eventId, teamId} = useParams();
    const [presentationId, setPresentationId] = useState("");
    const documentRef = useRef<SlideInterface[]>([]);
    const [detailsLoading, setDetailsLoading] = useState(true);
    const [detailsError, setDetailsError] = useState(false);
    const [gridViewActive, setGridViewActive] = useState(false)
    let getIsEvent = new URLSearchParams(window.location.search)
    const isEvent = !!getIsEvent.get("isEvent");
    const isOnlyAgenda = !!getIsEvent.get("isOnlyAgenda");
    const [eventDetails, setEventDetails] = useState<EventsInterface>()
    const [idleState, setIdleState] = useState(false);
    const [upcomingAgenda, setUpcomingAgenda] = useState<AgendaInterface>();
    const [collaboratorEndedModal, setCollaboratorEndedModal] = useState(false);
    const [eventPresentationEnded, setEventPresentationEnded] = useState(false)
    // This state is for when there is an update on the eventDetails from the admin, what i want to do here is to silently update details without any form of re-rendering happening.
    const [sessionUpdatedState, setSessionUpdatedState] = useState(false)
    const navigate = useNavigate()
    const [hideEndPresentationButton, setHideEndPresentationButton] = useState(false)

    // note that we need to save the active document id , so that we can know what document to load , if there isn't any , we load the first document
    const [activeDocumentId, setActiveDocumentId] = useState("");
    const [activeDocLoading, setActiveDocLoading] = useState(true);
    const [activeDocError, setActiveDocError] = useState(false);
    const [currentDocumentLoaded, setCurrentDocumentLoaded] = useState<{
        loaded: boolean;
        docId?: string;
    }>({loaded: false, docId: ""});
    const [activeSlideIndex, setActiveSlidIndex] = useState(0);
    const [inNextPresentation, setInNextPresentation] = useState(false)

    const [activeDocument, setActiveDocument] =
        useState<PresentationDocInterface>();
    const [presentationDisplayDetails, setPresentationDisplayDetails] = useState<PresentationDisplayInterface>();
    const [activeDocumentDetails, setActiveDocumentDetails] =
        useState<DocDetailsInterface>();
    const [presentationDetails, setPresentationDetails] =
        useState<InnerPresentationInterface>();
    // const presentationId = getPresentationId.get()
    const {connected, connecting, reconnecting, error, connection} =
        usePresentationConnectSignalR();

    const handleChangeSlide = (item: number, type ?: "prev" | "next", docId ?: string) => {
        setActiveSlidIndex(item);
        if (connected && presentationDisplayDetails) {
            connection?.invoke("MoveSlide", presentationId, item, docId ? docId : activeDocumentId, isEvent ? eventId : null);
        }
    };

    const getEvents = async () => {
        try {
            const result = await instance.get(`/Events/${eventId}?teamId=${teamId}`)
            setEventDetails(result.data)
            setActiveDocLoading(false)
        } catch (e) {
            console.log(e)
        } finally {
            // setSessionUpdatedState(false)
        }
    }

    // Make Api Call to get all the files
    const getImageUrl = async (id: string) => {
        try {
            const result = await imageStream(id);
            return result;
        } catch (e) {
            return "";
        } finally {
        }
    };

    const updateCurrentDocumentList = () => {
        // This is basically to get the details of the presentation
        if (!currentDocumentLoaded) {
            const prevData = {...presentationDisplayDetails};
            prevData[activeDocumentId] = documentRef.current;
            setPresentationDisplayDetails(prevData);
        }
    };

    const getDocumentDetail = async (docId?: string) => {
        if (!docId) {
            setActiveDocLoading(true);
        }
        try {
            const result = await instance.get(
                `/Document/${docId ? docId : activeDocumentId}`
            );
            // Now , all i have to do is get the slides and then , i have to process the slides in batches
            // This only applies to pptx, pdf , and excel files, docx
            if (result.data.slides.length && documentHasSlides(result.data.url)) {
                // Get the Slides and process in batches of 4
                // if they are slides basically , we then try to sort the document
                const reArrangedData = result.data.slides.sort(
                    (a: SlideInterface, b: SlideInterface) => {
                        if (a.position > b.position) {
                            return 1;
                        } else if (a.position < b.position) {
                            return -1;
                        }
                        return 0;
                    }
                );
                if (!docId) {
                    setActiveDocumentDetails(reArrangedData);
                }
                // Now we need to fetch the document Slides
                let index = 0;
                for (const slide of reArrangedData) {
                    reArrangedData[index]["imgUrl"] = await getImageUrl(slide.id);
                    documentRef.current.push(reArrangedData[index]);
                    index++;
                    // what i want to do is to basically show something to the user once the data is loaded , if five slides are ready then show it to the user
                    if (reArrangedData.length > 5 && index === 4) {
                        setActiveDocLoading(false);
                        setPresentationDisplayDetails((prev) => ({
                            ...prev,
                            [docId ? docId : activeDocumentId]: [
                                ...reArrangedData.slice(0, 5),
                            ],
                        }));
                    }
                }
                setCurrentDocumentLoaded({loaded: true, docId: docId ?? ""});
            } else {
                // we still need to get the document url , but in the case , ideally we would always only have on image
                // we need to form a new object from this , so that it conforms with the existing flow
                const slideObject: SlideInterface[] = [
                    {
                        id: result.data.id,
                        url: result.data.url,
                        position: 1,
                        title: result.data.title,
                        note: result.data.note ?? "",
                        imgUrl: "",
                    },
                ];
                try {
                    slideObject[0]["imgUrl"] = (await getImageUrl(result.data.id)) ?? "";
                    documentRef.current.push(slideObject[0]);
                    setActiveDocLoading(false);
                    setCurrentDocumentLoaded({
                        loaded: true,
                        docId: docId ? docId : activeDocumentId,
                    });
                } catch (e) {
                    toast.error("FE: Error Loading document");
                }
                // get the document imageStream
            }
        } catch (e) {
            setActiveDocError(true);
        } finally {
            setActiveDocLoading(false);
        }
    };

    const getPresentationDetails = async (eventPresentationId?: string) => {
        setDetailsLoading(true);

        try {
            const result = await instance.get(`/presentation/${eventPresentationId ? eventPresentationId : urlPresentationId}`);
            setPresentationDetails(result.data);
            setPresentationId(result.data.presentation.id)
            // bsed off what is gotten here , set the first document as the default document or the document that is saved on the localStorage

            if (result.data.doc[0].documentId) {
                // setPresenterActiveDocumentId(result.data.doc[0].documentId);
                setActiveDocumentId((prev) => result.data.doc[0].documentId);
                setActiveDocument(result.data.doc[0]);
                if (isEvent) {

                }
            } else {
                toast.error("Active document id not gotten");
            }
        } catch (e) {
            setDetailsError(true);
        } finally {
            setDetailsLoading(false);
        }
    };

    const handleDocumentSwitch = (prev: string, val: string) => {
        // Save the last page of the document or set it to the original one
        const originalObject = {...presentationDetails};
        const activeDoc = presentationDetails?.doc.find(
            (item) => item.documentId === val
        );
        const prevIndex = presentationDetails?.doc.findIndex(
            (item) => item.documentId === prev
        );
        if (originalObject.doc && prevIndex && prevIndex !== -1) {
            originalObject.doc[prevIndex].activeIndex = activeSlideIndex;
            //@ts-ignore
            setPresentationDetails(originalObject);
        }
        setActiveDocumentId(val);
        setActiveSlidIndex(activeDoc?.activeIndex ?? 0);
        setActiveDocument(activeDoc);
        // Right here try to ensure that the previous index or page is the active one when the page switches
        // Now handle call the changeSlide
        handleChangeSlide(activeDoc?.activeIndex ?? 0, 'prev', val);
    };
    const handleSlideMove = (id: string, int: number, docId: string) => {
        setActiveSlidIndex(int);
        setActiveDocumentId(docId);
    }
    const handleCheckOwnerIsPresenter = (nextAgenda ?: AgendaInterface) => {
        // Now Check if the email of the owner is in this agenda
        if (eventDetails?.role === "EventOwner") {
            const userInfo = getUserDetails();
            // Now get the email , if the email is in collaboator list , then update accordingly
            const userDetails = nextAgenda?.collaborators.find(item => {
                return item.email === userInfo.email
            })
            return !!userDetails;
        }
        return false
    }

    const findPresentationPosition = (presentationId: string) => {

        if (eventDetails) {
            const findSessionIndex = eventDetails.agenda.findIndex(item => {
                return item.presentationId === presentationId;
            })
            return findSessionIndex
        }


    }


    // This function is basically there to determine what happens whhen the current presentation has ended both for the collaborator and the event owner
    const handleCurrentPresentationEnd = (id: string) => {
        if (eventDetails) {
            if (eventDetails.role === "EventOwner" || eventDetails.role === "Collaborator") {
                // Now check if there's another one , if not , show something else'
                const agendaList = eventDetails.agenda.length;
                // now get the position of the ended presentation and see if there is more
                const currentPresentationIndex = eventDetails.agenda.findIndex(item => item.presentationId === id);

                if (currentPresentationIndex === agendaList - 1) {
                    // No presentation left
                    // Show the idle state for all presentation ended
                    setEventPresentationEnded(true)
                    // this is wher ethey are informed that the presentation has ended
                } else if (currentPresentationIndex < agendaList - 1) {
                    // Then pick the next one and then show it as the upcoming event
                    const innerUpcomingAgenda = eventDetails.agenda[currentPresentationIndex + 1]
                    setIdleState(true)
                    setUpcomingAgenda(eventDetails.agenda[currentPresentationIndex + 1])


                    // now if the current mode is the owner's , what should happen is that they next presention is automatically started
                    // Check if the user is in the next presentation, if they are ,just redirect to the next presentation

                    if (eventDetails.role === "Collaborator") {
                        const userDetails = getUserDetails()

                        const loggedInUserEmail = userDetails.email
                        // check if in the upcoming agenda
                        const inNextPresentation = innerUpcomingAgenda.collaborators.find(item => item.email === loggedInUserEmail);
                        if (inNextPresentation) {
                            setInNextPresentation(true)
                        } else {
                            setCollaboratorEndedModal(true)
                        }
                    }
                }
                // All things being equal , no other condition should be true
            }
        }

    }

    useEffect(() => {
        // Start connecttion immediately the file is connected
        // BAsically , this contains, all the required registrations
        if (connected && connection) {
            // The event response is triggered only for when you are the one that basically invoked the event
            connection.on("EventResponse", (id, eventName, status, message, presenterConnectionId) => {

                // Now, when this happens ,what i basically want to do is to be able trigger the collaborator modal and also redirect them if no action is performed
                if (eventName === "EndPresentation") {
                    setHideEndPresentationButton(true)
                    handleCurrentPresentationEnd(id)
                }
                if (eventName === "EndEvent") {
                    if (eventDetails?.role === "EventOwner") {
                        window.location.href = "/admin/events";
                    } else {
                        window.location.href = `admin/presentation-details_?q=${presentationId}`
                    }
                }
                if (eventName === "Attend") {
                    console.log(status, message, presenterConnectionId, "Status for attend here")
                }
            })
            connection.on("PresentationStarted", (id) => {
                // Now , use this for when the presentation is idle and we need to trigger that the event has started

                window.location.reload();
                return
                if (id) {
                    setActiveDocLoading(true)
                    setIdleState(false)
                    getPresentationDetails(id);
                }
            })
            // the Slide should move, whenever there is an invocation of the MoveSlide event
            connection.on("MoveSlide", handleSlideMove)


            if (isEvent && eventDetails) {
                connection.on("SessionUpdated", (id) => {
                    // Basically once this is set to be true , it would very likely not get triggered again , alternatively , i could just listen to it onMount
                    setSessionUpdatedState(true);
                    getEvents();
                    // getEvents()
                })

                connection.on("EndPresentation", (id, eventId) => {

                    // So , this is the presentation Id being sent on the id , so the next thing to do is to the new upcoming event based on the the event payload that is available
                    setPresentationId(id);
                    handleCurrentPresentationEnd(id);

                })
                if (eventDetails?.role !== "EventOwner") {
                    connection.on("EndEvent", (id, eventId) => {
                        // So , this is the presentation Id being sent on the id , so the next thing to do is to the new upcoming event based on the the event payload that is available
                        connection.invoke("EndPresentation", presentationId, eventDetails?.id ?? null)
                        window.location.href = "/admin/mypresentations";

                        // handleCurrentPresentationEnd(id);

                    })
                }

                //for event owner the mode is 4 and the presentationId is the eventId
                //for the presenter , pass the presntationId, and the last parameter would be , eventId
                // connection
                //     ?.invoke("Attend", presentationId, 0, getConnectionId(), isEvent , EventId)
                //     .then((res) => {
                //         console.log(res, "Attend success");
                //     })
                //     .catch((e) => {
                //         console.log(e, "Attend Failed");
                //     });

                // it's likely that most event presentation by a collaborator would always happen from the presentaitonList , which in the case the id that would be gotten would technically be the long one , so i can go on a limb here and use the urlPresentationId
                const isEventOwner = eventDetails.role.toLowerCase() === "eventowner"

                connection
                    ?.invoke("Attend", isEventOwner ? eventId : urlPresentationId, isEventOwner ? 4 : 0, getConnectionId(), isEvent, eventId)
                    .then((res) => {
                        console.log(res, "Attend success");
                    })
                    .catch((e) => {
                        console.log(e, "Attend Failed");
                    });

                // The next thing to do here is to basically check for the currentSession and then trigger the attend as though it's the presenter,
                // if (eventDetails.currentSession) {
                //     const currentAgenda = eventDetails.agenda.find(item => item.id === eventDetails.currentSession)
                //     // Now this is for when the presenter is in the current session
                //     if (handleCheckOwnerIsPresenter(currentAgenda)) {
                //         getPresentationDetails(currentAgenda?.presentationId)
                //         setIdleState(false)
                //         connection.invoke("Attend", presentationId, 0, getConnectionId(), isEvent, eventDetails.id)
                //     }
                // } else if (handleCheckOwnerIsPresenter(eventDetails?.agenda[0])) {
                //     // This is for when the event owner is a presenter in the first presentation .
                //     setIdleState(false)
                //     getPresentationDetails(eventDetails?.agenda[0].presentationId);
                //     connection.invoke("Attend", eventDetails?.agenda[0].presentationId, 0, getConnectionId(), isEvent,
                //         eventDetails.id
                //     )
                // }


            } else if (!isEvent && presentationId) {
                //for event owner the mode is 4 and the presentationId is the eventId
                //for the presenter , pass the presntationId, and the last parameter would be , eventId
                // connection
                //     ?.invoke("Attend", presentationId, 0, getConnectionId(), isEvent , EventId)
                //     .then((res) => {
                //         console.log(res, "Attend success");
                //     })
                //     .catch((e) => {
                //         console.log(e, "Attend Failed");
                //     });
                // the presentationId here has to be dynamically gottent because it is quite possible the friendly id is what is on the url , which would break, so i technically have to get the presentationId

                connection
                    ?.invoke("Attend", presentationId, 0, getConnectionId(), isEvent, null)
                    .then((res) => {
                        console.log(res, "Attend success");
                    })
                    .catch((e) => {
                        console.log(e, "Attend Failed");
                    });
            }
        }
    }, [connected, eventDetails, presentationId, sessionUpdatedState]);

    // i don't think i really need to see if it is connected or not , so , i'll go on the limb here and not check it and then see what happens

    // useEffect(() => {
    //   console.log(isConnected, connectionStatus, "connected to signal R");
    //   if (!isConnected || !connectionStatus) {
    //     dispatch(connectSignalR()).then((response) => {
    //       console.log(response);
    //     });
    //   }
    // }, [isConnected, connectionStatus]);

    useEffect(() => {
        if (isEvent) {
            getEvents();
        } else {
            // i got triggered two
            getPresentationDetails();
        }
    }, []);


    useEffect(() => {
        const handleCheckPresentationData = async () => {
            if (eventDetails?.role.toLowerCase() === "eventowner" && eventDetails.currentPresentation) {
                // Since no Presentation has started , then what i want to do is to show an idle state for the application and that is where the banner is going to show and then basically show the details of the position of the first event
                //i also need to check here to see if the eventOwner is not a collaborator in the currentSession
                // ideally if the presentation has ended then they should not,

                // Now, there is still need to check for if the presenter is the one currently in the presentation

                const presentationResult = await instance.get(`/presentation/${eventDetails.currentPresentation}?presentaConnectionId=${getConnectionId()}`);
                // Now check to see the status of the presentation
                const currentPresenationStatus = presentationResult.data.presentation.status
                if (currentPresenationStatus === 4) {
                    // Now , if it's not the last file then the upcoming event would be the next file , if it's the last file then we set the eneded state to tbe true
                    const agendaLength = presentationResult.data.agenda.length
                    const val_index = presentationResult.data.agenda.findIndex((item: AgendaInterface) => {
                        return item.presentationId === presentationResult.data.currentPresentation
                    })
                    if (agendaLength === val_index + 1) {
                        setEventPresentationEnded(true)
                    } else if (agendaLength > val_index + 1) {
                        // Set the next upcoming event
                        setUpcomingAgenda(presentationResult.data.agenda[val_index + 1])
                        setIdleState(true)
                    }
                    return
                }

                setIdleState(false)
                getPresentationDetails(eventDetails.currentPresentation);

                const position = findPresentationPosition(eventDetails.currentPresentation);
                // get the current agenda index
                if (position !== undefined && !(position < 0)) {
                    console.log(position, "got to this position")

                }
            } else if (eventDetails?.role === "Collaborator") {
                // THen Get the presentaiton Details immediatelky , i need to know where the user's presntation is in the presentatation
                // Now check if it is onlyAnAgenda
                if (isOnlyAgenda) {
                    // Now get where the id is and the set the upcoming agenda with that
                    const newUpcomingAgenda = eventDetails?.agenda.find(item => {
                        return item.id === urlPresentationId
                    })
                    setUpcomingAgenda(newUpcomingAgenda)
                    // i.e no api call is made , all that is done is just to set the upcoming agenda
                    return;
                }
                getPresentationDetails(urlPresentationId)
            } else if (eventDetails?.currentSession && !eventDetails?.currentPresentation) {
                // Now , in this case we need to check if the agenda is still active
                // Also, basically set the event to be the active one
                const agendaHere = eventDetails.agenda.find(item => {
                    return eventDetails.currentSession === item.id
                })
                setUpcomingAgenda(agendaHere);

            } else {
                // Now, i want to check if the event owner is a collaborator in the next presentation, if that is the case , then the current view should be converted to the presenter's view but at the same with the consideration that the person is the eventOwner, so , what i want to do is just to enable some of the controls and then also start the presentation basically.


                setUpcomingAgenda(eventDetails?.agenda[0]);
                // if (handleCheckOwnerIsPresenter(eventDetails?.agenda[0])) {
                //     // if this is confirmed ,then i can go ahead and get the presentation Details from the eventDetails
                //     // Now , in this case , i need to invoke the new Atted , but ofcourse with knowlege that the Event owner owns the presentation
                //
                //
                //     getPresentationDetails(eventDetails?.agenda[0].presentationId);
                //     // Now , i need to be able to trigger the attend inorder to show that the a presentation has started
                //     setIdleState(false);
                //     return;
                // }
                setIdleState(true);
            }
        }
        if (!sessionUpdatedState) {
            handleCheckPresentationData()
        }
    }, [eventDetails, sessionUpdatedState]);

    useEffect(() => {
        if (
            activeDocumentId &&
            !Object.keys(presentationDisplayDetails ?? {}).includes(activeDocumentId)
        ) {
            getDocumentDetail();
        }
    }, [activeDocumentId]);

    useEffect(() => {
        if (currentDocumentLoaded.loaded) {
            // get active document id then basically update with the correct value and after all of this is done , we set every other value back to its original form
            const existingDetails = {...presentationDisplayDetails};
            // const docKeys = Object.keys(presentationDisplayDetails);
            // const activeIndex = docKeys.findIndex(
            //   (item) => item == activeDocumentId
            // );
            // existingDetails[existingDetails[activeIndex]] = documentRef.current;
            existingDetails[
                currentDocumentLoaded.docId
                    ? currentDocumentLoaded.docId
                    : activeDocumentId
                ] = documentRef.current;
            setPresentationDisplayDetails((prev) => ({
                ...prev,
                ...existingDetails,
            }));
            // setCurrentDocumentLoaded({ loaded: true, docId: "" });
            documentRef.current = [];

            // After this first one is done , then there is a need to save for the remaining documents, now trigger the rest of the docs
            if (presentationDetails && presentationDetails?.doc?.length > 1) {
                const foundIndex = presentationDetails?.doc.findIndex(
                    (item) =>
                        item.documentId ==
                        (currentDocumentLoaded.docId
                            ? currentDocumentLoaded.docId
                            : activeDocumentId)
                );
                if (foundIndex < presentationDetails.doc.length - 1) {
                    const newDocId = presentationDetails.doc[foundIndex + 1].documentId;
                    getDocumentDetail(newDocId);
                    setCurrentDocumentLoaded({loaded: false, docId: newDocId});
                }
            }
        }
    }, [currentDocumentLoaded]);


    return (
        <>
            <div className="block lg:hidden">
                <PresentationMobileWrapper>
                    {connection?.state === "Connected" && (
                        <PresentationHeader
                            meetingId={presentationDetails?.presentation.friendlyId}
                            connection={connection}
                            presentationId={presentationId ?? ""}
                            isEvent={isEvent}
                            eventDetails={eventDetails}
                        />
                    )}


                    <PresentationScreen
                        activeDocument={activeDocument}
                        activeSlide={
                            presentationDisplayDetails?.[activeDocumentId][activeSlideIndex]
                        }
                        error={activeDocError}
                        loading={activeDocLoading}
                    />
                    {presentationDisplayDetails && (
                        <Sliders
                            handleActiveSlideIndex={setActiveSlidIndex}
                            activeSlideIndex={activeSlideIndex}
                            slides={presentationDisplayDetails[activeDocumentId]}
                            updateList={updateCurrentDocumentList}
                            handleMoveSlide={handleChangeSlide}
                        />
                    )}

                    {connected && presentationDisplayDetails && !error && (
                        <Controls
                            handleMoveSlide={handleChangeSlide}
                            slides={presentationDisplayDetails[activeDocumentId]}
                            activeSlideIndex={activeSlideIndex}
                            updateList={updateCurrentDocumentList}
                            canUpdateList={currentDocumentLoaded.loaded}
                            docs={presentationDetails?.doc}
                            activePresentations={presentationDisplayDetails}
                            activeDocumentId={activeDocumentId}
                            handleActiveDocument={handleDocumentSwitch}
                            docLength={presentationDetails?.doc.length ?? 0}

                        />
                    )}
                </PresentationMobileWrapper>
            </div>
            {/*For Desktop Screens */}
            <div className="hidden overflow-hidden h-screen max-h-screen relative  lg:block">
                <Row>
                    <Col xs={18}>
                        <div className="flex flex-col h-screen">
                            <DesktopPresentationHeader
                                loading={detailsLoading}
                                presentationDetails={presentationDetails}
                                connection={connection}
                                presentationId={presentationId}
                                isEvent={isEvent}
                                eventDetails={eventDetails}
                                connected={connected}
                                collaboratorEndedModal={collaboratorEndedModal}
                                setCollaboratorEndModal={setCollaboratorEndedModal}
                                inNextPresentation={inNextPresentation}
                                setInNextPresentation={setInNextPresentation}
                                upcomingEvent={upcomingAgenda}

                            />
                            <DesktopPrentationScreen
                                activeSlide={
                                    presentationDisplayDetails?.[activeDocumentId][
                                        activeSlideIndex
                                        ]
                                }
                                loading={activeDocLoading}
                                handleGridModeActive={setGridViewActive}
                                gridModeActive={gridViewActive}
                                slides={presentationDisplayDetails?.[activeDocumentId]}
                                handleChangeSlide={handleChangeSlide}
                                activeSlideIndex={activeSlideIndex}
                                isEvent={isEvent}
                                isIdle={idleState}
                                upcomingAgenda={upcomingAgenda}
                                eventDetails={eventDetails}
                                eventPresentationsEnded={eventPresentationEnded}

                            />
                            {(presentationDisplayDetails && presentationDisplayDetails[activeDocumentId] && ((!isEvent) || (isEvent && eventDetails?.role === "Collaborator"))) &&
                                <DesktopSlider
                                    handleMoveSlide={handleChangeSlide}
                                    setActiveDocIndex={setActiveSlidIndex}
                                    activeDocumentIndex={activeSlideIndex}
                                    presentationDetails={presentationDisplayDetails}
                                    activeDocumentId={activeDocumentId}/>}

                            {connected && presentationDisplayDetails && !error && (
                                <DesktopControl
                                    handleMoveSlide={handleChangeSlide}
                                    slides={presentationDisplayDetails[activeDocumentId]}
                                    activeSlideIndex={activeSlideIndex}
                                    updateList={updateCurrentDocumentList}
                                    canUpdateList={currentDocumentLoaded.loaded}
                                    docs={presentationDetails?.doc}
                                    activePresentations={presentationDisplayDetails}
                                    activeDocumentId={activeDocumentId}
                                    handleActiveDocument={handleDocumentSwitch}
                                    activeSlide={
                                        presentationDisplayDetails?.[activeDocumentId][activeSlideIndex]
                                    }
                                    handleGridModeActive={setGridViewActive}
                                    gridModeActive={gridViewActive}
                                />
                            )}
                        </div>
                    </Col>
                    <Col xs={6}>
                        <DesktopMenu
                            presentationId={presentationId}
                            activeSlide={presentationDisplayDetails?.[activeDocumentId][activeSlideIndex]}
                            connection={connection}
                            connected={connected}
                            friendlyId={presentationDetails?.presentation.friendlyId}
                            presentationDetails={presentationDetails}
                            isEvent={isEvent}
                            eventId={eventId}
                            eventDetails={eventDetails}
                            presentationDisplayDetails={presentationDisplayDetails}
                            activeDocumentId={activeDocumentId}
                            upcomingAgenda={upcomingAgenda}


                        />

                    </Col>
                </Row>
            </div>
        </>
    )
        ;
};

const PresentationScreen = ({
                                loading,
                                error,
                                activeSlide,
                                activeDocument,
                            }: {
    loading: boolean;
    error: boolean;
    activeSlide?: SlideInterface;
    activeDocument?: PresentationDocInterface;
}) => {
    return (
        <>
            {loading ? (
                <div className="h-[200px] min-h-[200px] rounded-2xl bg-white"></div>
            ) : error ? (
                <div className="h-[200px] min-h-[200px] flex justify-center items-center rounded-2xl bg-white">
                    Error Occured
                </div>
            ) : (
                <div className="bg-white my-3 pb-4 rounded-2xl overflow-hidden">
                    <div>
                        <img
                            alt={""}
                            src={activeSlide?.imgUrl}
                            className="w-full object-contain  h-[300px] min-h-[300px] min-w-full"
                        />
                    </div>
                    <div className="p-4 pb-0">
                        <p className=" text-xs text-[#545454] font-grotesk ">
                            {activeDocument?.note}
                        </p>
                        <div className="flex justify-end ">
                            <img
                                src={fullScreenIcon}
                                alt="fullscreen icon"
                                className="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const Sliders = ({
                     slides,
                     activeSlideIndex,
                     handleActiveSlideIndex,
                     updateList,
                     handleMoveSlide,
                 }: {
    slides: SlideInterface[];
    activeSlideIndex: number;
    handleActiveSlideIndex: (item: number) => void;
    updateList: () => void;
    handleMoveSlide: (index: number, type: "next" | "prev") => void;
}) => {
    const itemRefs = useRef<Array<HTMLDivElement | null>>([]);
    const scrollableRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        itemRefs.current?.[activeSlideIndex]?.scrollIntoView({
            behavior: "smooth",
            inline: "center",
        });
    }, [activeSlideIndex]);

    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            const element = scrollableRef.current;
            if (element) {
                // Calculate the maximum scrollable width
                const maxScrollLeft = element.scrollWidth - element.clientWidth;
                // Check if we've reached the end of the scrollable div
                if (element.scrollLeft >= maxScrollLeft - 1) {
                    updateList();
                    // Add additional functionality here if needed
                }
            }
        };

        const element = scrollableRef.current;
        // Attach the scroll event listener if element exists
        if (element) {
            element.addEventListener("scroll", handleScroll);
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (element) {
                element.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return (
        <div
            ref={scrollableRef}
            className="overflow-x-scroll hideScroll flex gap-2"
        >
            {slides.length
                ? slides.map((item, index) => {
                    return (
                        <div
                            // style={{
                            //   backgroundImage: `url(${item.imgUrl})`,
                            //   backgroundPosition: "center",
                            //   backgroundSize: "cover",
                            //   backgroundRepeat: "no-repeat",
                            // }}

                            ref={(element) => (itemRefs.current[index] = element)}
                            onClick={() => {
                                handleMoveSlide(index, "prev");
                                handleActiveSlideIndex(index);
                            }}
                            key={item.id}
                            className={`min-w-[200px] overflow-hidden object-fill max-h-[100px] cursor-pointer min-h-[100px] h-[100px] rounded-2xl w-[200px] ${
                                index === activeSlideIndex ? "border-[#FF6929] border-2" : ""
                            } `}
                        >
                            <img src={item.imgUrl} className="w-full h-full object-fill"/>
                        </div>
                    );
                })
                : ""}
        </div>
    );
};

const Controls = ({
                      handleMoveSlide,
                      slides,
                      activeSlideIndex,
                      canUpdateList,
                      updateList,
                      docs,
                      activePresentations,
                      activeDocumentId,
                      handleActiveDocument,
                      docLength
                  }: {
    handleMoveSlide: (item: number, type: "prev" | "next") => void;
    slides: SlideInterface[];
    activeSlideIndex: number;
    canUpdateList: boolean;
    updateList: () => void;
    docs?: PresentationDocInterface[];
    activePresentations: { [key: string]: SlideInterface[] };
    activeDocumentId: string;
    handleActiveDocument: (prev: string, val: string) => void;
    docLength: number

}) => {
    const [drawerActive, setShowDrawerActive] = useState(false);
    const [settingDrawerActive, setSettingsDrawerActive] = useState(false);
    return (
        <div className="min-h-[200px]  mt-4  flex justify-between h-[200px] ">
            <BottomDrawer
                title="Playlist"
                icon={modalIcon}
                handleClose={setShowDrawerActive}
                modalActive={drawerActive}
            >
                <Row gutter={12}>
                    {Object.keys(activePresentations)?.length &&
                        Object.keys(activePresentations)?.map((item) => {
                            return (
                                <Col key={item} xs={24}>
                                    <div
                                        onClick={() => handleActiveDocument(activeDocumentId, item)}
                                        className={`w-full p-3 py-3 border cursor-pointer border-[#F6F6F6] rounded-lg flex items-center ${
                                            activeDocumentId === item ? "bg-[#F6F6F6]" : ""
                                        }  my- 1`}
                                    >
                                        <div className="flex items-center  gap-2">
                                            <span className="">
                                                <img alt="" src={pptActive}/>
                                            </span>
                                            <p
                                                className={`${
                                                    activeDocumentId !== item
                                                        ? "text-[#333]"
                                                        : "text-[#FF6929]"
                                                }  text-xs`}
                                            >
                                                {" "}
                                                {docs?.find((item_) => item_.documentId === item)?.title}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}

                    {Object.keys(activePresentations)?.length < docLength &&
                        <Col xs={24}>
                            <div className="flex justify-center my-4">
                                <p className="text-xs text-[#667084]">Hold on a bit!, we are fetching your document,</p>
                            </div>
                        </Col>
                    }
                </Row>
            </BottomDrawer>

            <BottomDrawer
                modalActive={settingDrawerActive}
                icon={settingsColored}
                handleClose={setSettingsDrawerActive}
                title="Presentation Settings"
            >
                <div>
                    <div className="flex justify-between items-center my-4">
                        <p className="">Allow Questions</p>
                        <span>
                            <Switch checked={true}/>
                        </span>
                    </div>
                    <div className="flex justify-between items-center my-4">
                        <p className="">Display navigation</p>
                        <span>
                            <Switch checked={true}/>
                        </span>
                    </div>
                    <div className="flex justify-between items-center my-4">
                        <p className="">Display Instruction QR</p>
                        <span>
                            <Switch checked={true}/>
                        </span>
                    </div>
                    <div className="flex justify-between items-center my-4">
                        <p className="">Dispaly thumbnail</p>
                        <span>
                            <Switch checked={true}/>
                        </span>
                    </div>
                </div>
            </BottomDrawer>
            <button
                onClick={(e) => {
                    if (activeSlideIndex > 0) {
                        handleMoveSlide(activeSlideIndex - 1, "prev");
                        const button = e.currentTarget;
                        const ripple = document.createElement("span");
                        ripple.classList.add("ripple");

                        // Calculate size and position of the ripple
                        const rect = button.getBoundingClientRect();
                        ripple.style.width = ripple.style.height =
                            Math.max(rect.width, rect.height) + "px";
                        ripple.style.left =
                            e.clientX - rect.left - ripple.offsetWidth / 2 + "px";
                        ripple.style.top =
                            e.clientY - rect.top - ripple.offsetHeight / 2 + "px";

                        // Append the ripple to the button and remove it after animation
                        button.appendChild(ripple);
                        ripple.addEventListener("animationend", () => ripple.remove());
                    }
                }}
                className="bg-white ripple-button h-full justify-center rounded-2xl cursor-pointer flex w-full items-center"
            >
                <img src={arrowSquareLeft} alt=""/>
            </button>
            <div className="flex w-full gap-3 mx-3 flex-col ">
                <div
                    onClick={() => setShowDrawerActive(true)}
                    className="h-full cursor-pointer flex justify-center items-center bg-white rounded-xl"
                >
                    <img src={fileBlack}/>
                </div>
                <div
                    onClick={() => setSettingsDrawerActive(true)}
                    className=" h-full cursor-pointer flex justify-center items-center bg-white rounded-xl"
                >
                    <img alt={""} src={exclain}/>
                </div>
            </div>
            <button
                onClick={(e) => {
                    if (activeSlideIndex < slides.length - 1) {
                        const slideLength = slides.length;
                        if (slideLength - activeSlideIndex === 2) {
                            // update the list before the user navigates to that point
                            updateList();
                        }
                        handleMoveSlide(activeSlideIndex + 1, "next");
                        const button = e.currentTarget;
                        const ripple = document.createElement("span");
                        ripple.classList.add("ripple");

                        // Calculate size and position of the ripple
                        const rect = button.getBoundingClientRect();
                        ripple.style.width = ripple.style.height =
                            Math.max(rect.width, rect.height) + "px";
                        ripple.style.left =
                            e.clientX - rect.left - ripple.offsetWidth / 2 + "px";
                        ripple.style.top =
                            e.clientY - rect.top - ripple.offsetHeight / 2 + "px";

                        // Append the ripple to the button and remove it after animation
                        button.appendChild(ripple);
                        ripple.addEventListener("animationend", () => ripple.remove());
                    }
                }}
                className="bg-white ripple-button  h-full rounded-2xl cursor-pointer flex w-full  justify-center items-center"
            >
                <img src={arrowSquareRight} alt=""/>
            </button>
        </div>
    );
};