import { Dispatch, SetStateAction, useRef, useState } from 'react';

// Libraries & utils
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import moment from 'moment';
import { Drawer, Dropdown, Menu, Modal, Tooltip } from 'antd';
import { AvatarGroup } from 'primereact/avatargroup';
import { Avatar } from 'primereact/avatar';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import getColorfulBgClass from 'utils/randomBgColour';
import { toast } from 'react-toastify';
import { getInitials } from 'utils/formatter';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// Assets
import dot from 'assets/svg/_Dot.svg';
import sendSquareBTN from 'assets/svg/send-sqaure-2.svg';
import plusIcon from 'assets/svg/plus-small.svg';
import docText from 'assets/svg/document-text.svg';
import dots from 'assets/svg/dots.svg';
import trash from 'assets/svg/trash.svg';
import edit from 'assets/svg/edit-2.svg';
import duplicate from 'assets/svg/document-duplicate.svg';

// Stores
import { setTab } from 'store/Briefcase/briefcaseTabSlice';
import {
  getBriefcases,
  removeBriefcase,
  updateBriefcase,
} from 'store/Briefcase/briefcaseSlice';
import { AppDispatch, RootState } from 'store/store';
import { getTeamInfo, getTeamsState } from 'store/Teams/teamsSlice';

//Components
import CreateTag from '../Modals/CreateTag';
import ConfirmationModal from 'components/UI/Modal/ConfirmationModal';
import { TableEmpty } from 'components/UI/States/table/TableEmpty';
import FileThumbnail from 'components/Admin/BriefCase/FileThumbnail';
import RenameModal from 'components/UI/Modal/RenameModal';
import ShareBriefcase from '../Modals/ShareBriefcase';
import AvaterImage from 'components/UI/Avatar/AvaterImage';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

//types
interface Member {
  fullName: string;
  id: string;
  memberId: string;
  spaceId: string;
  spaceTitle: string;
  picUrl: string;
}

interface BriefcaseItems {
  bannerId: string;
  dateCreated: string;
  description: string;
  id: string;
  lastModified: string;
  members: Member[] | null;
  tags: string;
  teamId: string;
  title: string;
  documents: number;
}

interface CreateSpaceModalRef {
  resetModal: () => void;
}

interface MatchMode {
  value: string;
  matchMode: FilterMatchMode;
}

interface DataTableFilterMeta {
  [key: string]: MatchMode;
}
interface Props {
  filters: DataTableFilterMeta;
  briefcaseData: BriefcaseItems[];
  setDisableBTN: Dispatch<SetStateAction<boolean>>;
  selectedBriefcases: BriefcaseItems[];
  setSelectedBriefcases: Dispatch<SetStateAction<BriefcaseItems[]>>;
}

export default function AllBriefcases({
  filters,
  briefcaseData,
  setDisableBTN,
  selectedBriefcases,
  setSelectedBriefcases,
}: Props) {
  //
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const createSpaceModal = useRef<CreateSpaceModalRef>(null);
  const { pathname } = useLocation();

  //   Store
  const briefcases = useSelector(
    (state: RootState) => state.briefcase.briefcases.data
  );
  const { teamInfo } = useSelector(getTeamsState);

  // States
  const [briefcaseDetails, setBriefcaseDetails] =
    useState<BriefcaseItems | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const [spaceId, setSpaceId] = useState<string | null>(null);
  const [openShareBriefcaseDrawer, setOpenShareBriefCaseDrawer] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [titleName, setTitleName] = useState<string>('');

  //function
  //function
  const openCreateSpaceModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setOpenRenameModal(false);
    createSpaceModal.current?.resetModal();
  };

  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const handleRemoveDocument = () => {
    handleDeleteBriefcase(fileIndex);
  };

  const closeShareBriefcaseModal = () => {
    setOpenShareBriefCaseDrawer(false);
  };

  // const openEditBriefcaseModal

  const handleDeleteBriefcase = (index: number) => {
    setIsDeleteLoading(true);
    const spaceId = briefcases[index].id;
    const archive = false;
    dispatch(removeBriefcase({ spaceId, archive })).then(({ type }) => {
      setIsDeleteLoading(false);
      if (type.includes('fulfilled')) {
        closeConfirmDelete();
        toast.success('Document deleted successfully');
        const teamId = teamInfo.data?.id;
        teamId && dispatch(getBriefcases(teamId));
      }
    });
    dispatch(getTeamInfo()); //this would change the teamInfo state and would prompt the useEffect in the briefcase nav layout to run
  };

  const handleOptionsClick = (index: number, action: string) => {
    if (action === 'delete') {
      setConfirmDelete(true);
    }
    if (action === 'rename') {
      setBriefcaseDetails(briefcases[index]);
      setOpenRenameModal(true);
    }
  };

  const updateTitle = () => {
    setIsLoading(true);
    if (briefcaseDetails) {
      const { tags, id, description, teamId } = briefcaseDetails;

      const formData = new FormData();
      formData.append('SpaceId', id);
      formData.append('Title', titleName);
      formData.append('Description', description);
      formData.append('Tags', tags);

      dispatch(updateBriefcase(formData)).then(() => {
        setIsLoading(false);
        handleCancel();
        dispatch(getBriefcases(teamId));
      });
    }
  };

  const items = [
    {
      key: '1',
      action: 'rename',
      label: (
        <div className='flex gap-x-2 py2 pl-2 pr-5'>
          <img src={edit} alt='rename file' className='w-5' />
          <p>Rename</p>
        </div>
      ),
    },

    {
      key: '4',
      action: 'duplicate',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5'>
          <img src={duplicate} alt='share file' className='w-5' />
          <p>Duplicate briefcase</p>
        </div>
      ),
    },

    {
      key: '5',
      action: 'delete',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5' onClick={() => {}}>
          <img src={trash} alt='delete file' className='w-5' />
          <p>Delete</p>
        </div>
      ),
    },
  ];

  const columns = [
    {
      header: 'Name',
      field: 'title',
      body: (rowData: BriefcaseItems, { rowIndex }: { rowIndex: number }) => {
        return (
          <section>
            <section className='flex items-center gap-x-4'>
              <div className='bg-white text-white text-xs rounded-lg overflow-hidden w-[49px] h-[49px] flex items-center justify-center'>
                <FileThumbnail
                  file={rowData}
                  height={49}
                  width={49}
                  imgTagheight={49}
                  imgTagwidth={49}
                />
              </div>
              <div
                className='pt-1 font-medium capitalize cursor-pointer'
                onClick={() => {
                  dispatch(setTab(0));
                  navigate(`/admin/mybriefcase/details/${rowData.id}`);
                }}
              >
                <p className='text-black text-sm'>{rowData?.title}</p>
                <div className='flex gap-x-1 my-1 text-xs'>
                  <img src={docText} alt='document icon' />
                  <p>{rowData.documents}</p>
                  <p>files</p>
                </div>
              </div>
            </section>
          </section>
        );
      },
    },
    {
      header: 'Last updated',
      field: 'lastmodified',
      sortable: true,
      body: (rowData: BriefcaseItems) => {
        const relativeTime = moment.utc(rowData.lastModified).fromNow();
        const formattedTime = dayjs
          .utc(rowData.lastModified)
          .format('YYYY-MM-DD');

        return (
          <section className='group cursor-default w-fit'>
            <Tooltip
              title={<p className='text-black'>{formattedTime}</p>}
              color='white'
              placement='top'
              arrowContent={relativeTime}
            >
              <p className='time text-black'>{relativeTime}</p>
            </Tooltip>
          </section>
        );
      },
    },
    {
      header: 'tags',
      field: 'tags',
      sortable: true,
      body: (rowData: BriefcaseItems) => {
        const listofTags = rowData.tags?.split(',');
        return (
          <section
            className='w-full flex gap-x-2 cursor-pointer'
            onClick={() => {
              setBriefcaseDetails(rowData);
              openCreateSpaceModal();
            }}
          >
            {listofTags && listofTags?.length > 0 ? (
              <>
                <div className='flex gap-x-2'>
                  {listofTags.slice(0, 2).map((item, index) => (
                    <div
                      className='flex items-center gap-x-2 bg-[#F5F5F5] border border-[#DFDFDF] rounded-full w-fit px-2 py-0.5'
                      key={index}
                    >
                      <img src={dot} alt='profile' className='' />
                      <Tooltip
                        title={<p className='text-black capitalize'>{item}</p>}
                        color='white'
                        placement='top'
                      >
                        <div className='text-sm font-medium capitalize'>
                          <p className='text-xs'>{`${item.slice(0, 8)}${
                            item.length > 8 ? '...' : ''
                          }`}</p>
                        </div>
                      </Tooltip>
                    </div>
                  ))}
                </div>
                {listofTags?.length > 2 && (
                  <div className='flex items-center gap-x-2 bg-[#F5F5F5] border border-[#DFDFDF] rounded-full w-fit px-2 py-0.5'>
                    <div className='text-sm font-medium capitalize'>
                      <p>+ {listofTags.length - 2}</p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className='flex gap-x-1 items-center cursor-pointer'>
                <img src={plusIcon} alt='add tag' />
                <p>add tag</p>
              </div>
            )}
          </section>
        );
      },
    },

    {
      header: 'Access',
      field: 'members',
      sortable: true,
      body: (rowData: BriefcaseItems) => {
        return (
          <div>
            {/* <p>Members</p> */}
            <AvatarGroup>
              {rowData.members?.map((item, index) => (
                <AvaterImage member={item} index={index} />
              ))}
            </AvatarGroup>
          </div>
        );
      },
    },
    {
      header: '',
      sortable: true,
      body: (rowData: BriefcaseItems, options: { rowIndex: number }) => {
        return (
          <div className='flex gap-x-3 items-center'>
            <div
              className='cursor-pointer'
              onClick={() => {
                setSpaceId(rowData.id);
                setOpenShareBriefCaseDrawer(true);
              }}
            >
              <img src={sendSquareBTN} alt='' />
            </div>
            <section onClick={(e) => e.preventDefault()}>
              <Dropdown
                trigger={['click']}
                dropdownRender={(menu) => (
                  <Menu>
                    {items.map((item) => {
                      return (
                        <Menu.Item
                          key={item.key}
                          onClick={() => {
                            setFileIndex(options.rowIndex);
                            handleOptionsClick(options.rowIndex, item.action);
                          }}
                        >
                          {item.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                )}
              >
                <div className='cursor-pointer py-4'>
                  <img src={dots} alt='options' />
                </div>
              </Dropdown>
            </section>
          </div>
        );
      },
    },
  ];
  return (
    <section>
      <section className='overflow-hidden rounded-xl w-full'>
        <DataTable
          filters={filters}
          globalFilterFields={['title', 'tags']}
          value={briefcaseData}
          tableStyle={{ minWidth: '900px', maxHeight: '300px' }}
          emptyMessage={TableEmpty}
          selectionMode='checkbox'
          selection={selectedBriefcases}
          onSelectionChange={(e) => {
            setSelectedBriefcases(e.value);
            e.value.length < 1 ? setDisableBTN(true) : setDisableBTN(false);
          }}
        >
          <Column
            selectionMode='multiple'
            headerStyle={{ width: '3rem' }}
            headerClassName='bg-[#F5F5F5F5] text-blue300 font-normal text-sm'
          ></Column>
          {columns
            .filter(
              (col) => !(pathname?.includes('workspace') && col.header === '')
            )
            .map((col, index) => {
              // if (pathname?.includes('workspace') && col.header === '') {
              //   return;
              // }

              return (
                <Column
                  key={index}
                  body={col?.body}
                  field={col.field}
                  header={col.header}
                  headerClassName='bg-[#F5F5F5F5] text-blue300 font-normal text-sm'
                  className='text-sm'
                />
              );
            })}
        </DataTable>
      </section>

      {/* Modal */}
      {briefcaseDetails && (
        <Modal
          footer={null}
          centered={false}
          open={isModalOpen}
          onCancel={handleCancel}
          style={{
            top: 20,
          }}
        >
          <CreateTag
            closeModal={handleCancel}
            briefcaseDetails={briefcaseDetails}
          />
        </Modal>
      )}
      {/* rename briefcase */}
      {briefcaseDetails && (
        <Modal
          footer={null}
          centered={false}
          open={openRenameModal}
          onCancel={handleCancel}
          style={{
            top: 20,
          }}
          closeIcon={false}
        >
          <RenameModal
            closeModal={handleCancel}
            setTitleName={setTitleName}
            func={updateTitle}
            isLoading={isLoading}
            previousTitle={briefcaseDetails?.title}
            titleName={titleName}
            heading='Briefcase title'
            subHeading=' Rename your briefcase'
            buttonText=' Update title'
          />
        </Modal>
      )}

      {spaceId && (
        <Drawer
          placement='right'
          closable={false}
          onClose={closeShareBriefcaseModal}
          open={openShareBriefcaseDrawer}
          width={468}
        >
          <ShareBriefcase
            spaceId={spaceId}
            presentationType={0}
            schedulePresentation={false}
          />
        </Drawer>
      )}

      <ConfirmationModal
        open={confirmDelete}
        isLoading={isDeleteLoading}
        onClose={closeConfirmDelete}
        func={handleRemoveDocument}
        text='Delete Briefcase?'
        subText='Are you sure you want to delete this briefcase?'
      />
    </section>
  );
}
