import SidebarWrapper from './SidebarWrapper';
import Sidebar from './Sidebar';
import Header from './Header';
import DocumentDisplay from './DocumentDisplay';
import ControllerParent from './ControllerParent';
import usePreview from 'hooks/Preview/usePreview';
import FullScreen from './FullScreen';
import MainContentWrapper from './MainContentWrapper';

export interface ImageCache {
  id: string[];
}

interface Props {
  flattedDisplayDocuments: any;
  previewDocs: any;
  ownerName: string;
  previewTitle: string;
  previewIsLoading: boolean;
}

export default function Preview({
  flattedDisplayDocuments,
  previewDocs,
  ownerName,
  previewTitle,
  previewIsLoading,
}: Props) {
  const {
    screenWidth,
    minScreenWidth,
    activeImgRef,
    activeImage,
    isLoading,
    isFullscreen,
    isOpen,
    handleNextDoc,
    handlePrevDoc,
    handleFullscreen,
    handleGridDisplay,
    itemRefs,
    displayDocumentIndex,
    setDisplayDocumentIndex,
    imageCache,
    setSlideDocumentIndex,
    fetchedFlattenedDocIndex,
    slides,
    slideDocumentIndex,
    isGridDisplayActive,
    setIsGridDisplayActive,
    isSlides,
    handlePrevSlideDoc,
    handleNextSlideDoc,
    fullScreenContainerRef,
  } = usePreview({ flattedDisplayDocuments });
  return (
    <section className='relative h-screen max-h-screen'>
      <SidebarWrapper
        isOpen={isOpen}
        flattedDisplayDocuments={flattedDisplayDocuments}
      >
        {previewDocs && (
          <Sidebar
            displayDocumentIndex={displayDocumentIndex}
            setDisplayDocumentIndex={setDisplayDocumentIndex}
            previewDocs={previewDocs}
            flattedDisplayDocuments={flattedDisplayDocuments}
            setSlideDocumentIndex={setSlideDocumentIndex}
            imageCache={imageCache}
            fetchedFlattenedDocIndex={fetchedFlattenedDocIndex}
          />
        )}
      </SidebarWrapper>

      <MainContentWrapper flattedDisplayDocuments={flattedDisplayDocuments}>
        <Header
          ownerName={ownerName ?? "Creator's name"}
          title={previewTitle}
        />
        <DocumentDisplay
          isLoading={isLoading}
          activeImage={activeImage}
          activeImgRef={activeImgRef}
          isPresentationDetailsLoading={previewIsLoading}
          minScreenWidth={minScreenWidth}
          screenWidth={screenWidth}
          slides={slides}
          imageCache={imageCache}
          slideDocumentIndex={slideDocumentIndex}
          setSlideDocumentIndex={setSlideDocumentIndex}
          isGridDisplayActive={isGridDisplayActive}
          setIsGridDisplayActive={setIsGridDisplayActive}
          isSlides={isSlides}
          handleFullscreen={handleFullscreen}
          displayDocumentIndex={displayDocumentIndex}
        />
        <ControllerParent
          screenWidth={screenWidth}
          isSlides={isSlides}
          slides={slides}
          slideDocumentIndex={slideDocumentIndex}
          handlePrevSlideDoc={handlePrevSlideDoc}
          displayDocumentIndex={displayDocumentIndex}
          flattedDisplayDocuments={flattedDisplayDocuments}
          handleNextSlideDoc={handleNextSlideDoc}
          handlePrevDoc={handlePrevDoc}
          handleNextDoc={handleNextDoc}
          imageCache={imageCache}
          itemRefs={itemRefs}
          minScreenWidth={minScreenWidth}
          setSlideDocumentIndex={setSlideDocumentIndex}
          handleFullscreen={handleFullscreen}
          handleGridDisplay={handleGridDisplay}
          isGridDisplayActive={isGridDisplayActive}
        />
      </MainContentWrapper>

      {isFullscreen && (
        <FullScreen
          fullScreenContainerRef={fullScreenContainerRef}
          activeImage={activeImage}
          activeImgRef={activeImgRef}
          isSlides={isSlides}
          slideDocumentIndex={slideDocumentIndex}
          handlePrevSlideDoc={handlePrevSlideDoc}
          imageCache={imageCache}
          displayDocumentIndex={displayDocumentIndex}
          handleNextSlideDoc={handleNextSlideDoc}
          handlePrevDoc={handlePrevDoc}
          handleNextDoc={handleNextDoc}
        />
      )}
    </section>
  );
}
