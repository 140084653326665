import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//assets
import wand from "assets/svg/wand.svg";
import oldVN from "assets/svg/Badge.svg";
import plane from "assets/svg/plane.svg";
import likeImg from "assets/svg/like.svg";
import remove from "assets/svg/remove.svg";
import avatar from "assets/svg/avatar.svg";
import trash from "assets/svg/deleteVoice.svg";
import AIButton from "assets/svg/AIButton.svg";
import dislikeImg from "assets/svg/dislike.svg";
import recordingImg from "assets/svg/recording.svg";
import orangeRecord from "assets/svg/orangeRecord.svg";

//libraries
import {ClipLoader} from "react-spinners";
import {Skeleton} from "primereact/skeleton";

//signalR
import {AnswerQuestion} from "hooks/SignalR/Invokers/Presenter/AnswerQuestion";

//slices
import {clearTranslatedAudio, getSlidesState, translateQuestion,} from "store/Slides/slidesSlice";
import {GetQuestions} from "hooks/SignalR/Invokers/Presenter/GetQuestions";
import {useSignalREventListener} from "hooks/SignalR/Listeners/Global/useSignalRListener";
import {SummarizeText} from "hooks/SignalR/Invokers/Presenter/SummarizeText";
import {Toast} from "primereact/toast";
import {useGetSummarizedText} from "hooks/SignalR/Listeners/Presenter/useGetSumamrizedTexr";
import {DateFromNow} from "utils/formatDate";

let connection;
export default function NowAnswering({
                                         connection,
                                         isViewer,
                                         question,
                                         setSelectedQuestion,
                                         presentationId,
                                         isEvent,
                                         eventId
                                     }) {
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    // connection = getSignalRConnection();
    // const {presentationId} = useParams();
    const {dislikes, likes, name, askTime, request, slideTitle} =
    question ?? {};

    const slideNumber = slideTitle?.split("_")?.[1];

    //useref
    const toast = useRef(null);
    const audioRef = useRef(null);

    //useselector
    const {translatedAudio} = useSelector(getSlidesState);

    //usestate
    const [answer, setAnswer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // audio recording
    const audioChunksRef = useRef([]);
    const mediaRecorderRef = useRef(null);
    const [audioUrl, setAudioUrl] = useState("");
    const [audioFile, setAudioFile] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isSummarized, setIsSummarized] = useState(false);
    const [isSummaryLoading, setIsSummaryLoading] = useState(false);

    //hook
    useSignalREventListener(
        connection,
        (id, eventName, status, message, presenterConnectionId) => {
            const event = eventName?.toLowerCase();
            console.log(eventName, message, "event name");
            if (event === "answerquestion") {
                resetDrawer();
            }
        }
    );

    useGetSummarizedText(connection, presentationId, (summary) => {
        setIsSummaryLoading(false);
        setIsSummarized(false);

        summary && setAnswer(summary);
    });

    useEffect(() => {
        if (inputRef && inputRef?.current?.value) {
            inputRef.current.value = "";
        }

        setAnswer(null);
    }, []);

    useEffect(() => {
        if (answer && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.selectionStart = inputRef.current.selectionEnd =
                answer.length;
        }
    }, [answer]);

    useEffect(() => {
        const audioConvertion = translatedAudio.data?.questionFull;
        console.log("here");
        if (audioConvertion) {
            setAnswer(audioConvertion);
        }
    }, [translatedAudio]);

    const answerQuestion = () => {
        const audioAnswer = !isSummarized
            ? translatedAudio.data?.questionFull
            : translatedAudio.data?.request;

        if (!audioAnswer && !answer) {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Answer cannot be blank",
            });
            return;
        }

        console.log(answer, "editted ans");

        if (!connection || !question?.presentationId || !question?.id) {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "An error occurred, kindly refresh the page",
            });
            console.log(
                "connection",
                connection,
                "question",
                question,
                "something is null presentationID or id"
            );
            return;
        }

        setIsLoading(true);
        console.log(question.presentationId, question.id, "Question Details and answer hereee")
        AnswerQuestion(
            connection,
            question?.presentationId,
            question?.id,
            answer ?? audioAnswer,
            isEvent,
            eventId
        );
    };

    // useeffectE
    useEffect(() => {
        // Get access to the microphone
        const getMicrophoneAccess = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                });
                mediaRecorderRef.current = new MediaRecorder(stream);

                mediaRecorderRef.current.ondataavailable = (event) => {
                    audioChunksRef.current.push(event.data);
                };

                mediaRecorderRef.current.onstop = () => {
                    const audioBlob = new Blob(audioChunksRef.current, {
                        type: "audio/wav",
                    });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    setAudioUrl(audioUrl);
                    const file = new File([audioBlob], "audio.wav", {
                        type: "audio/wav",
                    });
                    setAudioFile(file);
                    audioChunksRef.current = [];
                };
            } catch (err) {
                console.error("Error accessing microphone:", err);
            }
        };

        getMicrophoneAccess();
    }, []);

    //function
    const resetDrawer = () => {
        setAudioUrl("");
        setAudioFile(null);
        setIsRecording(false);
        setIsSummarized(false);
        setIsSummaryLoading(false);
        dispatch(clearTranslatedAudio());
        setIsLoading(false);
        setAnswer(null);

        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Answer sent",
        });
        console.log("Position ONe Triggered")
        GetQuestions(connection, presentationId);
        setSelectedQuestion(null);

        if (inputRef && inputRef?.current?.value) {
            inputRef.current.value = "";
        }
    };

    const summmarizeText = () => {
        setIsSummaryLoading(true);
        SummarizeText(toast, connection, presentationId, question?.id, answer);
        setIsSummarized(true);
        // setTimeout(() => {
        //   setIsSummaryLoading(false);
        //   setIsSummarized(true);
        // }, [800]);
    };

    const startRecording = () => {
        if (inputRef.current?.value) {
            inputRef.current.value = "";
            setAnswer(null);
        }
        dispatch(clearTranslatedAudio());
        setAudioUrl("");
        setIsRecording(true);
        audioChunksRef.current = [];
        mediaRecorderRef.current.start();
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorderRef.current.stop();
    };

    const deleteVoice = () => {
        setAudioUrl("");
        setAnswer(null);
        setIsSummarized(false);
        dispatch(clearTranslatedAudio());
        audioChunksRef.current = [];
    };
    const translateAudio = () => {
        dispatch(clearTranslatedAudio());
        if (!audioUrl) {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Upload a voice note",
            });
            return;
        }

        const presentaConnectionId = localStorage.getItem("connectionId");

        const data = {
            presentationId,
            presentaConnectionId,
        };
        const formData = new FormData();
        formData.append("file", audioFile);

        dispatch(translateQuestion({audio: formData, data}));
    };

    const LGstyle = {
        background: "linear-gradient(159.93deg, #FF6929 13.38%, #993F18 80.83%)",
    };

    return (
        <section className="z-20 font-grotesk">
            <Toast className="text-sm" ref={toast}/>
            <section className="flex justify-between w-[95%]">
                <p className="text-xs font-bold gap-x-4 text-gray60">NOW ANSWERING</p>

                <i
                    className="cursor-pointer pi pi-times"
                    style={{fontSize: "1rem"}}
                    onClick={() => setSelectedQuestion(null)}
                ></i>
            </section>

            <section className="flex w-full py-4 border-b gap-x-2">
                <div className="">
                    <img
                        src={avatar}
                        alt={question?.userId}
                        className="w-10 rounded-full"
                    />
                </div>

                <div className="flex items-center w-full gap-x-2">
                    <div className="w-full">
                        <div className="flex justify-between ">
                            <h3 className="font-semibold ">{name}</h3>
                            <p className="flex items-center text-xs font-semibold cursor-pointer gap-x-1 text-gray40 w-[30%]">
                                {DateFromNow(askTime)}
                            </p>
                        </div>

                        <div>
                            <p className="text-xs text-gray60 w-[90%] py-1 ">{request}</p>

                            <div className="flex items-center py-2 gap-x-4">
                                <div
                                    className="flex items-center text-xs font-semibold cursor-pointer gap-x-1 text-gray40">
                                    <img src={remove} alt="remove viewer"/>
                                    <p>slide {slideNumber}</p>
                                </div>

                                <div
                                    className="flex items-center text-xs font-semibold cursor-pointer gap-x-1 text-gray40">
                                    <img src={likeImg} alt="remove viewer" className="w-4"/>
                                    <p>{likes}</p>
                                </div>

                                <div
                                    className="flex items-center text-xs font-semibold cursor-pointer gap-x-1 text-gray40">
                                    <img src={dislikeImg} alt="remove viewer" className="w-4"/>
                                    <p>{dislikes}</p>
                                </div>
                            </div>
                        </div>

                        {/* answer box */}
                        {!question?.isAnswered && !isViewer && (
                            <section className="relative flex items-center mt-2 gap-x-2">
                                <section
                                    className="flex w-10/12 h-[10rem] px-2 py-2 text-sm border-2 rounded-md outline-none ">
                                    {/* text area  */}
                                    <section
                                        className={`${
                                            !audioUrl && translatedAudio.data?.request
                                                ? "w-11/12"
                                                : "w-full"
                                        }`}
                                    >
                                        {!audioUrl && (
                                            <textarea
                                                type="text"
                                                ref={inputRef}
                                                rows={10}
                                                col={10}
                                                onChange={(e) => setAnswer(e.target.value)}
                                                placeholder={`${
                                                    isRecording ? "Listening...." : "Type your answer"
                                                }`}
                                                className="w-11/12 overflow-y-scroll text-xs outline-none min-h-20 max-h-20 hide-scrollbar"
                                            ></textarea>
                                        )}

                                        {audioUrl && (
                                            <section>
                                                {!translatedAudio.data?.request && (
                                                    <section className="flex w-full gap-x-4">
                                                        <audio
                                                            controls={true}
                                                            src={audioUrl}
                                                            ref={audioRef}
                                                            className="h-[2rem] w-full"
                                                        />
                                                        <section className="flex gap-x-4">
                                                            {!isSummarized ? (
                                                                <div>
                                                                    {!translatedAudio.isLoading ? (
                                                                        <img
                                                                            src={AIButton}
                                                                            alt="speech to text"
                                                                            onClick={translateAudio}
                                                                            className="cursor-pointer "
                                                                        />
                                                                    ) : (
                                                                        <div
                                                                            style={LGstyle}
                                                                            className="flex items-center justify-center rounded-full w-7 h-7"
                                                                        >
                                                                            <ClipLoader color="white" size={12}/>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    src={oldVN}
                                                                    alt="speech to text"
                                                                    onClick={() => setIsSummarized(false)}
                                                                    className="cursor-pointer "
                                                                />
                                                            )}
                                                        </section>
                                                    </section>
                                                )}

                                                <section className="">
                                                    {/* summarized AI response  */}
                                                    <section
                                                        className=" text-xs font-medium  overflow-y-scroll  max-h-[6rem]">
                                                        {!isSummarized ? (
                                                            <>
                                                                {answer && (
                                                                    <textarea
                                                                        type="text"
                                                                        ref={inputRef}
                                                                        rows={10}
                                                                        col={10}
                                                                        defaultValue={answer}
                                                                        onChange={(e) => setAnswer(e.target.value)}
                                                                        placeholder={`${
                                                                            isRecording
                                                                                ? "Listening...."
                                                                                : "Type your answer"
                                                                        }`}
                                                                        className="w-11/12 overflow-y-scroll text-xs outline-none min-h-20 max-h-20 hide-scrollbar"
                                                                    ></textarea>
                                                                )}
                                                            </>
                                                        ) : (
                                                            // <p>{translatedAudio.data?.questionFull}</p>
                                                            <div>
                                                                {isSummaryLoading ? (
                                                                    <section className="w-full">
                                                                        <Skeleton
                                                                            width="100%"
                                                                            height="12px"
                                                                            className=""
                                                                        ></Skeleton>
                                                                        <Skeleton
                                                                            width="100%"
                                                                            height="12px"
                                                                            className="my-2"
                                                                        ></Skeleton>
                                                                        <Skeleton
                                                                            width="100%"
                                                                            height="12px"
                                                                            className=""
                                                                        ></Skeleton>
                                                                    </section>
                                                                ) : (
                                                                    <p>{translatedAudio.data?.request}</p>
                                                                )}
                                                            </div>
                                                        )}
                                                    </section>
                                                    {/*  */}

                                                    {/* summarize text  */}
                                                    {translatedAudio.data?.questionFull?.length > 200 &&
                                                        !isSummarized && (
                                                            <section
                                                                className="absolute text-xs font-semibold left-2 bottom-2 rounded-xl">
                                                                <div
                                                                    onClick={summmarizeText}
                                                                    className="flex items-center px-4 py-2 text-white bg-black rounded-full cursor-pointer gap-x-1"
                                                                >
                                                                    <span>Summarize</span>
                                                                    <img src={wand} alt=""/>
                                                                </div>
                                                            </section>
                                                        )}

                                                    {/*  */}
                                                </section>
                                            </section>
                                        )}
                                    </section>
                                    {/*  */}

                                    {/* send button  */}
                                    {(!audioUrl || translatedAudio.data?.request) && (
                                        <section>
                                            {isLoading ? (
                                                <ClipLoader color="green" size={12}/>
                                            ) : (
                                                <img src={plane} alt="" onClick={answerQuestion}/>
                                            )}
                                        </section>
                                    )}
                                    {/*  */}
                                </section>

                                <div className="self-start px-2 pt-1 ">
                                    {!audioUrl ? (
                                        <img
                                            alt=""
                                            className="w-[1.8rem] cursor-pointer"
                                            src={isRecording ? recordingImg : orangeRecord}
                                            onClick={isRecording ? stopRecording : startRecording}
                                        />
                                    ) : (
                                        <img
                                            src={trash}
                                            alt="speech to text"
                                            onClick={deleteVoice}
                                            className="cursor-pointer"
                                        />
                                    )}
                                </div>
                            </section>
                        )}
                        {/*  */}
                    </div>
                </div>
            </section>
        </section>
    );
}
