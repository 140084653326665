import React, {useEffect, useState} from "react";
import roundedArrowLeft from "assets/svg/roundedArrowLeft.svg";
import smallIcon from "assets/svg/smallIcon.svg";
import {AgendaInterface, EventsInterface, InnerPresentationInterface} from "interfaces/interfaces-data";
import presentationEnd from "assets/svg/presenationEnd.svg"
import {HubConnection} from "@microsoft/signalr";
import type {MenuProps} from 'antd';
import {Dropdown, Modal} from "antd";
import EndPresentations from "../../../../views/Admin/ActivePresentation/Modal";
import {CopyToClipBoard} from "../../wrapper/CopyToClipBoard";
import {useNavigate} from "react-router-dom";
import {ModalWrapper} from "../../wrapper/modal-wrapper";
import {CButton} from "../../Button/Buttons";
import moreButtom from "assets/svg/eventOwnerIcon.svg"
import endSessionIcon from "assets/svg/endSessionIcon.svg";
import eventIcon from "assets/svg/endEventIcon.svg"

export const DesktopPresentationHeader = ({
                                              loading,
                                              presentationDetails,
                                              connection,
                                              presentationId,
                                              isEvent,
                                              eventDetails,
                                              connected,
                                              collaboratorEndedModal,
                                              setCollaboratorEndModal,
                                              inNextPresentation,
                                              setInNextPresentation,
                                              upcomingEvent

                                          }: {
    loading: boolean;
    presentationDetails?: InnerPresentationInterface;
    connection?: HubConnection | null;
    presentationId?: string,
    eventDetails?: EventsInterface
    isEvent?: boolean,
    connected?: boolean,
    collaboratorEndedModal: boolean,
    setCollaboratorEndModal: (val: boolean) => void,
    inNextPresentation: boolean;
    setInNextPresentation: (val: boolean) => void
    upcomingEvent?: AgendaInterface
}) => {
    const [endModalOpen, setEndModalOpen] = useState(false);
    const [triggerAfterEventModal, setTriggerAfterEventModal] = useState(false);
    const [timeLeft, setTimeLeft] = useState(45)

    const endPresentation = () => {
        // Now , when a presenter ends an event ,the experience is expected to be different, then i need to switch to the next presentation , based off the current event that is going on
        if (isEvent && eventDetails?.role === "EventOwner") {
            console.log(" i Got in here", presentationId)
            connection?.invoke("EndPresentation", presentationId, eventDetails?.id ?? null);
        } else {
            connection?.invoke("EndPresentation", presentationId, eventDetails?.id ?? null).then((res) => {
                console.log("Inside Success", res)
            }).catch(e => {
                console.log(e, "Error Invoking End presentation")
            })
            if (!isEvent) {
                window.location.href = `/admin/presentation-details_?q=${presentationId}`
            }
        }
    }

    const endEvent = () => {
        if (connection && connected) {
            connection?.invoke("EndEvent", eventDetails?.id)
        }
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div className="cursor-pointer">
                    {eventDetails?.currentPresentation && <>
                        <div onClick={endPresentation} className="flex cursor-pointer items-center gap-2">
                    <span>
                        <img src={endSessionIcon} alt={""}/>
                    </span>

                            <p className="font-grotesk text-[#333] font-semibold">
                                End Presentation
                            </p>
                        </div>
                    </>}

                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div onClick={endEvent} className="flex items-center gap-2 cursor-pointer">
                    <span>
                        <img src={eventIcon} alt={""}/>
                    </span>

                    <p className="font-grotesk text-[#AE372A] font-semibold">
                        End Event
                    </p>
                </div>
            ),
        },

    ];

    // const handleOnEndPresentation = () => {
    //
    // }

    // useEffect(() => {
    //     if (connection && connected) {
    //         connection.on("EventResponse", (id, eventName, status, message) => {
    //             console.log(eventName, id, "Event name value here")
    //
    //         })
    //     }
    //
    // }, [connection, connected]);


    const navigate = useNavigate();
    useEffect(() => {
        let timeOutVal = null
        if (collaboratorEndedModal && !(timeLeft < 0)) {
            timeOutVal = setTimeout(() => {
                if (timeLeft === 0) {
                    window.location.replace(`${window.location.origin}/join-presentation/${eventDetails?.id}?isEvent=true`);
                }
                setTimeLeft((prev) => prev === 0 ? 0 : prev - 1)

            }, 1000)
        }

        return () => {
            if (timeOutVal) {
                clearTimeout(timeOutVal)
            }
        }

    }, [collaboratorEndedModal, timeLeft]);

    useEffect(() => {
        let timeOutVal = null
        if (inNextPresentation && !(timeLeft < 0)) {
            timeOutVal = setTimeout(() => {
                if (timeLeft === 0) {
                    {
                        upcomingEvent?.presentationId ? window.location.replace(`${window.location.origin}/active-presentation/${upcomingEvent?.presentationId}/${eventDetails?.id}?isEvent=true`) : window.location.replace(`${window.location.origin}/active-presentation/${upcomingEvent?.id}/${eventDetails?.id}?isEvent=true&isOnlyAgenda=true`)
                    }

                }
                setTimeLeft((prev) => prev === 0 ? 0 : prev - 1)
            }, 1000)
        }

        return () => {
            if (timeOutVal) {
                clearTimeout(timeOutVal)
            }
        }

    }, [inNextPresentation, timeLeft]);


    return (
        <div>
            {/*This modal is only triggered when an event collaborator ends thier presentation */}
            <ModalWrapper open={collaboratorEndedModal} onCancel={() => setCollaboratorEndModal(false)}>
                <div>
                    <p className="text-[#1D1F24] text-2xl font-semibold font-grotesk">Presentation Ended</p>
                    <p className="text-[#667085]">You will be moved to the audience view in {timeLeft} secs</p>
                    <div className={"flex w-full justify-center my-4 text-2xl"}>
                        {timeLeft}
                    </div>
                    <div>
                        <p className={"text-xs text-[#667085]"}>
                            More actions
                        </p>
                        {/*This would only trigger when it is an event */}
                        <div className={"w-full flex mt-2 justify-center gap-2"}>
                            <CButton action={() => navigate(`/admin/presentation-details_?q=${presentationId}`)}
                                     longer={true}
                                     fullWidth={true} text={"View my analytics"}
                                     variant={"dark"}/>
                            <CButton
                                action={() => navigate(`/join-presentation/${eventDetails?.id}?isEvent=true`)}
                                longer={true} fullWidth={true} text={"Continue as an audience"}
                                variant={"dark"}/>
                        </div>
                    </div>
                </div>
            </ModalWrapper>
            <ModalWrapper open={inNextPresentation} onCancel={() => setInNextPresentation(false)}>
                <div>
                    <p className="text-[#1D1F24] text-2xl font-semibold font-grotesk">Your presentation is coming up
                        next</p>
                    <p className="text-[#667085]">You will be switched to a presenter in your next session in {timeLeft},
                        Get ready</p>
                    <div className={"flex justify-center text-2xl w-full my-4"}>
                        {timeLeft}
                    </div>
                    <div>
                        <p className={"text-xs text-[#667085]"}>
                            More actions
                        </p>
                        {/*This would only trigger when it is an event */}
                        <div className={"w-full flex mt-2 justify-center gap-2"}>
                            <CButton
                                action={() => window.location.replace(`${window.location.origin}/join-presentation/${eventDetails?.id}?isEvent=true`)}
                                longer={true}
                                fullWidth={true} text={"Stay as an audience"}
                                variant={"dark"}/>
                            {/*Now, if the presentation does nt have an id , what i want to send is the agendaId , but with a flag that shows that it is an agenda alone */}

                            {
                                upcomingEvent?.presentationId ? <CButton
                                    action={() => {
                                        window.location.replace(`${window.location.origin}/active-presentation/${upcomingEvent?.presentationId}/${eventDetails?.id}?isEvent=true`)
                                    }}
                                    longer={true} fullWidth={true} text={"Take me to my presentation"}
                                    variant={"dark"}/> : <CButton
                                    action={() => {
                                        window.location.replace(`${window.location.origin}/active-presentation/${upcomingEvent?.id}/${eventDetails?.id}?isEvent=true&isOnlyAgenda=true`)
                                    }}
                                    longer={true} fullWidth={true} text={"Take me to my presentation"}
                                    variant={"dark"}/>
                            }


                        </div>
                    </div>
                </div>
            </ModalWrapper>


            <Modal
                open={endModalOpen}
                onCancel={() => setEndModalOpen(false)}
                footer={null}
            >
                <EndPresentations
                    endPresentation={endPresentation}
                    closeModal={() => setEndModalOpen(false)}
                    isEvent={isEvent}
                    eventDetails={eventDetails}
                />
            </Modal>

            <div
                className="border-[#E5E7EB] flex justify-between item-center pr-4 items-center border-b border-r w-full ">

                <div className="flex items-center py-3 px-4">
                    <button onClick={() => navigate(-1)} className="pr-4 cursor-pointer">
                        <img alt="" src={roundedArrowLeft}/>
                    </button>
                    <div className="border-l pl-4 border-[#E5E7EB]">

                        <p className="text-[#757575] text-sm ">{isEvent ? eventDetails?.title : "Presentation"}</p>
                        <p className="text-[#111827] text-base font-semibold">
                            {loading ? "_" : presentationDetails?.presentation.title}
                        </p>
                    </div>
                </div>
                <CopyToClipBoard text={presentationDetails?.presentation.friendlyId ?? ""}>
                    <div className="bg-[#F6F6F6] p-3 rounded-2xl flex items-center gap-2">
                        <div>
                            <img src={smallIcon} alt=""/>
                        </div>
                        <p className="text-[#333333] font-semibold text-base">
                            Meeting code:
                        </p>
                        <p className="text-[#333333] font-semibold text-base">
                            {loading ? "_" : presentationDetails?.presentation.friendlyId}
                        </p>
                    </div>
                </CopyToClipBoard>

                {connection && connection.state === "Connected" && isEvent && eventDetails?.role === "EventOwner" ?
                    <Dropdown menu={{items}}>
                        <div className={"cursor-pointer"}>
                            <img src={moreButtom} alt={""}/>
                        </div>
                    </Dropdown>
                    : <button onClick={() => setEndModalOpen(true)}>
                        <img alt="" src={presentationEnd}/>
                    </button>}

            </div>
        </div>

    );
};
