import searchIcon from 'assets/svg/searchIcon.svg';

export const SearchInput = ({
  handleInputChange,
}: {
  handleInputChange: (val: string) => void;
}) => {
  return (
    <span className='relative'>
      <img className='absolute top-0 left-4' src={searchIcon} alt={''} />
      <input
        type={'text'}
        onChange={(e) => handleInputChange(e.target.value)}
        className='bg-[#F5F5F5] px-4 pl-10 focus:outline-none focus:border-2 text-sm font-grotesk font-semibold py-2 rounded-lg'
        placeholder='Search'
      />
    </span>
  );
};
