// Assets
import avatarIMG from 'assets/image/avatar-image1.jpg';

export default function Header({
  title = 'Creators name',
  ownerName = '',
}: {
  title?: string;
  ownerName?: string;
}) {
  return (
    <section className='bg-white py-2 w-full flex justify-between h-[10% md:h-[8% h-[40px] mb-5 twoK:h-[50px] eightK:h-[100px]'>
      <div>
        <p className='text-[#111827] text-sm twoK:text-lg fourK:text-xl eightK:text-3xl font-semibold'>
          {title}
        </p>
        <p className='text-[#757575] text-sm twoK:text-lg fourK:text-xl eightK:text-3xl'>
          {ownerName}
        </p>
      </div>
      <div className='w-8 h-8 rounded-full overflow-hidden'>
        <img
          src={avatarIMG}
          alt='profile'
          className='w-8 h-8 rounded-full overflow-hidden'
        />
      </div>
    </section>
  );
}
