import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

// Assets
import PollTemplates from './Tables/PollTemplates';
import MyPolls from './Tables/MyPolls';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { FetchedPoll } from 'store/PollCopy/pollCopySlice';

export default function Polls() {
  //
  let [searchParams] = useSearchParams();

  const [tab, setTab] = useState(0);

  const { isLoading, data: fetchedPolls } = useSelector(
    (state: RootState) => state.pollcopy.pollTemplates
  );
  const [polls, setPolls] = useState<FetchedPoll[] | null>(null);

  useEffect(() => {
    if (fetchedPolls) {
      setPolls(fetchedPolls);
    }
  }, [fetchedPolls]);

  useEffect(() => {
    const tabParam = searchParams.get('tab')?.toLowerCase();
    !tabParam && setTab(0);
    tabParam === 'my-polls' && setTab(0);
    tabParam === 'templates' && setTab(1);
  }, [searchParams]);

  const handleSearch = (e: string) => {
    const val = e.toLowerCase();
    if (fetchedPolls) {
      const result = fetchedPolls.filter((poll) => {
        return poll?.title?.toLowerCase().includes(val);
      });
      setPolls(result);
    }
  };

  return (
    <section>
      {/* Tabs */}
      <section className='flex gap-x-4 items-center justify-between'>
        <section className='flex gap-x-2 border-b'>
          <Link to={`/admin/polls?tab=my-polls`}>
            <button
              onClick={() => setTab(0)}
              className={`px-4 pb-2 cursor-pointer text-sm  ${
                tab === 0 && 'border-b-2  text-primary border-b-primary'
              }`}
            >
              My Polls
            </button>
          </Link>

          <Link to={`/admin/polls?tab=templates`}>
            <button
              onClick={() => setTab(1)}
              className={`px-4 pb-2 cursor-pointer text-sm  ${
                tab === 1 && 'border-b-2  text-primary border-b-primary'
              }`}
            >
              Templates
            </button>
          </Link>
        </section>
        <div>
          <SearchInput handleInputChange={handleSearch} />
        </div>
      </section>

      {/* Main content */}
      <section className='my-8 pb-20'>
        {tab === 0 && <MyPolls isLoading={isLoading} polls={polls} />}
        {tab === 1 && <PollTemplates />}
      </section>

      {/* Modals */}
    </section>
  );
}
