import React, {useEffect, useState} from "react";

//assets
import search from "assets/svg/search.svg";

//components
import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import {useSelector} from "react-redux";
import {getPresentationState} from "store/Presentations/presentationSlice";
import sortIcon from "assets/svg/sort.svg"

export default function NavigationTab({tab, setTab, searchPresentation}) {
    //useselector
    const {presentations} = useSelector(getPresentationState);
    const presentationList = presentations?.data;

    //usestate
    const [allCount, setAllCount] = useState(0);
    const [pastCount, setPastCount] = useState(0);
    // const [pausedCount, setPausedCount] = useState(0);
    const [ongoingCount, setOngoingCount] = useState(0);
    const [scheduledCount, setScheduledCount] = useState(0);
    const [unscheduledCount, setUnscheduledCount] = useState(0);

    useEffect(() => {
        const allCount = presentationList?.length;
        const ongoingCount = presentations?.data?.filter((presentation) => {
            return presentation?.status === 2 || presentation?.status === 3;
        })?.length;
        const scheduledCount = presentations?.data?.filter((presentation) => {
            return presentation?.status === 1;
        })?.length;

        const pastCount = presentations?.data?.filter((presentation) => {
            return presentation?.status === 4;
        })?.length;

        const unscheduledCount = presentations?.data?.filter((presentation) => {
            return presentation?.status === 0;
        })?.length;

        // const pausedCount = presentations?.data?.filter((presentation) => {
        //   return presentation?.status === 3;
        // })?.length;

        setAllCount(allCount);
        setPastCount(pastCount);
        // setPausedCount(pausedCount);
        setOngoingCount(ongoingCount);
        setScheduledCount(scheduledCount);
        setUnscheduledCount(unscheduledCount);
    }, [presentationList, presentations]);

    return (
        <section>
            <FlexContainer isResponsive={true}>
                {/* <section className="flex border-b gap-x-4 ">
          
          <section
            onClick={() => {
              localStorage.setItem("presentationTabActive", 1);
              setTab(1);
            }}
            className={`flex items-center gap-x-2 px-6 py-1 cursor-pointer ${
              tab === 1
                ? "text-primary border-b-primary border-b font-medium"
                : "text-black "
            }`}
          >
            <p className={`  `}>Scheduled presentation</p>

            <p
              className={` px-2 py-1    text-xs text-center justify-center items-center flex rounded-full font-semibold ${
                tab === 1 ? "bg-primary text-white" : "bg-gray-300 text-black"
              }`}
            >
              {scheduledCount}
            </p>
          </section>

          <section
            onClick={() => {
              localStorage.setItem("presentationTabActive", 2);

              setTab(2);
            }}
            className={`flex items-center gap-x-2 px-6 py-1 cursor-pointer ${
              tab === 2
                ? "text-primary border-b-primary border-b font-medium"
                : "text-black "
            }`}
          >
            <p className={`  `}>Ongoing Presentation</p>

            <p
              className={` px-2 py-1    text-xs text-center justify-center items-center flex rounded-full font-semibold ${
                tab === 2 ? "bg-primary text-white" : "bg-gray-300 text-black"
              }`}
            >
              {ongoingCount}
            </p>
          </section>

          <section
            onClick={() => {
              localStorage.setItem("presentationTabActive", 4);

              setTab(4);
            }}
            className={`flex items-center gap-x-2 px-6 py-1 cursor-pointer ${
              tab === 4
                ? "text-primary border-b-primary border-b font-medium"
                : "text-black "
            }`}
          >
            <p className={`  `}>Past Presentation</p>
            <p
              className={` px-2 py-1    text-xs text-center justify-center items-center flex rounded-full font-semibold ${
                tab === 4 ? "bg-primary text-white" : "bg-gray-300 text-black"
              }`}
            >
              {pastCount}
            </p>
          </section>
        </section> */}

                {/* <div className="flex items-center px-4 py-2 text-xs text-sm rounded-md lg:text-sm gap-x-2 bg-gray30">
        <img src={sort} alt="sort" />
        All presentation
      </div> */}
                <div className="flex items-center px-4 py-2 text-xs text-sm rounded-md lg:text-sm gap-x-2 bg-gray30">
                    <img src={sortIcon} alt={""}/>
                    <select
                        onChange={searchPresentation}
                        placeholder=" Search presentations"
                        className="bg-transparent w-[90%] py-1 outline-none"
                    >
                        <option value={""}>
                            Sort Presentation
                        </option>
                    </select>
                </div>

                <div className="flex items-center px-4 py-2 text-xs text-sm rounded-md lg:text-sm gap-x-2 bg-gray30">
                    <img src={search} alt="sort"/>
                    <input
                        onChange={searchPresentation}
                        placeholder=" Search presentations"
                        className="bg-transparent w-[90%] py-1 outline-none"
                    />
                </div>
            </FlexContainer>
        </section>
    );
}
