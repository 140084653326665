import MainContent from 'components/Admin/Polls/MainContent';
import Navbar from 'components/Admin/Polls/Navbar';
import Sidebar from 'components/Admin/Polls/Sidebar';
import { useEffect, useState } from 'react';
import { FetchedPollData, Poll, PollQuestion } from 'interfaces/PollSetup';
import {
  localStorageGetter,
  localStorageSetter,
} from 'utils/localStorageServices';
import { createPoll } from 'store/PollCopy/pollCopySlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { toast } from 'sonner';
import { useParams, useSearchParams } from 'react-router-dom';
import Axios from 'lib/axiosInterceptor';
import Loader from 'components/UI/Loader';

export default function PollSetup() {
  const dispatch = useDispatch<AppDispatch>();
  const { pollId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const title = searchParams.get('title');

  const newPoll: Poll = {
    title: title ?? 'My poll title',
    startTime: new Date(),
    position: 0,
    status: 0,
    questions: [
      {
        questionType: 'default',
        rank: 0,
        question: '',
        duration: 0,
        options: [
          {
            rank: 0,
            text: '',
            color: '',
            isSelected: false,
          },
        ],
      },
    ],
  };

  const [poll, setPoll] = useState<Poll | FetchedPollData>(newPoll);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0);
  const [activeQuestion, setActiveQuestion] = useState<PollQuestion>(
    poll.questions[activeQuestionIndex]
  );
  const [isLoading, setIsLoading] = useState(true);

  // Fetching Poll from db or Local storage
  useEffect(() => {
    if (pollId) {
      fetchPolls();
    } else {
      // const storedPoll = localStorageGetter('poll');
      // console.log('storedPoll ', storedPoll);
      // if (storedPoll) {
      //   storedPoll && setPoll(storedPoll);
      //   setActiveQuestion(storedPoll.questions[0]);
      // }
      setIsLoading(false);
    }
  }, [pollId]);

  // updating Poll
  useEffect(() => {
    setPoll((prevPoll) => {
      return {
        ...prevPoll,
        questions: prevPoll.questions.map((question, index) =>
          index === activeQuestionIndex
            ? { ...activeQuestion }
            : { ...question }
        ),
      };
    });
  }, [activeQuestion, activeQuestionIndex]);

  // functions
  const fetchPolls = async () => {
    try {
      const result = await Axios.get(`/Poll/Templates`);
      const data: FetchedPollData[] = result.data;
      if (data) {
        const foundPoll = data.find((poll) => poll.rowKey === pollId);
        if (foundPoll) {
          setPoll(foundPoll);
          setActiveQuestion(foundPoll.questions[0]);
        }
      }
    } catch (error) {
      console.log('An error occured!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    // Saves progress
    // localStorageSetter('poll', poll);
    toast.success('Progress saved!');
  };

  const handleCreatePoll = async () => {
    if (!pollId) {
      dispatch(createPoll({ data: poll }));
    } else {
      try {
        // await Axios.put('/Poll', poll);
      } catch (error) {
        toast.error('Failed to update poll');
      }
    }
  };

  const handleUpdateTitle = ({ newTitle }: { newTitle: string }) => {
    setPoll((prevPoll) => {
      return {
        ...prevPoll,
        title: newTitle,
      };
    });
    setSearchParams({ title: newTitle });
  };

  return (
    <section className='w-full relative bg-[#F2F2F2] h-screen overflow-hidden'>
      {isLoading ? (
        <Loader />
      ) : (
        <section>
          <Navbar
            handleCreatePoll={handleCreatePoll}
            poll={poll}
            handleUpdateTitle={handleUpdateTitle}
          />
          <Sidebar
            poll={poll}
            activeQuestionIndex={activeQuestionIndex}
            setActiveQuestionIndex={setActiveQuestionIndex}
            activeQuestion={activeQuestion}
            setPoll={setPoll}
            setActiveQuestion={setActiveQuestion}
          />
          <MainContent
            poll={poll}
            activeQuestion={activeQuestion}
            activeQuestionIndex={activeQuestionIndex}
            setActiveQuestionIndex={setActiveQuestionIndex}
            handleSave={handleSave}
            setPoll={setPoll}
            setActiveQuestion={setActiveQuestion}
          />
        </section>
      )}
    </section>
  );
}
