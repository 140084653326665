import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

// Assets
import imageTypeIcon from 'assets/svg/imagetype-icon-jpeg-png.svg';

// Slice
import { Tooltip } from 'antd';
import { ImageCache } from 'types/Users/SharedBriefcase/SharedBriefcaseTypes';

// Interface
interface PreviewDocument {
  sessionId: string;
  sessionType: number;
  documentId: string;
  title: string | null;
  note: string | null;
  position: number;
  url: string;
  type: string | null;
  deleted: boolean;
  id: string;
  folderId: string | null;
}

// Interface for each item in the main array
export interface PreviewPayload {
  id: string | null;
  title: string | null;
  docs: PreviewDocument[];
  subs: PreviewPayload[];
}

interface Props {
  doc: PreviewDocument;
  displayDocumentIndex: number;
  setDisplayDocumentIndex: Dispatch<SetStateAction<number>>;
  setSlideDocumentIndex: Dispatch<SetStateAction<number>>;
  flattedDisplayDocuments: PreviewDocument[];
  imageCache: MutableRefObject<ImageCache[]>;
  fetchedFlattenedDocIndex: number | null;
}

export default function FolderChildren({
  doc,
  displayDocumentIndex,
  setDisplayDocumentIndex,
  flattedDisplayDocuments,
  setSlideDocumentIndex,
  imageCache,
  fetchedFlattenedDocIndex,
}: Props) {
  // State

  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

  useEffect(() => {
    const docIndex = flattedDisplayDocuments.findIndex(
      (document) => document.id === doc.id
    );
    if (fetchedFlattenedDocIndex && docIndex <= fetchedFlattenedDocIndex - 1) {
      // console.log('Fetched', docIndex);
      setIsDocumentLoaded(true);
    } else {
      console.log('Image not fetched', docIndex);
    }
  }, [flattedDisplayDocuments, doc, fetchedFlattenedDocIndex]);

  return (
    <div
      className={`py-1 px-1 cursor-pointer flex gap-x-2 items-center my-2 ${
        flattedDisplayDocuments[displayDocumentIndex].id === doc.id
          ? 'bg-[#F6F3EF] border border-[#D7D7D7] rounded-md'
          : ''
      }
      ${isDocumentLoaded ? 'cursor-pointer' : 'pointer-events-none'}
      `}
      key={doc.id}
      onClick={() => {
        if (isDocumentLoaded) {
          const docIndex = flattedDisplayDocuments.findIndex(
            (document) => document.id === doc.id
          );
          setDisplayDocumentIndex(docIndex);
          setSlideDocumentIndex(0);
        }
      }}
    >
      <img src={imageTypeIcon} alt='type' className='w-5' />
      <Tooltip
        title={
          <p
            className={` text-black text-sm twoK:text-lg fourK:text-xl eightK:text-3xl`}
          >
            {doc.title}
          </p>
        }
        color='white'
        placement='top'
      >
        <p
          className={` ${
            isDocumentLoaded ? 'text-black' : 'text-gray-400'
          } truncate max-w-full text-sm twoK:text-lg fourK:text-xl eightK:text-3xl`}
        >
          {doc.title}
        </p>
      </Tooltip>
    </div>
  );
}
