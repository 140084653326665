import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

export default function NavLink({nav, isNewInvite}) {
    const {link, icon, inactiveIcon, name, otherLinks} = nav ?? {};
    const {pathname} = useLocation();

    const [isActive, setisActive] = useState(false);

    //useeffect

    useEffect(() => {
        const links = otherLinks ? [link, ...otherLinks] : [link];

        const isExist = links?.find((url) => {
            const lowercasePath = pathname?.toLowerCase();
            return lowercasePath?.includes(url);
        });
        setisActive(isExist);
    }, [pathname, link, otherLinks]);

    return (
        <Link to={link}>
            <div
                className={`${name === "Events" && "justify-between"} px-2 py-2 lg:py-1 xl:py-2 flex items-center text-xs lh-lg  my-1 gap-x-2  ${
                    isActive && ` border rounded-lg border-gray10 bg-orange30 w-full `
                } `}
            >
                <div className="flex gap-2">
                    <img
                        src={pathname === link ? icon : inactiveIcon}
                        alt={name}
                        className='w-4'
                    />
                    <p>{name}</p>
                </div>
                {name === "Events" && isNewInvite && <span className="w-[10px] h-[10px] rounded-full bg-[#FF6929]">

                </span>}

            </div>
        </Link>
    );
}
