import {
    AgendaInterface,
    EventsInterface,
    InnerPresentationInterface,
    PresentationDisplayInterface
} from "../../../../interfaces/interfaces-data";
import groupIconWhite from "../../../../assets/svg/agendaIconWhite.svg";
import groupIcon from "../../../../assets/svg/groupIcon.svg";
import {resolveImageType} from "../../../../utils/resolveImageType";
import {getFileExtension} from "../../../../utils/getFileExtension";
import {useEffect, useState} from "react";
import {CButton} from "../../Button/Buttons";
import playIcon from "assets/svg/playIcon.svg"
import arrowDown from "assets/svg/arrowDown.svg"
import {ModalWrapper} from "../../wrapper/modal-wrapper";
import {Col, Row} from "antd"
import {HubConnection} from "@microsoft/signalr";
import {getConnectionId} from "../../../../utils/localStorageServices";
import playBroken from "assets/svg/playBroken.svg"

export const DesktopAgenda = ({
                                  eventDetails,
                                  presentationDisplayDetails,
                                  presentationDetails,
                                  activeDocumentId,
                                  connection,
                                  presentationId,
                                  isEvent,
                                  upcomingAgenda
                              }: {
    eventDetails: EventsInterface,
    presentationDisplayDetails?: PresentationDisplayInterface,
    presentationDetails?: InnerPresentationInterface,
    activeDocumentId: string,
    connection?: HubConnection | null,
    presentationId?: string,
    isEvent?: boolean,
    upcomingAgenda?: AgendaInterface
}) => {
    const [showContent, setShowContent] = useState(true)
    const [startSessionModal, setStartSessionModal] = useState(false);
    const [presentationToStartId, setPresentationToStartId] = useState("");
    const [hideEndPresentationButton, setHideEndPresentationButton] = useState(false)

    const startNewPresentation = (nPresentationId: string) => {
        // endPresentation();
        console.log("Attend", nPresentationId, 0, getConnectionId(), isEvent, eventDetails.id)
        connection?.invoke("Attend", nPresentationId, 0, getConnectionId(), isEvent, eventDetails.id);
    }

    const endPresentation = () => {
        // Now , when a presenter ends an event ,the experience is expected to be different, then i need to switch to the next presentation , based off the current event that is going on
        // if (eventDetails?.role === "EventOwner") {
        connection?.invoke("EndPresentation", presentationId, eventDetails?.id ?? null);
        // }
        // else {
        //     connection?.invoke("EndPresentation", presentationId, eventDetails?.id ?? null).then((res) => {
        //         console.log("Inside Success", res)
        //     }).catch(e => {
        //         console.log(e, "Error Invoking End presentation")
        //     })
        //
        // }
    }

    useEffect(() => {
        if (connection) {
            connection.on("EventResponse", (id, eventName, status, message) => {
                if (eventName === "Attend") {
                    console.log(status);
                }
                if (eventName === "EndPresentation" && status) {
                    setHideEndPresentationButton(true)
                }
            })
        }
    }, [connection]);
    return <>
        <ModalWrapper open={startSessionModal} onCancel={() => setStartSessionModal(false)}>
            <div>
                <p className="font-grotesk text-[#333333] font-semibold text-xl">
                    Start Session
                </p>
                <p className={"font-grotesk font-[#333] my-2"}>
                    Starting this session would end the current presentation and notify the presenters and co-presenters
                    of the new presentation.
                </p>
                <Row>
                    <Col xs={24}>
                        <CButton action={() => {
                            // handleCurrentPresentationEnd("");
                            // startNewSession()
                            endPresentation()
                        }} variant={'dark'} text={"Continue"} fullWidth/>
                    </Col>

                </Row>
            </div>
        </ModalWrapper>
        {eventDetails && eventDetails?.agenda.length && eventDetails?.agenda.map(item => {
            return <div key={item.id}>
                <div
                    onClick={() => {
                        if (eventDetails.currentSession && eventDetails.currentSession === item.id) {
                            setShowContent(prev => !prev)
                        }
                    }}
                    className={`flex justify-between cursor-pointer p-2 rounded-lg items-center border border-[#D7D7D7]  ${eventDetails.currentSession && eventDetails.currentSession === item.id ? "bg-[#000000] text-white" : "bg-[#F6F3EF]"}`}>
                    <span className={"flex items-center gap-3"}>
                        <img
                            src={eventDetails.currentSession && eventDetails.currentSession === item.id ? groupIconWhite : groupIcon}
                            alt={""}/>
                        <p>{item.title}</p>
                    </span>
                    <div className={"flex items-center gap-2"}>
                        {eventDetails.role === "EventOwner" && <span>
                            {eventDetails.currentSession && eventDetails.currentSession === item.id ?
                               (!hideEndPresentationButton &&
                                   <CButton action={() => endPresentation()} text={"End Presentation"}
                                            variant={'orangePlain'}/>):
                                upcomingAgenda?.id === item.id ? <div>
                                    <CButton action={() => {
                                        // setPresentationToStartId(item.presentationId)
                                        // setStartSessionModal(true)
                                        startNewPresentation(item.presentationId)
                                    }} iconPosition={"left"} icon={playBroken} text={"Present now"} variant={'dark'}
                                    />
                                </div> : (item.presentation.presentation.status !== 4) && (item.presentation.presentation.status === 2 ?
                                    <CButton action={() => {
                                        setPresentationToStartId(item.presentationId)
                                        setStartSessionModal(true)
                                    }} iconPosition={"left"} icon={playIcon} text={"Go To Presentation"}
                                             variant={'plain'}
                                    /> : item.presentation.presentation.status === 3 ? <CButton action={() => {
                                        setPresentationToStartId(item.presentationId)
                                        setStartSessionModal(true)
                                    }} iconPosition={"left"} icon={playIcon} text={"Resume"} variant={'plain'}
                                    /> : <CButton action={() => {
                                        setPresentationToStartId(item.presentationId)
                                        setStartSessionModal(true)
                                    }} iconPosition={"left"} icon={playIcon} text={"Start Session"}
                                                  variant={'plain'}

                                    />)}

                        </span>}
                        <span>
                            <img src={arrowDown} alt={""}/>
                        </span>
                    </div>
                </div>
                {showContent && <div className={"py-2 mx-4  px-3 border-l"}>
                    {eventDetails.currentSession && eventDetails.currentSession === item.id && presentationDisplayDetails && Object.keys(presentationDisplayDetails).length && presentationDetails?.doc.map(item => {
                        return <div key={item.id}>
                            {presentationDisplayDetails[item.documentId] &&
                                <button onClick={() => {
                                    const firstUnlockedObject = presentationDisplayDetails[item.documentId].find(item => item.unlocked)
                                    // userOutOfSyncRef.current = true;
                                    // setUserOutOfSyncState(true);
                                    // setActiveDocumentId(item.documentId)
                                    // find the index of the first unlocked slide
                                    const newIndex = presentationDisplayDetails[item.documentId].findIndex(item => item.unlocked)
                                    // setActiveIndex(newIndex);
                                }} className={"pb-3 flex justify-start items-start gap-2 w-full"}>
                                    <div>
                                        <img
                                            alt={""}
                                            src={resolveImageType(item.documentId === activeDocumentId, getFileExtension(item.url))}/>
                                    </div>
                                    <span className={"w-full"}>
                                        <p className={`${item.documentId === activeDocumentId ? 'text-[#FF6929]' : 'text-[#000000]'} min-w-[200px] w-[250px] max-w-full font-semibold text-sm text-ellipsis overflow-hidden whitespace-nowrap text-left `}>{item.title}</p>
                                    </span>
                                </button>}
                        </div>
                    })
                    }
                </div>}
            </div>
        })}
    </>
}