import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

//libraries
import {Drawer} from "antd";
import {ClipLoader} from "react-spinners";

//assets
import link from "assets/svg/link.svg";
import more from "assets/svg/more.svg";
import settings from "assets/svg/settings.svg";
import outlinePlay from "assets/svg/outline-play.svg";

//components
import Playlist from "./Playlist";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import SchedulePresentation from "views/Admin/Presentation/Modals/SchedulePresentation";

//slices
import {
    getPresentationDetails,
    getPresentationState,
    removeDocumentFromPlaylist,
    updatePresentationEdit,
} from "store/Presentations/presentationSlice";

//utils
import {getSlidesState} from "store/Slides/slidesSlice";
import instance from "../../../../../lib/axiosInterceptor";
import {toast} from "sonner";

export default function EditPresentationNavbar({
                                                   startTime,
                                                   documentList,
                                                   presentationId,
                                                   activePlaylist,
                                                   setActivePlaylist,
                                                   presentationStatus,
                                                   isEvent,
                                                   eventId
                                               }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log(eventId, isEvent, "Event Id and IsEvent flag");

    //useref
    const dropdownRef = useRef(null);
    const scheduleModalRef = useRef();
    const moreDropdownRef = useRef(null);

    //useselector
    const {sideImages} = useSelector(getSlidesState);
    const {presentationDetails} = useSelector(getPresentationState);

    //usestate
    const [friendlyId, setFriendlyId] = useState(null);
    const [removeDocId, setRemoveDocId] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [openScheduleModal, setOpenScheduleModal] = useState(false);
    const [openOptionsDropdown, setOpenOptionsDropdown] = useState(false);
    const [isUpdateSlideLoading, setIsUpdateSlideLoading] = useState(false);
    const [openMoreOptionsDropdown, setOpenMoreOptionsDropdown] = useState(false);
    const [eventDetails, setEventDetails] = useState(false)

    //function
    const closeConfirmDelete = () => {
        setConfirmDelete(false);
    };

    const removeDocument = () => {
        setIsDeleteLoading(true);
        dispatch(removeDocumentFromPlaylist({presentationId, removeDocId})).then(
            ({type}) => {
                setIsDeleteLoading(false);
                setRemoveDocId(null);

                if (type.includes("fulfilled")) {
                    closeConfirmDelete();
                    toast.success("Document removed successfully");

                    // to be looked at
                    dispatch(getPresentationDetails(presentationId));
                }
            }
        );
    };

    //useeffect
    useEffect(() => {
        if (presentationDetails.data) {
            setFriendlyId(presentationDetails.data?.presentation?.friendlyId);
        }
    }, [presentationDetails]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenOptionsDropdown(false);
            }

            if (
                moreDropdownRef.current &&
                !moreDropdownRef.current.contains(event.target)
            ) {
                setOpenMoreOptionsDropdown(false);
            }
        };

        // Adding event listener to handle clicks outside the dropdown
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            // Cleanup: remove event listener when component unmounts
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const settingsOptions = [
        {
            label: "Allow questions",
        },

        {
            label: "Download access",
        },

        {
            label: "Watermark download",
        },

        {
            label: "Show banner",
        },

        {
            label: "Show timer",
        },
    ];

    const moreOptions = [
        {
            label: presentationStatus === 3 || presentationStatus === 2
                    ? "Continue presentation"
                    : "Present now",
            // label: "Present now",
            icon: outlinePlay,
            func: () => {
                (navigate((isEvent && eventId && eventDetails.status === 1) ? `/active-presentation/${presentationId}/${eventId}?isEvent=true` : `/active-presentation/${presentationId}`, {replace: true}))
            }
        },

        {
            label: "Create Event",
            icon: outlinePlay,
            func: () => {
                setOpenScheduleModal(true);
                scheduleModalRef.current?.openDrawer();
            },
        },
        {
            label: "Copy Presentation Link",
            icon: link,
            func: () => shareLink(),
        },
    ];

    //functions

    const handleCloseModal = () => {
        setOpenScheduleModal(false);
        scheduleModalRef.current.resetModal();
    };

    const shareLink = async () => {

        let routeOrigin = window.location.origin;
        let text = `${routeOrigin}/join-presentation/${friendlyId}`;
        try {
            await navigator.clipboard.writeText(text);
            toast.success("Link copied to clipboard");
        } catch (error) {
            toast.error("Unable to copy text:", error);
        }
    };

    const saveChanges = () => {
        const positions = sideImages.map((slide, index) => {
            let newPosition = index + 1;
            return {
                documentId: slide.id,
                position: newPosition,
                note: slide.note,
                title: `Slide_${newPosition}`,
            };
        });

        const data = {
            documentId: activePlaylist?.documentId,
            position: activePlaylist?.position,
            positions,
        };

        setIsUpdateSlideLoading(true);

        dispatch(updatePresentationEdit({presentationId, data})).then(
            ({payload}) => {
                if (payload === "") {
                    toast.success("Update successful");
                }

                setIsUpdateSlideLoading(false);
            }
        );
    };

    const getEventDetail = async () => {
        try {
            const result = await instance.get(`/Events/${presentationDetails.data?.presentation?.eventId}`);
            console.log(result)
            setEventDetails(result.data);

        } catch (e) {

        }
    }

    // Try to get the status of the event and then use it for the status

    useEffect(() => {
        if (presentationDetails.data?.presentation?.isEvent) {
            getEventDetail()
        }
    }, [presentationDetails]);

    return (
        <section>
            {/* lg:overflow-hidden md:overflow-hidden overflow-x-scroll hide-scrollbar */}
            <nav className="fixed top-0 z-40 w-full h-[5.5rem]  pt-3  bg-white border-b  ">
                <section className="flex items-center">
                    <div className="px-6 py-3 border-r">
                        <i
                            className="font-bold cursor-pointer pi pi-chevron-circle-left"
                            onClick={() => navigate(-1)}
                        ></i>
                    </div>

                    <FlexContainer extraClass="px-4 w-full items-center">
                        <section className="flex items-center gap-x-10">
                            <div className="px-4 border-r">
                                <p className="hidden text-sm text-gray-500 lg:block md:block ">
                                    Personal workspace
                                </p>

                                <h2 className="font-bold font-grotesk">
                                    {presentationDetails.data?.presentation?.title}
                                </h2>
                            </div>

                            <Playlist
                                documentList={documentList}
                                setRemoveDocId={setRemoveDocId}
                                activePlaylist={activePlaylist}
                                setConfirmDelete={setConfirmDelete}
                                setActivePlaylist={setActivePlaylist}
                            />
                        </section>

                        <div className="flex gap-x-4">
                            {/* settings */}
                            <div>
                                <div className="relative">
                                    <div
                                        onClick={() => setOpenOptionsDropdown(!openOptionsDropdown)}
                                        className="flex items-center justify-center w-8 h-8 border rounded-md cursor-pointer bg-gray20 lg:hidden "
                                    >
                                        <img src={settings} alt="settings" className="w-4"/>
                                    </div>

                                    <div className="absolute right-0 top-10">
                                        {openOptionsDropdown && (
                                            <div
                                                ref={dropdownRef}
                                                className="z-40 flex flex-col px-3 py-4 bg-white rounded-lg shadow w-52 gap-y-4"
                                            >
                                                {settingsOptions?.map((option, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <FlexContainer>
                                                                <p className="text-sm font-medium">
                                                                    {option.label}
                                                                </p>

                                                                <div className="custom-switch">
                                                                    <label className="switch">
                                                                        <input type="checkbox"/>
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                </div>
                                                            </FlexContainer>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/*  */}

                            {/* save changes */}
                            <div>
                                <FlexContainer
                                    onClick={saveChanges}
                                    extraClass="border border-green-500 cursor-pointer rounded-lg font-semibold  text-green-500 text-xs px-4 py-2 gap-x-1"
                                >
                                    {isUpdateSlideLoading && (
                                        <ClipLoader color="green" size="12px"/>
                                    )}

                                    {!isUpdateSlideLoading && (
                                        <>
                                            <i className="text-sm text-green-500 pi pi-save"></i>
                                            <p>Save Update</p>
                                        </>
                                    )}
                                </FlexContainer>
                            </div>
                            {/*  */}

                            {/* more options */}
                            <div>
                                <div className="relative">
                                    <div
                                        onClick={() =>
                                            setOpenMoreOptionsDropdown(!openMoreOptionsDropdown)
                                        }
                                        className="flex items-center justify-center w-8 h-8 border rounded-md cursor-pointer bg-gray20"
                                    >
                                        <img src={more} alt="settings" className="w-4"/>
                                    </div>

                                    <div className="absolute right-0 z-50 top-10">
                                        {openMoreOptionsDropdown && (
                                            <div
                                                ref={moreDropdownRef}
                                                className="z-40 flex flex-col px-3 py-4 bg-white rounded-lg shadow w-52 gap-y-4"
                                            >
                                                {moreOptions?.map((option, index) => {
                                                    return (
                                                        <div
                                                            className="flex cursor-pointer gap-x-2"
                                                            key={index}
                                                            onClick={option?.func}
                                                        >
                                                            <img
                                                                src={option.icon}
                                                                alt={option.label}
                                                                className="w-5"
                                                            />

                                                            <p className="text-sm font-medium">
                                                                {option.label}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/*  */}
                        </div>
                    </FlexContainer>
                </section>
            </nav>

            <>
                {/* Modal */}
                <ConfirmationModal
                    open={confirmDelete}
                    isLoading={isDeleteLoading}
                    onClose={closeConfirmDelete}
                    func={removeDocument}
                    text="Are you sure you want to remove this document from the presentation?"
                />

                {/*  */}
            </>

            {/* Drawer */}
            <>
                <Drawer
                    height="94%"
                    closable={false}
                    placement="bottom"
                    open={openScheduleModal}
                    onClose={handleCloseModal}
                    bodyStyle={{padding: "0px"}}
                >
                    <SchedulePresentation
                        shareLink={shareLink}
                        ref={scheduleModalRef}
                        scheduledTime={startTime}
                        closeModal={handleCloseModal}
                        presentationId={presentationId}
                    />
                </Drawer>
            </>

            {/*  */}
        </section>
    );
}
