//assets
import end from "assets/svg/endModalSVG.svg";

export default function EndPresentationPrompt({showSummary, closeModal, isEvent, eventDetails}) {

    return (
        <section className="">
            <div className="mt-[-10px]">
                <img src={end} alt="upload slide"/>
            </div>

            <section className="w-[93%] mx-auto my-4 font-grotesk">
                <div>
                    <h1 className="text-lg font-bold lg:text-2xl">
                        Are you sure you want to
                        end {isEvent && eventDetails?.role === "Collaborator" ? "your session" : isEvent && eventDetails?.role === "EventOwner" ? "this event" : "this presentation"} ?
                    </h1>
                    <p className="text-xs lg:mt-1">
                        Please confirm you are done
                        with {isEvent && eventDetails?.role === "Collaborator" ? "your session" : isEvent && eventDetails?.role === "EventOwner" ? "this event" : "this presentation"}
                    </p>
                </div>

                <section className="mt-4 text-xs lg:text-sm">
                    <button
                        onClick={showSummary}
                        className="w-full py-3 mb-3 text-white rounded-lg cursor-pointer bg-primary gap-x-2"
                    >
                        Yes, end {isEvent && eventDetails?.role === "Collaborator" ? "my session" : isEvent && eventDetails?.role === "EventOwner" ? "this event" : "this presentation"}
                    </button>

                    <button
                        onClick={closeModal}
                        className="w-full py-3 font-medium border rounded-lg cursor-pointer text-primary border-primary gap-x-2">
                        No, take me back
                    </button>
                </section>
            </section>
        </section>
    );
}
