import React, {useEffect, useRef, useState} from "react";

//assets
import likeImg from "assets/svg/like.svg";
import remove from "assets/svg/remove.svg";
import avatar from "assets/svg/avatar.svg";
import dislikeImg from "assets/svg/dislike.svg";

//libraries
import {Toast} from "primereact/toast";
import {ClipLoader} from "react-spinners";

//utils
import {imageStream} from "utils/imageStream";
import {getConnectionId, getPresentationId} from "utils/localStorageServices";

//hooks
import {VoteQuestion} from "hooks/SignalR/Invokers/Viewer/VoteQuestion";
import {GetQuestionDetails} from "hooks/SignalR/Invokers/Presenter/GetQuestionsDetails";
import {useSignalREventListener} from "hooks/SignalR/Listeners/Global/useSignalRListener";
import {useGetQuestionDetails} from "hooks/SignalR/Listeners/Presenter/useGetQuestionDetails";
import {GetQuestions} from "hooks/SignalR/Invokers/Presenter/GetQuestions";
import {UnvoteQuestion} from "hooks/SignalR/Invokers/Viewer/UnvoteQuestion";
import {DateFromNow} from "utils/formatDate";

export default function QuestionCard({
                                         isViewer,
                                         question,
                                         drawerRef,
                                         connection,
                                         setSelectedQuestion,
                                         presentationId,
                                         isEvent,
                                         eventOwner
                                     }) {
    //useref
    const toast = useRef(null);
    // const {presentationId} = useParams();
    const {name, askTime, request, slideTitle, id} = question ?? {};

    const slideNumber = slideTitle?.split("_")?.[1];

    // hooks
    useSignalREventListener(
        connection,
        (id, eventName, status, message, presenterConnectionId) => {
            const event = eventName?.toLowerCase();
            setIsLikeLoading(false);
            setIsDislikeLoading(false);
            if (event === "votequestion" || event === "unvotequestion") {
                console.log("Posiion Two here")
                GetQuestions(connection, presentationId, getConnectionId());

                GetQuestionDetails(
                    toast,
                    connection,
                    presentationId ?? getPresentationId(),
                    question?.id
                );
            }
        }
    );

    useGetQuestionDetails(connection, presentationId, (question) => {
        if (question?.id === id) {
            setQuestionDetail(question);
            setLikeCounts(question?.likes);
            setDislikeCounts(question?.dislikes);
            const getStream = async () => {
                try {
                    const url = await imageStream(question?.fileId, true);
                    setAudioUrl(url);
                } catch (err) {
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: err,
                    });
                }
            };

            question?.fileId && getStream();
        }
    });

    //usestate
    const [audioUrl, setAudioUrl] = useState(null);
    const [likeCounts, setLikeCounts] = useState(0);
    const [viewMore, setViewMore] = useState(false);
    const [dislikeCounts, setDislikeCounts] = useState(0);
    const [questionDetail, setQuestionDetail] = useState();
    const [isLikeLoading, setIsLikeLoading] = useState(false);
    const [isDislikeLoading, setIsDislikeLoading] = useState(false);

    //useeffect
    useEffect(() => {
        console.log("Position 3")
        GetQuestionDetails(
            toast,
            connection,
            presentationId ?? getPresentationId(),
            id
        );
    }, [connection, presentationId, id]);

    // function
    const getExtraDetails = () => {
        setViewMore(!viewMore);
    };

    const voteQuestion = (status) => {
        status ? setIsLikeLoading(true) : setIsDislikeLoading(true);

        if (question?.voted === status) {
            UnvoteQuestion(
                connection,
                presentationId ?? getPresentationId(),
                questionDetail?.id
            );
        } else {
            VoteQuestion(
                connection,
                presentationId ?? getPresentationId(),
                questionDetail?.id,
                status
            );
        }
    };

    return (
        <section className="flex w-[95%] py-4 border-b  gap-x-2 font-grotesk">
            <Toast ref={toast} className="text-sm" position="top-center"/>
            <section className="">
                <img
                    src={avatar}
                    alt={question?.userId}
                    className="w-10 rounded-full"
                />
            </section>

            <section className="w-full">
                <section className="flex justify-between w-full ">
                    <h3 className="font-semibold ">{name}</h3>
                    <p className="flex items-center text-xs font-semibold cursor-pointer gap-x-1 text-gray40 ">
                        {DateFromNow(askTime)}
                    </p>
                </section>

                <section className="w-full">
                    <p className="w-full pt-2 text-xs font-semibold text-gray60 ">
                        {request}
                    </p>

                    <section className="flex items-center justify-between w-full ">
                        <section className="flex items-center gap-x-4">
                            <section className="flex items-center py-2 gap-x-4">
                                <div
                                    className="flex items-center text-xs font-semibold cursor-pointer gap-x-1 text-gray40">
                                    <img src={remove} alt="remove viewer"/>
                                    <p>slide {slideNumber}</p>
                                </div>

                                <div
                                    onClick={() => getConnectionId() && voteQuestion(true)}
                                    className="flex items-center text-xs font-semibold cursor-pointer gap-x-1 text-gray40"
                                >
                                    {isLikeLoading ? (
                                        <ClipLoader color="gray" size={10}/>
                                    ) : (
                                        <>
                                            {question?.voted === true ? (
                                                <i className="text-xs text-green-400 pi pi-thumbs-up-fill"></i>
                                            ) : (
                                                <img src={likeImg} alt="like" className="w-4"/>
                                            )}

                                            <p>{likeCounts}</p>
                                        </>
                                    )}
                                </div>
                            </section>

                            <div
                                onClick={() => getConnectionId() && voteQuestion(false)}
                                className="flex items-center text-xs font-semibold cursor-pointer gap-x-1 text-gray40"
                            >
                                {isDislikeLoading ? (
                                    <ClipLoader color="gray" size={10}/>
                                ) : (
                                    <>
                                        {question?.voted === false ? (
                                            <i className="text-xs text-red-400 pi pi-thumbs-down-fill"></i>
                                        ) : (
                                            <img src={dislikeImg} alt="dislike" className="w-4"/>
                                        )}
                                    </>
                                )}

                                <p>{dislikeCounts}</p>
                            </div>
                        </section>
                        {console.log(!question?.isAnswered && !isViewer, "Show Reply here")}
                        {!question?.isAnswered && !isViewer && (
                            <div>
                                {(!isEvent || (isEvent && eventOwner === "EventOwner")) && <button
                                    onClick={() => {
                                        if (drawerRef.current) {
                                            drawerRef.current.scrollTop = 1;
                                        }
                                        // window.scrollTo(1,1)
                                        setSelectedQuestion(question);
                                    }}
                                    className=" w-[6rem] text-center rounded-md font-medium text-xs py-1 text-green400 border border-green400"
                                >
                                    Answer now
                                </button>}

                            </div>
                        )}
                    </section>

                    <section>
                        {questionDetail?.audioURL && !getConnectionId() && (
                            <button
                                onClick={getExtraDetails}
                                className="flex px-3 py-1 text-[13px] font-semibold rounded-full text-gray600 bg-gray900 gap-x-1"
                            >
                                <span>More details</span>

                                <i
                                    className={`pi pi-angle-down transition-all duration-500 ${
                                        !viewMore ? "rotate-0" : "rotate-180"
                                    }`}
                                    style={{color: "#333333"}}
                                ></i>
                            </button>
                        )}

                        {viewMore && (
                            <section className="px-4 py-2 mt-2 mb-4 rounded-lg text-gray60 bg-gray160">
                                <p className="text-[13px]  font-semibold w-[90%] py-1 ">
                                    {questionDetail?.questionFull}
                                </p>
                                {audioUrl && (
                                    <div>
                                        <audio
                                            className="h-8 my-2"
                                            controls
                                            id="html-audio"
                                            src={audioUrl}
                                            // autoplay
                                        />
                                    </div>
                                )}
                            </section>
                        )}
                    </section>
                </section>

                {question?.answer && (
                    <div className="flex items-center gap-x-2">
                        <p className="pt-2 text-xs font-semibold text-green-600">Answer:</p>
                        <p className="px-2 py-1 mt-2 text-xs font-medium text-green-800 border-green-400 ">
                            {question?.answer}
                        </p>
                    </div>
                )}
            </section>
        </section>
    );
}
