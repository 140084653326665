import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

//slices
import {
  getSlidesState,
  setActiveSlide,
  setSideImages,
} from 'store/Slides/slidesSlice';
import { getPresentationState } from 'store/Presentations/presentationSlice';

//utils
import { imageStream } from 'utils/imageStream';

//assets
import fileEmpty from 'assets/svg/emptyTemplate.svg';

//libraries
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

//comoponents
import Loader from 'components/UI/Loader';
import DragDropCard from 'components/Admin/Presentation/DragDropCard';
import Settings from 'components/Admin/Presentation/PresentationDetails/Settings';
import { getPollState, updatePresentationEdit } from 'store/Poll/pollSlice';
import { useParams } from 'react-router-dom';
import PollPreview from './PollPreview';
import ImagePreview from './ImagePreview';

export default function MainContent({
  activeImage,
  setActiveImage,
  activePlaylist,
}) {
  const dispatch = useDispatch();

  const { presentationId } = useParams();

  //usememo
  const imageCache = useMemo(() => ({}), []);

  //useselector

  const { pollDetails } = useSelector(getPollState);
  const { documentFiles } = useSelector(getPresentationState);
  const { sideImages, presentationUI } = useSelector(getSlidesState);

  const { activeSlide } = presentationUI ?? {};
  //usestate

  const [openSidebar, setOpenSidebar] = useState(true);
  const [transitionDragID, setTransitionDragID] = useState();
  const [isPoll, setIsPoll] = useState(false);

  useEffect(() => {
    setIsPoll(activePlaylist?.type?.toLowerCase() === 'poll');
  }, [activePlaylist]);

  //function
  const getStream = async (id) => {
    const cachedImg = imageCache[id];
    if (cachedImg) {
      setActiveImage(cachedImg);
    } else {
      const url = await imageStream(id);
      imageCache[id] = url;
      setActiveImage(url);
    }
  };

  //dndkit
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getCardId = (id) => sideImages.findIndex((card) => card.id === id);

  const setActive = (data) => {
    dispatch(setActiveSlide(data));

    if (!data?.question) {
      getStream(data.id);
    } else {
      setActiveImage(data);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id === over?.id) return;
    const repositionedSlide = () => {
      const originalPos = getCardId(active?.id);
      const newPos = getCardId(over?.id);
      return arrayMove(sideImages, originalPos, newPos);
    };

    dispatch(setSideImages(repositionedSlide()));

    const positions = repositionedSlide().map((slide, index) => {
      let newPosition = index + 1;
      return {
        documentId: slide.id,
        position: newPosition,
        note: slide.note,
        title: `Slide_${newPosition}`,
      };
    });

    const data = [
      {
        documentId: activePlaylist?.documentId,
        position: activePlaylist?.position,
        slides: positions,
      },
    ];

    dispatch(updatePresentationEdit({ presentationId, data })).then(
      ({ payload }) => {
        if (payload === '') {
          toast.success('Update successful');
        }
      }
    );
  };

  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const dragEnter = (e, id) => {
    e.preventDefault();
    setTransitionDragID(id);
  };

  const disableRightClick = (e) => {
    e.preventDefault();
  };

  ///

  console.log('Document Files', documentFiles.isLoading);
  console.log('Active Image', activeImage);
  console.log('PollsDetails loading', pollDetails.isLoading);
  console.log('side images', sideImages);

  return (
    <section>
      {(documentFiles.isLoading ||
        !activeImage ||
        pollDetails.isLoading ||
        !sideImages) && <Loader />}

      {!documentFiles.isLoading &&
        activeImage &&
        !pollDetails.isLoading &&
        sideImages && (
          <section className='max-h-screen'>
            {/* sidebar */}
            <aside
              className={` ${
                openSidebar ? 'translate-x-0' : 'translate-x-[-10000px]'
              }  lg:translate-x-0 duration-1000 ease-in-out py-4 md:pt-8 pt-20  lg:w-[16%] md:w-[30%] w-8/12 z-30 bg-white h-[90%] overflow-y-scroll bottom-0 fixed transition-all`}
            >
              {sideImages?.length < 1 && (
                <section className='flex flex-col items-center justify-center h-full text-sm '>
                  <img src={fileEmpty} alt='' />
                  <div className='text-center'>
                    <p className='font-semibold'>Select a file</p>
                    <p>File selected will display here</p>
                  </div>
                </section>
              )}

              <section>
                {sideImages?.length > 0 && (
                  <DndContext
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                    collisionDetection={closestCorners}
                  >
                    <section
                      className='w-[80%]  mx-auto flex flex-col gap-y-8'
                      onDragOver={dragOver}
                    >
                      <SortableContext
                        items={sideImages}
                        strategy={verticalListSortingStrategy}
                      >
                        {sideImages?.map((img, index) => {
                          return (
                            <DragDropCard
                              indexId={index + 1}
                              key={index}
                              card={img}
                              setActive={setActive}
                              dragEnter={dragEnter}
                              imageCache={imageCache}
                              activeImage={activeImage}
                              transitionDragID={transitionDragID}
                              setTransitionDragID={setTransitionDragID}
                            />
                          );
                        })}
                      </SortableContext>
                    </section>
                  </DndContext>
                )}
              </section>
            </aside>
            {/*  */}

            {/* active image */}

            <section
              onClick={() => setOpenSidebar(false)}
              className='bg-[#F2F2F2] h-screen overflow-y-hidden  relative z-10 mx-auto md:w-full pt-[6rem] pb-2 p flex justify-center gap-x-4 items-center   '
            >
              {/* absolute right-0   */}

              {/* toggler */}
              <section className='absolute left-8 top-28 lg:hidden '>
                <div
                  className=''
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenSidebar(true);
                  }}
                >
                  <i className='text-2xl cursor-pointer pi pi-align-justify'></i>
                </div>
              </section>
              {/*  */}

              {/* image */}

              <section>
                {activeImage ? (
                  <section className='w-full'>
                    {isPoll && (
                      <section
                        className={`[ min-xxl:w-[70.1875rem] min-vl:w-[80.1875rem]   ] min-lg:w-[65.1875rem] h-[100%] rounded-lg cursor-pointer flex justify-center items-center`}
                      >
                        <PollPreview activeDetails={activeImage} />
                      </section>
                    )}

                    {!isPoll && (
                      <ImagePreview
                        activeImage={activeImage}
                        disableRightClick={disableRightClick}
                      />
                    )}
                  </section>
                ) : (
                  <div
                    className={` [ min-xxl:w-[70.1875rem] min-vl:w-[80.1875rem]   ] min-lg:w-[65.1875rem] h-[100%] rounded-lg cursor-pointer flex justify-center items-center`}
                  >
                    <ClipLoader size={12} color='gray' />
                  </div>
                )}
              </section>

              {/*  */}

              {/* settings */}
              <Settings
                sideImages={sideImages}
                activePlaylist={activePlaylist}
              />
              {/*  */}
            </section>

            {/*  */}
          </section>
        )}
    </section>
  );
}
