import SharedBriefcase from 'views/Admin/SharedBriefcase';
import AcceptTeamInvite from 'views/User/AcceptTeamInvite';
import JoinPresentation from 'views/User/JoinPresentation';
import JoinSharedPresentation from 'views/User/JoinSharedPresentation';
import PresentationEnded from 'views/User/PresentationStatus/PresentationEnded';
import RemovedUser from 'views/User/RemovedUser';
import ViewerMode from 'views/User/ViewerMode';
import { UserPresentationScreen } from 'views/User/ViewerMode/user-presentation-screen';
import WaitingArea from 'views/User/WaitingArea';

const usersRoutes = [
  {
    path: 'join-presentation/:presentationId',
    element: <JoinPresentation />,
  },
  {
    path: 'waiting-area/',
    element: <WaitingArea />,
  },

  {
    path: 'viewer-mode/:friendlyId',
    element: <ViewerMode />,
  },

  {
    path: 'viewer-mode-new/:friendlyId',
    element: <UserPresentationScreen />,
  },

  {
    path: 'presentation-ended',
    element: <PresentationEnded />,
  },
  {
    path: 'removed',
    element: <RemovedUser />,
  },

  // Accept collaboration invite review for the AuthRoute wrapper
  {
    path: 'acceptinvite/:friendlyId',
    element: <AcceptTeamInvite />,
  },

  //  shared briefcase - confirm if this route should be protected
  {
    path: 'shared-briefcase/:presentationId',
    element: <SharedBriefcase />,
  },

  // access shared presentation
  {
    path: 'access-shared-presentation/:presentationId',
    element: <JoinSharedPresentation />,
  },
];

export default usersRoutes;
