import adminRoutes from './AdminRoutes/index';
import landingPageRoutes from './ExternalRoutes/landingPageRoutes';
import authRoutes from './ExternalRoutes/authRoutes';
import previewRoutes from './AdminRoutes/ProtectedRoutes/previewRoutes';
import presentationRoutes from './AdminRoutes/ProtectedRoutes/presentationRoutes';
import usersRoutes from './ExternalRoutes/usersRoutes';
import NotFound from 'views/ErrorPage/NotFound';
import pollsRoutes from './AdminRoutes/ProtectedRoutes/pollsRoutes';
const { createBrowserRouter } = require('react-router-dom');

export const router = createBrowserRouter([
  ...landingPageRoutes,
  ...authRoutes,
  ...adminRoutes,
  ...previewRoutes,
  ...presentationRoutes,
  ...usersRoutes,
  ...pollsRoutes,
  {
    path: '*',
    element: <NotFound />,
  },
]);
