import React, { useState } from 'react';

//assets
import addPlus from 'assets/svg/add-square.svg';
import pollIcon from 'assets/svg/poll-icon.svg';
import closeIcon from 'assets/svg/close-icon2.svg';

//libraries
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

interface Props {
  closeModal: () => void;
}

export default function PollName({ closeModal }: Props) {
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const handleCreatePoll = () => {
    setIsloading(true);
    navigate(`/poll-setup?title=${title}`);
    setIsloading(false);
  };

  return (
    <section className='py-3'>
      <div className='flex justify-between'>
        <div className='mt-[-20px]'>
          <img src={pollIcon} alt='' />
        </div>

        <div
          className='mt-[-20px] cursor-pointer '
          onClick={() => closeModal()}
        >
          <img src={closeIcon} alt='' />
        </div>
      </div>

      <section>
        <div className='mt-4'>
          <h1 className='text-2xl font-semibold'>Poll name</h1>
        </div>

        <section>
          <section className='flex flex-wrap my-2 gap-y-2 gap-x-3 text-sm'>
            Input your poll name for easy access when you want to use it
            anywhere on teamdocs
          </section>

          <p className='mt-5 text-base'>Enter poll name</p>
          {/* form  */}
          <section className='mt-1'>
            <div className='flex flex-col gap-y-1'>
              <label
                htmlFor='space'
                className='font-normal text-gray700'
              ></label>
              <input
                type='text'
                placeholder='e.g design'
                value={title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTitle(e.target.value)
                }
                className='py-3 pl-4 text-sm rounded-lg outline-none bg-orange10'
              />
            </div>

            <section className='flex my-4 mt-6 gap-x-4'>
              <button
                disabled={isLoading || title.length < 1 ? true : false}
                onClick={handleCreatePoll}
                className={`flex items-center justify-center w-full px-4 py-3 text-white  ${
                  isLoading || title.length < 1
                    ? 'opacity-40 cursor-not-allowed'
                    : 'opacity-100'
                } bg-black rounded-lg gap-x-2`}
              >
                {!isLoading ? (
                  <>
                    <img src={addPlus} alt='' />
                    <p>Add poll</p>
                  </>
                ) : (
                  <ClipLoader size={12} color='white' />
                )}
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}
