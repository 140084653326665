import {useEffect} from "react";

export const useGetQuestions = (connection, presentationId, eventHandler) => {
    useEffect(() => {
        const handleEventResponse = (id, questions) => {
            console.log(id, questions, "Response on Questions")
            eventHandler(questions);
        };
        if (connection) {
            connection.on("Questions", handleEventResponse);
        }

        return () => {
            if (connection) {
                connection.off("Questions", handleEventResponse);
            }
        };
    }, [connection, presentationId, eventHandler]);
};
