import VisitorsLeads from 'components/Admin/Presentation/PresentationMetrics/VisitorsLeads';
import Directory from 'views/Admin/Directory';
import Leads from 'views/Admin/Directory/Leads';

const directoryRoutes = {
  path: 'directory',
  element: <Directory />,
  children: [
    { path: 'visitors', element: <VisitorsLeads /> },
    { path: 'leads', element: <Leads /> },
  ],
};

export default directoryRoutes;
