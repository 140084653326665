interface Props {
  handleSave: () => void;
}
export default function SaveBotton({ handleSave }: Props) {
  return (
    <button
      className='h-14 w-full bg-black text-white font-medium rounded-lg mt-3'
      onClick={handleSave}
    >
      Save
    </button>
  );
}
