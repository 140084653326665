import PollsLayout from 'components/UI/PollsLayout';
import Polls from 'views/Admin/Polls';

const pollsRoutes = {
  path: 'polls',
  element: <PollsLayout />,
  children: [
    {
      path: '',
      element: <Polls />,
    },
  ],
};

export default pollsRoutes;
