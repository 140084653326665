// export const baseUrl = "https://api.thepresenta.com"
export const baseUrl = 'https://devapi.thepresenta.com';
// export const baseUrl = 'https://api.teamdocs.io';

// const beginConnection = () => {
//   setPresentationLoading(true);
//   connection = new signalR.HubConnectionBuilder()
//     .withUrl(`${baseUrl}/pubsub/present`, {
//       accessTokenFactory: () => {
//         return `${getToken()}`;
//       },
//     })
//     .withHubProtocol(new MessagePackHubProtocol())
//     .withAutomaticReconnect()
//     .build();

//   //start connection
//   connection
//     .start()
//     .then(() => {
//       toast.success("Connection established");
//     })
//     .catch((err) => toast.error("Error connecting SignalR:" + err));
// };
