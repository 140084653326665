import {useEffect, useRef, useState} from "react";
import * as signalR from "@microsoft/signalr";
import {baseUrl} from "utils/baseUrl";
import {getToken} from "utils/localStorageServices";
import {toast} from "sonner";

export const usePresentationConnectSignalR = () => {
    const [connected, setConnected] = useState(false);
    const [connectionState, setConnectionState] = useState("");
    const [reconnecting, setReconnecting] = useState(false);
    const [error, setError] = useState(false);
    const [connecting, setConnecting] = useState(true);
    const connectionRef = useRef<signalR.HubConnection | null>(null);
    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${baseUrl}/pubsub/present`, {
                accessTokenFactory: () => {
                    return `${getToken()}`;
                },
            })
            // .withHubProtocol(new MessagePackHubProtocol())
            .withAutomaticReconnect()
            .build();

        connectionRef.current = connection;
        connection
            .start()
            .then(() => {
                setConnected(true);
                toast.success("Connected");
                console.log("Signal R connected");
            })
            .catch((e) => {
                toast.error("Connected Failed");
                console.log("connection failed");
            })
            .finally(() => {
                setConnecting(false);
            });
        connection.onreconnecting((error) => {
            // dispatch(setReconnecting());
            // Use a state to handle whether or not it is reconnecting
            setConnected(false);
            setConnecting(true);

            toast.info("Connecting...");
            console.error("SignalR reconnecting due to error: ", error);
        });

        connection.onreconnected((connectionId) => {
            // use a state to handle whehter or not is is reconnected
            // dispatch(setReconnected());
            toast.success("Reconnected Successfully");
            setConnected(true);
            setConnecting(false);
            setError(false);
            console.log("SignalR reconnected. Connection ID: ", connectionId);
        });

        connection.onclose((error) => {
            if (!error) return;
            //  Use a state to handle show if it disconnected
            //  dispatch(setDisconnected(error ? error.message : "Connection closed"));
            setError(true);
            setConnected(false)
            toast.error("Connection interrupted");
            console.error("SignalR connection closed due to error: ", error);
        });

        return () => {
            connection.stop().then(() => {
                console.log("SignalR connection closed");
                toast.error("connection closed");
            });
        };
    }, []);

    return {
        connected,
        reconnecting,
        error,
        connecting,
        connection: connectionRef.current,
    };
};
