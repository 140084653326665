import React, {useEffect, useRef, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

//libraries
//assets
import empty from "assets/svg/emptyPresentation.svg";

//utils
//slices
import {getAllPresentations, getPresentationState,} from "store/Presentations/presentationSlice";

//components
import Loader from "components/UI/Loader";
// import EmptyState from "components/Admin/Workspace/ContentPage/EmptyState";
import NavigationTab from "components/Admin/Presentation/PresentationDetails/NavigationTab";
import PresentationCard from "components/Admin/Presentation/Cards/PresentationCard";
import NavbarNew from "../../../components/UI/AdminLayout/NavbarNew";
import {CButton} from "../../../components/UI/Button/Buttons";
import roundedCircle from "assets/svg/add-circle.svg"
import AddFile from "../Briefcase/Modals/AddFile";
import {OnGoingAction} from "../../../components/UI/Banner/OnGoingAction";
import {Modal} from "antd";
import {ModalWrapper} from "../../../components/UI/wrapper/modal-wrapper";
import Label from "../../../components/UI/Label";
import AuthInput from "../../../components/UI/Inputs/AuthInput";
import addIcon from "assets/svg/add-square.svg"
import instance from "../../../lib/axiosInterceptor";
import {useNavigate} from "react-router-dom";
import {toast} from "sonner"
import {setOnGoingPresentation} from "../../../store/OnGoing/onGoingAction";

export default function Presentation() {
    const dispatch = useDispatch();
    const {presentations} = useSelector(getPresentationState);
    const [foundOnGoingPresentation, setFoundOnGoingPresentation] = useState(false);
    const [foundOnGoingObject, setFoundOnGoingObject] = useState();
    const [createPresentationModal, setCreatePresentationModal] = useState(false);
    const [loading, setLoading] = useState(false)


    //useref
    const searchParam = useRef();

    //usestates

    const [tab, setTab] = useState(
        localStorage.getItem("presentationTabActive")
            ? +localStorage.getItem("presentationTabActive")
            : 1
    );
    const [allPresentations, setAllPresentations] = useState([]);
    const [originalPresentation, setOriginalPresentations] = useState([]);
    const [originalScheduledPresentation, setOriginalScheduledPresentation] =
        useState([]);
    const [originalOnGoingPresentation, setOriginalOnGoingPresentation] =
        useState([]);
    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
    const [FolderGroupId, setFolderGroupId] = useState("")
    const [presentationTitle, setPresentationTitle] = useState("");
    const [presentationId, setPresentationId] = useState("")
    const navigate = useNavigate();

    const handleFileUploadCancel = () => {
        setIsFileUploadModalOpen(false)
    }

    const handleCreatePresentation = async () => {
        setLoading(true)
        try {
            const result = await instance.post("/presentation/new", {title: presentationTitle, type: 1})
            // navigate(`/presentation-details/${result.data.id}`);
            toast.success("Presentation created successfully")
            setPresentationId(result.data.id)
            setCreatePresentationModal(false)
            setIsFileUploadModalOpen(true)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    //useeffect
    useEffect(() => {
        dispatch(getAllPresentations());
    }, [dispatch]);

    useEffect(() => {
        setOriginalPresentations(presentations?.data);
        setAllPresentations(presentations?.data);
        if (presentations.data) {
            const onGoingPresentation = presentations.data.find(item => {
                return item.status === 2;
            })
            if (onGoingPresentation) {
                // dispatch(set)
                dispatch(setOnGoingPresentation({
                    active: true,
                    id: onGoingPresentation.id,
                    isEvent: !!onGoingPresentation.isEvent
                }))
                setFoundOnGoingPresentation(true)
                setFoundOnGoingObject(onGoingPresentation)
            } else {
                setFoundOnGoingPresentation(false);
                setFoundOnGoingObject(null)
            }
        }
    }, [presentations]);

    //functions
    const searchPresentation = (e) => {
        const searchedTitle = e.target?.value?.toLowerCase();
        searchParam.current = searchedTitle;
        if (searchedTitle) {
            setAllPresentations([
                ...originalPresentation.filter((item) =>
                    item.title.toLowerCase().includes(searchedTitle)
                ),
            ]);
        } else {
            setAllPresentations([...originalPresentation]);
        }

        // setAllPresentations(filteredPresentations);
    };

    useEffect(() => {
    }, [tab]);

    return (
        <section>
            {/*{foundOnGoingPresentation &&*/}
            {/*{foundOnGoingPresentation && <OnGoingAction type={"Presentation"}*/}
            {/*                                            url={foundOnGoingObject?.isEvent ? `/active-presentation/${foundOnGoingObject.id}/${foundOnGoingObject.eventId}?isEvent=${true}` : `/active-presentation/${foundOnGoingObject?.id}`}/>}*/}

            <Modal
                open={isFileUploadModalOpen}
                onCancel={handleFileUploadCancel}
                footer={null}
                width={452}
                centered
                destroyOnClose={true}
                closable={false}
            >
                <AddFile
                    closeAddFileModal={handleFileUploadCancel}
                    spaceId={""}
                    groupId={FolderGroupId ? FolderGroupId : undefined}
                    presentationId={presentationId}
                    fromPresentation={true}

                />
            </Modal>
            <ModalWrapper open={createPresentationModal} onCancel={() => setCreatePresentationModal(false)}>
                <div>
                    <p className="font-grotesk text-[#333333] font-semibold text-xl">Presentation name</p>
                    <div className="my-4">
                        <Label text="Name your presentation"/>
                        <AuthInput
                            type="text"
                            name="text"
                            placeholder="Presentation title"
                            onChange={(e) => setPresentationTitle(e.target.value)}
                        />
                        <div className={"my-3"}>
                            <CButton action={() => {
                                handleCreatePresentation()
                            }} loading={loading} text={"Continue"} variant={"dark"} icon={addIcon} fullWidth
                                     iconPosition={"left"}/>
                        </div>
                    </div>
                </div>
            </ModalWrapper>
            {/*<OnGoingAction/>*/}
            <section className="relative">
                <NavbarNew mainText="My Presentation" subText="Explore your presentations" hasBreadCrumb={true}
                           mainBigger={true} leftContent={<CButton action={() => {
                    setCreatePresentationModal(true)
                    // setIsFileUploadModalOpen(true)
                }} iconPosition={"left"} text={"Create a presentation"}
                                                                   variant={"dark"}
                                                                   icon={roundedCircle} alt={""}/>}/>
                {/*<AdminNavbar navProps={navProps}/>*/}
            </section>

            <section className="mt-10">
                <NavigationTab
                    tab={tab}
                    setTab={setTab}
                    searchPresentation={searchPresentation}
                />
                {/* loader */}
                {/* {presentations?.isLoading && <Loader />} */}
                {/*  */}
                {!presentations?.isLoading && (
                    <>
                        <section className="flex flex-col justify-center w-full pt-10 pb-20">
                            {/* <div className="flex items-center justify-center w-full">
                {((grouppedPresentations?.length < 1 && tab !== "all") ||
                  allPresentations?.length < 1) && (
                  <div className="h-[60vh] flex  justify-center items-center w-full ">
                    {searchParam.current ? (
                      <p className="text-2xl font-medium text-center">
                        No Presentation found
                        <img src={notFound} alt="empty " className="" />
                      </p>
                    ) : (
                      <img src={empty} alt="empty " className="" />
                    )}
                  </div>
                )}
              </div> */}
                            <section
                                className="grid w-full  grid-cols-1 lg:gap-x-8 lg:gap-y-8 gap-y-10 lg:grid-cols-3 md:grid-cols-2">
                                {presentations?.loading ? (
                                    <Loader/>
                                ) : allPresentations?.length ? (
                                    allPresentations?.map((item) => {
                                        return (
                                            <PresentationCard presentation={item} key={item.id}/>
                                        );
                                    })
                                ) : (
                                    <div className="col-span-3 w-full ">
                                        <img className="mx-auto" src={empty} alt="empty "/>
                                    </div>
                                )}

                                {/* {tab === 1 ? (
                  scheduledPresentation?.length ? (
                    scheduledPresentation.map((item) => {
                      return (
                        <PresentationCard
                          type="scheduled"
                          presentation={item}
                          key={item.id}
                        />
                      );
                    })
                  ) : (
                    <div className="col-span-3 w-full ">
                      <img className="mx-auto" src={empty} alt="empty " />
                    </div>
                  )
                ) : tab === 2 ? (
                  onGoingPresentation?.length ? (
                    onGoingPresentation.map((item) => {
                      return (
                        <PresentationCard
                          type="ongoing"
                          presentation={item}
                          key={item.id}
                        />
                      );
                    })
                  ) : (
                    <div className="col-span-3 w-full ">
                      <img className="mx-auto" src={empty} alt="empty " />
                    </div>
                  )
                ) : tab === 4 ? (
                  pastPresentation?.length ? (
                    pastPresentation.map((item) => {
                      return (
                        <PresentationCard presentation={item} key={item.id} />
                      );
                    })
                  ) : (
                    <div className="col-span-3 w-full ">
                      <img className="mx-auto" src={empty} alt="empty " />
                    </div>
                  )
                ) : (
                  ""
                )} */}
                            </section>
                        </section>
                    </>
                )}
            </section>
        </section>
    );
}
