import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

//components
import Visitor from './Table/Visitors';
import Loader from 'components/UI/Loader';
import AdminNavbar from 'components/UI/AdminLayout/Navbar';
import Leads from './Table/Leads';
import LeadDetails from './Modal/LeadDetails';

// assets
import arrowRight from '../../../assets/svg/arrowRight.svg';

export default function Directory() {
  const dispatch = useDispatch();

  //usestates
  const [tab, setTab] = useState(2);
  const [loadingStatus] = useState(false);
  const [navProps, setNavProps] = useState({});

  const [visits] = useState([
    {
      name: 'phoenix baker',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter', 'Snapchat'],
      role: 'owner',
      status: 'active',
    },
    {
      name: 'phoenix baker',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter', 'Snapchat'],
      role: 'owner',
      status: 'active',
    },

    {
      name: 'phoenix baker',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter', 'Snapchat'],
      role: 'owner',
      status: 'active',
    },
  ]);

  const [leads] = useState([
    {
      name: 'phoenix baker',
      company: 'Meta',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter', 'Snapchat', 'LinkedIn'],
      role: 'Director',
      status: 'active',
    },
    {
      name: 'phoenix baker',
      company: 'Meta',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter'],
      role: 'Director',
      status: 'active',
    },

    {
      name: 'phoenix baker',
      company: 'Meta',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter', 'Skype'],
      role: 'owner',
      status: 'active',
    },
  ]);

  //useeffects
  useEffect(() => {
    setNavProps({
      title: 'Directory',
      breadcrumb: (
        <section className='flex gap-x-1 items-center'>
          <p className='lg:text-sm text-xs'>Directory</p>
          <img src={arrowRight} alt='arrow right icon' />
          <p className='lg:text-sm text-xs'>Leads</p>
          <img src={arrowRight} alt='arrow right icon' />
          <p className='lg:text-sm text-xs'>Details</p>
        </section>
      ),
      // subTitle: `Upload and share resources with your team`,
      //   modalTitle: "Add Team Member",
    });

    //   dispatch(getAllPresentations());
  }, [dispatch]);

  return (
    <section className='relative'>
      <AdminNavbar navProps={navProps} />

      <section className='mt-20'>
        {/* loader */}
        {loadingStatus && <Loader />}
        {/*  */}

        {!loadingStatus && (
          <section>
            {/* <section className='flex w-40 my-6 '>
              <div
                onClick={() => setTab(0)}
                // className={`pb-2 px-4 cursor-pointer ${
                //   tab !== 0
                //     ? 'border-b'
                //     : 'border-b-2 border-primary text-primary font-semibold'
                // }`}
              >
                <p>Visitors</p>
              </div>
              <img src={arrowRight} alt='arrow right icon' />
              <div
                onClick={() => setTab(1)}
                // className={`pb-2 px-8 cursor-pointer ${
                //   tab !== 1
                //     ? 'border-b'
                //     : 'border-b-2 border-primary text-primary font-semibold'
                // }`}
              >
                <p>Leads</p>
              </div>
            </section> */}

            <section>{tab === 0 && <Visitor visits={visits} />}</section>
            <section>{tab === 1 && <Leads leads={leads} />}</section>
            <section>{tab === 2 && <LeadDetails />}</section>
          </section>
        )}
      </section>
    </section>
  );
}
