import addIcon from 'assets/svg/add-square-outline.svg';
import pollImage from 'assets/svg/poll-thumbnail.svg';
import { Poll, PollQuestion } from 'interfaces/PollSetup';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  poll: Poll;
  activeQuestionIndex: number;
  setActiveQuestionIndex: Dispatch<SetStateAction<number>>;
  setPoll: Dispatch<SetStateAction<Poll>>;
  activeQuestion: PollQuestion;
  setActiveQuestion: Dispatch<SetStateAction<PollQuestion>>;
}

export default function Sidebar({
  poll,
  activeQuestionIndex,
  setActiveQuestionIndex,
  setPoll,
  activeQuestion,
  setActiveQuestion,
}: Props) {
  const newQuestion: PollQuestion = {
    questionType: 'default',
    rank: 0,
    question: '',
    duration: 0,
    options: [
      {
        rank: 0,
        text: '',
        color: '',
        isSelected: false,
        value: 0,
      },
    ],
  };
  return (
    <section className='w-[12%] h-screen fix top-0 left-0 bg-white pt-[80px] overflow-y-scroll pb-2 hide-scrollbar'>
      <section className='pt-2 px-2'>
        <button
          className='bg-black px-2 py-1 flex items-center gap-x-2 rounded-md align-bottom w-full'
          onClick={() => {
            setPoll((poll) => {
              return {
                ...poll,
                questions: [...poll.questions, newQuestion],
              };
            });
          }}
        >
          <img src={addIcon} alt='add new poll' className='w-6' />
          <p className='text-white text-xs'>Add new</p>
        </button>

        <section className='mt-3 grid grid-cols-1 gap-y-3'>
          {poll.questions.map((question, index) => (
            <div
              key={index}
              className='flex items-center justify-between cursor-pointer xxl:gap-x-5'
              onClick={() => {
                setActiveQuestion(poll.questions[index]);
                setActiveQuestionIndex(index);
              }}
            >
              <div
                className={`flex w-5 h-5 items-center justify-center rounded-full  text-xs ${
                  activeQuestionIndex === index
                    ? 'bg-primary text-white'
                    : 'bg-[#E8E8E8] text-gray-600'
                }`}
              >
                <p>{index + 1}</p>
              </div>
              <img
                src={pollImage}
                alt=''
                className={`w-full rounded-xl ${
                  activeQuestionIndex === index
                    ? 'border-2 border-primary '
                    : ''
                }`}
              />
            </div>
          ))}
        </section>
      </section>
    </section>
  );
}
