import React, { Dispatch, SetStateAction } from 'react';
import uploadBulk from 'assets/svg/people2.svg';
import sms from 'assets/svg/sms-tracking.svg';

interface Props {
  item: string;
  itemList: string[];
  setItem: Dispatch<SetStateAction<string>>;
  removeItem: (index: number) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  title?: string;
  toggleInput?: boolean;
  checked?: boolean;
  setChecked?: Dispatch<SetStateAction<boolean>>;
  header?: string;
  inputType?: 'text' | 'email' | 'number' | 'phone';
  allowDuplicate?: boolean;
}

export default function BulkUpload({
  item,
  itemList,
  setItem,
  removeItem,
  handleKeyDown,
  title,
  toggleInput,
  checked,
  setChecked,
  header,
  inputType,
}: Props) {
  return (
    <section className='p-2 rounded-xl border border-[#EEEEEE]'>
      <h3 className={`${header ? 'text-base font-semibold' : 'hidden'}`}>
        {header}
      </h3>

      <div
        className={`w-full ${
          toggleInput ? 'flex' : 'hidden'
        } flex justify-between items-center mt-3`}
      >
        <p className='text-base'>{title}</p>
        <label className='inline-flex items-center cursor-pointer'>
          <input
            type='checkbox'
            checked={checked}
            className='sr-only peer'
            onChange={() => {
              setChecked && setChecked(!checked);
            }}
          />
          <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
        </label>
      </div>

      <div className='grid grid-cols-9 gap-x-2 mt-3'>
        <label
          htmlFor='add-email'
          className='col-span-6 rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
        >
          <img src={sms} alt='email' className='w-4 h-4' />
          <input
            type={inputType ? inputType : 'text'}
            id='add-email'
            placeholder='add email'
            className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
            onKeyDown={handleKeyDown}
            value={item || ''}
            onChange={(e) => setItem(e.target.value)}
          />
        </label>
        <button className='flex gap-x-2 items-center px-3 py-2 col-span-3 w-full bg-[#F5F5F5] rounded-md'>
          <img src={uploadBulk} alt='upload' className='h-4 w-4' />
          <p className='text-sm text-[#6B6B6B]'>upload bulk</p>
        </button>
      </div>
      <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'>
        {itemList?.map((email, index) => {
          return (
            <div
              key={index}
              className='flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180'
            >
              <p>{email}</p>
              <i
                className='text-xs cursor-pointer pi pi-times mt-[2px]'
                onClick={() => removeItem(index)}
              ></i>
            </div>
          );
        })}
      </section>
    </section>
  );
}
