import EventSetup from 'components/Admin/Events/EventSetup';
import EventsLayout from 'components/UI/EventsLayout';
import Events from 'views/Admin/Events';
import EventDetails from 'views/Admin/Events/EventDetails';

const eventsRoutes = {
  path: 'events',
  element: <EventsLayout />,
  children: [
    {
      path: '',
      element: <Events />,
    },

    {
      path: 'create-event',
      element: <EventSetup />,
    },
    {
      path: 'create-event/:eventId',
      element: <EventSetup />,
    },
    {
      path: 'details/:eventId',
      element: <EventDetails />,
    },
  ],
};

export default eventsRoutes;
