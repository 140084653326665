import WorkspaceLayout from 'components/UI/WorkspaceLayout';
import Workspace from 'views/Admin/Workspace';
import WorkspaceDocumentDetails from 'views/Admin/Workspace/WorkspaceDocumentDetails';

const workspaceRoutes = {
  path: 'workspace',
  element: <WorkspaceLayout />,
  children: [
    { path: '', element: <Workspace /> },
    { path: 'details/:documentId', element: <WorkspaceDocumentDetails /> },
  ],
};

export default workspaceRoutes;
