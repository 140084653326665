import Loader from 'components/UI/Loader';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import SlidesPreview from './SlidesPreviewCopy';
import { ImageCache } from 'types/Users/SharedBriefcase/SharedBriefcaseTypes';

interface Props {
  screenWidth: number;
  minScreenWidth: number;
  isLoading: boolean;
  activeImage: string | null;
  activeImgRef: MutableRefObject<HTMLImageElement | null>;
  isPresentationDetailsLoading: boolean;
  slides: string[];
  slideDocumentIndex: number;
  imageCache: MutableRefObject<ImageCache[]>;
  setSlideDocumentIndex: Dispatch<SetStateAction<number>>;
  isGridDisplayActive: boolean;
  setIsGridDisplayActive: Dispatch<SetStateAction<boolean>>;
  isSlides: boolean;
  handleFullscreen: () => void;
  displayDocumentIndex: number;
}

export default function DocumentDisplay({
  screenWidth,
  minScreenWidth,
  isLoading,
  activeImage,
  activeImgRef,
  slides,
  slideDocumentIndex,
  imageCache,
  isPresentationDetailsLoading,
  setSlideDocumentIndex,
  isGridDisplayActive,
  setIsGridDisplayActive,
  isSlides,
  handleFullscreen,
  displayDocumentIndex,
}: Props) {
  return (
    <section
      className={`p-5 bg-gray100 w-full rounded-xl ${
        isGridDisplayActive ? '' : ' flex items-center justify-center'
      }  overflow-hidden [ dynamic-styling-height flex-grow flex-shrink basis-0 ]
    `}
    >
      {isLoading || !activeImage || isPresentationDetailsLoading ? (
        <Loader />
      ) : (
        <>
          {!isGridDisplayActive ? (
            <img
              ref={activeImgRef}
              src={activeImage}
              alt='file'
              className='rounded-lg object-contain'
              style={{
                maxWidth: `100%`,
                maxHeight: `100%`,
                width: '100%',
                height: 'auto',
              }}
              onDoubleClick={handleFullscreen}
            />
          ) : (
            <>
              {isSlides && (
                <section className='hide-scrollbar grid grid-cols-5 w-full max-h-full gap-3 px-5 overflow-y-auto h-ful auto-rows-aut'>
                  {imageCache.current[displayDocumentIndex]?.id.map(
                    (item, index) => (
                      <section
                        key={item}
                        onClick={() => {
                          setSlideDocumentIndex(index);
                          setIsGridDisplayActive(false);
                        }} // Adjust index for sliced items
                        // ref={(el) => (itemRefs.current[index] = el)}
                        className={`h-fit w-full `}
                      >
                        <SlidesPreview
                          slide={item}
                          index={index}
                          imageCache={imageCache}
                          slideDocumentIndex={slideDocumentIndex}
                          showImage={true}
                          isGridDisplay={true}
                          displayDocumentIndex={displayDocumentIndex}
                        />
                      </section>
                    )
                  )}
                </section>
              )}
            </>
          )}
        </>
      )}
    </section>
  );
}
