import { useEffect, useState } from 'react';
import EmptyEvents from '../../../components/Admin/Events/EmptyEvents';
import NavbarNew from '../../../components/UI/AdminLayout/NavbarNew';
import { CButton } from '../../../components/UI/Button/Buttons';
import addIcon from 'assets/svg/addOutline.svg';
import { useNavigate } from 'react-router-dom';
import instance from '../../../lib/axiosInterceptor';
import Loader from '../../../components/UI/Loader';
import type { MenuProps } from 'antd';
import { Col, Dropdown, Menu, Modal, Row } from 'antd';
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import playGreen from 'assets/svg/playGreen.svg';
import pastEvent from 'assets/svg/pastEvent.svg';
import draftIcon from 'assets/svg/draftIcon.svg';
import upcomingIcon from 'assets/svg/upcomingIcon.svg';
import moment from 'moment';
import { OptimizedImageWrapper } from '../../../components/UI/Image/OptimizedImageWrapper';
import { SearchInput } from '../../../components/UI/Inputs/SearchInput';
import sortIcon from 'assets/svg/sortIcon.svg';
import newInvite from 'assets/svg/newInvite.svg';
import trashDarkRed from 'assets/svg/trash-dark-red.svg';
import archiveIcon from 'assets/svg/archive-icon.svg';
import editIcon from 'assets/svg/edit2.svg';
import RenameModal from 'components/UI/Modal/RenameModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/store';
import { deleteEvent, updateEvent } from 'store/Events/EventsSlice';
import { getTeamsState } from 'store/Teams/teamsSlice';
import { EventsResponsePayload } from 'interfaces/Events/EventsInterfaces';
import ConfirmationModal from 'components/UI/Modal/ConfirmationModal';
import {OnGoingAction} from "../../../components/UI/Banner/OnGoingAction";
import {EventsInterface} from "../../../interfaces/interfaces-data";
import {getUserDetails} from "../../../utils/localStorageServices";
import {setOnGoingEvent} from "../../../store/OnGoing/onGoingAction";

export default function Events() {
  const navigate = useNavigate();
  const userInfo = getUserDetails();
  const { teamInfo } = useSelector(getTeamsState);

    // const [teamDetails, setTeamDetails] = useState<{
    //   id: string;
    //   title: string;
    // }>();
    const [teamLoading, setTeamLoading] = useState(true);
    const [eventLoading, setEventLoading] = useState(true);
    const [originalEvents, setOriginalEvents] = useState<EventsResponsePayload[]>(
        []
    );
    const [events, setEvents] = useState<EventsResponsePayload[]>([]);
    const [eventOnGoing, setEventOnGoing] = useState(false)
    const [eventOnGoingObject, setEventOnGoingObject] = useState<EventsResponsePayload>()
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch<AppDispatch>();


    const getEvents = async (teamId: string) => {
        try {
            const result = await instance.get(`/Events?teamId=${teamId}`);
            setEvents(result.data);
            const anEventStarted = result.data.find((item: EventsInterface) => item.status === 1);
            if (anEventStarted) {
                dispatch(setOnGoingEvent({isEvent: true, id: anEventStarted.id, active: true}));
                setEventOnGoingObject(anEventStarted)
                setEventOnGoing(true)
            } else {
                setEventOnGoing(false)
            }
            setOriginalEvents(result.data);
        } catch (e) {
        } finally {
            setEventLoading(false);
        }
    };

  useEffect(() => {
    const teamId = teamInfo?.data?.id;

    if (teamId) {
      setTeamLoading(false);
      getEvents(teamId);
    }
  }, [teamInfo]);

  const handleSearch = (e: string) => {
    const val = e.toLowerCase();
    const result = originalEvents.filter((item) => {
      return (
        item.title.toLowerCase().includes(val) ||
        item.description.toLowerCase().includes(val)
      );
    });
    setEvents(result);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <button className='font-xs font-grotesk'>Upcoming</button>,
    },
    {
      key: '2',
      label: <button className='font-xs font-grotesk'>Past Event</button>,
    },
    {
      key: '3',
      label: <button className='font-xs font-grotesk'>OnGoing Events</button>,
    },
    {
      key: '3',
      label: <button className='font-xs font-grotesk'>Drafted</button>,
    },
  ];

    // useEffect(() => {
    //   if (!teamLoading) {
    //     getEvents();
    //   }
    // }, [teamLoading]);

    // useEffect(() => {
    //   getTeamInfo();
    // }, []);
    return (
        <section>
            {/*{*/}
            {/*    eventOnGoing &&*/}
            {/*    <OnGoingAction*/}
            {/*        url={userInfo.email === eventOnGoingObject?.createdBy ? `/active-event/${eventOnGoingObject?.id}/${eventOnGoingObject?.teamId}?isEvent=${true}` : ""}*/}
            {/*        type={"Event"}/>*/}
            {/*}*/}
            <NavbarNew
                hasBreadCrumb
                leftContent={
                    <CButton
                        action={() => navigate('/admin/events/create-event')}
                        iconPosition={'left'}
                        icon={addIcon}
                        variant='dark'
                        text={'Create new event'}
                    />
                }
                subText={'Browse your event gallery'}
                mainText={'Events'}
                mainBigger
            />
            <div className={'mt-8'}>
                <div className='flex mb-4 items-center justify-between'>
                    <Dropdown menu={{items}} placement='bottomLeft'>
                        <div className='bg-[#f5f5f5] cursor-pointer px-4 py-2 rounded-lg flex items-center gap-1'>
              <span>
                <img src={sortIcon} alt={''} />
              </span>
              <span className='text-sm font-grotesk font-semibold'>
                All Events
              </span>
            </div>
          </Dropdown>
          <div>
            <SearchInput handleInputChange={handleSearch} />
          </div>
        </div>

        {eventLoading ? (
          <Loader />
        ) : originalEvents && originalEvents.length ? (
          <Row gutter={12}>
            {events.map((item) => {
              return (
                <Col xs={8}>
                  <EventsCard event={item} getEvents={getEvents} />
                </Col>
              );
            })}
          </Row>
        ) : (
          <EmptyEvents />
        )}
      </div>
    </section>
  );
}

const EventsCard = ({
  event,
  getEvents,
}: {
  event: EventsResponsePayload;
  getEvents: (teamId: string) => void;
}) => {
  const now = moment();
  const eventTime = moment(event.startDate);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { teamInfo } = useSelector(getTeamsState);

  const [isRenameLoading, setIsRenameLoading] = useState(false);
  const [titleName, setTitleName] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openRenameSessionModal, setOpenRenameSessionModal] = useState(false);

  const handleRename = () => {
    setIsRenameLoading(true);
    dispatch(
      updateEvent({
        description: event.description,
        eventId: event.id,
        startDate: event.startDate,
        title: titleName,
        teamId: event.teamId,
      })
    ).then(({ type, payload }) => {
      const teamId = teamInfo?.data?.id;

      getEvents(teamId);
      if (type.includes('fulfilled')) {
      }
      setIsRenameLoading(false);
      setOpenRenameSessionModal(false);
    });
  };

  const closeRenameModal = () => {
    setOpenRenameSessionModal(false);
  };

  const closeDelete = () => {
    setConfirmDelete(false);
  };

  const delete_archive = (archive: boolean) => {
    console.log('Ran!!');
    const teamId = teamInfo?.data?.id;
    setIsDeleting(true);
    dispatch(
      deleteEvent({ eventId: event.id, teamId: event.teamId, archive })
    ).then(({ type }) => {
      if (type.includes('fulfilled')) {
        getEvents(teamId);
      }
      setIsDeleting(false);
      setConfirmDelete(false);
    });
  };

  const handleDelete = () => {
    delete_archive(false);
  };

  const itemsEventOwner = [
    {
      key: '1',
      action: 'rename',
      label: (
        <div
          className='flex gap-x-2 pl- pr-5'
          onClick={(e) => {
            e.stopPropagation();
            setOpenRenameSessionModal(true);
          }}
        >
          <img src={editIcon} alt='rename file' className='w-5' />
          <p>Rename event</p>
        </div>
      ),
    },
    {
      key: '2',
      action: 'archive',
      label: (
        <div
          className={`flex gap-x-2 pl- pr-5`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            delete_archive(true);
          }}
        >
          <img src={archiveIcon} alt='archive' className='w-5' />
          <p>Archive</p>
        </div>
      ),
    },
    {
      key: '3',
      action: 'removeSession',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl- pr-5'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setConfirmDelete(true);
          }}
        >
          <img src={trashDarkRed} alt='delete file' className='w-5 ' />
          <p className='text-[#AE372A]'>Delete</p>
        </div>
      ),
    },
  ];

  const itemsCollaborator = [
    {
      key: '1',
      action: 'rename',
      label: (
        <div
          className='flex gap-x-2 pl- pr-5'
          onClick={(e) => {
            e.stopPropagation();
            setOpenRenameSessionModal(true);
          }}
        >
          <img src={editIcon} alt='rename file' className='w-5' />
          <p>Rename event</p>
        </div>
      ),
    },
    {
      key: '2',
      action: 'preview',
      label: (
        <div
          className={`flex gap-x-2 pl- pr-5`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img src={archiveIcon} alt='preview session' className='w-5' />
          <p>Archive</p>
        </div>
      ),
    },
    {
      key: '3',
      action: 'removeSession',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl- pr-5'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setConfirmDelete(true);
          }}
        >
          <img src={trashDarkRed} alt='delete file' className='w-5 ' />
          <p className='text-[#AE372A]'>Delete</p>
        </div>
      ),
    },
  ];

  return (
    <div className='bg-[#F6F6F6] rounded-lg p-3 border border-[#EEEEEE] mb-3 '>
      <span
        className='w-full cursor-pointer'
        onClick={() => {
          event.published
            ? navigate(`/admin/events/details/${event.id}`)
            : navigate(`/admin/events/create-event/${event.id}`);
        }}
      >
        <OptimizedImageWrapper id={event.id} event={event} />
      </span>
      <div className='mt-2'>
        <div className='flex items-center justify-between'>
          <p
            className='font-semibold text-base font-grotesk w-full truncate cursor-pointer'
            onClick={() => {
              event.published
                ? navigate(`/admin/events/details/${event.id}`)
                : navigate(`/admin/events/create-event/${event.id}`);
            }}
          >
            {event.title}
          </p>
          {event.role === 'EventOwner' && (
            <Dropdown
              trigger={['click']}
              dropdownRender={(menu) => (
                <Menu>
                  <>
                    {itemsEventOwner.map((item) => {
                      return <Menu.Item key={item.key}>{item.label}</Menu.Item>;
                    })}
                  </>
                </Menu>
              )}
            >
              <button className='pl-2'>
                <img alt={''} src={ellipsis} />
              </button>
            </Dropdown>
          )}
        </div>
        <p className='font-grotesk text-[#757575] text-sm mb-1'>
          Event date: {moment(event.startDate).format('MMMM D YYYY')}
        </p>
        <Tag
          variant={
            event.newInvite
              ? 'newinvite'
              : !event.published
              ? 'draft'
              : eventTime.isAfter(now)
              ? 'upcoming'
              : eventTime.isBefore()
              ? 'past'
              : 'draft'
          }
        />
      </div>

      <Modal
        footer={null}
        centered={false}
        open={openRenameSessionModal}
        onCancel={closeRenameModal}
        style={{
          top: 20,
        }}
        destroyOnClose
        closeIcon={false}
      >
        <RenameModal
          closeModal={closeRenameModal}
          setTitleName={setTitleName}
          func={handleRename}
          isLoading={isRenameLoading}
          previousTitle={event.title ?? ''}
          titleName={titleName}
          heading='Rename event'
          subHeading='Rename your event'
          buttonText='Update event'
        />
      </Modal>

      <ConfirmationModal
        open={confirmDelete}
        isLoading={isDeleting}
        onClose={closeDelete}
        func={handleDelete}
        text='Delete event'
        subText='Are you sure you want to delete this event?'
      />
    </div>
  );
};

const Tag = ({
  variant,
}: {
  variant: 'ongoing' | 'draft' | 'upcoming' | 'past' | 'newinvite';
}) => {
  return (
    <div className='flex '>
      <span
        className={`py-1 px-2 justify-center flex gap-1 rounded-[4px] ${
          variant === 'ongoing'
            ? 'border  border-[#0E8345] bg-[#EAF6ED] text-[#166C3B]'
            : variant === 'past'
            ? 'border  border-[#727272] bg-[#F3F3F3] text-[#5E5E5E]'
            : variant === 'draft'
            ? 'border  border-[#FFD1BD] bg-[#FFF0E9] text-[#A33B04]'
            : variant === 'upcoming'
            ? 'border  border-[#276EF1] bg-[#EFF4FE] text-[#175BCC]'
            : variant === 'newinvite'
            ? 'border  border-[#FFD1BD] bg-[#FFF0E9] text-[#A33B04]'
            : ''
        }`}
      >
        <img
          src={
            variant === 'ongoing'
              ? playGreen
              : variant === 'past'
              ? pastEvent
              : variant === 'draft'
              ? draftIcon
              : variant === 'upcoming'
              ? upcomingIcon
              : variant === 'newinvite'
              ? newInvite
              : ''
          }
          alt={''}
        />
        <p className='text-xs font-semibold'>
          {variant === 'ongoing'
            ? 'Ongoing'
            : variant === 'past'
            ? 'Past event'
            : variant === 'draft'
            ? 'Draft'
            : variant === 'upcoming'
            ? 'Upcoming'
            : variant === 'newinvite'
            ? 'New invite'
            : ''}
        </p>
      </span>
    </div>
  );
};
