import addCircle from 'assets/svg/add-circle-orange.svg';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import arrowDown from 'assets/svg/arrow-down-dropdown.svg';
import plusIconGray from 'assets/svg/add-circle-gray.svg';
import { AgendaList, Flow } from 'interfaces/Events/EventsInterfaces';
import InputTitle from './InputTitle';
import { getEmail } from 'utils/localStorageServices';

interface Props {
  otherAgenda?: boolean;
  agendaList?: AgendaList[];
  setAgendaList?: Dispatch<SetStateAction<AgendaList[]>>;
  flow?: Flow;
  setFlow?: Dispatch<SetStateAction<Flow>>;
  from?: 'eventDetailsPage';
  addSessionFromEventDetailsPage?: (
    title: string,
    emailList: string[],
    emailName: string,
    eventHost: 'You' | 'Guest'
  ) => void;
}

interface AgendaDetails {
  title: string;
  collaborators: string[];
}

export default function CreateAgenda({
  otherAgenda,
  agendaList,
  setAgendaList,
  from,
  addSessionFromEventDetailsPage,
}: Props) {
  const [eventHost, setEventHost] = useState<'You' | 'Guest'>('Guest');
  const [emailName, setEmailName] = useState<string>('');
  const [title, setTitle] = useState('');

  const [agendaDetails, setAgendaDetails] = useState<AgendaDetails>({
    title: '',
    collaborators: [],
  });
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (
      (title.length > 0 && agendaDetails.collaborators.length > 0) ||
      (title.length > 0 && eventHost === 'You')
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [agendaDetails, title, eventHost]);

  const items = [
    {
      key: '1',
      label: (
        <div onClick={() => setEventHost('Guest')}>
          <p>Guests</p>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div onClick={() => setEventHost('You')}>
          <p>You</p>
        </div>
      ),
    },
  ];

  const handleAddSession = () => {
    let updatedEmailList = [...agendaDetails.collaborators];
    if (emailName.trim().length > 0) {
      updatedEmailList = [...updatedEmailList, emailName];
    }
    setAgendaList &&
      setAgendaList((agenda_list) => [
        ...agenda_list,
        {
          title,
          id: `agenda-${agendaList?.length}`,
          collaborators: eventHost === 'You' ? [getEmail()] : updatedEmailList,
          position: agenda_list.length,
          added: false,
        },
      ]);
    setAgendaDetails({
      collaborators: [],
      title: '',
    });
    setTitle('');
    setEmailName('');
  };

  const removeEmail = (index: number) => {
    const updatedEmails = agendaDetails.collaborators.filter(
      (_, i) => i !== index
    );
    setAgendaDetails({ ...agendaDetails, collaborators: updatedEmails });
  };

  const handleKeyDownEmails = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value.trim();
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      if (email === ',' || email === ' ') {
        setEmailName('');
        return;
      }
      if (email) {
        if (agendaDetails.collaborators.includes(email)) {
          setEmailName('');
          return;
        } else {
          setAgendaDetails({
            ...agendaDetails,
            collaborators: [...agendaDetails.collaborators, email],
          });
        }
      }
      setEmailName('');
    }
  };

  return (
    <section className={`mt-5`}>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submission
          }
        }}
      >
        <div className='w-full grid grid-cols-1 mt-5'>
          <label htmlFor='title'>
            <InputTitle title='Agenda Title' />

            <input
              type='text'
              placeholder='E.g Weather presentation, Monthly Village meeting'
              className='w-full bg-[#F6F3EF] h-[50px] text-sm px-3 rounded-lg text-[#667085] font-medium'
              name='title'
              value={title || ''}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </label>
        </div>

        {!otherAgenda && (
          <div className='w-full grid grid-cols-1 mt-5 gap-5'>
            <label htmlFor='title'>
              <InputTitle title='Who is this agenda for?' />

              <Dropdown menu={{ items }} overlayClassName=''>
                <div className='w-full bg-[#F6F3EF] h-[50px] text-sm px-3 rounded-lg flex justify-between items-center cursor-pointer'>
                  <p>{eventHost}</p>
                  <img src={arrowDown} alt='more options' className='w-4' />
                </div>
              </Dropdown>
            </label>
          </div>
        )}

        {(eventHost !== 'You' || otherAgenda) && (
          <section className='mt-5'>
            <div className='w-full grid grid-cols-1 mt-5'>
              <label htmlFor='email'>
                <InputTitle title='Presenter Email' />
                <input
                  type='email'
                  placeholder='Example@teamdocs.io'
                  className='w-full bg-[#F6F3EF] h-[50px] text-sm p-3 rounded-lg resize-none text-[#667085] font-medium'
                  name='email'
                  value={emailName || ''}
                  onKeyDown={handleKeyDownEmails}
                  onChange={(e) => {
                    setEmailName(e.target.value);
                  }}
                />
              </label>
            </div>
            {/* emails */}
            <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'>
              {agendaDetails.collaborators?.map((email, index) => {
                return (
                  <div
                    key={index}
                    className='flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180'
                  >
                    <p className='text-sm'>{email}</p>
                    <i
                      className='text-xs cursor-pointer pi pi-times mt-[2px]'
                      onClick={() => removeEmail(index)}
                    ></i>
                  </div>
                );
              })}
            </section>
          </section>
        )}
        {from !== 'eventDetailsPage' && (
          <div className='w-full mt-2 '>
            <button
              className={`${
                disableButton ? 'text-[#B5B5B5]' : 'text-[#FF6929]'
              } bg-[#F5F5F5] px-2 text-sm rounded-lg py-1`}
              onClick={(e) => {
                e.preventDefault();
                handleAddSession();
              }}
              disabled={disableButton}
            >
              <div className='flex gap-x-2 items-center'>
                <img
                  src={disableButton ? plusIconGray : addCircle}
                  alt='add agenda'
                />
                <p className='text-sm font-semibold'>Add agenda</p>
              </div>
            </button>
          </div>
        )}
        {from === 'eventDetailsPage' && addSessionFromEventDetailsPage && (
          <button
            className='w-full bg-black text-white font-semibold text-sm rounded-lg  h-[50px] mt-5'
            onClick={(e) => {
              e.preventDefault();
              addSessionFromEventDetailsPage(
                title,
                agendaDetails.collaborators,
                emailName,
                eventHost
              );
            }}
          >
            Create agenda
          </button>
        )}
      </form>
    </section>
  );
}
