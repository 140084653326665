import React from "react";
import {AgendaInterface, EventsInterface} from "../../../../interfaces/interfaces-data";
import {Col, Row} from "antd";
import bannerPlaceholder from "../../../../assets/svg/eventWaiting.svg";
import conferenceSmall from "../../../../assets/svg/conferenceSmall.svg";

export const DesktopViewerPresentationScreen = ({
                                                    loading,
                                                    imageSource,
                                                    outOfSync,
                                                    isFullScreen,
                                                    setIsFullScreen,
                                                    idleState,
                                                    upcomingAgenda,
                                                    eventDetails,
                                                    eventPresentationsEnded

                                                }: {
    loading: boolean,
    imageSource: string,
    outOfSync: boolean,
    isFullScreen: boolean,
    setIsFullScreen: (val: boolean) => void,
    idleState: boolean,
    upcomingAgenda?: AgendaInterface,
    eventDetails?: EventsInterface
    eventPresentationsEnded: boolean
}) => {

    return <>


        <div
            className={`bg-[#F6F6F6]  ${outOfSync && "border-[#FF6929] border-2"}  p-4 rounded-xl justify-center  overflow-hidden h-full w-full`}>

            {((idleState && upcomingAgenda) || eventPresentationsEnded) ?

                <div className={"h-full w-full "}>
                    {!eventPresentationsEnded && <div
                        className="bg-[#E0DDD9] py-2 flex rounded-2xl justify-around text-[#FF6929] italic text-2xl font-grotesk gap-2">
                        <p className="relative">
                            <span className="absolute -left-2">*</span>
                            <span>Coming Up</span>
                            <span className="absolute -right-2">*</span>

                        </p><p className="relative">
                        <span className="absolute -left-2">*</span>
                        <span>Coming Up</span>
                        <span className="absolute -right-2">*</span>

                    </p><p className="relative">
                        <span className="absolute -left-2">*</span>
                        <span>Coming Up</span>
                        <span className="absolute -right-2">*</span>
                    </p>
                    </div>}

                    <Row justify={"center"} align={"middle"}
                         className={"flex py-8 items-center h-full "}>
                        <Col xs={8}>
                            <img src={bannerPlaceholder} alt={""}/>
                        </Col>
                        <Col xs={16} className="bg-white p-4 min-h-[80%] h-[80%] rounded-lg">

                            <p className="text-[#A53D1F] mb-3 text-4xl font-grotesk font-semibold">{eventPresentationsEnded ? "Thank you for joining the event" : upcomingAgenda?.title}</p>
                            <div className="flex  h-[80%] flex-col justify-between">

                                <div className="bg-[#FAFAFA] border p-4 border-[#EBF1FF] rounded-lg">
                                    <p className="italic text-sm text-[#1D1E1F]">
                                        {eventDetails?.description}
                                    </p>
                                </div>
                                <div className="flex gap-2 flex-wrap gap-2">
                                    {eventPresentationsEnded ? "Thank you for participating" : upcomingAgenda?.collaborators.length ? upcomingAgenda.collaborators.map(item => {
                                        return <span
                                            key={item.id}
                                            className="bg-[#EEEEEE] mb-2 border border-[#D3D3D3] rounded-full py-1 flex items-center gap-2 px-4">
                                                <img src={conferenceSmall} alt={""}/>
                                                <p className={"text-xs"}>
                                                    {item.fullName}
                                                </p>
                                            </span>
                                    }) : <div>{eventPresentationsEnded ? "" : "No Collaborator Found"}</div>

                                    }

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                : !loading && imageSource && <img src={imageSource} alt={""}
                                                  className=" overflow-hidden h-full w-full rounded-2xl object-contain"/>
            }
        </div>
    </>
}