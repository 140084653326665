import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

//libraries
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import {
  getConnectionState,
  getSignalRState,
} from "store/SignalR/signalRSlice";
import { RequestPresentationAccess } from "hooks/SignalR/Invokers/Viewer/RequestPresentationAccess";
import { getUserDetails } from "utils/localStorageServices";

export default function JoinOTP({
  connection,
  presentationId,
  reference,
  isLoading,
  triggerLoading,
}) {
  const { connectionId } = useSelector(getSignalRState);
  const connectionState = getConnectionState();

  //usestate
  // const [isButtonActive, setIsButtonActive] = useState(false);
  const [otpValues, setOtpValues] = useState(Array(6).fill(""));
  const [hasRequestedAccess, setHasRequestedAccess] = useState(false);
  const [placeholders, setPlaceholders] = useState(Array(6).fill("0"));

  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  useEffect(() => {
    const userDetails = getUserDetails();

    if (connectionState === "Connected" && userDetails && !hasRequestedAccess) {
      RequestPresentationAccess(
        connection,
        presentationId,
        userDetails?.email,
        userDetails?.fullName
      );
      setHasRequestedAccess(true);
    }
  }, [connection, presentationId, connectionState, hasRequestedAccess]);

  //functions
  const handlePaste = (e, index) => {
    e.preventDefault();

    // Access the clipboard data
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text/plain");

    if (/^[0-9]*$/.test(pastedData) && pastedData.length <= 6 - index) {
      const newValues = [...otpValues];

      for (let i = 0; i < pastedData.length; i++) {
        newValues[index + i] = pastedData[i];
      }

      setOtpValues(newValues);

      // Focus on the next input if available
      if (otpValues.length > index + pastedData.length) {
        inputRefs[index + pastedData.length].current.focus();
      }
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;

    if (/^[0-9]*$/.test(value)) {
      const newValues = [...otpValues];
      newValues[index] = value;
      setOtpValues(newValues);

      if (otpValues.length > index + 1) {
        inputRefs[index + 1].current.focus();
      }
    }
    // Update placeholder when value becomes empty
    if (value === "") {
      const newPlaceholders = [...placeholders];
      newPlaceholders[index] = "0"; // Or whatever your default placeholder is
      setPlaceholders(newPlaceholders);
    }
  };

  const submitOTP = (e) => {
    e.preventDefault();

    if (!otpValues) {
      toast.error("Enter OTP");
      return;
    }

    let otp = otpValues?.join("");

    triggerLoading();
    connection
      .invoke(
        "ConfirmAccessOTP",
        presentationId,
        otp,
        reference,
        false
        // getConnectionId()
      )
      .catch((err) => {
        console.log(err, "error");
        toast.error(
          "An error occurred invoking 'ConfirmAccessOTP' on the server"
        );
      });
  };
  return (
    <section>
      <div className="mt-4 text-center text-white lg:text-left">
        <h3 className="text-2xl"> OTP </h3>
        <p className="my-1 text-sm">Please provide the OTP sent to your mail</p>
      </div>

      <form onSubmit={submitOTP} className="my-6 text-white">
        <div className="flex items-center justify-center my-5 lg:my-10 gap-x-4">
          {otpValues.map((value, index) => {
            return (
              <input
                inputMode="numeric"
                ref={inputRefs[index]}
                key={index}
                placeholder={placeholders[index]}
                type="tel"
                value={value}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    if (index !== 0 && !otpValues[index]) {
                      inputRefs[index - 1].current.focus();
                    }
                  }
                }}
                onPaste={(e) => handlePaste(e, index)}
                onFocus={() => {
                  const newPlaceholders = [...placeholders];
                  newPlaceholders[index] = "";
                  setPlaceholders(newPlaceholders);
                }}
                onBlur={() => {
                  if (!value) {
                    const newPlaceholders = [...placeholders];
                    newPlaceholders[index] = "0";
                    setPlaceholders(newPlaceholders);
                  }
                }}
                className="
                 text-center text-lg 
                 [  border border-[#F6F3EF1F] ]
                 [ lg:w-[3.3rem] lg:h-[3.3rem] w-[2.2rem] h-[2.2rem] ]
                 [ bg-transparent backdrop-blur-lg bg-gradient-to-b from-[#F6F3EF1F]/10 to-[#F6F3EF1F]/10 ]
                 [ rounded-lg outline-none placeholder:text-sm  placeholder:text-center placeholder:text-2xl ] 
                  "
              />
            );
          })}
        </div>

        <button className="bg-[#0E0E0E] text-white w-full py-2 mt-4 rounded-md ">
          {" "}
          {isLoading ? (
            <ClipLoader color="white" size={12} />
          ) : (
            "Join presentation"
          )}
        </button>
      </form>
    </section>
  );
}
