import React, {MutableRefObject, useEffect, useRef} from 'react';
import {SlideInterface} from '../../../../interfaces/interfaces-data';
import SlidesPreview, {ImageCache,} from 'components/User/SharedBriefcase/SlidesPreview';

export const SlideIndicator = ({
                                   screenWidth,
                                   imageCache,
                                   minScreenWidth,
                                   slides,
                                   activeDocumentIndex,
                                   setActiveDocumentIndex,
                                   handleMoveSlide
                               }: {
    slides: SlideInterface[];
    screenWidth: number;
    minScreenWidth: number;
    activeDocumentIndex: number;
    setActiveDocumentIndex: (val: number) => void;
    imageCache: MutableRefObject<ImageCache>;
    handleMoveSlide: (val: number) => void
}) => {
    const itemRefs = useRef<(HTMLElement | null)[]>([]);

    useEffect(() => {
        const currentItem = itemRefs.current[activeDocumentIndex];
        if (currentItem) {
            currentItem.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center', // Center alignment for better view
            });
        }
    }, [activeDocumentIndex]);

    return (
        <>
            <section
                className={`hide-scrollbar  pl-4  w-full overflow-hidden overflow-x-auto py-5 flex gap-x-3 content-end ${
                    screenWidth < minScreenWidth
                        ? 'absolute bottom-0 left-0 -translate-y-[100%] group'
                        : 'my-[10px] h-[10rem]'
                } `}
            >
                {slides.map((item, index) => (
                    <section
                        key={item.id}
                        onClick={() => {
                            handleMoveSlide(index)
                            setActiveDocumentIndex(index)
                        }} // Adjust index for sliced items
                        ref={(el) => (itemRefs.current[index] = el)}
                        className={`  ${
                            screenWidth < minScreenWidth
                                ? 'h-[100px] min-w-[10rem] max-w-[10rem]'
                                : 'h-[10rem] min-w-[12rem] max-w-[12rem] aspect-[8.5/11 aspect-[16/9 h-[100px'
                        }`}
                    >
                        <SlidesPreview
                            slide={item}
                            index={index}
                            imageCache={imageCache}
                            slideDocumentIndex={activeDocumentIndex}
                            showImage={screenWidth >= minScreenWidth}
                            isGridDisplay={false}
                            fromPresentation
                        />
                    </section>
                ))}
            </section>
        </>
    );
};
