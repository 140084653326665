interface BackgroudColor {
  backgroudColor: string;
}

const getColorfulBgClass = (index: number): BackgroudColor => {
  const bgList = [
    { backgroudColor: '#EF75BE' },
    { backgroudColor: '#E375EF' },
    { backgroudColor: '#C575EF' },
  ];

  if (index < bgList.length) {
    return bgList[index];
  } else {
    return bgList[(index % index) - 1];
  }
};

export const defaultOptionsColour = [
  '#E375EF',
  '#EF75BE',
  '#EF759F',
  '#C575EF',
  '#8775EF',
  '#7581EF',
  '#0C06FF',
  '#2dbdd4',
  '#2c97d3',
  '#50b2e6',
];

export default getColorfulBgClass;
