import { toast } from 'sonner';

let routeOrigin = window.location.origin;

const copyLink = async (link: string) => {
  let url = `${routeOrigin}/${link}`;

  try {
    await navigator.clipboard.writeText(url);
    toast.success('Link copied to clipboard');
  } catch (error) {
    toast.error('Failed to copy link');
  }
};

export default copyLink;
