import { useEffect, useState } from 'react';

//assets
import uploadBulk from 'assets/svg/people2.svg';
import sms from 'assets/svg/sms-tracking.svg';
import calender from 'assets/svg/calendar-edit.svg';
import timer from 'assets/svg/timer3.svg';
import arrowRight from 'assets/svg/arrow-right-white.svg';
import linkSquare from 'assets/svg/link-square.svg';
import previewImg from 'assets/image/previewFile.png';
import previewIcon from 'assets/svg/preview-icon.svg';
import presentationChart from 'assets/svg/presention-chart.svg';
import copyIcon from 'assets/svg/copy-1.svg';

// Slices
import {
  getShareHistory,
  shareNewPresentation,
} from 'store/Briefcase/briefcaseSlice';

// Libraries
import { DatePicker } from 'antd';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

//Types
interface Props {
  spaceId: string;
  presentationType: number;
  schedulePresentation: boolean;
  documentId?: string | null;
}

interface DocumentShare {
  title: string;
  spaceId?: string;
  startTime: string; // ISO 8601 date-time string
  expiry?: string; // ISO 8601 date-time string
  canDownload: number;
  type: number;
  openLink: boolean;
  emails: string[];
  domains: string[];
  passcode: string;
  authMode: number;
  documentId?: string;
}

export default function ShareBriefcase({
  spaceId,
  presentationType,
  documentId,
}: Props) {
  //
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // states
  const [tab, setTab] = useState(0);
  const [emailName, setEmailName] = useState<string>('');
  const [domainName, setDomainName] = useState<string>('');
  const [usePasscode, setUsePasscode] = useState(false);
  const [linkExpires, setLinkExpires] = useState(false);
  const [shouldRestrictDomains, setShouldRestrictDomains] = useState(false);
  const [shareLinkItems, setShareLinkItems] = useState<DocumentShare>({
    title: '',
    spaceId: spaceId,
    startTime: '',
    expiry: '',
    canDownload: 0,
    type: presentationType,
    openLink: false,
    emails: [],
    domains: [],
    passcode: '',
    authMode: 1,
  });
  const [previewLinkId, setPreviewLinkId] = useState('');
  const [friendlyId, setFriendlyId] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState<any>();
  const [routeOrigin, setRouteOrigin] = useState(window.location.origin);
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    const time = dayjs() as any;
    setCurrentTime(time);
    const currentTime_ = dayjs.utc(time).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    setShareLinkItems({ ...shareLinkItems, startTime: currentTime_ });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeEmail = (index: number) => {
    const updatedEmails = shareLinkItems.emails.filter((_, i) => i !== index);
    setShareLinkItems({ ...shareLinkItems, emails: updatedEmails });
  };

  const removeDomain = (index: number) => {
    const updatedDomains = shareLinkItems.domains.filter((_, i) => i !== index);
    setShareLinkItems({ ...shareLinkItems, domains: updatedDomains });
  };

  const handleKeyDownEmails = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value.trim();
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      if (email === ',') {
        setEmailName('');
        return;
      }
      if (email) {
        setShareLinkItems({
          ...shareLinkItems,
          emails: [...shareLinkItems.emails, email],
        });
      }
      setEmailName('');
    }
  };

  const copyLink = async () => {
    let routeOrigin = window.location.origin;
    let presentationLink = `${routeOrigin}/${
      presentationType === 1
        ? 'join-presentation'
        : 'access-shared-presentation'
    }/${friendlyId}`;

    try {
      await navigator.clipboard.writeText(presentationLink);
      toast.success('Link copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  const handleKeyDownDomains = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const domain = (e.target as HTMLInputElement).value.trim();
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      if (domain === ',') {
        setDomainName('');
        return;
      }
      if (domain) {
        setShareLinkItems({
          ...shareLinkItems,
          domains: [...shareLinkItems.domains, domain],
        });
      }
      setDomainName('');
    }
  };

  const onOkExpiry = (value: string) => {
    const selectedDate = dayjs.utc(value); // Convert selected date string to dayjs object in UTC
    const formattedDate = selectedDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    setShareLinkItems({
      ...shareLinkItems,
      expiry: formattedDate,
    });
  };

  const handleShareBriefcaseUserInputs = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    setShareLinkItems({ ...shareLinkItems, [name]: value });
  };

  const handleShareBriefcase = async () => {
    setIsLoading(true);
    const ShareLinkItemscopy = { ...shareLinkItems };

    if (ShareLinkItemscopy.expiry === '') {
      delete ShareLinkItemscopy.expiry;
    }

    if (documentId) {
      console.log(documentId);
      delete ShareLinkItemscopy.spaceId;
      ShareLinkItemscopy.documentId = documentId;
    }

    const { type, payload } = await dispatch(
      shareNewPresentation(ShareLinkItemscopy)
    );

    if (!type.includes('fulfilled')) {
      toast.error('Presentation was not created!');
      return;
    } else {
      setTab(1);
      setPreviewLinkId(payload.id);
      setFriendlyId(payload.friendlyId);
      toast.success('Presentation created successfully');
    }
    spaceId && dispatch(getShareHistory(spaceId));

    setShareLinkItems({
      title: '',
      spaceId: spaceId,
      startTime: '',
      expiry: '',
      canDownload: 0,
      type: presentationType,
      openLink: false,
      emails: [],
      domains: [],
      passcode: '',
      authMode: 1,
    });
  };

  const handlePresentBriefcase = async () => {
    setIsLoading(true);
    const ShareLinkItemscopy = { ...shareLinkItems };

    if (ShareLinkItemscopy.expiry === '') {
      delete ShareLinkItemscopy.expiry;
    }

    if (documentId) {
      delete ShareLinkItemscopy.spaceId;
      ShareLinkItemscopy.documentId = documentId;
    }

    const { type, payload } = await dispatch(
      shareNewPresentation(ShareLinkItemscopy)
    );

    if (!type.includes('fulfilled')) {
      toast.error('Presentation was not created!');
      return;
    }

    if (payload) {
      setPreviewLinkId(payload.id);
      setFriendlyId(payload.friendlyId);
      setIsLoading(false);
      toast.success('Presentation created successfully');
      setTab(1);
    } else {
      setIsLoading(false);
      toast.error('Presentation was not created!');
    }

    // Reseting the inputs and tabs
    setShareLinkItems({
      title: '',
      spaceId: spaceId,
      startTime: '',
      expiry: '',
      canDownload: 0,
      type: presentationType,
      openLink: false,
      emails: [],
      domains: [],
      passcode: '',
      authMode: 1,
    });
  };

  return (
    <section className='pt-5 pb-20 min-h-[100vh] relative'>
      <div className='w-full border-b pb-2'>
        <h1 className='text-xl font-medium'>
          {presentationType === 0 ? 'Share link' : 'Presentation'}
        </h1>
      </div>

      {/* First Tab */}
      {tab === 0 && (
        <section className=''>
          <div className='flex flex-col gap-y-1 mt-5'>
            <label htmlFor='space' className='font-normal text-gray700'>
              Enter Presentation name
            </label>
            <input
              type='text'
              name='title'
              required
              value={shareLinkItems?.title || ''}
              placeholder='e.g for external board members'
              className='py-3 pl-4 text-sm rounded-lg outline-none bg-orange10'
              onChange={(e) => {
                handleShareBriefcaseUserInputs(e);
                if (e.target.value.trim().length > 0) {
                  setDisableButton(false);
                } else {
                  setDisableButton(true);
                }
              }}
            />
          </div>
          {/* share link */}
          <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
            <div className='w-full flex justify-between items-center mt-3'>
              <p className='text-base'>Share link with anyone</p>
              <label className='inline-flex items-center cursor-pointer'>
                <input
                  type='checkbox'
                  checked={shareLinkItems.openLink}
                  className='sr-only peer'
                  onChange={() => {
                    setShareLinkItems({
                      ...shareLinkItems,
                      openLink: !shareLinkItems.openLink,
                    });
                  }}
                />
                <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
            {/* add email */}
            <div className='grid grid-cols-9 gap-x-2 mt-3'>
              <label
                htmlFor='add-email'
                className='col-span-6 rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
              >
                <img src={sms} alt='email' className='w-4 h-4' />
                <input
                  type='text'
                  id='add-email'
                  placeholder='add email'
                  className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                  onKeyDown={handleKeyDownEmails}
                  value={emailName || ''}
                  onChange={(e) => setEmailName(e.target.value)}
                />
              </label>
              <button className='flex gap-x-2 items-center px-3 py-2 col-span-3 w-full bg-[#F5F5F5] rounded-md'>
                <img src={uploadBulk} alt='upload' className='h-4 w-4' />
                <p className='text-sm text-[#6B6B6B]'>upload bulk</p>
              </button>
            </div>
            {/* email... */}
            <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'>
              {shareLinkItems.emails?.map((email, index) => {
                return (
                  <div
                    key={index}
                    className='flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180'
                  >
                    <p>{email}</p>
                    <i
                      className='text-xs cursor-pointer pi pi-times mt-[2px]'
                      onClick={() => removeEmail(index)}
                    ></i>
                  </div>
                );
              })}
            </section>
          </section>

          {/* use password */}
          {!presentationType && (
            <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
              <div className='w-full flex justify-between items-center mt-3'>
                <p className='text-base'>Use passcode</p>
                <label className='inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    checked={usePasscode}
                    onChange={() => {
                      if (usePasscode) {
                        setShareLinkItems({
                          ...shareLinkItems,
                          passcode: '',
                        });
                        setUsePasscode(false);
                      } else {
                        setUsePasscode(true);
                      }
                    }}
                    className='sr-only peer'
                  />
                  <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                </label>
              </div>
              {/* enter password */}
              {usePasscode && (
                <div className='w-full mt-3'>
                  <label
                    htmlFor='add-email'
                    className='w-full rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
                  >
                    <img src={sms} alt='email' className='w-4 h-4' />
                    <input
                      type='text'
                      id='add-email'
                      placeholder='Enter password'
                      className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                      name='passcode'
                      value={shareLinkItems.passcode || ''}
                      onChange={(e) => {
                        setUsePasscode(true);
                        handleShareBriefcaseUserInputs(e);
                      }}
                    />
                  </label>
                </div>
              )}
            </section>
          )}

          {/* Link time */}
          {!presentationType && (
            <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
              <>
                {/* link expires */}
                <div className='w-full flex justify-between items-center'>
                  <p className='text-base'>Set link expiry date</p>
                  <label className='inline-flex items-center cursor-pointer'>
                    <input
                      type='checkbox'
                      checked={linkExpires}
                      className='sr-only peer'
                      onChange={() => {
                        if (linkExpires) {
                          setLinkExpires(false);
                          setShareLinkItems({ ...shareLinkItems, expiry: '' });
                        } else {
                          setLinkExpires(true);
                        }
                      }}
                    />
                    <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                  </label>
                </div>
                {/* expires */}
                {linkExpires && (
                  <div className='w-full mt-3 grid grid-cols-2 gap-x-2'>
                    <div
                      className={`col-span-1 bg-[#F5F5F5] w-full py- rounded-md px flex items-center gap-x-2 ${
                        !linkExpires && 'pointer-events-none'
                      }`}
                    >
                      <DatePicker
                        showTime
                        onOk={onOkExpiry}
                        minDate={dayjs()}
                        // onChange={onChangeExpiry}
                        suffixIcon={
                          <img
                            src={calender}
                            alt='calender'
                            className='w-4 h-4'
                          />
                        }
                        format='YYYY-MM-DD'
                        className='bg-[#F5F5F5] w-full h-full border-none outline-none'
                      />
                    </div>
                    <div className='col-span-1 bg-[#F5F5F5] w-full py-3 rounded-md px-3 flex items-center gap-x-2'>
                      <img src={timer} alt='timer' />
                      <p className='text-sm text-[#6B6B6B]'>
                        {shareLinkItems.expiry === ''
                          ? '0:00 AM'
                          : dayjs(shareLinkItems.expiry).format('h:mm A')}
                      </p>
                    </div>
                  </div>
                )}
              </>
            </section>
          )}

          {/* Allow downloads */}
          <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
            <div className='w-full flex justify-between items-center'>
              <p className='text-base'>Allow downloads</p>
              <label className='inline-flex items-center cursor-pointer'>
                <input
                  type='checkbox'
                  onChange={() => {
                    setShareLinkItems({
                      ...shareLinkItems,
                      canDownload: shareLinkItems.canDownload === 0 ? 1 : 0,
                    });
                  }}
                  className='sr-only peer'
                />

                <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
          </section>

          {/* More security */}
          <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
            <h2 className='text-base font-semibold'>More security</h2>
            <div className='w-full flex justify-between items-center mt-3'>
              <p className='text-base'>Restrict domain</p>
              <label className='inline-flex items-center cursor-pointer'>
                <input
                  type='checkbox'
                  checked={shouldRestrictDomains}
                  onChange={() => {
                    if (shouldRestrictDomains) {
                      setShouldRestrictDomains(false);
                    } else {
                      setShouldRestrictDomains(true);
                    }
                  }}
                  className='sr-only peer'
                />
                <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
            {/* add domain */}
            <div className='grid grid-cols-9 gap-x-2 mt-3'>
              <label
                htmlFor='add-domain'
                className='col-span-6 rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
              >
                <img src={sms} alt='domain' className='w-4 h-4' />
                <input
                  type='text'
                  id='add-email'
                  placeholder='Add domain'
                  className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                  value={domainName}
                  onKeyDown={handleKeyDownDomains}
                  onChange={(e) => setDomainName(e.target.value)}
                />
              </label>
              <button className='flex gap-x-2 items-center px-3 py-2 col-span-3 w-full bg-[#F5F5F5] rounded-md'>
                <img src={uploadBulk} alt='upload' className='h-4 w-4' />
                <p className='text-sm text-[#6B6B6B]'>upload bulk</p>
              </button>
            </div>
            {/* email... */}
            <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'>
              {shareLinkItems.domains?.map((domain, index) => {
                return (
                  <div
                    key={index}
                    className='flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180'
                  >
                    <p>{domain}</p>
                    <i
                      className='text-xs cursor-pointer pi pi-times mt-[2px]'
                      onClick={() => removeDomain(index)}
                    ></i>
                  </div>
                );
              })}
            </section>
          </section>
        </section>
      )}
      {/* Third Tab */}
      {tab === 1 && (
        <section>
          <section className='p-3 bg-[#E6F2ED] min-h-20 flex gap-x-4 rounded-xl items-center '>
            <img src={linkSquare} alt='review!' className='w-6' />
            <div className=''>
              <p className='text-sm text-black font-semibold'>
                Link created successfully
              </p>
              <p className='cursor-pointer' onClick={copyLink}>
                {`${routeOrigin}/${
                  presentationType === 1
                    ? 'join-presentation'
                    : 'access-shared-presentation'
                }/${friendlyId}`}{' '}
                <span className='mr-1'>
                  <img src={copyIcon} alt='copy' className='cursor-pointer' />
                </span>
              </p>
            </div>
          </section>

          {presentationType === 0 ? (
            <section className='rounded-md border p-3 flex flex-col gap-y-2 mt-5'>
              <img src={previewImg} alt='preview file' className='w-[83px]' />
              <p>See how your file will look like to the public</p>
              <button
                className='flex gap-x-2 bg-[#F5F5F5] py-2 px-5 rounded-md w-fit'
                onClick={() => {
                  navigate(`/shared-briefcase/${previewLinkId}`);
                }}
              >
                <img src={previewIcon} alt='preveiw botton' />
                <p className='text-black'>Preview file</p>
              </button>
            </section>
          ) : (
            <section className='rounded-md border p-3 flex flex-col gap-y-2 mt-5'>
              <img src={previewImg} alt='preview file' className='w-[83px]' />
              <p>Edit your presentation and share</p>
              <button
                className='flex gap-x-2 bg-[#F5F5F5] py-2 px-5 rounded-md w-fit'
                onClick={() => {
                  navigate(`/presentation-details/${previewLinkId}`);
                }}
              >
                <img src={presentationChart} alt='preveiw botton' />
                <p className='text-black'>Go to presentation</p>
              </button>
            </section>
          )}
        </section>
      )}

      {/* Next and previous */}
      <section className='absolute bottom-0 right-0 w-full border-y h-[66px] flex justify-end px-5 items-center'>
        {tab === 0 && (
          <button
            className={`text-white h-10 px-5 flex gap-x-2 items-center justify-center rounded-md min-w-[100px] 
             ${disableButton ? 'bg-orange-300' : 'bg-primary'}
             `}
            onClick={() => {
              if (presentationType) {
                handlePresentBriefcase();
              } else {
                handleShareBriefcase();
              }
            }}
            disabled={disableButton}
          >
            {!isLoading ? (
              <span className='flex items-center gap-x-2'>
                <p className='font-semibold'>Share link</p>
                <img src={arrowRight} alt='next' />
              </span>
            ) : (
              <ClipLoader size={12} color='white' />
            )}
          </button>
        )}
      </section>
    </section>
  );
}
