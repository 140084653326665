import { useEffect } from 'react';

// Liberies
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

import { getTeamInfo, getTeamsState } from 'store/Teams/teamsSlice';

import {
  getDocumentPreview,
  getEvent,
  setEventsPreview,
  setSessionPreview,
} from 'store/Events/EventsSlice';
import Preview from 'components/UI/Preview';

export default function EventsPreview() {
  const { spaceId, documentId, eventId, sessionId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { teamInfo } = useSelector(getTeamsState);

  const previewIsLoading = useSelector(
    (state: RootState) => state.events.preview.isLoading
  );

  const flattedDisplayDocuments = useSelector(
    (state: RootState) => state.events.preview.flattedDisplayDocuments
  );

  const { data: eventDetails } = useSelector(
    (state: RootState) => state.events.event
  );

  const previewDocs = useSelector(
    (state: RootState) => state.events.preview.data
  );

  // useeffects
  useEffect(() => {
    dispatch(getTeamInfo());
  }, [dispatch, spaceId, documentId]);

  useEffect(() => {
    const teamId = teamInfo?.data?.id;

    if (documentId) {
      dispatch(getDocumentPreview(documentId));
    } else {
      eventId &&
        dispatch(getEvent({ eventId, teamId })).then(({ type }) => {
          if (type.includes('fulfilled')) {
            if (sessionId) {
              dispatch(setSessionPreview(sessionId));
            } else {
              dispatch(setEventsPreview());
            }
          }
        });
    }
  }, [dispatch, spaceId, teamInfo.data, eventId, documentId, sessionId]);

  return (
    <Preview
      flattedDisplayDocuments={flattedDisplayDocuments}
      previewDocs={previewDocs}
      ownerName={teamInfo?.data?.title ?? "Creator's name"}
      previewTitle={eventDetails?.title ?? ''}
      previewIsLoading={previewIsLoading}
    />
  );
}
