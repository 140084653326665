import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { PollQuestion } from 'interfaces/PollSetup';

interface Props {
  activeQuestion: PollQuestion;
  setActiveQuestion: Dispatch<SetStateAction<PollQuestion>>;
}

export default function Duration({ activeQuestion, setActiveQuestion }: Props) {
  const [selectedMinute, setSelectedMinute] = useState<number | null>(null);
  const [selectedSeconds, setSelectedSeconds] = useState<number | null>(null);

  // Sync initial values from activeQuestion.duration
  useEffect(() => {
    if (
      activeQuestion.duration !== null &&
      activeQuestion.duration !== undefined
    ) {
      setSelectedMinute(Math.floor(activeQuestion.duration / 60));
      setSelectedSeconds(activeQuestion.duration % 60);
    }
  }, [activeQuestion.duration]);

  useEffect(() => {
    if (selectedMinute !== null && selectedSeconds !== null) {
      const totalSeconds = selectedMinute * 60 + selectedSeconds;

      setActiveQuestion((prevQuestion) => ({
        ...prevQuestion,
        duration: totalSeconds,
      }));
    }
  }, [selectedMinute, selectedSeconds, setActiveQuestion]);

  return (
    <div className='mt-3'>
      <p className='mb-3 text-[#667084]'>DURATION</p>
      <div className='grid grid-cols-2 gap-x-2'>
        <input
          type='text'
          placeholder='Mins'
          className='flex gap-x-3 h-11 items-center w-full pl-3 rounded-lg border border-[#CBCBCB] overflow-hidden'
          value={selectedMinute ?? ''}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              setSelectedMinute(value === '' ? null : Number(value)); // Allow only numbers or empty
            }
          }}
        />
        <input
          type='text'
          placeholder='Secs'
          className='flex gap-x-3 h-11 items-center w-full pl-3 rounded-lg border border-[#CBCBCB] overflow-hidden'
          value={selectedSeconds ?? ''}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value) && Number(value) < 60) {
              setSelectedSeconds(value === '' ? null : Number(value)); // Allow only numbers between 0 and 59
            }
          }}
        />
      </div>
    </div>
  );
}
