import {CopyToClipBoard} from "../../wrapper/CopyToClipBoard";
import {CopyWrapper} from "../../wrapper/copy-wrapper";
import {CopyActionWrapper} from "../../wrapper/copy-action-wrapper";
import {Switch} from "antd";
import messageDashes from "../../../../assets/svg/messageDashed.svg";
import {TagWrapper} from "../../wrapper/tag-wrapper";
import React, {useEffect, useState} from "react";
import instance from "../../../../lib/axiosInterceptor";
import emailValidation from "../../../../utils/emailValidation";
import {toast} from "sonner"
import noticeBlack from "assets/svg/noticeBlack.svg"
import {
    EditableObjectInterface,
    EventsInterface,
    InnerPresentationInterface
} from "../../../../interfaces/interfaces-data";
import Label from "../../Label";
import {CButton} from "../../Button/Buttons";
import airPlane from "../../../../assets/svg/airPlane.svg";
import linkIcon from "../../../../assets/svg/buttonLinkIcon.svg";
import {useNavigate} from "react-router-dom";

export const DesktopShare = ({friendlyId, presentationId, presentationDetails, eventDetails, isEvent}: {
    friendlyId?: string,
    presentationId?: string,
    presentationDetails?: InnerPresentationInterface,
    isEvent?: boolean;
    eventDetails?: EventsInterface
}) => {
    const [users, setUsers] = useState<string[]>([]);
    const [activeEmail, setActiveEmail] = useState("")
    const [loading, setLoading] = useState(true)
    const [urlText, setUrlText] = useState("");
    const navigate = useNavigate();
    const [projectorOtp, setProjectorOtp] = useState("");
    const [projectorAuthorizeLoading, setProjectorAuthorizeLoading] = useState(false)
    const [editablePresentationObject, setEditablePresentationObject] = useState<EditableObjectInterface>()
    const getGuests = async () => {
        setLoading(true)
        try {
            const result = await instance.get(`/Presentation/Guests/${presentationId ?? ""}`)
            setUsers(result.data)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }
    const addGuests = async () => {

        try {
            const result = await instance.post(`/Presentation/AddGuests/${presentationId}`, [activeEmail]);
            setActiveEmail("")
            getGuests();
        } catch (e) {

        } finally {

        }
    }


    useEffect(() => {
        if (presentationDetails) {
            const {
                expiry,
                canDownload,
                authMode,
                title,
                passcode,
                watermark,
                questionsAllowed,
                allowedDomains,
                waitingRoom
            } = presentationDetails?.presentation
            setEditablePresentationObject({
                expiry,
                canDownload,
                authMode,
                enableWaitingRoom: waitingRoom,
                title,
                passcode,
                watermark,
                allowQuestion: questionsAllowed,
                domains: allowedDomains
            })
        }
        if (!isEvent) {

            getGuests();
        }
    }, [])

    const editObject = async (editableObject: EditableObjectInterface) => {
        try {
            const result = await instance.post(`/Presentation/Update/${presentationId}`, editableObject);
            toast.success("Setting Configured")
        } catch (e) {

        }
    }

    useEffect(() => {
        if (isEvent && eventDetails) {
            setUrlText(`${window.location.origin}/join-presentation/${eventDetails.id}?isEvent=true`)
        } else {
            setUrlText(`${window.location.origin}/join-presentation/${friendlyId ?? ""}`)
        }
    }, [isEvent, eventDetails]);

    const handleAuthorizeProjector = async () => {
        try {
            setProjectorAuthorizeLoading(true)
            if (isEvent) {
                const result = await instance.post(`/Presentation/Project/${eventDetails?.id}`);
            } else {
                const result = await instance.post(`/Presentation/Project/${presentationId}`);
            }
            toast.success("Projector Authorized")
        } finally {
            setProjectorAuthorizeLoading(false)
        }
    }


    return <>
        <div>
            <CopyToClipBoard text={urlText}>
                <CopyWrapper text={urlText}/>
            </CopyToClipBoard>

            <CopyActionWrapper text={urlText}/>
            {(!isEvent && (isEvent && eventDetails?.role === "EventOwner")) &&
                <div className="border p-4 border-[#EEEEEE]">
                    <div className="flex items-center justify-between ">
                        <p className="text-[#3D4045] text-sm font-grotesk font-semibold">
                            Share link with anyone{" "}
                        </p>
                        <span>
                        <Switch/>
                    </span>
                    </div>
                    <div className="my-3 flex items-center gap-2 w-full">
                        <div className="">
                        <span className="relative ">
                            <img alt={""} src={messageDashes} className="absolute top-0 left-3"/>
                            <input
                                placeholder="Add email"
                                className="bg-[#F5F5F5] rounded-lg py-2 px-10 w-full"
                                onChange={(e) => setActiveEmail(e.target.value)}
                                value={activeEmail}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        if (emailValidation.checkEmail(activeEmail)) {
                                            addGuests()
                                        } else {
                                            toast.error("Invalid email")
                                        }
                                    }
                                }}
                            />
                        </span>
                        </div>

                        {/*<CButton*/}
                        {/*    icon={groupIcon}*/}
                        {/*    iconPosition="left"*/}
                        {/*    variant="grey"*/}
                        {/*    text="Upload Bulk"*/}
                        {/*/>*/}
                    </div>
                    <div className="flex flex-wrap gap-2">
                        {users.length ? users.map((item, index) => {
                            return (
                                <TagWrapper
                                    click={() => {
                                        const user_ = [...users];
                                        user_.splice(index, 1);
                                        setUsers([...user_]);
                                    }}
                                    text={item}
                                    key={item}
                                />
                            );
                        }) : <div className={"flex justify-center text-xs my-4 w-full"}>No guests added</div>}
                    </div>

                    <ActionWrapper
                        handleSwitchValue={(checked: boolean) => {
                            if (editablePresentationObject) {
                                setEditablePresentationObject({
                                    ...editablePresentationObject,
                                    enableWaitingRoom: checked
                                })
                                editObject({
                                    ...editablePresentationObject,
                                    enableWaitingRoom: checked
                                })
                            }
                        }}
                        switchValue={editablePresentationObject?.enableWaitingRoom ?? false}
                        text={"Enable Waiting Room"}/>
                    <ActionWrapper handleSwitchValue={(checked: boolean) => {
                        if (editablePresentationObject) {

                            setEditablePresentationObject({
                                ...editablePresentationObject,
                                authMode: checked ? 1 : 0
                            })
                            console.log("I Got triggered")
                            editObject({
                                ...editablePresentationObject,
                                authMode: checked ? 1 : 0
                            })
                        }
                    }}
                                   switchValue={editablePresentationObject?.authMode === 1}
                                   text={"Confirm Email Address"}

                    />
                </div>}

            {(!isEvent || (isEvent && eventDetails?.role === "EventOwner")) && <div className="border-b py-4">
                <p className="font-grotesk text-lg font-semibold">
                    Projector
                </p>
                <div className="border my-2 border-[#EEEEEE] p-4 rounded-lg">
                    <span>
                        <img alt={""} src={airPlane}/>
                    </span>
                    <p className="text-sm font-grotesk  my-2 font-semibold">Go to Projector Screen</p>
                    <CButton
                        action={() => {
                            const url = new URL(`/projector/${presentationId}`, window.location.origin).toString();
                            window.open(url, '_blank');
                        }}
                        icon={linkIcon}
                        iconPosition="left"
                        variant="plain"
                        text="Go to projector"
                        loading={false}
                    />
                </div>
                <div className={"mt-4"}>
                    <Label text={"Enter OTP"}/>
                    <div className="mt-1">
                        <input
                            placeholder="Enter OTP on projector"
                            className="w-full outline-none bg-orange10 rounded-lg border-none placeholder:text-sm py-3 pl-5 text-sm"/>
                    </div>
                    <div className="flex justify-end mt-2">
                        <CButton loading={projectorAuthorizeLoading} action={() => handleAuthorizeProjector()}
                                 text="Authorize" variant={"dark"}/>
                    </div>
                </div>
            </div>}

        </div>
    </>
}


const ActionWrapper = ({
                           text, switchValue, handleSwitchValue
                       }: {
    text: string,
    switchValue
        :
        boolean,
    handleSwitchValue
        :
        (val: boolean) => void
}) => {

    return <>
        <div className="border my-2 border-[#EEEEEE] flex items-center justify-between p-4 rounded-lg">
            <div className={"flex items-center gap-2"}>
                <p>
                    {text}
                </p>
                <img src={noticeBlack} alt=""/>
            </div>
            <Switch onChange={(checked, event) => handleSwitchValue(checked)} value={switchValue}/>
        </div>
    </>
}