export const AllowGuestIn = (
    connection,
    presentationId,
    email,
    setIsGrantAccessLoading,
    status,
    isEvent,
    eventId
) => {
    if (connection) {
        console.log(!!isEvent ? eventId : presentationId, email, status, !!isEvent, "Value for Adding Gues in")
        connection.invoke("GrantAccess", !!isEvent ? eventId : presentationId, email, status, !!isEvent).then(() => {
            setIsGrantAccessLoading(false);
        });
    }
};
