import AuthInput from 'components/UI/Inputs/AuthInput';
import Label from 'components/UI/Label';

export default function ContactForm() {
  return (
    <section className='py-10 bg-white rounded-2xl h-full'>
      <div className='px-4 text-black  h-full'>
        <h3 className='text-lg font-semibold text-center'>Send us a mail</h3>
        <form className='px-4 h-full flex flex-col justify-between'>
          <div className='my-3'>
            <Label text='Full name' />
            <AuthInput placeholder='Enter your full name' name='password' />
          </div>

          <div className='my-3'>
            <Label text='Email address' />
            <AuthInput placeholder='example@teamdocs.io' name='email' />
          </div>

          <div className='my-3'>
            <Label text='Phone number' />
            <AuthInput placeholder='Enter phone number' name='tel' />
          </div>

          <div className='my-3 h-fit'>
            <Label text='Description' />
            <textarea
              className='flex justify-between w-full px-4 mt-2 rounded-md text-gray700 bg-orange10 min-h-[100px] resize-none'
              name=''
              id=''
              rows='3'
            ></textarea>
          </div>

          <div className='pb-5 mt-3 h-fit'>
            <button className='w-full py-3 text-sm text-white bg-black rounded-lg'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}
