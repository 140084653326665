import {HubConnection} from "@microsoft/signalr";
import {toast} from "react-toastify";

export const GetUsersListNew = (
    presentationId: string,
    connection?: HubConnection | null,
    isEvent ?: boolean,
    eventId ?: string
) => {
    if (connection?.state === "Connected") {
        console.log("GetUsers", isEvent ? eventId : presentationId, isEvent);
        connection
            ?.invoke("GetUsers", isEvent ? eventId : presentationId, isEvent)
            .then((res) => console.log(res, "response fro mthe invocation"))
            .catch((err) => {
                toast.error("An error occured while fetching the list of viewers");
                console.log(err);
            });
    }
};
