import BriefcaseLayout from 'components/UI/BriefcaseLayout';
import MyBriefCase from 'views/Admin/Briefcase';
import BriefcaseDetails from 'views/Admin/Briefcase/BriefcaseDetails';
import BriefcaseFileDetails from 'views/Admin/Briefcase/BriefcaseFileDetails';

const briefcaseRoutes = {
  path: 'mybriefcase',
  element: <BriefcaseLayout />,
  children: [
    { path: '', element: <MyBriefCase /> },
    { path: 'details/:spaceId', element: <BriefcaseDetails /> },
    { path: 'details/:spaceId/:fileId', element: <BriefcaseFileDetails /> },
  ],
};

export default briefcaseRoutes;
