import settingsIcon from 'assets/svg/settings-polls.svg';
import qrCode from 'assets/svg/qr-code-icon.svg';

export default function Settings() {
  return (
    <section className='col-span-1 text-xs text=[#667084]'>
      <div className='bg-white rounded-md w-full flex items-center flex-col pt-3 pb-3'>
        <div className='mb-2'>
          <p className='font-bold'>SETTINGS</p>
          <hr className='w-full border ' />
        </div>
        <section className='font-medium grid grid-cols-1 gap-7'>
          <div className='flex flex-col items-center gap-1'>
            <img src={settingsIcon} alt='display result' />
            <p>Display result</p>
            <label className='inline-flex items-center cursor-pointer'>
              <input type='checkbox' value='' className='sr-only peer' />
              <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
            </label>
          </div>
          <div className='flex flex-col items-center gap-1'>
            <img src={settingsIcon} alt='display result' />
            <p>Live response</p>
            <label className='inline-flex items-center cursor-pointer'>
              <input type='checkbox' value='' className='sr-only peer' />
              <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
            </label>
          </div>
          <div className='flex flex-col items-center gap-1'>
            <img src={qrCode} alt='QR code' />
            <p>Display result</p>
            <label className='inline-flex items-center cursor-pointer'>
              <input type='checkbox' value='' className='sr-only peer' />
              <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
            </label>
          </div>
        </section>
      </div>
    </section>
  );
}
