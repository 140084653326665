import {toast} from "react-toastify";
// import { GetQuestions } from "./GetQuestions";
// import store from "store/store";
// import { clearTranslatedAudio } from "store/Slides/slidesSlice";

export const AnswerQuestion = (
    connection,
    presentationId,
    questionId,
    answer,
    isEvent,
    eventId
) => {
    const state = connection?.state;
    if (state === "Connected") {
        console.log("AnswerQuestion", presentationId, questionId, answer, isEvent ? eventId : null);
        connection
            .invoke("AnswerQuestion", presentationId, questionId, answer, isEvent ? eventId : null).catch((err) => {
            toast.error(
                "An error occurred invoking 'AnswerQuestion' on the server"
            );
        });
    }
};
