import React, { useRef, useState } from 'react';
import emptyState from 'assets/svg/emptyBriefcase.svg';
import emptyEvent from 'assets/svg/emptyEvent.svg';
import addPlus from 'assets/svg/add-circle.svg';
import { Modal } from 'antd';
import CreateBriefcase from 'views/Admin/Briefcase/Modals/CreateBriefcase';
import { useNavigate } from 'react-router-dom';

export default function EmptyEvents() {
  const navigate = useNavigate();
  //   const [isModalOpen, setIsModalOpen] = useState(false);
  //   interface CreateSpaceModalRef {
  //     resetModal: () => void;
  //   }
  //   //useRef
  //   const createSpaceModal = useRef<CreateSpaceModalRef>(null);
  //   //function
  //   const openCreateSpaceModal = () => {
  //     setIsModalOpen(true);
  //   };
  //   const handleCancel = () => {
  //     setIsModalOpen(false);
  //     createSpaceModal.current?.resetModal();
  //   };

  return (
    <section className="mt-20">
      <section className='flex flex-col  items-center justify-center lg:h-[60vh]'>
        <div className='mb-4 center-content'>
          <img src={emptyEvent} alt='No briefcase' className='center-content' />
        </div>

        <div className='text-center'>
          <h1 className='text-xl font-semibold lg:text-2xl'>
            Create Together, Present Better.
          </h1>
          <p className='text-sm'>
            Set up an event agenda, add presenters and manage playlists
          </p>
          <div className='mt-2 center-content'>
            <button
              //   onClick={openCreateSpaceModal}
              onClick={() => navigate('/admin/events/create-event')}
              className='flex items-center px-4 py-2 text-sm text-white bg-black rounded-lg gap-x-2 '
            >
              <img src={addPlus} alt='' className='w-5 h-5' />
              <span>Create an event</span>
            </button>
          </div>
        </div>
      </section>
      {/* Modal */}
      {/* <Modal
        footer={null}
        centered={false}
        open={isModalOpen}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
      >
        <CreateBriefcase closeModal={handleCancel} ref={createSpaceModal} />
      </Modal> */}
    </section>
  );
}
