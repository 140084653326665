import banner from 'assets/image/eventAgendaBanner.png';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

export default function AgendaBanner() {
  const eventDetails = useSelector(
    (state: RootState) => state.events.event.data
  );

  return (
    <section className='p-3 rounded-xl bg-[#F6F6F6] border border-[#EEEEEE] mb-5'>
      <section>
        <img src={banner} alt='banner' className='w-full ' />

        <div className='mt-2 '>
          <h3 className='text-2xl font-medium'>{eventDetails?.title ?? ''}</h3>
          <p className='text-sm text-[#757575]'>
            {eventDetails?.description ?? ''}
          </p>
        </div>
      </section>
    </section>
  );
}
