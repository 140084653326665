import { PollQuestionOption } from 'interfaces/PollSetup';
import { defaultOptionsColour } from 'utils/randomBgColour';

interface Props {
  option: PollQuestionOption;
  index: number;
}

export default function QuizzesColouredOptionsDisplay({
  option,
  index,
}: Props) {
  return (
    <div className='flex flex-col h-full'>
      <div className='h-[200px] min-h-full rounded-md overflow-hidden'>
        <div className='bg-[#EAECF0] w-full h-[90%]'></div>
        <div
          className={`h-[10%] w-full rounded`}
          style={{
            backgroundColor: option.color
              ? option.color
              : defaultOptionsColour[index],
          }}
        ></div>
      </div>
      <div className='flex items-center justify-center w-full'>
        <p className='text-sm font-medium text-[#344054]'>{option.text}</p>
      </div>
    </div>
  );
}
