// Assets
import arrowDown from 'assets/svg/arrowDown.svg';
import arrowUp from 'assets/svg/arrowUp.svg';
import rename from 'assets/svg/rename.svg';
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import addCircle from 'assets/svg/add-circle2.svg';
import dragNdropIcon from 'assets/svg/drag-drop-icon.svg';
import smsStar from 'assets/svg/sms-star.svg';
import profileAdd from 'assets/svg/profile-add.svg';
import trashDarkRed from 'assets/svg/trash-dark-red.svg';
import editIcon from 'assets/svg/edit2.svg';
import eyeI from 'assets/svg/eye-2.svg';
import { Dropdown, Menu, Modal } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ConfirmationModal from 'components/UI/Modal/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/store';
import {
  AgendaItem,
  CreateEventResponsePayload,
  UpdateSessionRequestPayload,
} from 'interfaces/Events/EventsInterfaces';
import UploadFiles from '../Modals/UploadFiles';
import InviteCoPresenters from '../Modals/InviteCoPresenters';
import { getEmail } from 'utils/localStorageServices';
import {
  addCoPresenters,
  addPresenters,
  getEvent,
  removeSession,
  updateSession,
} from 'store/Events/EventsSlice';
import RenameModal from 'components/UI/Modal/RenameModal';
import { getTeamsState } from 'store/Teams/teamsSlice';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';

interface Props {
  folderIndex: number;
  section: AgendaItem;
  viewFolderDocuments: boolean;
  setViewFolderDocuments: Dispatch<SetStateAction<boolean>>;
  eventId: string;
  eventDetails: CreateEventResponsePayload;
  listeners: SyntheticListenerMap | undefined;
  attributes: DraggableAttributes;
}
export default function GroupHeader({
  folderIndex,
  section,
  viewFolderDocuments,
  setViewFolderDocuments,
  eventId,
  eventDetails,
  attributes,
  listeners,
}: Props) {
  //
  const dispatch = useDispatch<AppDispatch>();

  // States
  const [confirmRemoveSession, setConfirmRemoveSession] = useState(false);
  const [isRemovingSession, setIsRemovingSession] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openRenameSessionModal, setOpenRenameSessionModal] = useState(false);
  const [isRenameLoading, setIsRenameLoading] = useState(false);
  const [titleName, setTitleName] = useState('');
  const [teamId, setTeamId] = useState('');
  const [isEventOwner, setIsEventOwner] = useState(false);

  const { teamInfo } = useSelector(getTeamsState);

  const openInNewTab = (url: string) => {
    window.open(url, '_blank'); // Opens in a new tab
  };

  useEffect(() => {
    const teamId_ = teamInfo?.data?.id;
    if (teamId_) {
      setTeamId(teamId_);
    }
  }, [teamInfo.data]);

  useEffect(() => {
    if (eventDetails.role === 'EventOwner') {
      setIsEventOwner(true);
      // setViewFolderDocuments(true);
      setCanEdit(true);
    } else {
      const hasAccess = section.collaborators.some(
        (it) => it.email === getEmail()
      );

      if (hasAccess) {
        // setViewFolderDocuments(true);
        setCanEdit(true);
      } else {
        setViewFolderDocuments(false);
      }
      //
    }
  }, [eventDetails.role, section.role]);

  //   Variables
  const eventOwnerItems = [
    {
      key: '1',
      action: 'rename',
      label: (
        <div
          className='flex gap-x-2 pl-2 pr-5'
          onClick={(e) => {
            setOpenRenameSessionModal(true);
            e.stopPropagation();
          }}
        >
          <img src={rename} alt='rename file' className='w-5' />
          <p>Rename</p>
        </div>
      ),
    },
    {
      key: '2',
      action: 'manage',
      label: (
        <div
          className={`flex gap-x-2 pl-2 pr-5`}
          onClick={(e) => {
            e.stopPropagation();
            openInNewTab(`/presentation-details/${section.presentationId}`);
          }}
        >
          <img src={editIcon} alt='preview session' className='w-5' />
          <p>Manage presentation</p>
        </div>
      ),
    },
    {
      key: '6',
      action: 'preview',
      label: (
        <div
          className={`flex gap-x-2 pl-2 pr-5`}
          onClick={(e) => {
            e.stopPropagation();
            openInNewTab(
              `/events/preview/${eventId}/${section.presentationId}`
            );
          }}
        >
          <img src={eyeI} alt='preview session' className='w-5' />
          <p>Preview</p>
        </div>
      ),
    },
    {
      key: '3',
      action: 'addfile',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl-2 pr-5'
          onClick={(e) => {
            e.stopPropagation();
            setOpenUploadModal(true);
          }}
        >
          <img src={addCircle} alt='metrics file' className='w-4' />
          <p>Upload content</p>
        </div>
      ),
    },
    {
      key: '4',
      action: 'addPresenter',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl-2 pr-5'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenInviteModal(true);
          }}
        >
          <img src={profileAdd} alt='delete file' className='w-5' />
          <p>Add presenter</p>
        </div>
      ),
    },
    {
      key: '5',
      action: 'removeSession',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl-2 pr-5'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setConfirmRemoveSession(true);
          }}
        >
          <img src={trashDarkRed} alt='delete file' className='w-5 ' />
          <p className='text-[#AE372A]'>Delete session</p>
        </div>
      ),
    },
  ];

  const coPresenterItems = [
    {
      key: '1',
      action: 'manage',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl-2 pr-5'
          onClick={(e) => {
            e.stopPropagation();
            openInNewTab(`/presentation-details/${section.presentationId}`);
          }}
        >
          <img src={editIcon} alt='preview session' className='w-4' />
          <p>Manage presentation</p>
        </div>
      ),
    },
    {
      key: '6',
      action: 'preview',
      label: (
        <div
          className={`flex gap-x-2 pl-2 pr-5`}
          onClick={(e) => {
            e.stopPropagation();
            openInNewTab(
              `/events/preview/${eventId}/${section.presentationId}`
            );
          }}
        >
          <img src={eyeI} alt='preview session' className='w-5' />
          <p>Preview</p>
        </div>
      ),
    },
    {
      key: '2',
      action: 'addfile',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl-2 pr-5'
          onClick={(e) => {
            e.stopPropagation();
            setOpenUploadModal(true);
          }}
        >
          <img src={addCircle} alt='metrics file' className='w-4' />
          <p>Upload content</p>
        </div>
      ),
    },
    {
      key: '3',
      action: 'addPresenter',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl-2 pr-5'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenInviteModal(true);
          }}
        >
          <img src={profileAdd} alt='delete file' className='w-5' />
          <p>Add presenter</p>
        </div>
      ),
    },
  ];

  const handleRemoveSession = async () => {
    setIsRemovingSession(true);

    dispatch(
      removeSession({
        eventId,
        teamId,
        sessionId: section.id,
      })
    ).then(({ type }) => {
      if (type.includes('fulfilled')) {
        setIsRemovingSession(false);
        dispatch(getEvent({ eventId, teamId }));
      }
      setIsRemovingSession(false);
    });
  };

  const closeConfirmRemoveSession = () => {
    setConfirmRemoveSession(false);
  };

  const handleRenameSession = () => {
    setIsRenameLoading(true);
    const data: UpdateSessionRequestPayload = {
      teamId,
      duration: 0,
      eventId,
      position: section.position,
      time: section.time,
      title: titleName,
    };

    dispatch(updateSession({ sessionId: section.id, data })).then(
      ({ type }) => {
        if (type.includes('fulfilled')) {
          dispatch(getEvent({ eventId, teamId }));
        }
        setIsRenameLoading(false);
      }
    );
  };
  const closeRenameModal = () => {
    setOpenRenameSessionModal(false);
  };

  const handleInviteCoPresenters = async ({
    email,
    emailList,
  }: {
    email: string;
    emailList: string[];
  }) => {
    if (!emailList.length) {
      return;
    }

    if (isEventOwner) {
      dispatch(
        addPresenters({
          sessionId: section.id,
          data: {
            eventId,
            teamId,
            collaborators: emailList,
          },
        })
      ).then(({ type }) => {
        if (type.includes('fulfilled')) {
          dispatch(getEvent({ eventId, teamId }));
        }
        setOpenInviteModal(false);
      });
    } else {
      dispatch(
        addCoPresenters({
          data: {
            coPresenters: emailList,
          },
          eventId,
          sessionId: section.id,
        })
      ).then(({ type }) => {
        if (type.includes('fulfilled')) {
          dispatch(getEvent({ eventId, teamId }));
        }
        setOpenInviteModal(false);
      });
    }
  };

  return (
    <section className=''>
      <section
        className='bg-[#EEEEEE] text-[#333333] text-sm min-h-16 flex gap-x-3 items-center cursor-pointer rounded-md w-full py-2'
        onClick={() => {
          canEdit && setViewFolderDocuments(!viewFolderDocuments);
        }}
        {...attributes}
        {...listeners}
      >
        <div className='gap-x-3 w-[86%] pl-4 flex'>
          {isEventOwner && (
            <div className='cursor-grab opacity-0 hover:opacity-100 hover:w-12 flex items-center bg-transparent '>
              <img
                src={dragNdropIcon}
                alt='drag'
                className='w-4 pointer-events-none'
              />
            </div>
          )}
          <div>
            <p className='font-semibold'>{section.title}</p>
            <div className='flex gap-x-2 items-start'>
              <img src={smsStar} alt='emails' className='mt-1' />
              <div className='flex gap-x-2 flex-wrap'>
                {section.collaborators &&
                  section.collaborators.map((collaborator) => {
                    return (
                      <p className='text-sm'>
                        {eventDetails.role === 'EventOwner'
                          ? collaborator.email
                          : collaborator.fullName}
                      </p>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        {canEdit && (
          <div className='w-[14%]'>
            <div className='w-full flex items-center gap-x-3 justify-end pr-10'>
              <div>
                <img src={viewFolderDocuments ? arrowUp : arrowDown} alt='' />
              </div>
              <section onClick={(e) => e.preventDefault()}>
                <Dropdown
                  trigger={['click']}
                  dropdownRender={(menu) => (
                    <Menu>
                      {eventDetails.role === 'EventOwner' ? (
                        <>
                          {eventOwnerItems
                            .filter((item) => {
                              if (!section.presentationId) {
                                return item.action !== 'preview';
                              } else {
                                return item;
                              }
                            })
                            .map((item) => {
                              return (
                                <Menu.Item key={item.key}>
                                  {item.label}
                                </Menu.Item>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {coPresenterItems
                            .filter((item) => {
                              if (!section.presentationId) {
                                return item.action !== 'preview';
                              } else {
                                return item;
                              }
                            })
                            .map((item) => {
                              return (
                                <Menu.Item key={item.key}>
                                  {item.label}
                                </Menu.Item>
                              );
                            })}
                        </>
                      )}
                    </Menu>
                  )}
                >
                  <div
                    className='cursor-pointer py-4'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img src={ellipsis} alt='options' />
                  </div>
                </Dropdown>
              </section>
            </div>
          </div>
        )}
      </section>

      <Modal
        footer={null}
        centered={false}
        open={openUploadModal}
        width={452}
        closeIcon={false}
        style={{
          top: 20,
        }}
        destroyOnClose
      >
        <UploadFiles
          agenda={section}
          setOpenUploadModal={setOpenUploadModal}
          setOpenInviteModal={setOpenInviteModal}
          presentationId={section.presentationId}
        />
      </Modal>
      <Modal
        footer={null}
        centered={false}
        open={openInviteModal}
        width={452}
        closeIcon={false}
        style={{
          top: 20,
        }}
        destroyOnClose
      >
        <InviteCoPresenters
          agenda={section}
          setOpenInviteModal={setOpenInviteModal}
          func={handleInviteCoPresenters}
        />
      </Modal>
      <Modal
        footer={null}
        centered={false}
        open={openRenameSessionModal}
        onCancel={closeRenameModal}
        style={{
          top: 20,
        }}
        destroyOnClose
        closeIcon={false}
      >
        <RenameModal
          closeModal={closeRenameModal}
          setTitleName={setTitleName}
          func={handleRenameSession}
          isLoading={isRenameLoading}
          previousTitle={section.title ?? ''}
          titleName={titleName}
          heading='Rename session'
          subHeading='Rename your session'
          buttonText='Update session'
        />
      </Modal>

      <ConfirmationModal
        open={confirmRemoveSession}
        isLoading={isRemovingSession}
        onClose={closeConfirmRemoveSession}
        func={handleRemoveSession}
        text='Remove session'
        subText='Are you sure you want to remove session?'
      />
    </section>
  );
}
