import eventDetailsImage from 'assets/svg/eventDetails.svg';
import activeAgenda from 'assets/svg/activeAgenda.svg';
import inactiveAgenda from 'assets/svg/inactiveAgenda.svg';
import activePublish from 'assets/image/activePublish.png';
import inactivePublish from 'assets/image/inactivePublish.png';
import EventDetails from './EventDetails';
import Agenda from './Agenda';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { getEvent } from 'store/Events/EventsSlice';
import { getTeamsState } from 'store/Teams/teamsSlice';
import { AgendaList, Flow } from 'interfaces/Events/EventsInterfaces';
import Loader from 'components/UI/Loader';
// Assets
import bell from 'assets/svg/bell.svg';
import menu from 'assets/svg/hamburger.svg';

export default function EventSetup() {
  const [flow, setFlow] = useState<Flow>('addEventdetails');
  const [agendaList, setAgendaList] = useState<AgendaList[]>([]);
  const { eventId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);

  const { data: eventDetails } = useSelector(
    (state: RootState) => state.events.event
  );

  const { teamInfo } = useSelector(getTeamsState);

  useEffect(() => {
    console.log(flow);
    if (flow !== 'publish') {
      if (eventId) {
        console.log(eventId);
        const teamId = teamInfo.data?.id;
        if (teamId) {
          console.log(teamId);
          dispatch(getEvent({ eventId, teamId }));
        }
      }
    }
  }, [eventId, teamInfo]);

  useEffect(() => {
    if (eventId) {
      setIsLoading(true);

      if (eventDetails && eventDetails.id) {
        setIsLoading(false);

        if (eventDetails?.agenda?.length) {
          const listAgenda = eventDetails.agenda.map((agenda) => {
            const collabList = agenda.collaborators.map((col) => col.email);
            return {
              title: agenda.title,
              collaborators: collabList,
              id: agenda.id,
              position: agenda.position,
              added: true,
            };
          });
          setAgendaList(listAgenda);
          flow !== 'publish' && flow !== 'addpresenter' && setFlow('publish');
        } else {
          setAgendaList([]);
          setFlow('addpresenter');
        }
      }
    } else {
      setIsLoading(false);
    }
  }, [eventId, eventDetails, dispatch, teamInfo]);

  // variable
  const eventProgress = [
    {
      activeIcon: eventDetailsImage,
      inactiveIcon: eventDetailsImage,
      title: 'Event details',
      subTitle: 'Tell us about your event',
      progress: 'details',
    },
    {
      activeIcon: activeAgenda,
      inactiveIcon: inactiveAgenda,
      title: 'Agenda',
      subTitle: 'invite authors to add files',
      progress: 'agenda',
    },
    {
      activeIcon: activePublish,
      inactiveIcon: inactivePublish,
      title: 'Publish',
      subTitle: '',
      progress: 'publish',
    },
  ];
  return (
    <section>
      {isLoading ? (
        <Loader />
      ) : (
        <section className='w-full'>
          <nav className='fixed top-0  lg:left-[18%] left-0 z-30 lg:w-[82%] w-full h-20 bg-white pt-4'>
            <section className='w-[95%] mx-auto'>
              <div className='flex items-center justify-between  flex-col lg:flex-row gap-y-4'>
                <section className='flex items-center justify-between w-full lg:w-[40%] overflow-hidden'>
                  <img
                    src={menu}
                    alt='open sidenav'
                    className='block lg:hidden'
                  />

                  <div className='leading-tight text-right lg:text-left justify-items-end lg:justify-items-start'>
                    <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                      Events
                    </h1>
                    <p className='lg:text-sm text-xs mt-[-3px]'>
                      Create events and manage your presentations
                    </p>
                  </div>
                </section>

                <div className='flex items-center flex-col lg:flex-row gap-y-4 gap-x-2 text-sm font-medium'>
                  <div className='px-2 border rounded-md cursor-pointer bg-gray20 h-8 flex items-center'>
                    <img src={bell} alt='notification' className='w-4' />
                  </div>
                </div>
              </div>
            </section>
          </nav>
          <div className='grid sm:gap-5 gap-10 sm:grid-cols-[1fr] md:grid-cols-[1fr_2fr] mt-20'>
            <section></section>
            <section className='fixed'>
              <div className='p-4'>
                {eventProgress.map((event) => {
                  let progressImage = event.inactiveIcon;
                  let border = 'border-dashed';

                  if (event.progress === 'details') {
                    border = 'border-black border-solid';
                  }

                  if (
                    event.progress === 'agenda' &&
                    (flow === 'addpresenter' || flow === 'publish')
                  ) {
                    progressImage = event.activeIcon;
                    border = 'border-black border-solid';
                  }
                  if (event.progress === 'publish' && flow === 'publish') {
                    progressImage = event.activeIcon;
                    border = 'border-black border-solid';
                  }
                  return (
                    <section
                      className={`border-l-[2px]  pl-5 relative py-3 ${border} `}
                    >
                      <div
                        className={`absolute ${
                          event.progress === 'publish'
                            ? '-bottom-2'
                            : 'top-[50%] -translate-y-[50%] '
                        } left-0 -translate-x-[50%] bg-white`}
                      >
                        <img src={progressImage} alt='' className='w-6' />
                      </div>
                      <div>
                        <div>
                          <h3
                            className={`text-sm font-medium text-[#333333]
                        
                          ${event.progress === 'publish' ? 'opacity-0' : ''}
                        `}
                          >
                            {event.title}
                          </h3>
                          <h3
                            className={`text-sm font-medium text-[#333333]
                        
                        ${
                          event.progress === 'publish'
                            ? ' absolute -bottom-1.5'
                            : 'hidden'
                        }
                       `}
                          >
                            {event.title}
                          </h3>
                          {event.subTitle && (
                            <p className='text-sm'>{event.subTitle}</p>
                          )}
                        </div>
                      </div>
                    </section>
                  );
                })}
              </div>
            </section>

            <div className='p-4'>
              {flow === 'addEventdetails' && (
                <EventDetails flow={flow} setFlow={setFlow} />
              )}
              <Agenda
                flow={flow}
                setFlow={setFlow}
                setAgendaList={setAgendaList}
                agendaList={agendaList}
              />
            </div>
          </div>
        </section>
      )}
    </section>
  );
}
