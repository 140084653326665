import React, { ReactNode } from 'react';
interface PreviewDocument {
  sessionId: string;
  sessionType: number;
  documentId: string;
  title: string | null;
  note: string | null;
  position: number;
  url: string;
  type: string | null;
  deleted: boolean;
  id: string;
  folderId: string | null;
}
interface Props {
  children: ReactNode;
  flattedDisplayDocuments: PreviewDocument[];
}

export default function MainContentWrapper({
  children,
  flattedDisplayDocuments,
}: Props) {
  return (
    <section
      className={`absolute right-0 ${
        flattedDisplayDocuments.length === 1 ? 'w-[100%]' : 'w-[82%]'
      } px-5 bg-white h-screen max-h-screen overflow-hidden flex flex-col`}
    >
      {children}
    </section>
  );
}
