import { useIntersectionObserver } from 'primereact/hooks';
import { useEffect, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import eventPlaceholder from 'assets/svg/eventPlaceholderNew.svg';
import instance from '../../../lib/axiosInterceptor';
import { EventsResponsePayload } from 'interfaces/Events/EventsInterfaces';

export const OptimizedImageWrapper = ({
  id,
  event,
}: {
  id: string;
  event: EventsResponsePayload;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const visible = useIntersectionObserver(elementRef);
  const [imageLoading, setImageLoading] = useState(true);
  const [imageSource, setImageSource] = useState<string | null>('');
  //   useEffect(() => {
  //     const getImageStream = async () => {
  //       try {
  //         const imageValue = (await imageStream(id, true, 500, 500)) ?? '';
  //         setImageSource('');
  //         // if (imageValue) {
  //         //   setImageSource(imageValue);
  //         // } else {
  //         //   setImageSource('');
  //         // }
  //       } catch (e) {
  //       } finally {
  //         setImageLoading(false);
  //       }
  //     };
  //     if (visible && imageLoading) {
  //       getImageStream();
  //     }
  //   }, [visible]);

  // useEffect
  useEffect(() => {
    if (event.id) {
      const fetchProfileImage = async () => {
        try {
          const response = await instance.get(`/Events/Thumbnail/${event.id}`, {
            responseType: 'blob',
          });

          if (response) {
            const image =
              response.data?.size > 0
                ? URL.createObjectURL(new Blob([response.data]))
                : null;
            setImageSource(image);
          } else {
            setImageSource(null);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setImageLoading(false);
        }
      };

      if (visible && imageLoading) {
        fetchProfileImage();
      }
    }
  }, [visible, imageLoading, event]);

  return (
    <div
      ref={elementRef}
      className='h-[200px] w-full flex justify-center items-center'
    >
      {imageLoading ? (
        <div>
          <ClipLoader size={12} />
        </div>
      ) : imageSource ? (
        <img
          className='object-cover w-full h-[200px] rounded-lg'
          src={imageSource}
          alt=''
        />
      ) : (
        <img
          className='object-cover w-full h-[200px] rounded-lg'
          src={eventPlaceholder}
          alt={''}
        />
      )}
    </div>
  );
};
