import smsStar from 'assets/svg/sms-star.svg';
import dragIcon from 'assets/svg/drag-icon.svg';
import removeIconOrange from 'assets/svg/remove-icon-orange.svg';
import { Dispatch, SetStateAction } from 'react';
import { AgendaList, Flow } from 'interfaces/Events/EventsInterfaces';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
interface Props {
  flow: Flow;
  setFlow: Dispatch<SetStateAction<Flow>>;
  agenda: AgendaList;
  agendaList: AgendaList[];
  setAgendaList: Dispatch<SetStateAction<AgendaList[]>>;
  handleRemoveSession: (id: string) => void;
}

export default function AgendaRow({
  flow,
  agenda,
  handleRemoveSession,
}: Props) {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: agenda.id,
    data: {
      type: 'Row',
      agenda,
    },
    //to disable drag and drop in edit mode
    // disabled: editMode,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <section
        className='mt-3'
        {...attributes}
        {...listeners}
        ref={setNodeRef}
        style={style}
      >
        <section className='flex items-center gap-x-2 cursor-grab border border-primary'>
          <img src={dragIcon} alt='drag icon' className='opacity-0' />
          <section
            className={`flex justify-between items-center bg-[#F6F6F6] px-5 py-3 rounded-sm w-full opacity-0 ${
              flow === 'publish' && 'border-b'
            }`}
          >
            <div className='w-full'>
              <p className='font-medium'>{agenda.title ?? ''}</p>
              <div className='flex items-center'>
                <img src={smsStar} alt='sms' />
                <div className='flex gap-x-1 flex-wrap px-1'>
                  {agenda.collaborators.map((item) => (
                    <p className='text-sm'>{item}</p>
                  ))}
                </div>
              </div>
            </div>
            <div
              className='cursor-pointer w-fit'
              onClick={() => {
                handleRemoveSession(agenda.id);
              }}
            >
              <img src={removeIconOrange} alt='remove agenda' className='w-5' />
            </div>
          </section>
        </section>
      </section>
    );
  }

  return (
    <section
      className='mt-3'
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
    >
      <section className='flex items-center gap-x-2 cursor-grab'>
        <img src={dragIcon} alt='drag icon' />
        <section
          className={`flex justify-between items-center bg-[#F6F6F6] px-5 py-3 rounded-sm w-full ${
            flow === 'publish' && 'border-b'
          }`}
        >
          <div className='w-full'>
            <p className='font-medium'>{agenda.title ?? ''}</p>
            <div className='flex items-center'>
              <img src={smsStar} alt='sms' />
              <div className='flex gap-x-1 flex-wrap px-1'>
                {agenda.collaborators.map((item) => (
                  <p className='text-sm'>{item}</p>
                ))}
              </div>
            </div>
          </div>
          <div
            className='cursor-pointer w-fit'
            onClick={() => {
              handleRemoveSession(agenda.id);
            }}
          >
            <img src={removeIconOrange} alt='remove agenda' className='w-5' />
          </div>
        </section>
      </section>
    </section>
  );
}
