export const GetQuestions = (connection, presentationId, connectionId, eventId, isEvent) => {
    const state = connection?.state;
    const presentaConnectionId = connectionId ?? "";

    if (state === "Connected") {
        console.log("GetQuestions", presentationId, presentaConnectionId, isEvent ? eventId : null);

        connection
            .invoke("GetQuestions", presentationId, presentaConnectionId, isEvent ? eventId : null)
            .catch((err) => {
                console.log(err, "Invocation error")
            });
    }
};
