import React from 'react';

export default function InputTitle({ title }: { title: string }) {
  return (
    <div className='flex items-center gap-x-1 w-fit relative '>
      <p className='flex items-center font-medium text-[#344054]'>{title}</p>
      <p className='text-[#ED6E33] text-2xl font-medium absolute -right-3 top-0'>
        *
      </p>
    </div>
  );
}
