import { useEffect, useRef, useState } from 'react';

// Assets
// import rightNav from 'assets/svg/right-nav-arrow.svg';
// import leftNav from 'assets/svg/left-nav-arrow.svg';

//utils
import { imageStream } from 'utils/imageStream';

// Liberies
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

// Components
import Loader from 'components/UI/Loader';
import {
  getPresentationDetails,
  getSlides,
} from 'store/sharedBriefcase/sharedBriefcaseSlice';
import {
  connectSignalR,
  getSignalRConnection,
} from 'store/SignalR/signalRSlice';
import { getConnectionId } from 'utils/localStorageServices';
import { toast } from 'sonner';
import { useSignalREventListener } from 'hooks/SignalR/Listeners/Global/useSignalRListener';
// import Sidebar from 'components/User/SharedBriefcase/Sidebar';
import useScreenWidth from 'hooks/UseScreenWidth';
// import SidebarWrapper from 'components/User/SharedBriefcase/SidebarWrapper';
// import Header from 'components/User/SharedBriefcase/Header';
// import ControllerParent from 'components/User/SharedBriefcase/ControllerParent';
// import DocumentDisplay from 'components/User/SharedBriefcase/DocumentDisplay';
import FullScreen from 'components/UI/Preview/FullScreen';
import MainContentWrapper from 'components/UI/Preview/MainContentWrapper';
import SidebarWrapper from 'components/UI/Preview/SidebarWrapper';
import Sidebar from 'components/UI/Preview/Sidebar';
import Header from 'components/UI/Preview/Header';
import DocumentDisplay from 'components/UI/Preview/DocumentDisplay';
import ControllerParent from 'components/UI/Preview/ControllerParent';

export default function SharedBriefcase() {
  const { presentationId } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const minScreenWidth = 2000;
  const activeImgRef = useRef<HTMLImageElement | null>(null);
  const fullScreenContainerRef = useRef<HTMLElement | null>(null);

  // State
  const [activeImage, setActiveImage] = useState<string | null>(null);
  const [isSlides, setIsSlides] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [displayDocumentIndex, setDisplayDocumentIndex] = useState<number>(0);
  const [slideDocumentIndex, setSlideDocumentIndex] = useState<number>(0);
  const [isGridDisplayActive, setIsGridDisplayActive] = useState(false);
  const [isFullscreen, setIsFullScreen] = useState(false);
  const [fetchedFlattenedDocIndex, setFetchedFlattenedDocIndex] = useState<
    number | null
  >(null); //holds the max fetched document index
  const [slides, setSlides] = useState<string[]>([]);

  // Store

  const isPresentationLoading = useSelector(
    (state: RootState) => state.sharedBriefcase.presentation.isLoading
  );

  const flattedDisplayDocuments = useSelector(
    (state: RootState) => state.sharedBriefcase.flattedDisplayDocuments
  );

  // const slides = useSelector(
  //   (state: RootState) => state.sharedBriefcase.slides.data
  // );

  const presentationDetails = useSelector(
    (state: RootState) => state.sharedBriefcase.presentation
  ).data;

  const presentation_ = useSelector(
    (state: RootState) => state.sharedBriefcase.presentation.data
  );
  const { doc } = presentation_ ?? {};

  console.log('doc 1', doc);

  // interface ImageCache {
  //   [id: string]: string;
  // }
  interface ImageCache {
    id: string[];
  }
  //usememo for memoizing the fetched url
  const imageCache = useRef<ImageCache[]>([]);
  const BATCH_SIZE = 2; // Number of documents/slides to fetch in a batch

  useEffect(() => {
    if (flattedDisplayDocuments?.length > 0) {
      const fetchBatch = async (
        startIndex: number,
        endIndex: number
      ): Promise<ImageCache[]> => {
        const batch = flattedDisplayDocuments.slice(startIndex, endIndex);
        const batchResults: ImageCache[] = [];

        for (let i = 0; i < batch.length; i++) {
          const doc = batch[i];
          if (doc?.url?.endsWith('.pptx') || doc?.url?.endsWith('.pdf')) {
            // Fetch slides for presentations
            const result = await dispatch(getSlides(doc.documentId));
            if (result.type.includes('fulfilled')) {
              const slidePromises = result.payload.slides.map(
                async (slide: { id: string }) => await imageStream(slide.id)
              );
              const slides = await Promise.all(slidePromises); // Fetch all slide URLs concurrently
              batchResults.push({ id: slides }); // Store as array of strings
            }
          } else {
            // Fetch single document images
            const url = await imageStream(doc.documentId);
            url && batchResults.push({ id: [url] }); // Store as single string
          }
        }

        return batchResults;
      };

      const fetchAllDocumentsIncrementally = async () => {
        const allResults: ImageCache[] = [];
        for (let i = 0; i < flattedDisplayDocuments.length; i += BATCH_SIZE) {
          const endIndex = Math.min(
            i + BATCH_SIZE,
            flattedDisplayDocuments.length
          );
          const batchResults = await fetchBatch(i, endIndex);
          allResults.push(...batchResults);

          imageCache.current.push(...batchResults);
          console.log(`Fetched batch from ${i} to ${endIndex}`, batchResults);
          setFetchedFlattenedDocIndex(endIndex);

          // // Optionally update UI or cache state after each batch
          // setFetchedDocs((prev) => [...prev, ...batchResults]);
        }

        console.log('All documents fetched:', allResults);
        imageCache.current = allResults;
      };

      fetchAllDocumentsIncrementally();
    }
  }, [flattedDisplayDocuments, dispatch]);

  //get presentation details
  const [connection, setConnection] = useState(getSignalRConnection());

  useEffect(() => {
    if (!connection) {
      dispatch(connectSignalR()).then(() => {
        setConnection(getSignalRConnection());
      });
    }
  }, [dispatch, connection]);

  console.log('connection =====>', connection);

  useSignalREventListener(
    connection,
    (
      id: string,
      eventName: string,
      status: string,
      message: any,
      presenterConnectionId: string
    ) => {
      console.log('id', id);
      console.log('eventName', eventName);
      console.log('status', status);
      console.log('message', message);
      console.log('presenterConnectionId', presenterConnectionId);

      const connectionId = getConnectionId();

      if (
        connectionId &&
        status &&
        eventName === 'RequestSharedAccess' &&
        message &&
        message.message === 'ConnectionId Updated'
      ) {
        presentationId &&
          dispatch(getPresentationDetails({ presentationId, connectionId }));
      }

      // Handling if connectionId is wrong. This should also cover if it is expired
      if (!status && eventName === 'RequestSharedAccess') {
        navigate(`/access-shared-presentation/${presentationId}`);
      }
    }
  );

  // For verifying that the user has access
  useEffect(() => {
    const connectionId = getConnectionId();
    if (connection) {
      if (connectionId) {
        connection
          .invoke(
            'RequestSharedAccess',
            presentationId,
            '',
            '',
            '',
            connectionId
          )
          .catch((err: Error) => {
            toast.error(
              "An unexpected error occurred invoking 'RequestAccess' on the server"
            );
          });
      } else {
        navigate(`/access-shared-presentation/${presentationId}`);
      }
    }
  }, [dispatch, connection, presentationId, navigate]);

  // Handling Presentation details
  useEffect(() => {
    // setIsLoading(true);
    setActiveImage(null); //setting active image to null before handling the if statement.

    if (
      fetchedFlattenedDocIndex &&
      displayDocumentIndex <= fetchedFlattenedDocIndex - 1
    ) {
      if (
        flattedDisplayDocuments[displayDocumentIndex]?.url?.endsWith('.pptx') ||
        flattedDisplayDocuments[displayDocumentIndex]?.url?.endsWith('.pdf')
      ) {
        imageCache.current[displayDocumentIndex]?.id &&
          setSlides(imageCache.current[displayDocumentIndex]?.id);

        setActiveImage(imageCache.current[displayDocumentIndex]?.id[0]);
        setIsLoading(false);
        setIsSlides(true);
      } else {
        setActiveImage(imageCache.current[displayDocumentIndex]?.id[0]);
        setIsLoading(false);
        setIsSlides(false);
        setIsGridDisplayActive(false);
      }
    }
  }, [
    displayDocumentIndex,
    flattedDisplayDocuments,
    dispatch,
    fetchedFlattenedDocIndex,
  ]);

  // For setting active slide or document, handles getting the stream when the slideindex and document index changes
  useEffect(() => {
    // setIsLoading(true);
    // setActiveImage(null);
    if (imageCache.current.length && !isSlides) {
      // const image = imageCache
      imageCache.current[displayDocumentIndex]?.id &&
        setActiveImage(imageCache.current[displayDocumentIndex]?.id[0]);
    } else if (flattedDisplayDocuments && isSlides && slides.length > 0) {
      setActiveImage(
        imageCache.current[displayDocumentIndex]?.id[slideDocumentIndex]
      );
    }
  }, [
    flattedDisplayDocuments,
    displayDocumentIndex,
    isSlides,
    slides,
    slideDocumentIndex,
  ]);

  const [isOpen] = useState(false);
  const [timeSpent, setTimeSpent] = useState(0);

  const timeSpentRef = useRef(timeSpent);

  // Timer to update timeSpent
  useEffect(() => {
    // This technically does not get re-rendered but because the setInterval timer is active, it would basically continue running
    const slideTime = setInterval(() => {
      timeSpentRef.current += 1;
    }, 1000);

    return () => clearInterval(slideTime);
  }, []);

  const handleNextDoc = () => {
    if (displayDocumentIndex === flattedDisplayDocuments.length - 1) {
      return;
    } else {
      // let unlockedSlides = getUnlockedSlides();
      let currentSlide = flattedDisplayDocuments[displayDocumentIndex + 1]?.id;
      let previousSlide = flattedDisplayDocuments[displayDocumentIndex]?.id;
      //record timeline
      connection
        .invoke(
          'RecordTimeline',
          presentationId,
          getConnectionId(),
          currentSlide,
          'presenter',
          timeSpentRef.current,
          previousSlide
        )
        .catch((err: Error) => {
          // toast.error("An error occured while recording timeline");
          console.log(err);
        });

      timeSpentRef.current = 0;
      setDisplayDocumentIndex((prevIndex) => prevIndex + 1);
    }
  };
  const handlePrevDoc = () => {
    if (displayDocumentIndex === 0) {
      return;
    } else {
      // let unlockedSlides = getUnlockedSlides();
      let currentSlide = flattedDisplayDocuments[displayDocumentIndex - 1]?.id;
      let previousSlide = flattedDisplayDocuments[displayDocumentIndex]?.id;
      //record timeline
      connection
        .invoke(
          'RecordTimeline',
          presentationId,
          getConnectionId(),
          currentSlide,
          'presenter',
          timeSpentRef.current,
          previousSlide
        )
        .catch((err: Error) => {
          // toast.error("An error occured while recording timeline");
          console.log(err);
        });
      timeSpentRef.current = 0;

      setDisplayDocumentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const itemRefs = useRef<(HTMLElement | null)[]>([]); // Store references to all items
  useEffect(() => {
    const currentItem = itemRefs.current[slideDocumentIndex];
    console.log('Current item:', currentItem); // Debug to confirm ref

    if (currentItem) {
      currentItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center', // Center alignment for better view
      });
    }
  }, [slideDocumentIndex, itemRefs]);

  const handleNextSlideDoc = () => {
    setSlideDocumentIndex((prevIndex) =>
      Math.min(prevIndex + 1, slides.length - 1)
    );
  };

  const handlePrevSlideDoc = () => {
    setSlideDocumentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  // Assuming activeImgRef is defined as a ref for an HTML element
  const enterFullscreen = () => {
    const elem = document.documentElement; // Entire document (browser fullscreen)
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((elem as any)?.mozRequestFullScreen) {
      // Firefox
      (elem as any).mozRequestFullScreen();
    } else if ((elem as any)?.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      (elem as any).webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      // Firefox
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      // Chrome, Safari, and Opera
      (document as any).webkitExitFullscreen();
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    // Ensuring this only run only when the there is slides
    if (slides.length === 0) {
      return;
    }
    if (e.key === 'ArrowLeft') {
      handlePrevSlideDoc();
    } else if (e.key === 'ArrowRight') {
      handleNextSlideDoc();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [slides]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    // Cleanup listeners on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      enterFullscreen();
      setIsFullScreen(true);
    } else {
      exitFullscreen();
    }
  };

  const handleGridDisplay = () => {
    setIsGridDisplayActive(!isGridDisplayActive);
    console.log('grid display');
  };

  return (
    <section className='relative min-h-screen '>
      {!connection ? (
        <Loader />
      ) : (
        <>
          <SidebarWrapper
            isOpen={isOpen}
            flattedDisplayDocuments={flattedDisplayDocuments}
          >
            {doc && (
              <Sidebar
                displayDocumentIndex={displayDocumentIndex}
                setDisplayDocumentIndex={setDisplayDocumentIndex}
                previewDocs={doc}
                flattedDisplayDocuments={flattedDisplayDocuments}
                setSlideDocumentIndex={setSlideDocumentIndex}
                imageCache={imageCache}
                fetchedFlattenedDocIndex={fetchedFlattenedDocIndex}
              />
            )}
          </SidebarWrapper>

          <MainContentWrapper flattedDisplayDocuments={flattedDisplayDocuments}>
            <Header
              ownerName={presentationDetails?.presentation?.createdBy}
              title={presentationDetails?.presentation?.title}
            />
            <DocumentDisplay
              isLoading={isLoading}
              activeImage={activeImage}
              activeImgRef={activeImgRef}
              isPresentationDetailsLoading={isPresentationLoading}
              minScreenWidth={minScreenWidth}
              screenWidth={screenWidth}
              slides={slides}
              imageCache={imageCache}
              slideDocumentIndex={slideDocumentIndex}
              setSlideDocumentIndex={setSlideDocumentIndex}
              isGridDisplayActive={isGridDisplayActive}
              setIsGridDisplayActive={setIsGridDisplayActive}
              isSlides={isSlides}
              handleFullscreen={handleFullscreen}
              displayDocumentIndex={displayDocumentIndex}
            />
            <ControllerParent
              screenWidth={screenWidth}
              isSlides={isSlides}
              slides={slides}
              slideDocumentIndex={slideDocumentIndex}
              handlePrevSlideDoc={handlePrevSlideDoc}
              displayDocumentIndex={displayDocumentIndex}
              flattedDisplayDocuments={flattedDisplayDocuments}
              handleNextSlideDoc={handleNextSlideDoc}
              handlePrevDoc={handlePrevDoc}
              handleNextDoc={handleNextDoc}
              imageCache={imageCache}
              itemRefs={itemRefs}
              minScreenWidth={minScreenWidth}
              setSlideDocumentIndex={setSlideDocumentIndex}
              handleFullscreen={handleFullscreen}
              handleGridDisplay={handleGridDisplay}
              isGridDisplayActive={isGridDisplayActive}
            />
          </MainContentWrapper>

          {isFullscreen && (
            <FullScreen
              fullScreenContainerRef={fullScreenContainerRef}
              activeImage={activeImage}
              activeImgRef={activeImgRef}
              isSlides={isSlides}
              slideDocumentIndex={slideDocumentIndex}
              handlePrevSlideDoc={handlePrevSlideDoc}
              imageCache={imageCache}
              displayDocumentIndex={displayDocumentIndex}
              handleNextSlideDoc={handleNextSlideDoc}
              handlePrevDoc={handlePrevDoc}
              handleNextDoc={handleNextDoc}
            />
          )}
        </>
      )}
    </section>
  );
}
