import leftArrow from "assets/svg/leftArrow.svg";
import catchUp from "assets/svg/catchUpWithPresenter.svg";
import playListIcon from "assets/svg/playListIcon.svg"
import fullScreenIcon from "assets/svg/fullSceen.svg"
import peopleIcon from "assets/svg/peopleIconDesktop.svg"
import messageDesktop from "assets/svg/messageDesktop.svg"
import shareDesktopIcon from "assets/svg/shareIconDesktop.svg"
import desktopLeave from "assets/svg/desktopLeave.svg"
import {DesktopViewerMenuType} from "../../../../interfaces/interfaces-ui";
import playListIconActive from "assets/svg/playlistIconActive.svg";
import peopleActive from "assets/svg/peopleActive.svg"
import shareDeskIconActive from "assets/svg/viewerShareActive.svg"
import {PresentationDisplayInterface} from "../../../../interfaces/interfaces-data";
import React, {MutableRefObject, useEffect, useState} from "react";
import catchUpActive from "assets/svg/catchupActive.svg"
import {HubConnection} from "@microsoft/signalr";
import {getConnectionId} from "../../../../utils/localStorageServices";
import {useNavigate} from "react-router-dom";


export const DesktopViewerController = ({
                                            activeMenu,
                                            handleActiveMenu,
                                            activeIndexValue,
                                            presentationDisplayDetails,
                                            activeDocumentId,
                                            setActiveIndexValue,
                                            userOutOfSyncRef,
                                            presenterActiveSlideIndex,
                                            presenterActiveDocument,
                                            setActiveDocumentId,
                                            userOutOfSyncState,
                                            setUserOutOfSyncState,
                                            connected,
                                            connection,
                                            presentationId,
                                            imageSource,
                                            friendlyId,
                                            isEvent,
                                            outOfSyncActiveIndex,
                                            setOutOfSyncActiveIndex,
                                            outOfCurrentAgenda,
                                            setOutOfCurrentAgenda,
                                            outOfSyncPresentationDetails,
                                            setOutOfSyncPresentationDetails,
                                            outOfSyncDetailsRef,
                                            outOfSyncActiveDocumentId
                                        }: {
    activeMenu: DesktopViewerMenuType,
    handleActiveMenu: (val: DesktopViewerMenuType) => void,
    activeIndexValue: number,
    presentationDisplayDetails?: PresentationDisplayInterface,
    activeDocumentId: string;
    setActiveIndexValue: (val: number) => void;
    userOutOfSyncRef: MutableRefObject<boolean>;
    presenterActiveSlideIndex: number;
    presenterActiveDocument: string;
    setActiveDocumentId: (val: string) => void
    userOutOfSyncState: boolean;
    setUserOutOfSyncState: (val: boolean) => void;
    connected: boolean;
    connection?: HubConnection | null,
    presentationId: string,
    imageSource: string,
    friendlyId?: string,
    isEvent: boolean,
    outOfSyncActiveIndex: number,
    setOutOfSyncActiveIndex: (val: number) => void,
    outOfCurrentAgenda: boolean,
    setOutOfCurrentAgenda: (val: boolean) => void,
    outOfSyncPresentationDetails: PresentationDisplayInterface,
    setOutOfSyncPresentationDetails: (val: PresentationDisplayInterface) => void,
    outOfSyncDetailsRef: MutableRefObject<PresentationDisplayInterface>,
    outOfSyncActiveDocumentId: string

}) => {

    const [isFullScreen, setIsFullScreen] = useState(false)
    const enterFullscreen = async () => {
        const elem = document.documentElement; // Entire document (browser fullscreen)
        if (elem.requestFullscreen) {
            await elem.requestFullscreen();
        }
    };

    const exitFullscreen = async () => {
        if (document.exitFullscreen) {
            await document.exitFullscreen();
        }
    };

    const handleFullscreen = () => {
        if (!document.fullscreenElement) {
            enterFullscreen();
            setIsFullScreen(true);
        } else {
            exitFullscreen();
        }
    };
    const navigate = useNavigate();

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                setIsFullScreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        // Cleanup listeners on component unmount
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const handleNext = () => {
        // get the index of the next value
        // from the current index get the next unlocked value and the get the value
        if (!outOfCurrentAgenda) {
            if (presentationDisplayDetails) {
                const activeDocs = presentationDisplayDetails[activeDocumentId]
                const arrayToCheck = activeDocs.slice(activeIndexValue + 1)

                // Basically, what we want to do is to obtain the nearest value that they can go to, because of optimization pretty much , we obtain using the current index as yardstick
                const nextObject = arrayToCheck.find(item => item.unlocked);
                if (nextObject) {
                    // find the index of that id , in the existing presentation
                    const newIndex = presentationDisplayDetails[activeDocumentId].findIndex(item => item.id === nextObject.id)
                    if (newIndex === presenterActiveSlideIndex && activeDocumentId === presenterActiveDocument) {
                        userOutOfSyncRef.current = false
                        setUserOutOfSyncState(false)
                    } else if (newIndex !== presenterActiveSlideIndex) {
                        userOutOfSyncRef.current = true
                        setUserOutOfSyncState(true)

                    }
                    setActiveIndexValue(newIndex);
                }

            }
        } else {
            if (outOfSyncPresentationDetails) {
                const activeDocs = outOfSyncPresentationDetails[outOfSyncActiveDocumentId]
                const arrayToCheck = activeDocs.slice(outOfSyncActiveIndex + 1)

                // Basically, what we want to do is to obtain the nearest value that they can go to, because of optimization pretty much , we obtain using the current index as yardstick
                const nextObject = arrayToCheck.find(item => item.unlocked);
                if (nextObject) {
                    // find the index of that id , in the existing presentation
                    const newIndex = outOfSyncPresentationDetails[outOfSyncActiveDocumentId].findIndex(item => item.id === nextObject.id)
                    if (newIndex === presenterActiveSlideIndex && activeDocumentId === presenterActiveDocument) {
                        userOutOfSyncRef.current = false
                        setUserOutOfSyncState(false)
                    } else if (newIndex !== presenterActiveSlideIndex) {
                        userOutOfSyncRef.current = true
                        setUserOutOfSyncState(true)
                    }
                    setOutOfSyncActiveIndex(newIndex);
                }
            }
        }

    }
    const handlePrevious = () => {
        // get the index of the previous value
        if (!outOfCurrentAgenda) {
            if (presentationDisplayDetails) {
                const activeDocs = presentationDisplayDetails[activeDocumentId]
                const arrayToCheck = activeDocs.slice(0, activeIndexValue);
                const prevObject = arrayToCheck.reverse().find(item => item.unlocked);
                if (prevObject) {
                    const newIndex = presentationDisplayDetails[activeDocumentId].findIndex(item => item.id === prevObject.id);
                    if (newIndex === presenterActiveSlideIndex && activeDocumentId === presenterActiveDocument) {
                        userOutOfSyncRef.current = false
                        setUserOutOfSyncState(false)
                    } else if (newIndex !== presenterActiveSlideIndex) {
                        userOutOfSyncRef.current = true
                        setUserOutOfSyncState(true)
                    }
                    setActiveIndexValue(newIndex)
                }

            }
        } else {
            if (outOfSyncPresentationDetails) {
                const activeDocs = outOfSyncPresentationDetails[outOfSyncActiveDocumentId]
                const arrayToCheck = activeDocs.slice(0, outOfSyncActiveIndex);
                const prevObject = arrayToCheck.reverse().find(item => item.unlocked);
                if (prevObject) {
                    const newIndex = outOfSyncPresentationDetails[outOfSyncActiveDocumentId].findIndex(item => item.id === prevObject.id);
                    setOutOfSyncActiveIndex(newIndex)
                }
            }
        }
    }
    const checkCanNext = (): boolean => {
        // pick the active index of the current Document and then see if any is unlocked
        // get the current doc and then shard it from the activeIndex to the end and see if the condition matches
        if (!outOfCurrentAgenda) {
            if (presentationDisplayDetails && presentationDisplayDetails[activeDocumentId] && activeIndexValue !== presentationDisplayDetails[activeDocumentId].length - 1) {
                const activeDocs = presentationDisplayDetails[activeDocumentId] ?? []
                const arrayToCheck = activeDocs.slice(activeIndexValue + 1)
                const canNextVal = arrayToCheck.find(item => item.unlocked)
                return !!canNextVal
            }
            return false
        } else {
            if (outOfSyncPresentationDetails) {
                const activeDocOutOfSync = outOfSyncPresentationDetails[outOfSyncActiveDocumentId] ?? [];
                const arrayToCheck = activeDocOutOfSync.slice(activeIndexValue + 1)
                const canNextVal = arrayToCheck.find(item => item.unlocked)
                return !!canNextVal
            }
            return false
        }
    }
    const checkCanPrevious = (): boolean => {
        // Now , i want to get the last position of the array based off the active index and then i want to be able to reverse it accordingly
        if (!outOfCurrentAgenda) {
            if (presentationDisplayDetails && presentationDisplayDetails[activeDocumentId] && activeIndexValue !== 0) {
                const activeDocs = presentationDisplayDetails[activeDocumentId] ?? []
                const arrayToCheck = activeDocs.slice(0, activeIndexValue);
                const canPrevVal = arrayToCheck.reverse().find(item => item.unlocked)
                return !!canPrevVal
            }
            return false
        } else {
            if (outOfSyncPresentationDetails) {
                const activeDocOutOfSync = outOfSyncPresentationDetails[outOfSyncActiveDocumentId] ?? [];
                const arrayToCheck = activeDocOutOfSync.slice(0, outOfSyncActiveIndex);
                const canPrevVal = arrayToCheck.reverse().find(item => item.unlocked)
                return !!canPrevVal
            }
            return false
        }
    }


    const leavePresentation = () => {
        if (connected && connection && getConnectionId()) {
            console.log("Got in hereeee", getConnectionId())
            // This is invoked Successfully
            connection.invoke("RemoveUser", isEvent ? friendlyId : presentationId, getConnectionId(), false, isEvent);

        }
    }

    useEffect(() => {
        if (connected && connection && connection.state === "Connected") {

            connection.on("EventResponse", (id: string, eventName: string, status: any, presenterConnectionId: string) => {
                if (eventName === "RemoveUser" && status === true) {
                    localStorage.removeItem("connectionId");
                    if (friendlyId) {
                        navigate(`/admin/mypresentations`);
                        return
                    }
                    navigate("/")
                }
            })
        }
    }, [connected, connection]);

    useEffect(() => {
        checkCanNext();
        checkCanPrevious()
    }, [presentationDisplayDetails]);
    return <>
        {isFullScreen &&
            <section
                className={`absolute top-0 left-0 w-screen h-screen z-20`}>
                <section
                    className={`relative ${userOutOfSyncState && 'border-4 border-[#FF6929]'}   w-screen h-screen`}>
                    <section
                        className=' bg-white w-full h-full'
                    >
                        {imageSource && <img
                            src={imageSource}
                            alt='file'
                            className='rounded-lg object-contain'
                            style={{
                                maxWidth: `100%`,
                                maxHeight: `100%`,
                                width: '100%',
                                height: 'auto',
                            }}
                        />}

                    </section>
                </section>
            </section>}
        <div
            className="p-4 justify-between min-h-[10%] max-h-[10%] h-[10%] rounded-xl  flex flex-grow items-center bg-[#F6F6F6] border border-[#EBF1FF]  mt-3 w-full ">
            <div className={`${isFullScreen && "fixed bottom-10 left-5 z-20"} flex items-center gap-2`}>
                {/*For this to be active , atleast one of the previous ones must have a property of unlocked */}
                {checkCanPrevious() && <ControlButton click={handlePrevious} text="Previous Slide" position="left"/>}

                <p className="rounded-full p-2 px-4 bg-[#AFAFAF] text-black">
                    {outOfCurrentAgenda ? outOfSyncActiveIndex + 1 : activeIndexValue + 1}
                </p>
                {/*This button is considered active or inactive based on whether any slide that follows has the property of unlocked to be true , basiclaly see if atleast one after it is unlocked */}
                {checkCanNext() && <ControlButton click={handleNext} position={"right"} text="Next Slide" rotate/>}

            </div>
            {/*Navigation for the page */}
            <div className="flex items-center gap-8">
                {userOutOfSyncState &&
                    <button
                        className={`${isFullScreen && "fixed bottom-10 right-[100px] z-20"} flex flex-col items-center justify-center`}
                        onClick={() => {
                            userOutOfSyncRef.current = false;
                            setActiveDocumentId(presenterActiveDocument);
                            setActiveIndexValue(presenterActiveSlideIndex);
                            setUserOutOfSyncState(false);
                        }}>
                    <span>
                        <img className={`${isFullScreen && "w-[40px] h-[40px]"}`}
                             src={userOutOfSyncState ? catchUpActive : catchUp} alt={""}/>
                    </span>
                        <p className={`text-[#667084] mt-2 ${isFullScreen ? "text-lg" : "text-xs"}`}>Catch up</p>
                    </button>}

                <button onClick={() => handleActiveMenu("playlist")}
                        className="flex flex-col items-center justify-center">
                    <span>
                        <img src={activeMenu === "playlist" ? playListIconActive : playListIcon} alt={""}/>
                    </span>
                    <p className={`${activeMenu === "playlist" && "text-[#FF6929]"} text-xs text-[#667084] mt-2`}>{isEvent ? "Agenda" : "Playlist"} </p>
                </button>
                <button onClick={() => handleFullscreen()} className="flex flex-col items-center justify-center">
                    <span>
                        <img src={fullScreenIcon} alt={""}/>
                    </span>
                    <p className={"text-xs text-[#667084] mt-2"}>Full screen</p>
                </button>
                <button onClick={() => handleActiveMenu("attendees")}
                        className="flex flex-col items-center justify-center">
                    <span>
                        <img src={activeMenu === "attendees" ? peopleActive : peopleIcon} alt={""}/>
                    </span>
                    <p className={`${activeMenu === "attendees" && "text-[#FF6929]"} text-xs text-[#667084] mt-2`}>Attendees</p>
                </button>
                <button onClick={() => handleActiveMenu("question")}
                        className="flex flex-col items-center justify-center">
                    <span>
                        <img src={messageDesktop} alt={""}/>
                    </span>
                    <p className={"text-xs text-[#667084] mt-2"}>Questions</p>
                </button>
                <button onClick={() => handleActiveMenu("share")} className="flex flex-col items-center justify-center">
                    <span>
                        <img src={activeMenu === "share" ? shareDeskIconActive : shareDesktopIcon} alt={""}/>
                    </span>
                    <p className={`${activeMenu === "share" && "text-[#FF6929]"} text-xs text-[#667084] mt-2`}>Share</p>
                </button>
                <button onClick={leavePresentation}>
                    <span>
                        <img src={desktopLeave} alt={""}/>
                    </span>
                </button>
            </div>
        </div>
    </>
}

const ControlButton = ({position = "right", text, rotate, click}: {
    position?: 'right' | 'left',
    text: string,
    rotate?: boolean,
    click: () => void
}) => {
    return (
        <button onClick={click}
                className="text-[#545454] gap-2 rounded-full py-2 px-4 flex items-center bg-[#E2E2E2] text-xs">
            {position === "left" && <img alt={""} src={leftArrow}/>}
            <p className="text-sm">
                {text}
            </p>
            {position === "right" && <img className={`${rotate && 'rotate-180'}`} alt={""} src={leftArrow}/>}
        </button>
    )
}