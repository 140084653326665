import { Dispatch, SetStateAction } from 'react';

import { PollQuestion } from 'interfaces/PollSetup';
import QuestionOption from '../../QuestionOption';
import QuestionInput from '../../SharedComponents/QuestionInput';
import SaveBotton from '../../SharedComponents/SaveBotton';
import Duraton from '../../SharedComponents/Duraton';

interface Props {
  activeQuestion: PollQuestion;
  setActiveQuestion: Dispatch<SetStateAction<PollQuestion>>;
  handleSave: () => void;
}

export default function MultiQuestion({
  activeQuestion,
  setActiveQuestion,
  handleSave,
}: Props) {
  return (
    <section className='text-sm'>
      <QuestionInput
        activeQuestion={activeQuestion}
        setActiveQuestion={setActiveQuestion}
      />

      <div className='mt-3'>
        <p className='text-[#667084] mb-3'>CREATE YOUR OPTIONS?</p>
        <section>
          {activeQuestion.options &&
            activeQuestion.options.map((option, index) => (
              <QuestionOption
                key={index}
                option={option}
                index={index}
                options={activeQuestion.options}
                activeQuestion={activeQuestion}
                setActiveQuestion={setActiveQuestion}
              />
            ))}
        </section>
      </div>
      <div className='flex gap-x-3 h-11 justify-between items-center w-full px-3 rounded-lg border border-[#EEEEEE] mt-2 overflow-hidden'>
        <p className='text-[#3D4045]'>Show result as percentage</p>

        <label className='inline-flex items-center cursor-pointer'>
          <input type='checkbox' value='' className='sr-only peer' />
          <div className="relative w-[36px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
        </label>
      </div>

      <Duraton
        activeQuestion={activeQuestion}
        setActiveQuestion={setActiveQuestion}
      />

      <SaveBotton handleSave={handleSave} />
    </section>
  );
}
