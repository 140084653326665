import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Poll } from 'interfaces/PollSetup';
import {
  _deletePollQuestionType,
  _getPollDetails,
  _getPollTemplates,
  _getPolls,
  _pollCreation,
  _takePoll,
} from 'services/Poll/pollServices';
import {
  _removeDocumentFromPlaylist,
  _updatePresentationEdit,
} from 'services/Presentations/presentationServices';

interface Option {
  id: string;
  rank: number;
  text: string;
  imageUrl: string | null;
  shape: string | null;
  color: string | null;
  isSelected: boolean;
  value: number;
}

interface Question {
  id: string;
  questionType: string;
  rank: number;
  question: string;
  questionImageUrl: string | null;
  answer: string | null;
  duration: number;
  options: Option[];
}

export interface FetchedPoll {
  partitionKey: string;
  rowKey: string;
  timestamp: string;
  eTag: string;
  title: string | null;
  owner: string;
  ownerId: string;
  createdDate: string | null;
  launchDate: string;
  status: number;
  jsonQuestions: string;
  questions: Question[];
}

//   type Data = FetchedPoll[];

interface InitialState {
  pollTemplates: {
    isLoading: boolean;
    data: FetchedPoll[] | null;
  };
}

const initialState: InitialState = {
  pollTemplates: {
    isLoading: false,
    data: null,
  },
  // polls: {
  //   isLoading: true,
  //   data: null,
  // },
  // presentations: {
  //   isLoading: true,
  //   data: null,
  // },
  // presentationDetails: {
  //   isLoading: true,
  //   data: null,
  // },

  // invitedGuests: {
  //   isLoading: false,
  //   data: null,
  // },
  //   pollDetails: {
  //     isLoading: false,
  //     data: null,
  //   },
};

//GET

// export const getPolls = createAsyncThunk(
//   'pollcopy/getPolls',
//   async (presentationId) => {
//     const response = await _getPolls(presentationId);
//     return response.data;
//   }
// );

// export const getPollDetails = createAsyncThunk(
//   'pollcopy/getPollDetails',
//   async ({ presentationId, pollId }) => {
//     const response = await _getPollDetails(presentationId, pollId);
//     return response.data;
//   }
// );

export const getPollTemplates = createAsyncThunk(
  'pollcopy/getPollTemplates',
  async () => {
    const response = await _getPollTemplates();
    return response.data;
  }
);

//POST

// export const takePoll = createAsyncThunk('pollcopy/takePoll', async (data) => {
//   const response = await _takePoll(data);
//   return response.data;
// });

export const createPoll = createAsyncThunk(
  'pollcopy/createPoll',
  async ({ data, presentationId }: { data: Poll; presentationId?: string }) => {
    const response = await _pollCreation(data, presentationId);
    return response.data;
  }
);

// export const deletePollQuestionType = createAsyncThunk(
//   'pollcopy/deletePollQuestionType',
//   async ({ presentationId, pollId }) => {
//     const response = await _deletePollQuestionType(presentationId, pollId);
//     return response.data;
//   }
// );

// //PUT
// export const updatePresentationEdit = createAsyncThunk(
//   'presentations/updatePresentationEdit',
//   async ({ presentationId, data }) => {
//     const response = await _updatePresentationEdit(presentationId, data);
//     return response.data;
//   }
// );

// //DELETE
// export const removeDocumentFromPlaylist = createAsyncThunk(
//   'presentations/removeDocumentFromPlaylist',
//   async ({ presentationId, removeDocId }) => {
//     const response = await _removeDocumentFromPlaylist(
//       presentationId,
//       removeDocId
//     );
//     return response.data;
//   }
// );

export const pollCopySlice = createSlice({
  name: 'pollcopy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get document files/slides
    // builder
    //   .addCase(getPolls.pending, (state) => {
    //     state.polls.data = null;
    //     state.polls.isLoading = true;
    //   })
    //   .addCase(getPolls.fulfilled, (state, { payload }) => {
    //     state.polls.isLoading = false;
    //     state.polls.data = payload;
    //   })
    //   .addCase(getPolls.rejected, (state, { payload }) => {
    //     state.polls.isLoading = false;
    //   });

    // //get pollcopy details
    // builder
    //   .addCase(getPollDetails.pending, (state) => {
    //     state.pollDetails.data = null;
    //     state.pollDetails.isLoading = true;
    //   })
    //   .addCase(getPollDetails.fulfilled, (state, { payload }) => {
    //     state.pollDetails.isLoading = false;
    //     state.pollDetails.data = payload;
    //   })
    //   .addCase(getPollDetails.rejected, (state, { payload }) => {
    //     state.pollDetails.isLoading = false;
    //   });

    //templates
    builder
      .addCase(getPollTemplates.pending, (state) => {
        state.pollTemplates.data = null;
        state.pollTemplates.isLoading = true;
      })
      .addCase(getPollTemplates.fulfilled, (state, { payload }) => {
        state.pollTemplates.isLoading = false;
        state.pollTemplates.data = payload;
      })
      .addCase(getPollTemplates.rejected, (state, { payload }) => {
        state.pollTemplates.isLoading = false;
      });
  },
});

// export const getPollState = (state) => state.pollcopy;

export default pollCopySlice.reducer;
