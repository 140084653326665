import {
  AddPresentationDocumentsRequestPayload,
  AddPresentationRequestPayload,
  AddSessionRequestPayload,
  CreateEventRequestPayload,
  ShareEventLinkRequestPayload,
  UpdateEventRequestPayload,
  updatePresentationDocumentPositionRequestPayload,
  UpdateSessionRequestPayload,
} from 'interfaces/Events/EventsInterfaces';
import Axios from 'lib/axiosInterceptor';

// GET
export const _getEvents = (teamId: string) => {
  return Axios.get(`/Events?teamId=${teamId}`);
};
export const _getEvent = (eventId: string, teamId: string) => {
  return Axios.get(`/Events/${eventId}/?teamId=${teamId}`);
};
export const _getEventThumbnail = (eventId: string) => {
  return Axios.get(`/Events/Thumbnail/${eventId}`, {
    responseType: 'arraybuffer',
  });
};

export const _getPresentation = (
  presentationId: string,
  connectionId?: string
) => {
  return Axios.get(
    `/presentation/${presentationId}?presentaConnectionId=${
      connectionId ?? null
    }`
  );
};

// UPDATE
export const _updateEvent = (data: UpdateEventRequestPayload) => {
  return Axios.post('/Events/Update', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// POST
export const _createEvent = (data: CreateEventRequestPayload) => {
  return Axios.post('/Events/Create', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const _addSession = (data: AddSessionRequestPayload) => {
  return Axios.post('/Events/AddSession', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const _removeSession = ({
  sessionId,
  eventId,
  teamId,
}: {
  sessionId: string;
  eventId: string;
  teamId: string;
}) => {
  return Axios.post(
    `/Events/RemoveSession/${sessionId}/${eventId}/?teamId=${teamId}`
  );
};
export const _updateSession = ({
  sessionId,
  data,
}: {
  sessionId: string;
  data: UpdateSessionRequestPayload;
}) => {
  return Axios.post(`/Events/UpdateSession/${sessionId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const _addPresentationDocuments = (
  data: AddPresentationDocumentsRequestPayload
) => {
  return Axios.post('/Presentation/AddDocument/Multiple', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const _addGuests = ({
  eventId,
  teamId,
  data,
}: {
  eventId: string;
  teamId: string;
  data: string[];
}) => {
  return Axios.post(`/Events/AddGuests/${eventId}/?teamId=${teamId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const _removeGuests = ({
  eventId,
  teamId,
  data,
}: {
  eventId: string;
  teamId: string;
  data: string[];
}) => {
  return Axios.post(`/Events/AddGuests/${eventId}/?teamId=${teamId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const _addPresentationDocument = (data: FormData) => {
  return Axios.post('/Presentation/AddDocument', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const _addEventThumbnail = (
  eventId: string,
  teamId: string,
  data: FormData
) => {
  return Axios.post(`/Events/Thumbnail/${eventId}/?teamId=${teamId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const _publishEvent = (eventId: string, teamId: string) => {
  return Axios.post(`/Events/Publish/${eventId}/?teamId=${teamId}`);
};

export const _addPresentation = (data: AddPresentationRequestPayload) => {
  return Axios.post('/Events/Collaborator/AddPresentation', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const _addCoPresenters = ({
  sessionId,
  eventId,
  data,
}: {
  sessionId: string;
  eventId: string;
  data: { coPresenters: string[] };
}) => {
  return Axios.post(
    `/Events/Collaborator/AddPresenters/${sessionId}/${eventId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};
export const _addPresenters = ({
  sessionId,
  data,
}: {
  sessionId: string;
  data: {
    eventId: string;
    teamId: string;
    collaborators: string[];
  };
}) => {
  return Axios.post(`/Events/AddPresenters/${sessionId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const _shareEvent = (
  eventId: string,
  data: ShareEventLinkRequestPayload,
  teamId?: string
) => {
  return Axios.post(
    `/Events/Share/${eventId}${teamId ? `/?teamId=${teamId}` : ''}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const _removePresentationDocument = ({
  presentationId,
  documentId,
}: {
  presentationId: string;
  documentId: string;
}) => {
  return Axios.post(
    `/Presentation/RemoveDocument/${presentationId}/${documentId}`
  );
};
export const _sendSessionReminder = ({
  sessionId,
  teamId,
}: {
  sessionId: string;
  teamId: string;
}) => {
  return Axios.post(`/Events/SendReminder/${sessionId}/?teamId=${teamId}`);
};

export const _updateDocument = (data: FormData) => {
  return Axios.post(`/Document/Update`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const _updateSessionPosition = ({
  eventId,
  data,
}: {
  eventId: string;
  data: { position: number; sessionId: string }[];
}) => {
  return Axios.post(`/Events/UpdatePosition/${eventId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const _updatePresentationDocumentPosition = ({
  presentationId,
  data,
}: {
  presentationId: string;
  data: updatePresentationDocumentPositionRequestPayload[];
}) => {
  return Axios.post(`/Presentation/UpdatePosition/${presentationId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const _deleteEvent = ({
  eventId,
  teamId,
  archive,
}: {
  eventId: string;
  teamId: string;
  archive: boolean;
}) => {
  return Axios.post(
    `Events/Delete/${eventId}?teamId=${teamId}&archive=${archive}`
  );
};
