import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';

//assets
import lock from 'assets/svg/lock.svg';
import teamIcon from 'assets/svg/people2.svg';
import arrowDown from 'assets/svg/arrowDown.svg';
import arrowRight from 'assets/svg/arrowRight.svg';
import closeIcon from 'assets/svg/close-icon.svg';

//components
import FlexContainer from 'components/UI/StyleComponents/FlexContainer';
import ContentPage from 'components/Admin/Workspace/ContentPage/ContentPage';

//libraries
import {Modal} from 'antd';

//slices
import {
  getFolderFiles,
  getWorkspaceFolders,
  getAllWorkspace,
} from 'store/Workspace/workspaceSlice';
import { addPresentationDocument } from 'store/Events/EventsSlice';

//components
import PersonalWorkspace from 'components/Admin/Workspace/PersonalWS/PersonalWorkspace';
import {
    getFileStructureState,
    setMostActiveFolder,
    setPersonalWorkspaceId,
} from 'store/FileStructure/fileStructureSlice';
import CreateFolder from 'views/Admin/Workspace/Modal/CreateFolder';
import UploadDocuments from 'views/Admin/Dashboard/Modal/UploadMultiDocument/UploadDocuments';
import {
    addDocumentToPresentation,
    getPresentationDetails,
    getPresentationState,
} from 'store/Presentations/presentationSlice';
import {toast} from 'react-toastify';

// Briefcase
import {addBriefcaseFile, getBriefcaseDocuments, getFolder,} from 'store/Briefcase/briefcaseSlice';
import {useParams} from 'react-router-dom';
import {addPresentationDocuments} from 'store/Events/EventsSlice';

export default function UploadFromWorkspace({
                                                closeAddFileModal,
                                                closeUploadFromWorkspaceModal,
                                                groupId,
                                                presentationId,
  fromEvent,
  eventFunc,
                                                fromPresentation = false,
                                            }) {
    const dispatch = useDispatch();
    const {spaceId, eventId} = useParams();

    //useref

    const uploadModalRef = useRef();
    const isFirstRender = useRef(true);

    //useselector
    const {presentationDetails} = useSelector(getPresentationState);

    const {personalWorkspaceId, mostActiveFolder} = useSelector(
        getFileStructureState
    );

    //usestates
    const [isUpgraded, setIsUpgraded] = useState(true);
    const [toggleTeamDD, setToggleTeamDD] = useState(false);
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
    const [uploadSelectedFile, setUploadSelectedFile] = useState(null);

    //get all workspace
    useEffect(() => {
        dispatch(getAllWorkspace()).then(({payload}) => {
            if (payload) {
                if (!personalWorkspaceId) {
                    const workspace = payload.filter(
                        (el) => el?.title?.toLowerCase() === 'personal'
                    )?.[0];

                    dispatch(setPersonalWorkspaceId(workspace));
                    dispatch(getWorkspaceFolders(workspace?.workspaceId)).then(
                        ({payload}) => {
                            //hide audio
                            let payload2 = payload?.filter((doc) => {
                                return !doc?.title?.includes('vrecord');
                            });
                            if (payload2?.length > 0) {
                                dispatch(setMostActiveFolder(payload2?.[0]));
                                dispatch(getFolderFiles(payload2?.[0]?.id));
                            }
                        }
                    );
                }
            }
        });
    }, [dispatch, personalWorkspaceId]);

    //functions
    const handleFileUploadCancel = () => {
        uploadModalRef.current.resetModal();
        setIsFileUploadModalOpen(false);
    };
    const handleCloseFolderModal = () => {
        setIsFolderModalOpen(false);
    };

    const submitUpload = () => {
        const presentationId = presentationDetails?.data?.presentation?.id;
        const folderId = uploadSelectedFile?.isFolder
            ? uploadSelectedFile?.id
            : null;
        const documentId = !uploadSelectedFile?.isFolder
            ? uploadSelectedFile?.id
            : null;

        const data = {
            presentationId,
            folderId,
            documentId,
            position: presentationDetails.data?.doc?.length,
        };
        const config = {};

        dispatch(addDocumentToPresentation({data, config})).then(({meta}) => {
            const {requestStatus = ''} = meta ?? {};

            if (requestStatus?.toLowerCase() === 'fulfilled') {
                toast.success('File added successfully');
                dispatch(getPresentationDetails(presentationId));
                setUploadSelectedFile(null);
            }
        });
        //
    };

    // Handling adding items to briefcase. Clean up later
    const handleAddFIletoBriefcase = async () => {
        const formData = new FormData();
        uploadSelectedFile.id &&
        formData.append('DocumentId', uploadSelectedFile.id);
        uploadSelectedFile.title &&
        formData.append('Title', uploadSelectedFile.title);

        if (groupId) {
            formData.append('GroupId', groupId);
        }

        const {type} = await dispatch(addBriefcaseFile({spaceId, formData}));

        if (type.includes('fulfilled')) {
            toast.success(`File added successfully`);
            if (groupId) {
                dispatch(getFolder({spaceId, groupId}));
            } else {
                dispatch(getBriefcaseDocuments(spaceId));
            }
        } else {
            toast.error(`File failed to add file to briefcase`);
        }

    closeAddFileModal();
    closeUploadFromWorkspaceModal();
  };
  const handleAddFIletoEvent = async () => {
    const formData = new FormData();

    formData.append('presentationId', presentationId ?? '');
    formData.append('documentTitle', uploadSelectedFile.title);
    formData.append('DocumentId', uploadSelectedFile.id);
    formData.append('position', 0);

    // return dispatch(addPresentationDocument(formData));
    // uploadSelectedFile.id &&
    //   formData.append('DocumentId', uploadSelectedFile.id);
    // uploadSelectedFile.title &&
    //   formData.append('Title', uploadSelectedFile.title);

    // if (groupId) {
    //   formData.append('GroupId', groupId);
    // }

    dispatch(addPresentationDocument(formData));

    // const { type } = await dispatch(addBriefcaseFile({ spaceId, formData }));

    // if (type.includes('fulfilled')) {
    //   toast.success(`File added successfully`);
    //   if (groupId) {
    //     dispatch(getFolder({ spaceId, groupId }));
    //   } else {
    //     dispatch(getBriefcaseDocuments(spaceId));
    //   }
    // } else {
    //   toast.error(`File failed to add file to briefcase`);
    // }

    closeAddFileModal();
    closeUploadFromWorkspaceModal();
  };

  // Handling adding items to briefcase. Clean up later
  const handleBulkDocumentUpload = async (selectedFiles) => {
    if (eventId && presentationId) {
      const documents = selectedFiles.map((doc, index) => {
        return {
          documentId: doc.id,
          documentTitle: doc.title,
          position: index,
        };
      });

            const data = {
                publicationId: presentationId,
                documents,
            };

            dispatch(addPresentationDocuments(data));
        } else {
            // upload to briefcase
            const uploadPromises = selectedFiles.map(async (document) => {
                const formData = new FormData();
                formData.append('DocumentId', document.id);
                formData.append('Title', document.title);
                if (groupId) {
                    formData.append('GroupId', groupId);
                }

                return dispatch(addBriefcaseFile({spaceId, formData}))
                    .then((response) => {
                        const {type} = response;
                        if (type.includes('fulfilled')) {
                            toast.success(`${document.name ?? ''} File added successfully`);
                            dispatch(getBriefcaseDocuments(spaceId));
                        } else {
                            toast.error(`File failed to add file to briefcase`);
                        }
                    })
                    .catch((error) => {
                        toast.error(`Failed to upload`);
                    });
            });

            // Wait for all uploads to complete
            await Promise.all(uploadPromises);
        }

        if (spaceId) {
            dispatch(getFolder({spaceId, groupId}));
        }

        closeAddFileModal();
        closeUploadFromWorkspaceModal();
    };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (!uploadSelectedFile?.isFolder) {
      if (spaceId && uploadSelectedFile && uploadSelectedFile.id) {
        handleAddFIletoBriefcase();
      } else if (fromEvent) {
        handleAddFIletoEvent();
      } else {
        submitUpload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSelectedFile]);

    return (
        <section className='hide-scrollbar bg-white flex h-[80vh] overflow-hidden '>
            <aside className='fixe  w-[19% w-[25%] h-[100%] px-4 py-8 border-r '>
                {/* team workspace */}
                <section className=''>
                    <FlexContainer
                        extraClass='cursor-pointer text-sm '
                        onClick={() => setToggleTeamDD(!toggleTeamDD)}
                    >
                        <div className='flex items-center gap-x-1'>
                            <img src={teamIcon} alt='team workspace' className='w-6'/>
                            <p>Team workspace</p>
                        </div>

                        {isUpgraded ? (
                            <img src={arrowDown} alt='dropdown'/>
                        ) : (
                            <img src={lock} alt='secured'/>
                        )}
                    </FlexContainer>

                    <section
                        className={`flex flex-col my-4 gap-y-2 ${
                            toggleTeamDD ? 'block' : 'hidden'
                        }`}
                    ></section>
                </section>
                {/* personal workspace */}
                <section className='mt-6'>
                    <PersonalWorkspace/>
                </section>
            </aside>

            <main className='hide-scrollbar w-[81% w-[75%] h-[100%] overflow-hidden overflow-y-auto '>
                <section className='p-6 '>
                    {/* navigation */}
                    <nav>
                        <FlexContainer>
                            {/* breadcrumb */}
                            <div className='flex items-center gap-x-1 '>
                                <p>Workspace</p>
                                {personalWorkspaceId && (
                                    <>
                                        <img src={arrowRight} alt='next page'/>
                                        <p>{personalWorkspaceId?.title}</p>
                                        {mostActiveFolder && (
                                            <>
                                                <img src={arrowRight} alt='next page'/>
                                                <p>{mostActiveFolder?.title}</p>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            {/*  */}

                            {/* actions */}
                            <div className='border border-[#EBEBEB] bg-[#FAFAFA] p-1 rounded-lg'>
                                <img
                                    src={closeIcon}
                                    alt='close'
                                    className='cursor-pointer'
                                    onClick={closeAddFileModal}
                                />
                            </div>
                        </FlexContainer>
                    </nav>

                    {/* body */}
                    <section className='my-6 bg-white'>
                        <ContentPage
                            setUploadSelectedFile={setUploadSelectedFile}
                            handleBulkDocumentUpload={handleBulkDocumentUpload}
              fromEvent={true}
              eventFunc={eventFunc}
                            fromPresentation={fromPresentation}
                            presentationId={presentationId}
                        />
                    </section>
                </section>
            </main>

            <>
                {/* Modals */}
                <Modal
                    open={isFolderModalOpen}
                    onCancel={handleCloseFolderModal}
                    footer={null}
                >
                    <CreateFolder closeModal={handleCloseFolderModal}/>
                </Modal>

                <Modal
                    open={isFileUploadModalOpen}
                    onCancel={handleFileUploadCancel}
                    footer={null}
                >
                    <UploadDocuments
                        ref={uploadModalRef}
                        closeModal={handleFileUploadCancel}
                    />
                </Modal>

                {/*  */}
            </>
        </section>
    );
}
