import React from "react";

export default function Banner() {
  return (
    <section className="[lg:w-[80%] w-[95%] ] mx-auto">
      <div className="text-center">
        <h1 className="   [ lg:text-[4rem]  text-2xl lg:leading-tight font-semibold ]">
          Contact us
        </h1>

        <p className=" mx-auto text-black30  [ lg:w-[50%] w-[90%] ] [ lg:text-[1.25rem] text-sm ] [ lg:mt-6 mt-4 ]  [ lg:leading-[2rem] leading-[1rem] ] ">
          We're thrilled to assist you with any questions, feedback, or support
          needs you may have regarding TeamDocs. Our team is dedicated to
          ensuring you have the best experience possible. Please feel free to
          reach out to us through any of the following channels
        </p>
      </div>
    </section>
  );
}
