// Assets
import rightNav from 'assets/svg/right-nav-arrow.svg';
import leftNav from 'assets/svg/left-nav-arrow.svg';
import { MutableRefObject } from 'react';

export interface ImageCache {
  id: string[];
}

interface Props {
  fullScreenContainerRef: MutableRefObject<HTMLElement | null>;
  activeImage: string | null;
  activeImgRef: MutableRefObject<HTMLImageElement | null>;
  isSlides: boolean;
  slideDocumentIndex: number;
  handlePrevSlideDoc: () => void;
  imageCache: MutableRefObject<ImageCache[]>;
  displayDocumentIndex: number;
  handleNextSlideDoc: () => void;
  handlePrevDoc: () => void;
  handleNextDoc: () => void;
}

export default function FullScreen({
  fullScreenContainerRef,
  activeImage,
  activeImgRef,
  isSlides,
  slideDocumentIndex,
  handlePrevSlideDoc,
  imageCache,
  displayDocumentIndex,
  handleNextSlideDoc,
  handlePrevDoc,
  handleNextDoc,
}: Props) {
  return (
    <section className='absolute top-0 left-0 w-screen h-screen z-50'>
      <section className='relative w-screen h-screen'>
        <section
          className=' bg-white w-full h-full'
          ref={fullScreenContainerRef}
        >
          {activeImage && (
            <img
              ref={activeImgRef}
              src={activeImage}
              alt='file'
              className='rounded-l object-contain'
              style={{
                maxWidth: `100%`,
                maxHeight: `100%`,
                width: '100%',
                height: 'auto',
              }}
            />
          )}
        </section>

        {/* control BTN */}
        {isSlides && (
          <section className='flex items-center gap-x-5 absolute z-50 left-10 bottom-10 bg-[#E8E8E87A] border border-[#F5F5F5] rounded-full h-[40px] justify-between px-2'>
            <button
              className={`flex gap-x-3 items-center rounded-lg ${
                slideDocumentIndex === 0
                  ? 'cursor-default text-[#E2E2E2]'
                  : 'cursor-pointer text-black'
              }`}
              onClick={handlePrevSlideDoc}
            >
              <img src={leftNav} alt='previous slide' className='w-5' />
            </button>

            <div className='rounded-[20px] text-black px-2 h-[44px] flex items-center select-none'>
              <p className='font-medium text-sm'>{`${
                slideDocumentIndex + 1
              } of ${imageCache.current[displayDocumentIndex]?.id.length}`}</p>
            </div>

            <button
              className='flex gap-x-3 items-center rounded-lg cursor-pointer'
              onClick={handleNextSlideDoc}
            >
              <img src={rightNav} alt='next slide' className='w-5' />
            </button>
          </section>
        )}
        {/* Side control */}
        <section
          className='absolute z-40 left-0 top-0 w-[20vw] h-screen '
          onClick={() => {
            isSlides ? handlePrevSlideDoc() : handlePrevDoc();
          }}
        ></section>
        <section
          className='absolute z-40 right-0 top-0 w-[20vw] h-screen '
          onClick={() => {
            isSlides ? handleNextSlideDoc() : handleNextDoc();
          }}
        ></section>
      </section>
    </section>
  );
}
