import { useEffect, useState } from 'react';

//assets
import uploadBulk from 'assets/svg/people2.svg';
import sms from 'assets/svg/sms-tracking.svg';
import calender from 'assets/svg/calendar-edit.svg';
import timer from 'assets/svg/timer3.svg';

// Slices
import {
  addGuests,
  getPresentationDetailsAuth,
  getPresentationGuests,
  getShareHistory,
  removeGuests,
  updatePresentation,
} from 'store/Briefcase/briefcaseSlice';

// Libraries
import { DatePicker } from 'antd';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { toast } from 'sonner';
import { ClipLoader } from 'react-spinners';
import Loader from 'components/UI/Loader';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

//Types
interface Props {
  presentationId: string;
  spaceId: string;
  onCloseEditBriefcaseModal: () => void;
}

interface DocumentShare {
  title: string;
  spaceId: string;
  startTime: string; // ISO 8601 date-time string
  expiry?: string; // ISO 8601 date-time string
  canDownload: number;
  type: number;
  openLink: boolean;
  emails: string[];
  domains: string[];
  passcode: string;
  authMode: number;
  watermark: string;
}

// =========>>>>>>>>>>>>>>>>>>>>>>>>>\
interface Presentation {
  spaceId: string;
  friendlyId: string;
  title: string;
  thumbnail: string;
  userId: string;
  dateAdded: string;
  expiry: string | null;
  startTime: string | null;
  endTime: string | null;
  scheduledTime: string;
  visibility: boolean;
  currentSlide: number;
  allowedDomains: string | null;
  passcode: string;
  requestPasscode: boolean;
  createdBy: string;
  status: number;
  authMode: number;
  type: number;
  canDownload: number;
  questionsAllowed: boolean;
  watermark: string | null;
  deleted: boolean;
  currentItem: string | null;
  duration: number;
  questions: number;
  visitors: number;
  views: number;
  id: string;
}

export default function EditBriefcaseLink({
  presentationId,
  spaceId,
  onCloseEditBriefcaseModal,
}: Props) {
  const dispatch = useDispatch<AppDispatch>();

  // States
  const presentationDetails = useSelector(
    (state: RootState) => state.briefcase.presentationDetails.data?.presentation
  );
  const presentationGuests = useSelector(
    (state: RootState) => state.briefcase.presentationsGuests?.data
  );

  const [shareLinkItems, setShareLinkItems] = useState<DocumentShare>({
    title: '',
    spaceId: '',
    startTime: '',
    expiry: '',
    canDownload: 0,
    type: 0,
    openLink: false,
    emails: [],
    domains: [],
    passcode: '',
    authMode: 1,
    watermark: '',
  });
  const [usePasscode, setUsePasscode] = useState(false);
  const [linkExpires, setLinkExpires] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [shouldRestrictDomains, setShouldRestrictDomains] = useState(false);
  const [domainName, setDomainName] = useState<string>('');
  const [emailName, setEmailName] = useState<string>('');

  useEffect(() => {
    dispatch(getPresentationDetailsAuth(presentationId));
    dispatch(getPresentationGuests(presentationId));
  }, [dispatch, presentationId]);

  useEffect(() => {
    if (presentationDetails && presentationGuests) {
      if (
        presentationDetails.passcode &&
        presentationDetails.passcode.length > 0
      ) {
        setUsePasscode(true);
      }

      setShareLinkItems({
        title: presentationDetails.title,
        spaceId: presentationDetails.spaceId,
        startTime: presentationDetails?.startTime ?? '',
        expiry: presentationDetails?.expiry ?? '',
        canDownload: presentationDetails.canDownload,
        type: presentationDetails.type,
        openLink: false,
        emails: presentationGuests,
        domains: presentationDetails?.allowedDomains?.split(',') ?? [],
        passcode: presentationDetails.passcode,
        authMode: 1,
        watermark: '',
      });
    }

    // resetting the options to default
    return () =>
      setShareLinkItems({
        title: '',
        spaceId: '',
        startTime: '',
        expiry: '',
        canDownload: 0,
        type: 0,
        openLink: false,
        emails: [],
        domains: [],
        passcode: '',
        authMode: 1,
        watermark: '',
      });
  }, [presentationDetails, presentationGuests]);

  // useEffect for handling the default settings
  useEffect(() => {
    if (shareLinkItems?.title?.trim()?.length > 0) {
      setDisableButton(false);
    }

    if (shareLinkItems.passcode && shareLinkItems.passcode.length > 0) {
      setUsePasscode(true);
    }

    if (shareLinkItems.expiry) {
      // const ex = dayjs.utc(shareLinkItems.expiry);
      // const ex = dayjs(shareLinkItems.expiry).format('YYYY-MM-DD');
      // console.log(ex);
      setLinkExpires(true);
    }
  }, [shareLinkItems]);

  // Functions
  const handleShareBriefcaseUserInputs = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    setShareLinkItems({ ...shareLinkItems, [name]: value });
  };

  const onOkExpiry = (value: string) => {
    const selectedDate = dayjs.utc(value); // Convert selected date string to dayjs object in UTC
    const formattedDate = selectedDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    setShareLinkItems({
      ...shareLinkItems,
      expiry: formattedDate,
    });
  };

  const handleUpdatePresentation = () => {
    interface UpdatePresentation {
      expiry?: string;
      canDownload?: number;
      authMode?: number;
      title?: string;
      startTime?: string;
      passcode?: string;
      watermark?: string;
      allowQuestion?: boolean;
      domains?: string[];
    }
    const data: UpdatePresentation = {
      title: shareLinkItems.title,
    };

    if (shareLinkItems.expiry) {
      data.expiry = shareLinkItems.expiry;
    }

    if (shareLinkItems.canDownload === 1) {
      data.canDownload = 1;
    } else if (shareLinkItems.canDownload === 0) {
      data.canDownload = 0;
    }

    if (shareLinkItems.authMode) {
      data.authMode = shareLinkItems.authMode;
    }
    if (shareLinkItems.startTime) {
      data.startTime = shareLinkItems.startTime;
    }
    if (shareLinkItems.passcode) {
      data.passcode = shareLinkItems.passcode;
    }
    if (shareLinkItems.watermark) {
      data.watermark = shareLinkItems.watermark;
    }
    if (shareLinkItems.domains) {
      data.domains = shareLinkItems.domains;
    }

    setIsLoading(true);
    dispatch(updatePresentation({ presentationId, data })).then((response) => {
      const { type } = response;
      if (type.includes('fulfilled')) {
        toast.success('Updated successfully!');
        dispatch(getShareHistory(spaceId));
      } else {
        toast.error('Update failed!');
      }
      setIsLoading(false);
      onCloseEditBriefcaseModal();
    });

    // Checks to see removed emails and add new emails

    // Checks for newly added members
    const newlyAddedEmails = shareLinkItems.emails.filter(
      (email) => !presentationGuests.some((email_) => email === email_)
    );

    // checks for removed members
    const removedEmails = presentationGuests.filter(
      (email) => !shareLinkItems.emails.some((email_) => email === email_)
    );

    if (newlyAddedEmails.length > 0) {
      dispatch(addGuests({ presentationId, data: newlyAddedEmails }));
    }

    if (removedEmails.length > 0) {
      dispatch(removeGuests({ presentationId, data: removedEmails }));
    }
  };

  const handleKeyDownEmails = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value.trim();
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      if (email === ',') {
        setEmailName('');
        return;
      }
      if (email) {
        setShareLinkItems({
          ...shareLinkItems,
          emails: [...shareLinkItems.emails, email],
        });
      }
      setEmailName('');
    }
  };

  const handleKeyDownDomains = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const domain = (e.target as HTMLInputElement).value.trim();
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      if (domain === ',') {
        setDomainName('');
        return;
      }
      if (domain) {
        setShareLinkItems({
          ...shareLinkItems,
          domains: [...shareLinkItems.domains, domain],
        });
      }
      setDomainName('');
    }
  };

  const removeDomain = (index: number) => {
    const updatedDomains = shareLinkItems.domains.filter((_, i) => i !== index);
    setShareLinkItems({ ...shareLinkItems, domains: updatedDomains });
  };

  const removeEmail = (index: number) => {
    const updatedEmails = shareLinkItems.emails.filter((_, i) => i !== index);
    setShareLinkItems({ ...shareLinkItems, emails: updatedEmails });
  };

  return (
    <section className='pt-5 pb-20 min-h-[100vh] relative'>
      {!presentationDetails ? (
        <Loader />
      ) : (
        <section>
          <div className='w-full border-b pb-2'>
            <h1 className='text-xl font-medium'>Edit briefcase</h1>
          </div>
          <section className=''>
            <div className='flex flex-col gap-y-1 mt-5'>
              <label htmlFor='space' className='font-normal text-gray700'>
                Enter Presentation name
              </label>
              <input
                type='text'
                name='title'
                required
                value={shareLinkItems?.title || ''}
                placeholder='e.g for external board members'
                className='py-3 pl-4 text-sm rounded-lg outline-none bg-orange10'
                onChange={(e) => {
                  handleShareBriefcaseUserInputs(e);
                  if (e.target.value.trim().length > 0) {
                    setDisableButton(false);
                  } else {
                    setDisableButton(true);
                  }
                }}
              />
            </div>
            {/* share link */}
            <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
              <div className='w-full flex justify-between items-center mt-3'>
                <p className='text-base'>Share link with anyone</p>
                <label className='inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    checked={shareLinkItems.openLink}
                    className='sr-only peer'
                    onChange={() => {
                      setShareLinkItems({
                        ...shareLinkItems,
                        openLink: !shareLinkItems.openLink,
                      });
                    }}
                  />
                  <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                </label>
              </div>
              {/* add email */}
              <div className='grid grid-cols-9 gap-x-2 mt-3'>
                <label
                  htmlFor='add-email'
                  className='col-span-6 rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
                >
                  <img src={sms} alt='email' className='w-4 h-4' />
                  <input
                    type='text'
                    id='add-email'
                    placeholder='add email'
                    className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                    onKeyDown={handleKeyDownEmails}
                    value={emailName || ''}
                    onChange={(e) => setEmailName(e.target.value)}
                  />
                </label>
                <button className='flex gap-x-2 items-center px-3 py-2 col-span-3 w-full bg-[#F5F5F5] rounded-md'>
                  <img src={uploadBulk} alt='upload' className='h-4 w-4' />
                  <p className='text-sm text-[#6B6B6B]'>upload bulk</p>
                </button>
              </div>
              {/* email... */}
              <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'>
                {shareLinkItems.emails?.map((email, index) => {
                  return (
                    <div
                      key={index}
                      className='flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180'
                    >
                      <p>{email}</p>
                      <i
                        className='text-xs cursor-pointer pi pi-times mt-[2px]'
                        onClick={() => removeEmail(index)}
                      ></i>
                    </div>
                  );
                })}
              </section>
            </section>

            {/* use password */}
            <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
              <div className='w-full flex justify-between items-center mt-3'>
                <p className='text-base'>Use password</p>
                <label className='inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    checked={usePasscode}
                    onChange={() => {
                      if (usePasscode) {
                        setShareLinkItems({
                          ...shareLinkItems,
                          passcode: '',
                        });
                        setUsePasscode(false);
                      } else {
                        setUsePasscode(true);
                      }
                    }}
                    className='sr-only peer'
                  />
                  <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                </label>
              </div>
              {/* enter password */}
              {usePasscode && (
                <div className='w-full mt-3'>
                  <label
                    htmlFor='add-email'
                    className='w-full rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
                  >
                    <img src={sms} alt='email' className='w-4 h-4' />
                    <input
                      type='text'
                      id='add-email'
                      placeholder='Enter password'
                      className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                      name='passcode'
                      value={shareLinkItems.passcode || ''}
                      onChange={(e) => {
                        setUsePasscode(true);
                        handleShareBriefcaseUserInputs(e);
                      }}
                    />
                  </label>
                </div>
              )}
            </section>

            {/* Link time */}
            <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
              <>
                {/* link expires */}
                <div className='w-full flex justify-between items-center'>
                  <p className='text-base'>Set link expiry date</p>
                  <label className='inline-flex items-center cursor-pointer'>
                    <input
                      type='checkbox'
                      checked={linkExpires}
                      className='sr-only peer'
                      onChange={() => {
                        if (linkExpires) {
                          setLinkExpires(false);
                          setShareLinkItems({ ...shareLinkItems, expiry: '' });
                        } else {
                          setLinkExpires(true);
                        }
                      }}
                    />
                    <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                  </label>
                </div>
                {/* expires */}
                {linkExpires && (
                  <div className='w-full mt-3 grid grid-cols-2 gap-x-2'>
                    <div
                      className={`col-span-1 bg-[#F5F5F5] w-full py- rounded-md px flex items-center gap-x-2 ${
                        !linkExpires && 'pointer-events-none'
                      }`}
                    >
                      <DatePicker
                        showTime
                        onOk={onOkExpiry}
                        minDate={dayjs()}
                        suffixIcon={
                          <img
                            src={calender}
                            alt='calender'
                            className='w-4 h-4'
                          />
                        }
                        format='YYYY-MM-DD'
                        className='bg-[#F5F5F5] w-full h-full border-none outline-none'
                        defaultValue={dayjs.utc(shareLinkItems.expiry) as any}
                      />
                    </div>
                    <div className='col-span-1 bg-[#F5F5F5] w-full py-3 rounded-md px-3 flex items-center gap-x-2'>
                      <img src={timer} alt='timer' />
                      <p className='text-sm text-[#6B6B6B]'>
                        {shareLinkItems.expiry === ''
                          ? '0:00 AM'
                          : dayjs(shareLinkItems.expiry).format('h:mm A')}
                      </p>
                    </div>
                  </div>
                )}
              </>
            </section>

            {/* Allow downloads */}
            <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
              <div className='w-full flex justify-between items-center'>
                <p className='text-base'>Allow downloads</p>
                <label className='inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    onChange={() => {
                      setShareLinkItems({
                        ...shareLinkItems,
                        canDownload: shareLinkItems.canDownload === 0 ? 1 : 0,
                      });
                    }}
                    className='sr-only peer'
                    checked={shareLinkItems.canDownload === 0 ? false : true}
                  />

                  <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                </label>
              </div>
            </section>

            {/* More security */}
            <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
              <h2 className='text-base font-semibold'>More security</h2>
              <div className='w-full flex justify-between items-center mt-3'>
                <p className='text-base'>Restrict domain</p>
                <label className='inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    checked={shouldRestrictDomains}
                    onChange={() => {
                      if (shouldRestrictDomains) {
                        setShouldRestrictDomains(false);
                      } else {
                        setShouldRestrictDomains(true);
                      }
                    }}
                    className='sr-only peer'
                  />
                  <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                </label>
              </div>
              {/* add domain */}
              <div className='grid grid-cols-9 gap-x-2 mt-3'>
                <label
                  htmlFor='add-domain'
                  className='col-span-6 rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
                >
                  <img src={sms} alt='domain' className='w-4 h-4' />
                  <input
                    type='text'
                    id='add-email'
                    placeholder='Add domain'
                    className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                    value={domainName}
                    onKeyDown={handleKeyDownDomains}
                    onChange={(e) => setDomainName(e.target.value)}
                  />
                </label>
                <button className='flex gap-x-2 items-center px-3 py-2 col-span-3 w-full bg-[#F5F5F5] rounded-md'>
                  <img src={uploadBulk} alt='upload' className='h-4 w-4' />
                  <p className='text-sm text-[#6B6B6B]'>upload bulk</p>
                </button>
              </div>
              {/* email... */}
              <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'>
                {shareLinkItems.domains?.map((domain, index) => {
                  return (
                    <div
                      key={index}
                      className='flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180'
                    >
                      <p>{domain}</p>
                      <i
                        className='text-xs cursor-pointer pi pi-times mt-[2px]'
                        onClick={() => removeDomain(index)}
                      ></i>
                    </div>
                  );
                })}
              </section>
            </section>

            <section className='w-full flex justify-end mt-5'>
              <button
                className={`text-white h-10 px-5 flex gap-x-2 items-center justify-center rounded-md min-w-[100px] 
             ${disableButton ? 'bg-orange-300' : 'bg-primary'}
             `}
                onClick={() => {
                  handleUpdatePresentation();
                }}
                disabled={disableButton}
              >
                {!isLoading ? (
                  <span className='flex items-center gap-x-2'>
                    <p className='font-semibold'>Save changes</p>
                  </span>
                ) : (
                  <ClipLoader size={12} color='white' />
                )}
              </button>
            </section>
          </section>
        </section>
      )}
    </section>
  );
}
