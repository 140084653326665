import ErrorPage from 'views/ErrorPage';
import AdminLayout from 'components/UI/AdminLayout';
import Dashboard from 'views/Admin/Dashboard';
import Presentation from 'views/Admin/Presentation';
import PresentationDetailsNew from 'views/Admin/Presentation/PresentationDetailsNew';
import PresentationMetrics from 'views/Admin/Presentation/PresentationMetrics';
import MyDocuments from 'views/Admin/Documents';
import Team from 'views/Admin/Team';
import TeamPlanCheckout from 'views/Admin/Team/Checkout';
import Settings from 'views/Admin/Settings';
import ProtectedRoute from 'routes/ProtectedRoute';
import briefcaseRoutes from './adminRoutes/briefcaseRoutes';
import workspaceRoutes from './adminRoutes/workspaceRoutes';
import eventsRoutes from './adminRoutes/eventsRoutes';
import directoryRoutes from './adminRoutes/directoryRoutes';
import pollsRoutes from './adminRoutes/pollsRoutes';

const adminRoutes = [
  {
    path: '/admin',
    element: (
      <ProtectedRoute>
        <AdminLayout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: 'dashboard',
        element: <Dashboard />,
      },

      {
        path: 'mypresentations',
        element: <Presentation />,
      },
      {
        path: 'presentation-details_',
        element: <PresentationDetailsNew />,
      },

      briefcaseRoutes,
      workspaceRoutes,
      directoryRoutes,
      eventsRoutes,
      pollsRoutes,

      {
        path: 'presentation-metric/:presentationId',
        element: <PresentationMetrics />,
      },

      {
        path: 'mydocuments',
        element: <MyDocuments />,
      },

      {
        path: 'team',
        element: <Team />,
      },

      {
        path: 'checkout/:id',
        element: <TeamPlanCheckout />,
      },

      {
        path: 'settings',
        element: <Settings />,
      },
    ],
  },
];

export default adminRoutes;
