import bell from 'assets/svg/bell.svg';
import menu from 'assets/svg/hamburger.svg';
import plusWhite from 'assets/svg/plus-white.svg';
import { Modal } from 'antd';
import PollName from 'views/Admin/Polls/Modals/PollName';
import { useState } from 'react';

// Libraries

export default function Navbar() {
  const [openCreatePollModal, setOpenCreatePollModal] = useState(false);

  const handleCancel = () => {
    setOpenCreatePollModal(false);
  };

  return (
    <nav className='fixed top-0  lg:left-[18%] left-0 z-30 lg:w-[82%] w-full h-20 bg-white pt-4'>
      <section className='w-[95%] mx-auto'>
        <div className='flex items-center justify-between  flex-col lg:flex-row gap-y-4'>
          <section className='flex items-center justify-between w-full lg:w-[30%]'>
            <img src={menu} alt='open sidenav' className='block lg:hidden' />

            <div className='leading-tight text-right lg:text-left lg:self-start'>
              <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                Polls
              </h1>
              <p className='lg:text-sm text-xs mt-[-3px]'>
                Create engagement polls
              </p>
            </div>
          </section>

          <section className='gap-x-2 lg:flex hidden items-center justify-between'>
            <section onClick={(e) => e.preventDefault()}>
              <div
                className='bg-black text-white text-sm h-8 rounded-md flex gap-x-1 items-center px-5 cursor-pointer'
                onClick={() => setOpenCreatePollModal(true)}
              >
                <img src={plusWhite} alt='ellipsis icon' />
                <p>Create new poll</p>
              </div>
            </section>
            <div className='px-2 py-2 border rounded-md cursor-pointer bg-gray20'>
              <img src={bell} alt='notification' className='w-4' />
            </div>
          </section>
        </div>
      </section>

      <Modal
        footer={null}
        centered={false}
        open={openCreatePollModal}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
        closable={false}
      >
        <PollName closeModal={handleCancel} />
      </Modal>
    </nav>
  );
}
