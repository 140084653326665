import AuthRoute from 'routes/AuthRoute';
import { RedirectFromLogin } from '../RedirectFromLogin';
import Login from 'views/Authentication/Login';
import ErrorPage from 'views/ErrorPage';
import Register from 'views/Authentication/Register';
import { ResetPassword } from 'views/Authentication/ResetPassword';
import OTP from 'views/Authentication/OTP';
import { ForgotPassword } from 'views/Authentication/ForgotPassword';
import Onboarding from 'views/Authentication/Onboarding';

const authRoutes = [
  {
    path: '/login',
    element: (
      <RedirectFromLogin>
        <AuthRoute>
          <Login />
        </AuthRoute>
      </RedirectFromLogin>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: '/signup',
    element: (
      <AuthRoute>
        <Register />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/ResetPassword',
    element: (
      <AuthRoute>
        <ResetPassword />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: '/OTP',
    element: (
      <AuthRoute>
        <OTP />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/forgot-password',
    element: (
      <AuthRoute>
        <ForgotPassword />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: '/onboarding',
    element: <Onboarding />,
    errorElement: <ErrorPage />,
  },
];

export default authRoutes;
