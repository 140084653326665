import imageIcon from 'assets/svg/image-icon.svg';
import { PollQuestion, PollQuestionOption } from 'interfaces/PollSetup';
import { Dispatch, SetStateAction } from 'react';
import { ColorPicker } from 'antd';
// import ColorPickerIcon from 'assets/icons/ColorPickerIcon';
import { defaultOptionsColour } from 'utils/randomBgColour';

interface Props {
  options: PollQuestionOption[];
  option: PollQuestionOption;
  index: number;
  activeQuestion: PollQuestion;
  setActiveQuestion: Dispatch<SetStateAction<PollQuestion>>;
}

export default function QuestionOption({
  options,
  option,
  index,
  activeQuestion,
  setActiveQuestion,
}: Props) {
  return (
    <section className='w-full'>
      <label
        htmlFor=''
        className='flex gap-x-3 h-11 items-center justify- w-full px-3 rounded-lg border border-[#CBCBCB] mt-2 overflow-hidden'
      >
        <ColorPicker
          children={
            <div className='w-5 h-[21px] cursor-pointer'>
              <div className={`stroke-[${option.color}]`}>
                {/*<ColorPickerIcon*/}
                {/*  color={*/}
                {/*    option.color && option.color.length*/}
                {/*      ? option.color*/}
                {/*      : defaultOptionsColour[index]*/}
                {/*  }*/}
                {/*/>*/}
              </div>
            </div>
          }
          onChange={(value, colour) => {
            setActiveQuestion((prevQuestion) => {
              return {
                ...prevQuestion,
                options: prevQuestion.options.map((option, idx) => {
                  if (idx === index) {
                    return { ...option, color: colour };
                  } else {
                    return option;
                  }
                }),
              };
            });
          }}
        />

        <input
          type='text'
          className='h-full w-full outline-none border-none'
          placeholder='Type another choice '
          value={option.text}
          onChange={(e) => {
            setActiveQuestion((prevQuestion) => {
              return {
                ...prevQuestion,
                options: prevQuestion.options.map((option, idx) => {
                  if (idx === index) {
                    return { ...option, text: e.target.value };
                  } else {
                    return option;
                  }
                }),
              };
            });
          }}
        />
        <div
          onClick={() => {
            setActiveQuestion((prevQuestion) => {
              return {
                ...prevQuestion,
                options: prevQuestion.options.map((option, idx) => {
                  if (idx === index) {
                    return { ...option, isSelected: true };
                  } else {
                    return { ...option, isSelected: false };
                  }
                }),
              };
            });
          }}
          className='cursor-pointer'
        >
          <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M16.7045 4.71597C17.034 4.967 17.0976 5.43759 16.8466 5.76707L8.84657 16.2671C8.71541 16.4392 8.51627 16.5463 8.30033 16.5608C8.08439 16.5754 7.87271 16.4959 7.71967 16.3429L3.21967 11.8429C2.92678 11.55 2.92678 11.0751 3.21967 10.7822C3.51256 10.4893 3.98744 10.4893 4.28033 10.7822L8.17351 14.6754L15.6534 4.85801C15.9045 4.52853 16.3751 4.46493 16.7045 4.71597Z'
              fill={option.isSelected ? '#3AA76D' : '#333333'}
            />
          </svg>
        </div>
        <img src={imageIcon} alt='type another choice' />
      </label>
    </section>
  );
}
