import { DatePicker, TimePicker } from 'antd';
import arrowSquareRight from 'assets/svg/arrow-square-right.svg';
import calender from 'assets/image/calendar.png';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createEvent, getEvent, updateEvent } from 'store/Events/EventsSlice';
import { AppDispatch, RootState } from 'store/store';
import utc from 'dayjs/plugin/utc';
import dayjs, { Dayjs } from 'dayjs';
import { getTeamsState } from 'store/Teams/teamsSlice';
import arrowRightGray from 'assets/svg/arrow-square-right-gray.svg';
import { useNavigate, useParams } from 'react-router-dom';
import InputTitle from './InputTitle';
import { Flow } from 'interfaces/Events/EventsInterfaces';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

interface Props {
  flow: Flow;
  setFlow: Dispatch<SetStateAction<Flow>>;
}

export default function EventDetails({ flow, setFlow }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { teamInfo } = useSelector(getTeamsState);
  const { eventId } = useParams();

  const getUpcomingEventTime = useCallback(() => {
    const now = dayjs();

    // Determine the next increment of :30 or :00
    const minutes = now.minute();
    const roundedTime =
      minutes < 30
        ? now.minute(30).second(0).millisecond(0) // Set to :30
        : now.add(1, 'hour').minute(0).second(0).millisecond(0); // Set to next hour :00

    const utcDateTime = roundedTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    return utcDateTime; // Format in local time
  }, []);

  const emptyEventDetails = {
    title: '',
    description: '',
    startDate: getUpcomingEventTime(),
    teamId: '',
  };
  // States
  const [eventInputDetails, setEventInputDetails] = useState(emptyEventDetails);
  const [disableButton, setDisableButton] = useState(true);
  const [selectedDate, setSelectedDate] = useState<string | null>(
    getUpcomingEventTime()
  );
  const [selectedTime, setSelectedTime] = useState<string | null>(
    getUpcomingEventTime()
  );

  const eventDetails = useSelector(
    (state: RootState) => state.events.event.data
  );

  useEffect(() => {
    if (eventId && eventDetails) {
      setEventInputDetails({
        title: eventDetails.title,
        description: eventDetails.description,
        teamId: eventDetails.teamId,
        startDate: eventDetails.startDate,
      });
      //

      const fetchedTime = dayjs.utc(eventDetails.startDate);

      const combinedDateTime = fetchedTime
        .hour(fetchedTime.hour())
        .minute(fetchedTime.minute())
        .second(fetchedTime.second());

      setSelectedDate(fetchedTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
      setSelectedTime(combinedDateTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
    }
  }, [eventDetails, eventId]);

  useEffect(() => {
    const teamId = teamInfo.data?.id;
    if (teamId) {
      setEventInputDetails((prevDetails) => {
        // Only update if the teamId is different
        if (prevDetails.teamId !== teamId) {
          return { ...prevDetails, teamId };
        }
        return prevDetails;
      });
    }
  }, [teamInfo]);

  useEffect(() => {
    let hasEmptyField = false;

    for (const [, value] of Object.entries(eventInputDetails)) {
      // key, value
      if (value === '') {
        hasEmptyField = true;
      }
    }

    if (!hasEmptyField) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [eventInputDetails]);

  const handleCombineDateTime = ({
    selectedDate,
    selectedTime,
  }: {
    selectedDate?: string;
    selectedTime?: string;
  }) => {
    if (!selectedDate || !selectedTime) return;
    const selectedDate_ = dayjs.utc(selectedDate);
    const selectedTime_ = dayjs.utc(selectedTime);
    const combinedDateTime = selectedDate_
      .hour(selectedTime_.hour())
      .minute(selectedTime_.minute())
      .second(selectedTime_.second());

    setEventInputDetails((prevDetails) => {
      return {
        ...prevDetails,
        startDate: combinedDateTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      };
    });
  };

  const handleCreateEvent = () => {
    if (!eventId) {
      dispatch(createEvent(eventInputDetails)).then(({ type, payload }) => {
        if (type.includes('fulfilled')) {
          const teamId = teamInfo.data?.id;
          if (teamId) {
            dispatch(getEvent({ eventId: payload.id, teamId }));
          }
          navigate(`/admin/events/create-event/${payload.id}`);
          setFlow('addpresenter');
        }
      });
    } else {
      dispatch(
        updateEvent({
          description: eventInputDetails.description,
          eventId: eventId,
          startDate: eventInputDetails.startDate,
          title: eventInputDetails.title,
          teamId: eventInputDetails.teamId,
        })
      ).then(({ type, payload }) => {
        if (type.includes('fulfilled')) {
          const teamId = teamInfo.data?.id;
          if (teamId) {
            dispatch(getEvent({ eventId: payload.id, teamId }));
          }
          navigate(`/admin/events/create-event/${payload.id}`);
          setFlow('addpresenter');
        }
      });
    }
  };

  const handleEventDetailsInput = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    setEventInputDetails({ ...eventInputDetails, [name]: value });
  };

  const handleDateChange = (value: Dayjs | null) => {
    if (!value) {
      setEventInputDetails((prevDetails) => {
        return { ...prevDetails, startDate: '' };
      });
      setSelectedDate(null);
      return;
    } else {
      const formatteValue = value.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      setSelectedDate(formatteValue);
      if (selectedTime) {
        handleCombineDateTime({ selectedDate: formatteValue, selectedTime });
      }
    }
  };

  const handleTimeChange = (value: Dayjs | null) => {
    if (!value) {
      setEventInputDetails((prevDetails) => {
        return { ...prevDetails, startDate: '' };
      });
      setSelectedTime(null);
      return;
    } else {
      const formatteValue = value.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      setSelectedTime(formatteValue);
      if (selectedDate) {
        handleCombineDateTime({ selectedTime: formatteValue, selectedDate });
      }
    }
  };

  return (
    <section className='rounded-xl border border-[#EEEEEE] p-3'>
      <section>
        <form>
          <h1 className='text-2xl font-semibold'>Event Details</h1>
          <p className='text-sm'>Tell us about your events</p>

          <div className='w-full grid grid-cols-1 mt-5'>
            <label htmlFor='title'>
              <InputTitle title='Event Title' />

              <input
                type='text'
                placeholder='Enter your event title'
                className='w-full bg-[#F6F3EF] h-[50px] text-sm px-3 rounded-lg text-[#667085] font-medium'
                name='title'
                value={eventInputDetails.title || ''}
                onChange={(e) => {
                  handleEventDetailsInput(e);
                }}
              />
            </label>
          </div>

          <div className='w-full grid grid-cols-[2fr_1fr] mt-5 gap-5'>
            <label htmlFor='title'>
              <InputTitle title='Event Date' />

              <div
                className={`w-full bg-[#F6F3EF] h-[50px] text-sm rounded-lg flex items-center gap-x-2 `}
              >
                {eventId && eventDetails && eventDetails.startDate ? (
                  <DatePicker
                    onChange={handleDateChange}
                    minDate={dayjs()}
                    suffixIcon={
                      <img src={calender} alt='calender' className='w-4 h-4' />
                    }
                    format='YYYY-MM-DD'
                    className='bg-[#F6F3EF] w-full h-full border-none outline-none text-[#667085] font-medium'
                    defaultValue={dayjs.utc(eventDetails.startDate) as any}
                    placeholder='Select event date'
                  />
                ) : (
                  <DatePicker
                    onChange={handleDateChange}
                    minDate={dayjs()}
                    suffixIcon={
                      <img src={calender} alt='calender' className='w-4 h-4' />
                    }
                    format='YYYY-MM-DD'
                    className='bg-[#F6F3EF] w-full h-full border-none outline-none text-[#667085] font-medium'
                    placeholder='Select event date'
                    defaultValue={dayjs.utc(selectedDate) as any}
                  />
                )}
              </div>
            </label>
            <label htmlFor='title'>
              <InputTitle title='Event Time' />

              <div className='bg-[#F6F3EF] w-full rounded-md flex items-center justify-between  h-[50px]'>
                {eventId && eventDetails && eventDetails.startDate ? (
                  <TimePicker
                    onChange={handleTimeChange}
                    defaultOpenValue={dayjs('00:00', 'HH:mm')}
                    className='bg-transparent w-full h-full border-none outline-none text-[#667085] font-medium'
                    suffixIcon={
                      <img src={calender} alt='calender' className='w-4 h-4' />
                    }
                    defaultValue={dayjs.utc(eventDetails.startDate) as any}
                    placeholder='0:00 AM'
                    format={'HH:mm'}
                    needConfirm={false}
                  />
                ) : (
                  <TimePicker
                    onChange={handleTimeChange}
                    defaultOpenValue={dayjs(getUpcomingEventTime(), 'HH:mm')}
                    className='bg-transparent w-full h-full border-none outline-none text-[#667085] font-medium'
                    suffixIcon={
                      <img src={calender} alt='calender' className='w-4 h-4' />
                    }
                    defaultValue={dayjs.utc(selectedTime) as any}
                    placeholder='0:00 AM'
                    format={'HH:mm'}
                    needConfirm={false}
                  />
                )}
              </div>
            </label>
          </div>

          <div className='w-full grid grid-cols-1 mt-5'>
            <label htmlFor='title'>
              <InputTitle title='Description' />

              <textarea
                value={eventInputDetails.description || ''}
                placeholder='what is your event about?'
                className='w-full bg-[#F6F3EF] h-[100px] text-sm p-3 rounded-lg resize-none text-[#667085] font-medium'
                name='description'
                onChange={(e) => {
                  handleEventDetailsInput(e);
                }}
              />
            </label>
          </div>

          <div className='w-full flex justify-end mt-3'>
            <button
              className={`${
                disableButton
                  ? 'bg-[#CBCBCB] text-[#AFAFAF]'
                  : 'bg-black text-white'
              } h-10 px-3 rounded-lg`}
              onClick={(e) => {
                e.preventDefault();
                handleCreateEvent();
              }}
              disabled={disableButton}
            >
              <div className='flex gap-x-2 items-center text-sm'>
                <p className={``}>Continue</p>
                <img
                  src={disableButton ? arrowRightGray : arrowSquareRight}
                  alt='continue'
                />
              </div>
            </button>
          </div>
        </form>
      </section>
    </section>
  );
}
