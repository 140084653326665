import {UserPresentationWrapper} from "../../../components/UI/wrapper/UserPresentationWrapper";
import {
    DesktopViewerPresentationScreen
} from "../../../components/UI/Presentation/viewer/DesktopViewerPresentationScreen";
import {DesktopViewerController} from "../../../components/UI/Presentation/viewer/DesktopViewerController";
import {DesktopViewerMenuType} from "../../../interfaces/interfaces-ui";
import React, {useEffect, useRef, useState} from "react"
import {ViewerUsers} from "../../../components/UI/Presentation/viewer/viewer-users";
import {DesktopViewerPlaylist} from "../../../components/UI/Presentation/viewer/DesktopViewerPlaylist";
import {DesktopViewerShare} from "../../../components/UI/Presentation/viewer/ViewerDesktopShare";
import {usePresentationConnectSignalR} from "../../../hooks/SignalR/usePresentationConnectSignalR";
import {
    AgendaInterface,
    EventsInterface,
    InnerPresentationInterface,
    PresentationDisplayInterface,
    PresentationDocInterface,
    SlideInterface,
    UnlockedInterface,
    UserInterface
} from "../../../interfaces/interfaces-data";
import instance from "../../../lib/axiosInterceptor";
import {useNavigate, useParams} from "react-router-dom";
import {getConnectionId} from "../../../utils/localStorageServices";
import {documentHasSlides} from "../../../utils/documentHasSlides";
import {imageStream} from "../../../utils/imageStream";
import {toast} from "sonner"
import {DesktopViewerQuestion} from "../../../components/UI/Presentation/viewer/DesktopViewerQuestion";
import {CButton} from "../../../components/UI/Button/Buttons";
import {ModalWrapper} from "../../../components/UI/wrapper/modal-wrapper";

export const UserPresentationScreen = () => {
    const [presentationDetails, setPresentationDetails] = useState<InnerPresentationInterface>()
    const [presentationId, setPresentationId] = useState("")
    const [rearrangedDoc, setRearrangedDoc] = useState<PresentationDocInterface[]>([])
    const [presentationDisplayDetails, setPresentationDisplayDetails] = useState<PresentationDisplayInterface>()
    const presentationDisplayDetailsRef = useRef<PresentationDisplayInterface>({});

    const [firstChunkLoading, setFirstChunkLoading] = useState(true);
    const [activeDocumentId, setActiveDocumentId] = useState("");
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [presenterActiveSlideIndex, setPresenterActiveSlideIndex] = useState(0);
    const [presenterActiveDocument, setPresenterActiveDocument] = useState("")
    const userOutOfSyncRef = useRef(false)
    const [userOutOfSyncState, setUserOutOfSyncState] = useState(false)
    const navigate = useNavigate()
    const [presentationDetailsLoaded, setPresentationDetailsLoaded] = useState(false);
    const [overallDocLoadedDetails, setOverallDocLoadedDetails] = useState({
        loaded: false,
        noDownloaded: 0,
        justLoadedDocId: ""
    })
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [eventDetails, setEventDetails] = useState<EventsInterface>();
    const [upcomingEvent, setUpcomingEvent] = useState<AgendaInterface>()
    const [timeLeft, setTimeLeft] = useState(45)
    const [eventPresentationEnded, setEventPresentationEnded] = useState(false);
    const [activeEventId, setActiveEventId] = useState("")
    const {friendlyId} = useParams();
    const testRef = useRef(0)
    const [desktopMenu, setDesktopMenu] = useState<DesktopViewerMenuType>("playlist");
    const [users, setUsers] = useState<UserInterface[]>([]);
    const [canShowActiveImage, setCanShowActiveImage] = useState(true)
    const unlockedSlidesRef = useRef<UnlockedInterface>({})
    const [idleState, setIdleState] = useState(false);
    const paramGetter = new URLSearchParams(window.location.search);
    const isEvent = !!paramGetter.get("isEvent");
    const [inNextPresentation, setInNextPresentation] = useState(false)
    const [agendaPlayList, setAgendaPlaylist] = useState<InnerPresentationInterface[]>([])
    const [agendaPresentationDisplayDetails, setAgendaPresentationDisplayDetails] = useState<PresentationDisplayInterface>();
    const sessionUpdatedRef = useRef(false);
    const [anotherAgendaDocLoading, setAnotherAgendaDocLoading] = useState(false);
    const [outOfSyncPresentationDetails, setOutOfSyncPresentationDetails] = useState<PresentationDisplayInterface>({})
    const outOfSyncPresentationRef = useRef<PresentationDisplayInterface>({})
    const [outOfSyncActiveIndex, setOutOfSyncActiveIndex] = useState(0);
    const [outOfSyncActiveDocumentId, setOutOfSyncActiveDocumentId] = useState("");
    const [outOfCurrentAgenda, setOutOfCurrentAgenda] = useState(false);


    // First Step is to initiate the signalRConnection
    const {connection, connected, connecting, reconnecting, error} = usePresentationConnectSignalR();
    // "Use the connected for when you need the connection to act as a state , pretty much"
    // Handle Download of Playlist

    // Api Calls

    const getEvents = async () => {
        try {
            const result = await instance.get(`/Events/${friendlyId}?presentaConnectionId=${getConnectionId()}`)
            setEventDetails(result.data)
            if (result.data.currentPresentation) {
                // Now , the next thing i want to do here is to basically get the presentation details, and then see the status
                const presentationResult = await instance.get(`/presentation/${result.data.currentPresentation}?presentaConnectionId=${getConnectionId()}`);
                // Now check to see the status of the presentation
                const currentPresenationStatus = presentationResult.data.presentation.status
                console.log(currentPresenationStatus, "Current Presentation Status here");
                if (currentPresenationStatus === 4) {
                    // Now , if it's not the last file then the upcoming event would be the next file , if it's the last file then we set the eneded state to tbe true
                    const agendaLength = result.data.agenda.length
                    const val_index = result.data.agenda.findIndex((item: AgendaInterface) => {
                        return item.presentationId === result.data.currentPresentation
                    })
                    if (agendaLength === val_index + 1) {
                        setEventPresentationEnded(true)
                    } else if (agendaLength > val_index + 1) {
                        // Set the next upcoming event
                        setUpcomingEvent(result.data.agenda[val_index + 1])
                    }
                    setIdleState(true)
                    return
                }

                setIdleState(false)
                if (!sessionUpdatedRef.current) {
                    getPresentationDetails(result.data.currentPresentation)
                }

            } else {
                // The current session should be null when the event has not ideally started
                setUpcomingEvent(result.data.agenda[0])
                setIdleState(true);
            }
            // setActiveDocLoading(false)
        } catch (e) {
            console.log(e)
        } finally {

        }
    }

    // connection.on("EndEvent", ((id) => {
    //     console.log("Got inside hereeeee for some reason")
    //     if (eventDetails?.role === "EventOwner") {
    //         console.log("i Got in heree")
    //         navigate("/admin/events")
    //     } else {
    //         console.log("I got inside the else block here")
    //         navigate(`admin/presentation-details_?q=${presentationId}`)
    //     }
    // }))

    const getDocumentDetails = async (docId: string) => {
        try {
            const result = await instance.get(`/Document/${docId}`)
            if (result.data.slides.length && documentHasSlides((result.data.url))) {
                const reArrangedData = result.data.slides.sort(
                    (a: SlideInterface, b: SlideInterface) => {
                        if (a.position > b.position) {
                            return 1;
                        } else if (a.position < b.position) {
                            return -1;
                        }
                        return 0;
                    }
                );

                // Get the current slide in the document that the presenter is on
                // Find the index and then load five from it and then load the remaining after you have availed the user with the 10 slides
                // const activeSlide = presentationDetails.p

                // get the length of the slide , the and then get the current slide , and see if you can load five from the current Slide


                //save the expected data format for the data here inside the data that is attached to the useRef, that way it can be easily updated once any change is noticed or whatnot
                presentationDisplayDetailsRef.current[docId] = reArrangedData;


                const dataLength = presentationDisplayDetailsRef.current[docId].length;
                // see if the remaining slide is up to 5 from the current index, then update the slides with that information
                const currentSlideIndex = presentationDetails?.presentation.currentSlide ?? 0

                const remainingSlides = (dataLength - 1) - currentSlideIndex
                // Basically fetching the current document and five forward
                if (remainingSlides === 0) {
                    // then load just the current document and then start loading the remaining documents

                    const imageUrlVal = await imageStream(presentationDisplayDetailsRef.current[docId][currentSlideIndex].id);
                    presentationDisplayDetailsRef.current[docId][currentSlideIndex].imgUrl = imageUrlVal ?? ""
                    // The only reason , i am doing this is because the backend does not send a correct data
                    if (docId === activeDocumentId) {
                        presentationDisplayDetailsRef.current[docId][currentSlideIndex].unlocked = true
                    }
                    setPresentationDisplayDetails(presentationDisplayDetailsRef.current)
                    if (firstChunkLoading) {
                        setFirstChunkLoading(false)
                        setActiveDocumentId(docId);
                        setPresenterActiveDocument(docId)
                        setActiveSlideIndex(currentSlideIndex)
                        setPresenterActiveSlideIndex(currentSlideIndex);
                    }

                } else if (remainingSlides > 0) {
                    // load a maximum of five documents and then show the user to work with that basically
                    let indexActive = 0
                    for (indexActive; indexActive < remainingSlides; indexActive++) {
                        // when the index is zero , i want to update that the file is unlocked
                        if (indexActive === 0 && activeDocumentId === docId) {
                            presentationDisplayDetailsRef.current[docId][currentSlideIndex].unlocked = true
                        }
                        const imageUrlVal = await imageStream(presentationDisplayDetailsRef.current[docId][currentSlideIndex + indexActive].id);
                        presentationDisplayDetailsRef.current[docId][currentSlideIndex + indexActive].imgUrl = imageUrlVal ?? ""
                        // break after the indexActive is 5 
                        if (indexActive === 5) {
                            break
                        }
                    }
                    // Now that the expected Active chunk of the data is loaded , then i can easily update the ui and show the viewer something to view basically , basically always update the ref value
                    setPresentationDisplayDetails(presentationDisplayDetailsRef.current)
                    if (firstChunkLoading) {
                        setFirstChunkLoading(false)
                        setActiveDocumentId(docId);
                        setPresenterActiveDocument(docId)
                        setActiveSlideIndex(currentSlideIndex)
                        setPresenterActiveSlideIndex(currentSlideIndex);
                    }

                }
                // Now load the remaining documents
                //Note that while this ref is active, the good things is that it is used to control every update for presentationDisplayDetails and also to ensure , so in cases where there the user moves the slides and there is an update on the presentataionDisplayDetailsState through it's corresponding ref, everything that has not been added to it before would automatically be added to it.

                let index = 0
                for (const slide of presentationDisplayDetailsRef.current[docId]) {
                    if (presentationDisplayDetailsRef.current[docId][index].imgUrl) {
                        index++
                        continue
                        // that is once there is some sort of data in the url, then no api call should be made
                    }
                    const result = await imageStream(slide.id);
                    presentationDisplayDetailsRef.current[docId][index].imgUrl = result ?? ""
                    index++
                }
                // Activate unlocked slides after all the documents have been loaded , this is because, we don't want a situation where it is shown to the user that they can go backwards but then in the actual sense the image is not exactly loaded yet for them
                // Curate data from the presentation Display Details
                if (presentationDetails && unlockedSlidesRef.current?.[docId]) {
                    unlockedSlidesRef.current[docId].forEach(item => {
                        if (presentationDisplayDetailsRef.current) {
                            presentationDisplayDetailsRef.current[docId][item].unlocked = true
                        }
                    })
                }
                setPresentationDisplayDetails({...presentationDisplayDetailsRef.current});
            } else {
                // this basically implies that the document only has one slide
                const slideObject: SlideInterface[] = [
                    {
                        id: result.data.id,
                        url: result.data.url,
                        position: 1,
                        title: result.data.title,
                        note: result.data.note ?? "",
                        imgUrl: "",
                        unlocked: activeDocumentId === docId
                    }
                ]
                try {
                    slideObject[0]["imgUrl"] = (await imageStream(result.data.id)) ?? "";
                    presentationDisplayDetailsRef.current[docId] = slideObject;
                    if (firstChunkLoading) {
                        setFirstChunkLoading(false)
                        setActiveDocumentId(docId);
                        setPresenterActiveDocument(docId)
                        setActiveSlideIndex(0)
                        setPresenterActiveSlideIndex(0);
                    }
                    if (presentationDetails && unlockedSlidesRef.current?.[docId]) {
                        unlockedSlidesRef.current[docId].forEach(item => {
                            if (presentationDisplayDetailsRef.current) {
                                presentationDisplayDetailsRef.current[docId][item].unlocked = true
                            }
                        })
                    }
                    setPresentationDisplayDetails({...presentationDisplayDetailsRef.current});
                } catch (e) {
                    toast.error("FE: Error Loading Document")
                }

            }

            // Check if all the documents have been loaded
            if (Object.keys(presentationDisplayDetailsRef.current).length === presentationDetails?.doc.length) {
                setOverallDocLoadedDetails({
                    loaded: true,
                    noDownloaded: Object.keys(presentationDisplayDetailsRef.current).length,
                    justLoadedDocId: docId
                });
            } else {
                setOverallDocLoadedDetails({
                    loaded: false,
                    noDownloaded: Object.keys(presentationDisplayDetailsRef.current).length,
                    justLoadedDocId: docId
                });
            }

        } catch (e) {

        }

    }

    useEffect(() => {
        if (!overallDocLoadedDetails.loaded && !firstChunkLoading) {
            // Now , we need to load the next document
            // Now , i need to be able to call getDocumentId and with the next id
            const currentIndex = rearrangedDoc.findIndex(item => {
                return item.documentId === overallDocLoadedDetails.justLoadedDocId
            })
            // then the next details is to be obtained
            getDocumentDetails(rearrangedDoc[currentIndex + 1].documentId)
        }

    }, [overallDocLoadedDetails]);

    const getPresentationDetails = async (eventPresentationId ?: string) => {
        try {
            const result = await instance.get(`/presentation/${eventPresentationId ? eventPresentationId : friendlyId}?presentaConnectionId=${getConnectionId()}`);
            if (result.data) {
                setPresentationId(result.data.presentation.id);
                setPresentationDetails(result.data);
                unlockedSlidesRef.current = {...result.data.presentation.unlocked}
                // Get the activeDocument and then prioritize it for loading, after getting it , then prioritize the activeSlide in the document
                const activeDocFromPresenter = result.data.presentation.currentItem ?? 0;
                // it really doesn't matter how i really order this file , what basically matters is that the user has access to the file when they presenter moves, so i can basically rearrange this and then do the needful,

                // New Step : Get the inex of the active document , if it is not zero , then rearrange it to be the first document

                let presentationDocs = [...result.data.doc];
                // if the number of slides is 1 then the presenter is also going to have just one doucment active
                if (presentationDocs.length) {
                    const findActiveDocIndex = presentationDocs.findIndex(item => item.documentId === activeDocFromPresenter);
                    // i don't need to check if the index exists because , every currentItem is gotten from the doc (docId);
                    if (findActiveDocIndex !== 0 && findActiveDocIndex !== -1) {
                        // Do the rearrange, and let that be the first document that is loaded
                        const removedValue = presentationDocs.splice(findActiveDocIndex, 1);
                        presentationDocs = [...removedValue, ...presentationDocs];
                        // Now , this is modified , it can now be used to sufficiently fetch the data needed
                    }
                }
                // Now Call the getDocDetails
                setRearrangedDoc(presentationDocs)
                // call it with the first index, and then you can handle the rest later
                setActiveDocumentId(presentationDocs[0].documentId)
                setPresenterActiveDocument(presentationDocs[0].documentId)
                setPresentationDetailsLoaded(true)

            }
        } catch (e) {
            console.log(e)

        } finally {

        }
    }

    useEffect(() => {
        if (presentationDetailsLoaded && activeDocumentId) {
            getDocumentDetails(activeDocumentId);
            setPresentationDetailsLoaded(false)
        }
    }, [presentationDetailsLoaded, activeDocumentId]);

    useEffect(() => {
        // Basically Register that the user attended the presenation once, so that for
        if (connected && connection?.state === "Connected") {
            console.log(friendlyId, 1, getConnectionId())
            console.log(friendlyId, 1, getConnectionId(), isEvent, isEvent ? friendlyId : null, "Payload for invocation")

            console.log(connection.state, "Connection State", connected)
            const innerConnectionId = getConnectionId();
            console.log("Tryig to attend", getConnectionId())

            connection.invoke("Attend", friendlyId, 1, getConnectionId(), isEvent, friendlyId).catch(e => {
                console.log(e, "Error on Attend")
            })
        }
    }, [connected]);

    useEffect(() => {
        if (isEvent) {
            getEvents();
        } else {
            // The Friendly id here is the same as the event id
            getPresentationDetails(friendlyId);
        }
    }, []);


    const handleSlideMove = (id: string, int: number, docId: string) => {
        // set the active index and indicate that the slide is unlocked too,
        // if the documentId does not exist or if the document id exist but it does not have an image url , then there's a need to show a loading state instead of just showing the image property that does not exit

        // Now , what i really have to do at this point is to pretty much check if the doc id does not exist and when it doesn't exist and i pretty much want to try to have the document active in presentationDetailsRef and then also ensure that it is unlocked
        if (!presentationDisplayDetailsRef.current[docId]) {
            // Then the unlocked Slides has to be updated with the document id and the index of the files unlocked
            if (!unlockedSlidesRef.current[docId]) {
                // start from scratch and then push the current index into the ref
                unlockedSlidesRef.current[docId] = [int];
            } else {
                unlockedSlidesRef.current[docId].push(int)
            }
        }
        if (!userOutOfSyncRef.current) {
            // This is for when the user is out of sync with the presenter , the activeIndex is not automatically controlled , it only controls the viewer's screen when the user is not out of sync , but then what we do is still to keep track of the presenter's movement
            setActiveSlideIndex(int)
        }
        setPresenterActiveSlideIndex(int);
        setPresenterActiveDocument(docId)
        if (!userOutOfSyncRef.current) {
            // We use the ref becuase of the closure thingy
            setActiveDocumentId(docId)
        }
        // Now Unlock the particular Slide
        // find the position it is on the presentationDisplayDetails
        // Now check if it is unlocked or not and set the unlocked property , this is for the current File
        if (presentationDisplayDetailsRef.current && !presentationDisplayDetailsRef.current[docId][int].unlocked) {
            presentationDisplayDetailsRef.current[docId][int].unlocked = true;
            setPresentationDisplayDetails(presentationDisplayDetailsRef.current);
        }

        // Do for the previous file that was navigated from
        // if (presentationDisplayDetailsRef.current && !presentationDisplayDetailsRef.current[docId][int].unlocked) {
        //     presentationDisplayDetailsRef.current[docId][int].unlocked = true
        // }
    }

    const handleUser = (id: string, users: UserInterface[]) => {
        setUsers(users)
    }

    const handleEndPresentation = (id: string) => {

        if (isEvent && eventDetails) {
            // then switch to the next event and show the idle state and then show an idle state
            // get the presentation ended position in the eventdetail
            const agendaLength = eventDetails.agenda.length
            const endedIndex = eventDetails.agenda.findIndex(item => {
                return item.presentationId === id;
            })
            if (agendaLength - 1 > endedIndex) {
                // Then you can set the next one to be the actve index
                setUpcomingEvent(eventDetails.agenda[endedIndex + 1]);
                setIdleState(true)
                // now i have to take it further and then see if the current user in the presentation is goint ot , i need to get the connection id and then strip it off every extra character
                const connectionId = getConnectionId();
                let userEmail: string[] | string = connectionId.split("-");
                // Now , i need to get the one in the middle
                if (userEmail.length === 3) {
                    userEmail = userEmail[1];
                }
                // Now, there's a need to check if the email is in any of the email
                const checkUserInNextPresentaion = eventDetails.agenda[endedIndex + 1].collaborators.find(item => {
                    return item.email.replace(/[^a-zA-Z ]/g, '') === userEmail;
                })
                if (checkUserInNextPresentaion) {
                    // Then this implies the user is a collaborator in the next presentation , so ideally they are supposed to be redirected to the next presentation
                    setInNextPresentation(true)
                }
            } else if (agendaLength - 1 === endedIndex) {
                //Then set an idle state to show that the event has ended
                setEventPresentationEnded(true)
            }

            return
        }
        navigate("/presentation-ended");
    }

    useEffect(() => {
        if (connected && connection?.state === "Connected") {
            connection.on("MoveSlide", handleSlideMove)
            if (isEvent) {
                connection.on("PresentationStarted", (id) => {
                    window.location.reload()

                    return;
                    if (id) {
                        setIdleState(false);
                        getPresentationDetails(id);
                    }
                })

                connection.on("SessionUpdated", (id) => {
                    sessionUpdatedRef.current = true;
                    getEvents()
                })

                connection.on("EndEvent", (id) => {
                    navigate("/presentation-ended");
                })
            }


            connection.on("EndPresentation", (id) => {
                handleEndPresentation(id)
            })

            connection.on("UserList", handleUser)
            connection.on("EventResponse", (id, eventName, status, message) => {
                // This does not work because, the viewer does not trigger the e
                console.log(id, eventName, status, message)
            })
            // Try to get Users after the eventlistener has been defined
            console.log("users getting here", isEvent ? friendlyId : presentationId, isEvent)
            if ((isEvent && friendlyId) || presentationId) {
                const friendlyIdHere = isEvent ? friendlyId : presentationId;
                connection.invoke("GetUsers", friendlyIdHere, isEvent);
            }
        }
        return () => {
            connection?.off("MoveSlide", handleSlideMove);
            connection?.off("EndPresentation");
            connection?.off("UserList");
        };

    }, [connected, connection, presentationId])

    useEffect(() => {
        let timeOutVal = null
        if (inNextPresentation && !(timeLeft < 0)) {
            timeOutVal = setTimeout(() => {
                if (timeLeft === 0) {
                    {
                        upcomingEvent?.presentationId ? window.location.replace(`${window.location.origin}/active-presentation/${upcomingEvent?.presentationId}/${eventDetails?.id}?isEvent=true`) : window.location.replace(`${window.location.origin}/active-presentation/${upcomingEvent?.id}/${eventDetails?.id}?isEvent=true&isOnlyAgenda=true`)
                    }

                }
                setTimeLeft((prev) => prev === 0 ? 0 : prev - 1)

            }, 1000)
        }

        return () => {
            if (timeOutVal) {
                clearTimeout(timeOutVal)
            }
        }

    }, [inNextPresentation, timeLeft]);
    return <>
        <ModalWrapper open={inNextPresentation} onCancel={() => setInNextPresentation(false)}>
            <div>
                <p className="text-[#1D1F24] text-2xl font-semibold font-grotesk">Your presentation is coming up
                    next</p>
                <p className="text-[#667085]">You will be switched to a presenter in {timeLeft}, Get ready</p>
                <div className={"flex justify-center text-2xl w-full my-4"}>
                    {timeLeft}
                </div>
                <div>
                    <p className={"text-xs text-[#667085]"}>
                        More actions
                    </p>
                    {/*This would only trigger when it is an event */}
                    <div className={"w-full flex mt-2 justify-center gap-2"}>
                        <CButton action={() => setInNextPresentation(false)} longer={true}
                                 fullWidth={true} text={"Stay as an audience"}
                                 variant={"dark"}/>
                        {/*Now, if the presentation does nt have an id , what i want to send is the agendaId , but with a flag that shows that it is an agenda alone */}

                        {
                            upcomingEvent?.presentationId ? <CButton
                                action={() => {
                                    window.location.replace(`${window.location.origin}/active-presentation/${upcomingEvent?.presentationId}/${eventDetails?.id}?isEvent=true`)
                                }}
                                longer={true} fullWidth={true} text={"Take me to my presentation"}
                                variant={"dark"}/> : <CButton
                                action={() => {
                                    window.location.replace(`${window.location.origin}/active-presentation/${upcomingEvent?.id}/${eventDetails?.id}?isEvent=true&isOnlyAgenda=true`)
                                }}
                                longer={true} fullWidth={true} text={"Take me to my presentation"}
                                variant={"dark"}/>
                        }


                    </div>
                </div>
            </div>
        </ModalWrapper>
        <UserPresentationWrapper>
            <div className="flex flex-grow min-h-[400px] w-full  min-w-screen">
                <div
                    className={`${(idleState || eventPresentationEnded) ? 'w-full lg:w-full' : "w-[70%] lg:w-[75%]"} `}>
                    <DesktopViewerPresentationScreen
                        outOfSync={userOutOfSyncState}
                        loading={firstChunkLoading || anotherAgendaDocLoading}
                        imageSource={outOfCurrentAgenda ? (outOfSyncPresentationDetails && outOfSyncPresentationDetails[outOfSyncActiveDocumentId] && (outOfSyncPresentationDetails?.[outOfSyncActiveDocumentId][outOfSyncActiveIndex].imgUrl ?? "")) : presentationDisplayDetails?.[activeDocumentId]?.[activeSlideIndex]?.imgUrl ?? ""}
                        isFullScreen={isFullScreen}
                        setIsFullScreen={setIsFullScreen}
                        idleState={idleState}
                        upcomingAgenda={upcomingEvent}
                        eventDetails={eventDetails}
                        eventPresentationsEnded={eventPresentationEnded}
                    />
                </div>
                {!idleState && !eventPresentationEnded && <div className="pl-4 overflow-hidden w-[30%] lg:w-[25%]">
                    {desktopMenu === 'attendees' && <ViewerUsers users={users}/>}
                    {desktopMenu === "playlist" && presentationDetails &&
                        <DesktopViewerPlaylist
                            activeDocumentId={activeDocumentId}
                            presentationDetails={presentationDetails}
                            presentationDisplayDetails={presentationDisplayDetails}
                            userOutOfSyncState={userOutOfSyncState}
                            setUserOutOfSyncState={setUserOutOfSyncState}
                            setActiveIndex={setActiveSlideIndex}
                            setActiveDocumentId={setActiveDocumentId}
                            userOutOfSyncRef={userOutOfSyncRef}
                            isEvent={isEvent}
                            eventDetails={eventDetails}
                            agendaPlaylist={agendaPlayList}
                            setAgendaPlaylist={setAgendaPlaylist}
                            agendaPresentationDisplayDetails={agendaPresentationDisplayDetails}
                            setAgendaPresentationDisplayDetails={setAgendaPresentationDisplayDetails}
                            setAnotherAgendaDocLoading={setAnotherAgendaDocLoading}
                            anotherAgendaDocLoading={anotherAgendaDocLoading}
                            outOfSyncPresentationDetails={outOfSyncPresentationDetails}
                            setOutOfSyncPresentationDetails={setOutOfSyncPresentationDetails}
                            outOfSyncDetailsRef={outOfSyncPresentationRef}
                            setOutOfCurrentAgenda={setOutOfCurrentAgenda}
                            setOutOfSyncDocId={setOutOfSyncActiveDocumentId}
                            setOutOfSyncActiveIndex={setOutOfSyncActiveIndex}
                        />
                    }
                    {desktopMenu === "share" && <DesktopViewerShare isEvent={isEvent} friendlyId={friendlyId ?? ""}/>}
                    {desktopMenu === "question" &&
                        <DesktopViewerQuestion
                            activeSlideId={presentationDisplayDetails?.[activeDocumentId]?.[activeSlideIndex].id ?? ""}
                            activeDocument={presentationDisplayDetails?.[activeDocumentId]?.[activeSlideIndex]}
                            activeImage={presentationDisplayDetails?.[activeDocumentId]?.[activeSlideIndex]?.imgUrl ?? ""}
                            activeSlideIndex={activeSlideIndex} presentationId={presentationId}
                            connected={connected}
                            connection={connection}
                            isEvent={isEvent}
                            eventDetails={eventDetails}
                        />}
                </div>}

            </div>
            {!idleState && presentationDisplayDetails && !eventPresentationEnded &&
                <DesktopViewerController
                    presenterActiveDocument={presenterActiveDocument}
                    userOutOfSyncRef={userOutOfSyncRef}
                    presenterActiveSlideIndex={presenterActiveSlideIndex}
                    activeDocumentId={activeDocumentId}
                    presentationDisplayDetails={presentationDisplayDetails}
                    activeIndexValue={activeSlideIndex}
                    handleActiveMenu={setDesktopMenu} activeMenu={desktopMenu}
                    setActiveIndexValue={setActiveSlideIndex}
                    setActiveDocumentId={setActiveDocumentId}
                    userOutOfSyncState={userOutOfSyncState}
                    setUserOutOfSyncState={setUserOutOfSyncState}
                    connection={connection}
                    connected={connected}
                    presentationId={presentationId}
                    friendlyId={friendlyId ?? ""}
                    imageSource={presentationDisplayDetails?.[activeDocumentId]?.[activeSlideIndex]?.imgUrl ?? ""}
                    isEvent={isEvent}
                    outOfSyncActiveIndex={outOfSyncActiveIndex}
                    setOutOfSyncActiveIndex={setOutOfSyncActiveIndex}
                    outOfCurrentAgenda={outOfCurrentAgenda}
                    setOutOfCurrentAgenda={setOutOfCurrentAgenda}
                    outOfSyncPresentationDetails={outOfSyncPresentationDetails}
                    setOutOfSyncPresentationDetails={setOutOfSyncPresentationDetails}
                    outOfSyncDetailsRef={outOfSyncPresentationRef}
                    outOfSyncActiveDocumentId={outOfSyncActiveDocumentId}
                />

            }
        </UserPresentationWrapper>
    </>
}