import { Poll, PollQuestion } from 'interfaces/PollSetup';
import QuestionsOptionsDisplay from './QuestionType/Quizzes/QuizzesColouredOptionsDisplay';
import champion from 'assets/svg/champion.svg';
import participant from 'assets/svg/participant.svg';

interface Props {
  activeQuestion: PollQuestion;
  poll: Poll;
}
export default function QuestionCanvas({ activeQuestion, poll }: Props) {
  return (
    <section className='col-span-6 h-fit xxl:h-[80vh]'>
      <div
        className={`bg-white p-3 rounded-md h-[80%] flex flex-col overflow-hidden`}
      >
        <p className='text-2xl font-bold mb-3'>
          {activeQuestion.question
            ? activeQuestion.question
            : 'Your questions will appear here'}
        </p>
        {/* Content */}
        <section className='bg-gray550 rounded-md flex flex-col flex-grow flex-shrink basis-0'>
          <section className=' px-5 pt-20 py-5 grid grid-cols-5 gap-x-5 mt-5 flex-grow flex-shrink basis-0'>
            {activeQuestion.questionType === 'Multi Choice' &&
              activeQuestion.options &&
              activeQuestion.options.map((option, index) => (
                <QuestionsOptionsDisplay
                  option={option}
                  key={index}
                  index={index}
                />
              ))}
          </section>

          {/* Participant */}
          <section className='p-5 min-h-fit'>
            <section className='flex rounded-full gap-x-5 py-2 px-5 bg-[#E8E8E8] w-fit'>
              <div className='flex gap-x-2'>
                <div>
                  <img src={participant} alt='number of participant' />
                </div>
                <div>
                  <p className='text-sm'>No of participant</p>
                  <p className='font-semibold'>0</p>
                </div>
              </div>
              <div className='flex gap-x-2'>
                <div>
                  <img src={champion} alt='number of participant' />
                </div>
                <div>
                  <p className='text-sm'>No of participant</p>
                  <p className='font-semibold'>0</p>
                </div>
              </div>
            </section>
          </section>
        </section>
      </div>
    </section>
  );
}
