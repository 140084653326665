import {clearTranslatedAudio} from "store/Slides/slidesSlice";
import store from "store/store";
import {toast} from "sonner";

export const ViewerAskQuestion = (
    connection,
    presentationId,
    connectionId,
    slideId,
    question,
    audioQuestionId,
    setisLoading,
    closeModal,
    isEvent,
    eventId
) => {
    if (connection) {
        console.log(
            "AskQuestion",
            presentationId,
            connectionId,
            slideId,
            question,
            audioQuestionId,
            eventId
        );
        connection
            .invoke(
                "AskQuestion",
                presentationId,
                connectionId,
                slideId,
                question,
                audioQuestionId,
                eventId
            )
            .then(() => {
                store.dispatch(clearTranslatedAudio());
                // setQuestion('');
                toast.success("Question sent");

                closeModal();
                setisLoading(false);
            });
    }
};
