import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  _addSession,
  _createEvent,
  _getEvent,
  _publishEvent,
  _removeSession,
  _addPresentationDocuments,
  _getPresentation,
  _addPresentation,
  _addPresentationDocument,
  _shareEvent,
  _updateEvent,
  _addEventThumbnail,
  _getEventThumbnail,
  _updateSession,
  _removeGuests,
  _addGuests,
  _addCoPresenters,
  _removePresentationDocument,
  _updateDocument,
  _updateSessionPosition,
  _sendSessionReminder,
  _updatePresentationDocumentPosition,
  _addPresenters,
  _getEvents,
  _deleteEvent,
} from 'services/Events/EventsServices';
import {
  AddPresentationDocumentsRequestPayload,
  AddPresentationRequestPayload,
  AddSessionRequestPayload,
  AgendaItem,
  Collaborator,
  CreateEventRequestPayload,
  GetEventResponsePayload,
  InitialState,
  PresentationData,
  PresentationDocument,
  ShareEventLinkRequestPayload,
  updatePresentationDocumentPositionRequestPayload,
  UpdateSessionRequestPayload,
  UpdateEventRequestPayload,
  EventsResponsePayload,
} from 'interfaces/Events/EventsInterfaces';
import { _getDocument } from 'services/Briefcase/briefcaseServices';

interface AgendaItemNotNull {
  eventId: string;
  position: number;
  title: string;
  time: string;
  duration: number;
  presentationId: string | null;
  presentation: PresentationData;
  collaborators: Collaborator[];
  role: 'EventOwner' | 'Collaborator';
  id: string;
}

const initialState: InitialState = {
  events: {
    isLoading: false,
    data: null,
  },
  event: {
    isLoading: false,
    data: null,
  },
  preview: {
    isLoading: false,
    data: null,
    displayDocument: null,
    flattedDisplayDocuments: [],
    displayDocIndex: 0,
    slides: {
      isLoading: false,
      data: [],
      displayDocIndex: 0,
      displayDocument: null,
    },
  },
  // presentationDetails: [],
};

interface Document_Preview {
  id: string;
  parentId: string | null;
  folderId: string;
  url: string;
  title: string;
  note: string | null;
  slides: any[]; // Adjust this type depending on what the `slides` array contains, for now it's `any[]`
}

// GET
export const getEvents = createAsyncThunk(
  'events/getEvents',
  async (teamId: string) => {
    const response = await _getEvents(teamId);
    return response.data;
  }
);
export const getEvent = createAsyncThunk(
  'events/getEvent',
  async ({ eventId, teamId }: { eventId: string; teamId: string }) => {
    const response = await _getEvent(eventId, teamId);
    return response.data;
  }
);
export const getEventThumbnail = createAsyncThunk(
  'events/getEventThumbnail',
  async (eventId: string) => {
    const response = await _getEventThumbnail(eventId);
    return response.data;
  }
);

export const getPresentation = createAsyncThunk(
  'events/getPresentation',
  async (presentationId: string) => {
    const response = await _getPresentation(presentationId);
    return response.data;
  }
);

// POST
export const createEvent = createAsyncThunk(
  'events/createEvent',
  async (data: CreateEventRequestPayload) => {
    const response = await _createEvent(data);
    return response.data;
  }
);
export const updateEvent = createAsyncThunk(
  'events/updateEvent',
  async (data: UpdateEventRequestPayload) => {
    const response = await _updateEvent(data);
    return response.data;
  }
);

export const addSession = createAsyncThunk(
  'events/addSession',
  async (data: AddSessionRequestPayload) => {
    const response = await _addSession(data);
    return response.data;
  }
);

export const addPresentationDocuments = createAsyncThunk(
  'events/addPresentationDocuments',
  async (data: AddPresentationDocumentsRequestPayload) => {
    const response = await _addPresentationDocuments(data);
    return response.data;
  }
);

export const addPresentationDocument = createAsyncThunk(
  'events/addPresentationDocument',
  async (data: FormData) => {
    const response = await _addPresentationDocument(data);
    return response.data;
  }
);
export const addEventThumbnail = createAsyncThunk(
  'events/addEventThumbnail',
  async ({
    eventId,
    teamId,
    data,
  }: {
    eventId: string;
    teamId: string;
    data: FormData;
  }) => {
    const response = await _addEventThumbnail(eventId, teamId, data);
    return response.data;
  }
);
export const addPresentation = createAsyncThunk(
  'events/addPresentation',
  async (data: AddPresentationRequestPayload) => {
    const response = await _addPresentation(data);
    return response.data;
  }
);
export const shareEvent = createAsyncThunk(
  'events/shareEvent',
  async ({
    eventId,
    data,
    teamId,
  }: {
    eventId: string;
    data: ShareEventLinkRequestPayload;
    teamId?: string;
  }) => {
    const response = await _shareEvent(eventId, data, teamId);
    return response.data;
  }
);

export const removeSession = createAsyncThunk(
  'events/removeSession',
  async ({
    sessionId,
    eventId,
    teamId,
  }: {
    sessionId: string;
    eventId: string;
    teamId: string;
  }) => {
    const response = await _removeSession({ sessionId, eventId, teamId });
    return response.data;
  }
);
export const updateSession = createAsyncThunk(
  'events/updateSession',
  async ({
    sessionId,
    data,
  }: {
    sessionId: string;
    data: UpdateSessionRequestPayload;
  }) => {
    const response = await _updateSession({ sessionId, data });
    return response.data;
  }
);
export const removeGuests = createAsyncThunk(
  'events/removeGuests',
  async ({
    eventId,
    teamId,
    data,
  }: {
    data: string[];
    teamId: string;
    eventId: string;
  }) => {
    const response = await _removeGuests({ data, eventId, teamId });
    return response.data;
  }
);
export const addGuests = createAsyncThunk(
  'events/removeGuests',
  async ({
    eventId,
    teamId,
    data,
  }: {
    data: string[];
    teamId: string;
    eventId: string;
  }) => {
    const response = await _addGuests({ data, eventId, teamId });
    return response.data;
  }
);
export const addCoPresenters = createAsyncThunk(
  'events/addCoPresenter',
  async ({
    sessionId,
    eventId,
    data,
  }: {
    sessionId: string;
    eventId: string;
    data: { coPresenters: string[] };
  }) => {
    const response = await _addCoPresenters({ data, eventId, sessionId });
    return response.data;
  }
);
export const addPresenters = createAsyncThunk(
  'events/addPresenters',
  async ({
    sessionId,
    data,
  }: {
    sessionId: string;
    data: {
      eventId: string;
      teamId: string;
      collaborators: string[];
    };
  }) => {
    const response = await _addPresenters({ data, sessionId });
    return response.data;
  }
);
export const removePresentationDocument = createAsyncThunk(
  'events/addCoPresenter',
  async ({
    presentationId,
    documentId,
  }: {
    presentationId: string;
    documentId: string;
  }) => {
    const response = await _removePresentationDocument({
      presentationId,
      documentId,
    });
    return response.data;
  }
);

export const publishEvent = createAsyncThunk(
  'events/publishEvent',
  async ({ eventId, teamId }: { eventId: string; teamId: string }) => {
    const response = await _publishEvent(eventId, teamId);
    return response.data;
  }
);

export const getDocumentPreview = createAsyncThunk(
  'events/getDocumentPreview',
  async (documentId: string) => {
    const response = await _getDocument(documentId);
    return response.data;
  }
);

export const updateDocument = createAsyncThunk(
  'events/updateDocument',
  async (data: FormData) => {
    const response = await _updateDocument(data);
    return response.data;
  }
);
export const updateSessionPosition = createAsyncThunk(
  'events/updateSessionPosition',
  async ({
    eventId,
    data,
  }: {
    eventId: string;
    data: { position: number; sessionId: string }[];
  }) => {
    const response = await _updateSessionPosition({ eventId, data });
    return response.data;
  }
);
export const updatePresentationDocumentPosition = createAsyncThunk(
  'events/updateSessionPosition',
  async ({
    presentationId,
    data,
  }: {
    presentationId: string;
    data: updatePresentationDocumentPositionRequestPayload[];
  }) => {
    const response = await _updatePresentationDocumentPosition({
      presentationId,
      data,
    });
    return response.data;
  }
);
export const sendSessionReminder = createAsyncThunk(
  'events/sendSessionReminder',
  async ({ sessionId, teamId }: { sessionId: string; teamId: string }) => {
    const response = await _sendSessionReminder({ sessionId, teamId });
    return response.data;
  }
);
export const deleteEvent = createAsyncThunk(
  'events/delete',
  async ({
    eventId,
    teamId,
    archive,
  }: {
    eventId: string;
    teamId: string;
    archive: boolean;
  }) => {
    const response = await _deleteEvent({ eventId, teamId, archive });
    return response.data;
  }
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setAgenda(state, action: PayloadAction<AgendaItem[]>) {
      if (state.event.data) {
        state.event.data.agenda = action.payload;
      }
    },
    setSessionPreview(state, action: PayloadAction<string>) {
      console.log(state.event.data?.agenda);

      const session = state.event.data?.agenda.find(
        (agd): agd is AgendaItemNotNull => {
          return agd.presentationId === action.payload;
        }
      );

      if (session) {
        const extractAllDocuments = (
          categories: AgendaItemNotNull[],
          parentFolderId: string | null = null
        ): PresentationDocument[] => {
          const docs = categories.flatMap((category) => {
            // Combine the documents in the current category with those in its subcategories
            const currentDocsWithFolder = category.presentation.doc.map(
              (doc) => ({
                ...doc,
                folderId: parentFolderId || category.id, // Add folderId reference to each document
              })
            );

            return currentDocsWithFolder;
          });
          console.log(docs);
          return docs;
        };

        // Configuring to match component's data
        const previewPayload = {
          id: session.id,
          title: session.title,
          docs: session.presentation.doc,
          subs: [],
        };

        state.preview.flattedDisplayDocuments = extractAllDocuments([session]);

        state.preview.data = [previewPayload];
        console.log(session);
      }
    },
    setEventsPreview(state) {
      // for the preview

      console.log('Level 0');
      if (state.event.data && state.event.data.agenda) {
        console.log('Level 1');
        const filteredAgenda: AgendaItemNotNull[] =
          state.event.data.agenda.filter(
            (item): item is AgendaItemNotNull => item.presentation !== null
          );

        const extractAllDocuments = (
          categories: AgendaItemNotNull[],
          parentFolderId: string | null = null
        ): PresentationDocument[] => {
          const docs = categories.flatMap((category) => {
            // Combine the documents in the current category with those in its subcategories
            const currentDocsWithFolder = category.presentation.doc.map(
              (doc) => ({
                ...doc,
                folderId: parentFolderId || category.id, // Add folderId reference to each document
              })
            );

            return currentDocsWithFolder;
          });
          console.log(docs);
          return docs;
        };

        // Configuring to match component's data
        const previewPayload = filteredAgenda.map((item) => {
          return {
            id: item.id,
            title: item.title,
            docs: item.presentation.doc,
            subs: [],
          };
        });

        state.preview.flattedDisplayDocuments =
          extractAllDocuments(filteredAgenda);

        state.preview.data = previewPayload;
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state) => {
        state.events.isLoading = true;
        state.events.data = null;
      })
      .addCase(
        getEvents.fulfilled,
        (state, action: PayloadAction<EventsResponsePayload[]>) => {
          state.events.data = action.payload;
          state.events.isLoading = false;
        }
      )
      .addCase(getEvents.rejected, (state) => {
        state.events.isLoading = false;
      });

    //   getEvent
    builder
      .addCase(getEvent.pending, (state) => {
        state.event.isLoading = true;
        state.event.data = null;
      })
      .addCase(
        getEvent.fulfilled,
        (state, action: PayloadAction<GetEventResponsePayload>) => {
          state.event.isLoading = false;

          const sortedDocs = action.payload.agenda.map((item) => {
            let presentation;
            if (item.presentation) {
              presentation = {
                doc: item.presentation?.doc.sort(
                  (a, b) => a.position - b.position
                ),
                presentation: item.presentation?.presentation,
              };
            } else {
              presentation = null;
            }

            return {
              ...item,
              presentation,
            };
          });

          state.event.data = {
            ...action.payload,
            agenda: [...sortedDocs],
          };

          // for the preview
          // interface AgendaItemNotNull {
          //   eventId: string;
          //   position: number;
          //   title: string;
          //   time: string;
          //   duration: number;
          //   presentationId: string | null;
          //   presentation: PresentationData;
          //   collaborators: Collaborator[];
          //   role: 'EventOwner' | 'Collaborator';
          //   id: string;
          // }
          // // const filteredAgenda : AgendaItemNotNull[] = action.payload.agenda.filter((item) => item.presentation !== null)
          // const filteredAgenda: AgendaItemNotNull[] =
          //   action.payload.agenda.filter(
          //     (item): item is AgendaItemNotNull => item.presentation !== null
          //   );

          // const extractAllDocuments = (
          //   categories: AgendaItemNotNull[],
          //   parentFolderId: string | null = null
          // ): PresentationDocument[] => {
          //   const docs = categories.flatMap((category) => {
          //     // Combine the documents in the current category with those in its subcategories
          //     const currentDocsWithFolder = category.presentation.doc.map(
          //       (doc) => ({
          //         ...doc,
          //         folderId: parentFolderId || category.id, // Add folderId reference to each document
          //       })
          //     );

          //     return currentDocsWithFolder;
          //   });
          //   console.log(docs);
          //   return docs;
          // };

          // // Configuring to match component's data
          // const previewPayload = filteredAgenda.map((item) => {
          //   return {
          //     id: item.id,
          //     title: item.title,
          //     docs: item.presentation.doc,
          //     subs: [],
          //   };
          // });

          // state.preview.flattedDisplayDocuments =
          //   extractAllDocuments(filteredAgenda);

          // state.preview.data = previewPayload;
        }
      )
      .addCase(getEvent.rejected, (state) => {
        state.event.isLoading = false;
      });

    builder
      .addCase(getDocumentPreview.pending, (state) => {
        state.preview.isLoading = true;
        state.preview.data = null;
      })
      .addCase(
        getDocumentPreview.fulfilled,
        (state, action: PayloadAction<Document_Preview>) => {
          state.preview.isLoading = false;

          if (action.payload.slides) {
            const sortedSlides = action.payload.slides.sort(
              (slideA, slideB) => slideA.position - slideB.position
            );
            state.preview.slides.data = sortedSlides;
          }

          const doc = [
            {
              sessionId: '',
              sessionType: 0,
              documentId: action.payload.id,
              title: action.payload.title,
              note: null,
              position: 0,
              url: action.payload.url,
              type: '',
              deleted: false,
              id: '',
              folderId: action.payload.folderId,
            },
          ];

          state.preview.flattedDisplayDocuments = doc;
        }
      )
      .addCase(getDocumentPreview.rejected, (state) => {
        state.preview.isLoading = false;
      });
  },
});

export default eventsSlice.reducer;
export const { setAgenda, setEventsPreview, setSessionPreview } =
  eventsSlice.actions;
