import React from "react";

//assets
import card1 from "assets/svg/aboutCard1.svg";
import card2 from "assets/svg/aboutCard2.svg";
import card3 from "assets/svg/aboutCard3.svg";
import { TypeAnimation } from "react-type-animation";

export default function Deck() {
  return (
    <section className=" mx-auto [ lg:mt-24 mt-10 ] [lg:w-[80%] w-full ]">
      <section className="text-center">
        <h1 className="  [ lg:text-[3.8rem]  text-2xl  lg:leading-tight font-medium ] ">
          Now you have a <br className="[ hidden lg:block ]" /> visually{" "}
          <br className="[ lg:hidden block ]" />
          <span className="text-primary">
            {" "}
            <TypeAnimation
              sequence={[" stunning", 1000, "remarkable", 2000, "astonishing"]}
              speed={20}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
            />{" "}
          </span>{" "}
          <br className="[ hidden lg:block ]" /> deck
        </h1>

        <p className=" mx-auto  text-black30 [ lg:w-[70%] w-[90%] ] [ lg:text-[1.2rem] text-xs ] [ lg:mt-6 mt-4 ] [ lg:leading-[2rem] leading-[1rem] ]">
          TeamDocs offers unparalleled insights into audience engagement and
          reception. With robust analytics and reporting features, presenters
          gain valuable knowledge about their audience demographics, slide
          effectiveness, and presentation impact.
        </p>
      </section>

      <section className="flex justify-center w-full mt-10 ">
        <section className="flex items-center gap-x-4 [  lg:flex-row flex-col items-center gap-y-4 ] ">
          <section className=" bg-white rounded-2xl [ lg:py-16 py-8 ] [ lg:w-[26rem] w-[85%] ] ">
            <div className="flex justify-center gap-x-2 ">
              <img src={card1} alt="" />
              <img src={card2} alt="" className=" [ lg:block hidden ]" />
            </div>

            <p className="mt-4 [ w-[80%] mx-auto ] [ text-sm text-center text-gray600 leading-loose ]   ">
              For sales organizations, these insights are game-changers. Armed
              with data-driven intelligence, sales teams can refine their
              approach, optimize pitch decks, and pinpoint areas for improvement
              to drive sales success.
            </p>
          </section>

          <section className=" bg-white rounded-2xl [ lg:py-10 py-8 ] [ lg:w-[26rem] w-[85%] ] ">
            <div className="[ w-[90%] mx-auto ]">
              <img src={card3} alt="" />
            </div>

            <p className="mt-4 [ w-[80%] mx-auto ]  [ text-sm text-center text-gray600 leading-loose ]   ">
              who trust TeamDocs to elevate their presentations. Experience the
              transformative power of our platform and unlock your full
              potential with TeamDocs. Welcome to a new era of presentation
              excellence.
            </p>
          </section>
        </section>
      </section>
    </section>
  );
}
