import { Dispatch, SetStateAction, useEffect } from 'react';

// Components
import { Poll, PollQuestion, PollQuestionTypes } from 'interfaces/PollSetup';
import QuestionType from './QuestionType';
import QuestionCanvas from './QuestionCanvas';
import Settings from './Settings';

export interface VariableQuestionTypes {
  title: string;
  image: string;
  type: PollQuestionTypes;
}
interface Props {
  poll: Poll;
  activeQuestionIndex: number;
  setActiveQuestionIndex: Dispatch<SetStateAction<number>>;
  handleSave: () => void;
  activeQuestion: PollQuestion;
  setPoll: Dispatch<SetStateAction<Poll>>;
  setActiveQuestion: Dispatch<SetStateAction<PollQuestion>>;
}

export default function MainContent({
  poll,
  activeQuestionIndex,
  setActiveQuestionIndex,
  handleSave,
  setPoll,
  activeQuestion,
  setActiveQuestion,
}: Props) {
  useEffect(() => {
    const emptyOption = {
      rank: 0,
      text: '',
      color: '',
      isSelected: false,
    };
    // checks if the last input question is being typed and adds new item to the list
    if (
      activeQuestion.options.length > 0 &&
      activeQuestion.options.length < 5
    ) {
      if (
        activeQuestion.options[activeQuestion.options.length - 1].text.length >
        0
      ) {
        setActiveQuestion((prevQuestion) => {
          return {
            ...prevQuestion,
            options: [...prevQuestion.options, emptyOption],
          };
        });
      }
    }
  }, [activeQuestion.options]);

  return (
    <section className='pt-[80px] absolute right-0 top-0 w-[88%] h-screen'>
      <section className='p-3'>
        <section className='grid gap-x-3 grid-cols-10 h-screen'>
          <QuestionType
            setPoll={setPoll}
            activeQuestion={activeQuestion}
            setActiveQuestion={setActiveQuestion}
            handleSave={handleSave}
          />
          <QuestionCanvas activeQuestion={activeQuestion} poll={poll} />
          <Settings />
        </section>
      </section>
    </section>
  );
}
