import { useEffect } from 'react';

// Liberies
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

import {
  getPreview,
  getBriefcase,
  getBriefcaseDocumentPreview,
} from 'store/Briefcase/briefcaseSlice';
import { getTeamInfo, getTeamsState } from 'store/Teams/teamsSlice';

import Preview from 'components/UI/Preview';

interface TeamInfoData {
  id: string;
}

export default function BriefcasePreview() {
  const { spaceId, documentId } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  // Store
  const { teamInfo } = useSelector(getTeamsState);

  const previewIsLoading = useSelector(
    (state: RootState) => state.briefcase.preview.isLoading
  );

  const flattedDisplayDocuments = useSelector(
    (state: RootState) => state.briefcase.preview.flattedDisplayDocuments
  );

  const { data: briefcaseDetails } = useSelector(
    (state: RootState) => state.briefcase.briefcase
  );

  const previewDocs = useSelector(
    (state: RootState) => state.briefcase.preview.data
  );

  // useeffects
  useEffect(() => {
    dispatch(getTeamInfo());

    // Fetches the preview docuemnts
    if (documentId) {
      dispatch(getBriefcaseDocumentPreview(documentId));
    } else {
      dispatch(getTeamInfo());
      spaceId && dispatch(getPreview(spaceId));
    }
  }, [dispatch, spaceId, documentId]);

  useEffect(() => {
    if (teamInfo.data && spaceId) {
      const teamInfoData = teamInfo.data as TeamInfoData;
      const teamId = teamInfoData.id;
      dispatch(getBriefcase({ spaceId, teamId }));
    }
  }, [dispatch, spaceId, teamInfo.data]);

  return (
    <Preview
      flattedDisplayDocuments={flattedDisplayDocuments}
      previewDocs={previewDocs}
      ownerName={teamInfo?.data?.title ?? "Creator's name"}
      previewTitle={briefcaseDetails?.title ?? ''}
      previewIsLoading={previewIsLoading}
    />
  );
}
