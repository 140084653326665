import { MobilePresentation } from 'components/UI/mobile/mobilePresentation';
import { PreviewPresentationMobile } from 'components/UI/mobile/previewPresentation';
import ProtectedRoute from 'routes/ProtectedRoute';
import ActivePresentation from 'views/Admin/ActivePresentation';
import PresentationDetails from 'views/Admin/Presentation/PresentationDetails';
import ErrorPage from 'views/ErrorPage';
import { ExternalActivePresentation } from 'views/external-files/presentation/ex-activepresentation';
import ExLandingPage from 'views/external-files/presentation/ex-landing';
import { ExPresentations } from 'views/external-files/presentation/ex-presentations';

const presentationRoutes = [
  {
    path: 'presentation-details/:presentationId',
    element: (
      <ProtectedRoute>
        <PresentationDetails />
      </ProtectedRoute>
    ),
  },

  {
    path: 'active-presentation/:urlPresentationId',
    element: (
      <ProtectedRoute>
        <ActivePresentation />
      </ProtectedRoute>
    ),
  },

  {
    path: '/external',
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <ExLandingPage />,
      },
      {
        path: 'presentations',
        element: (
          <ProtectedRoute>
            <ExPresentations />
          </ProtectedRoute>
        ),
      },
      {
        path: 'active-presentation/:urlPresentationId',
        element: (
          <ProtectedRoute>
            <ExternalActivePresentation />
          </ProtectedRoute>
        ),
      },
    ],
  },

  // {
  //   path: 'presentation-details/:presentationId',
  //   element: (
  //     <ProtectedRoute>
  //       <PresentationDetails />
  //     </ProtectedRoute>
  //   ),
  // },

  // {
  //   path: 'active-presentation/:urlPresentationId/:eventId?',
  //   element: (
  //     <ProtectedRoute>
  //       <ActivePresentation />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: 'active-event/:eventId/:teamId',
  //   element: (
  //     <ProtectedRoute>
  //       <ActivePresentation />
  //     </ProtectedRoute>
  //   ),
  // },

  {
    path: '/test',
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'preview-presentation',
        element: <PreviewPresentationMobile />,
      },
      {
        path: 'presentation',
        element: <MobilePresentation />,
      },
    ],
  },
];

export default presentationRoutes;
