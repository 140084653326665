import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import arrowRightGray from 'assets/svg/arrow-square-right-gray.svg';
import arrowSquareRightWhite from 'assets/svg/arrow-square-right.svg';
import closeIcon from 'assets/svg/close-circle-dark-gray.svg';
import AgendaBanner from './AgendaBanner';
import CreateAgenda from './CreateAgenda';
import AgendaRow from './AgendaRow';
import circle_i from 'assets/svg/circle_i-black.svg';
import { AppDispatch } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getTeamsState } from 'store/Teams/teamsSlice';
import {
  addSession,
  getEvent,
  publishEvent,
  removeSession,
  updateSessionPosition,
} from 'store/Events/EventsSlice';
import arrowLeft from 'assets/svg/arrow-square-left.svg';
import {
  AddSessionRequestPayload,
  AgendaList,
  Flow,
} from 'interfaces/Events/EventsInterfaces';
import { toast } from 'sonner';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';

interface Props {
  agendaList: AgendaList[];
  setAgendaList: Dispatch<SetStateAction<AgendaList[]>>;
  flow: Flow;
  setFlow: Dispatch<SetStateAction<Flow>>;
}

export default function Agenda({
  agendaList,
  setAgendaList,
  flow,
  setFlow,
}: Props) {
  const { eventId } = useParams();
  const { teamInfo } = useSelector(getTeamsState);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [disableNextBTN, setDisableNextBTN] = useState(true);
  const [activeRow, setActiveRow] = useState<AgendaList | null>(null);

  useEffect(() => {
    if (!agendaList.length) {
      setDisableNextBTN(true);
    } else {
      setDisableNextBTN(false);
    }
  }, [agendaList]);

  const handlePublishEvent = async () => {
    await handleAddSession();
    const teamId = teamInfo.data?.id;
    eventId &&
      dispatch(publishEvent({ eventId, teamId })).then(({ type, payload }) => {
        if (type.includes('fulfilled')) {
          navigate(`/admin/events/details/${eventId}`);
        }
      });
  };

  const handleAddSession = async () => {
    const teamId = teamInfo.data?.id;

    // filters out agendas without an id - ps: those with id has already been added.
    const filteredList = agendaList
      .map((agenda, index) => {
        return { ...agenda, position: index }; //updating the position - accounting for when it was rearranged
      })
      .filter((agenda_) => agenda_.id.includes('agenda'));

    const promiseList = filteredList.map((agenda_, index) => {
      const data: AddSessionRequestPayload = {
        eventId: eventId ?? '',
        teamId: teamId ?? '',
        sessions: [
          {
            position: agenda_.position,
            title: agenda_.title,
            time: '2024-11-20T07:32:13.403Z',
            duration: 0,
            collaborators: agenda_.collaborators,
          },
        ],
      };
      return dispatch(addSession(data)).then(({ type }) => {
        if (type.includes('fulfilled')) {
        }
      });
    });

    await Promise.all(promiseList);
  };

  const handleNextEvent = () => {
    if (!disableNextBTN) {
      if (flow === 'addEventdetails') {
        setFlow('addpresenter');
      }
      if (flow === 'addpresenter') {
        setFlow('publish');
        // handleAddSession();
      }
      if (flow === 'publish') {
        handlePublishEvent();
      }
    }
  };
  const handleBackButton = () => {
    if (flow === 'publish') {
      // if (eventId) {
      //   const teamId = teamInfo.data?.id;
      //   if (teamId) {
      //     dispatch(getEvent({ eventId, teamId }));
      //   }
      // }
      setFlow('addpresenter');
    }
    if (flow === 'addpresenter') {
      setFlow('addEventdetails');
    }
  };

  const handleRemoveSession = (id: string) => {
    const teamId = teamInfo.data?.id;

    setAgendaList((agendas) => agendas.filter((agenda) => agenda.id !== id));

    if (!id.includes('agenda')) {
      eventId &&
        dispatch(
          removeSession({
            sessionId: id,
            eventId,
            teamId,
          })
        );
    }
  };

  function onDragStart(event: DragStartEvent) {
    if (event.active.data.current?.type === 'Row') {
      setActiveRow(event.active.data.current.agenda); //this would set the active column - nice
      return;
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setActiveRow(null);
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    setAgendaList((agendas) => {
      const activeRowIndex = agendas.findIndex((col) => col.id === activeId);
      const overRowIndex = agendas.findIndex((col) => col.id === overId);

      const rearrangedSession = arrayMove(agendas, activeRowIndex, overRowIndex)
        .map((agend, idx) => {
          return {
            sessionId: agend.id,
            position: idx,
          };
        })
        .filter((agenda) => !agenda.sessionId.includes('agenda'));
      eventId &&
        dispatch(updateSessionPosition({ eventId, data: rearrangedSession }));

      return arrayMove(agendas, activeRowIndex, overRowIndex); // the swaps the activeCOlumnIndex with the overColumnIndex and returns a new array.
    });
  }

  // DND
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );
  return (
    <section>
      {flow !== 'addEventdetails' && <AgendaBanner />}

      <section
        className={`rounded-xl border ${
          flow === 'publish' ? '' : 'border-[#EEEEEE] p-3'
        } ${
          flow === 'addEventdetails'
            ? 'opacity-20 mt-5 pointer-events-none'
            : ''
        }`}
      >
        <section
          className={`${flow === 'addEventdetails' && 'mt-5'} ${
            flow === 'publish' && 'bg-[#F6F6F6] p-3 rounded-xl'
          }`}
        >
          <div>
            <h1 className='text-2xl font-semibold'>Agenda</h1>
            {flow !== 'publish' && (
              <p className='text-sm'>
                Create agenda and invite presenters to upload their document
              </p>
            )}
          </div>

          {(flow === 'addpresenter' || flow === 'publish') && (
            <section>
              {agendaList.length > 0 && (
                <DndContext
                  sensors={sensors}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                >
                  <SortableContext items={agendaList}>
                    {agendaList?.map((item, index) => {
                      return (
                        <AgendaRow
                          key={index}
                          flow={flow}
                          setFlow={setFlow}
                          agenda={item}
                          setAgendaList={setAgendaList}
                          agendaList={agendaList}
                          handleRemoveSession={handleRemoveSession}
                        />
                      );
                    })}

                    {createPortal(
                      <DragOverlay>
                        {activeRow && (
                          <AgendaRow
                            flow={flow}
                            setFlow={setFlow}
                            agenda={activeRow}
                            setAgendaList={setAgendaList}
                            agendaList={agendaList}
                            handleRemoveSession={handleRemoveSession}
                          />
                        )}
                      </DragOverlay>,
                      document.body
                    )}
                  </SortableContext>
                </DndContext>
              )}
            </section>
          )}

          {flow !== 'publish' && (
            <CreateAgenda
              flow={flow}
              setFlow={setFlow}
              setAgendaList={setAgendaList}
              agendaList={agendaList}
            />
          )}

          {flow === 'publish' && (
            <div className='h-[50px] w-full rounded-xl bg-[#E2E2E2] flex items-center gap-x-3 px-3 mt-5 text-black'>
              <img src={circle_i} alt='important!' className='w-6' />
              <p className='font-medium text-sm'>
                Publishing an event would send invitation emails to your
                presenters.
              </p>
            </div>
          )}

          <div className='w-full flex justify-end mt-3'>
            <div className='flex gap-x-2'>
              <button
                className='bg-transparent border border-[#AFAFAF] text-black h-10 px-3 rounded-lg'
                onClick={handleBackButton}
              >
                <div className='flex gap-x-2 items-center'>
                  <img src={arrowLeft} alt='continue' />
                  <p className='text-sm'>Back</p>
                </div>
              </button>
              {flow === 'publish' && (
                <button
                  className='bg-transparent border border-[#AFAFAF] text-black h-10 px-3 rounded-lg'
                  onClick={() => {
                    handleAddSession();
                    toast.success('Event progress saved!');
                    setTimeout(() => {
                      navigate('/admin/events');
                    }, 1000);
                  }}
                >
                  <div className='flex gap-x-2 items-center'>
                    <p className='text-sm'>Save and close</p>
                    <img src={closeIcon} alt='continue' />
                  </div>
                </button>
              )}
              <button
                className={`h-10 px-3 text-sm rounded-lg ${
                  (flow === 'addEventdetails' || disableNextBTN) &&
                  'bg-[#CBCBCB] text-[#AFAFAF] '
                } ${
                  flow !== 'addEventdetails' &&
                  !disableNextBTN &&
                  'bg-black text-white '
                }`}
                disabled={disableNextBTN}
                onClick={(e) => {
                  e.preventDefault();
                  handleNextEvent();
                }}
              >
                <div className='flex gap-x-2 items-center'>
                  <p className=''>
                    {flow === 'publish' ? 'Publish' : 'Continue'}
                  </p>
                  <img
                    src={
                      disableNextBTN ? arrowRightGray : arrowSquareRightWhite
                    }
                    alt='continue'
                  />
                </div>
              </button>
            </div>
          </div>
        </section>
      </section>
    </section>
  );
}
