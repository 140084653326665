import React, {useEffect, useRef, useState} from "react";
import noteActive from "assets/svg/notesActive.svg";
import noteInactive from "assets/svg/notesInactive.svg";
import peopleActive from "assets/svg/peopleActive.svg";
import peopleInactive from "assets/svg/peopleInactive.svg";
import questionInactive from "assets/svg/questionInactive.svg";
import pollsInactive from "assets/svg/pollInactive.svg";
import pollsActive from "assets/svg/pollActive.svg";
import shareActive from "assets/svg/shareActive.svg";
import shareInactive from "assets/svg/shareInactive.svg";
import {DesktopViewers} from "components/UI/Presentation/desktop/desktop-viewers";
import {
    AgendaInterface,
    EventsInterface,
    InnerPresentationInterface,
    PresentationDisplayInterface,
    SlideInterface,
    UserInterface
} from "../../../../interfaces/interfaces-data";
import {HubConnection} from "@microsoft/signalr";
import {GetUsersListNew} from "../../../../hooks/SignalR/Invokers/Presenter/GetUserListNew";
import {DesktopShare} from "./DesktopShare";
import {DesktopQuestion} from "./DesktopQuestion";
import {useGetQuestions} from "../../../../hooks/SignalR/Listeners/Presenter/useGetQuestions";
import {GetQuestions} from "../../../../hooks/SignalR/Invokers/Presenter/GetQuestions";
import {CopyToClipBoard} from "../../wrapper/CopyToClipBoard";
import {getConnectionId} from "../../../../utils/localStorageServices";
import {DesktopAgenda} from "./desktop-agenda";


export const DesktopMenu = ({
                                activeSlide,
                                connection,
                                presentationId,
                                connected,
                                friendlyId,
                                presentationDetails,
                                isEvent,
                                eventId,
                                eventDetails,
                                presentationDisplayDetails,
                                activeDocumentId,
                                upcomingAgenda,
                            }: {
    activeSlide?: SlideInterface,
    connection?: HubConnection | null,
    presentationId?: string
    connected: boolean
    friendlyId?: string
    presentationDetails?: InnerPresentationInterface
    isEvent?: boolean
    eventId?: string
    eventDetails?: EventsInterface,
    presentationDisplayDetails?: PresentationDisplayInterface,
    activeDocumentId: string,
    upcomingAgenda?: AgendaInterface,
}) => {

    const [activeState, setActiveState] = useState<
        "note" | "people" | "question" | "poll" | "share" | "agenda"
    >("people");
    const [users, setUsers] = useState<UserInterface[]>([]);
    const [requests, setRequests] = useState<UserInterface[]>([])
    const [questionCount, setQuestionCount] = useState(0);
    const [latestQuestion, setLatestQuestion] = useState(null);
    const [showNotif, setShowNotif] = useState(false);
    const [notifAnimation, setNotifAnimation] = useState("");
    const isMountedRef = useRef(true);
    const [canShowNav, setCanShowNav] = useState(false)
    // Handler for question
    useGetQuestions(connection, presentationId, (questions: any) => {
        if (!questions) return;
        const questionLength = questions?.length;
        setQuestionCount(questionLength);
        let storedLength;

        if (localStorage.getItem("questionLength")) {
            storedLength = JSON.parse(
                localStorage.getItem("questionLength") ?? ""
            );
        }

        const getLatestQuestion = questions.sort((a: any, b: any) =>
            a?.askTime > b?.askTime ? 1 : -1
        );

        if (isMountedRef.current) {
            localStorage.setItem("questionLength", questionLength);
            isMountedRef.current = false;
        } else if (questionLength !== storedLength) {
            setLatestQuestion(getLatestQuestion[questionLength - 1]);

            localStorage.setItem("questionLength", questionLength);
            setTimeout(() => {
                setShowNotif(true);
                setNotifAnimation("bounce-in");
                setTimeout(() => {
                    setNotifAnimation("bounce-out");
                    setTimeout(() => {
                        setShowNotif(false);
                    }, 500);
                }, 2000);
            }, 2000);
        }
    });

    useEffect(() => {
        const showNav = !isEvent || (isEvent && eventDetails?.role === "Collaborator")
        setCanShowNav(showNav)
        if (!showNav) {
            setActiveState('note')
        }
    }, [isEvent, eventDetails]);

    useEffect(() => {
        if (connected && connection) {
            const handleEventResponse = (id: string, users: UserInterface[]) => {
                setUsers((prev) => users.filter((item) => item.status === 1));
                setRequests((prev) => users.filter((item) => item.status === 0));
            };

            connection.on("UserList", handleEventResponse);
            if (connection && eventDetails) {
                // Fetch initial user list when connection is activec
                console.log("Got Outside here", connection)
                // if it is an event type , the first argument should be the eventId
            }

            console.log("Getuser payload", presentationId ?? "", connection, isEvent, isEvent ? eventDetails?.id : "")

            GetUsersListNew(presentationId ?? "", connection, isEvent, isEvent ? eventDetails?.id : "");


            // Cleanup event listener on component unmount or when connection changes
            return () => {
                connection?.off("UserList", handleEventResponse);
            };
        }
    }, [connected, eventDetails]);

    useGetQuestions(connection, presentationId, (questions: any) => {
        if (!questions) return;
        const questionLength = questions?.length;
        setQuestionCount(questionLength);
        let storedLength;

        if (localStorage.getItem("questionLength")) {
            storedLength = JSON.parse(
                localStorage.getItem("questionLength") ?? ""
            );
        }

        const getLatestQuestion = questions.sort((a: any, b: any) =>
            a?.askTime > b?.askTime ? 1 : -1
        );

        if (isMountedRef.current) {
            localStorage.setItem("questionLength", questionLength);
            isMountedRef.current = false;
        } else if (questionLength !== storedLength) {
            setLatestQuestion(getLatestQuestion[questionLength - 1]);

            localStorage.setItem("questionLength", questionLength);
            setTimeout(() => {
                setShowNotif(true);
                setNotifAnimation("bounce-in");
                setTimeout(() => {
                    setNotifAnimation("bounce-out");
                    setTimeout(() => {
                        setShowNotif(false);
                    }, 500);
                }, 2000);
            }, 2000);
        }
    });


    useEffect(() => {
        if (connected && connection?.state === "Connected") {
            GetQuestions(connection, presentationId, getConnectionId(), eventId, isEvent);
        }
    }, [connected, connection]);


    return (
        <div
            className="border border-[#E5E7EB] overflow-y-scroll h-screen min-h-screen max-h-screen mb-10 p-4 ml-2 rounded-lg">
            <div className="bg-[#F6F6F6] p-2 rounded-lg flex justify-between">
                {canShowNav && <button
                    onClick={() => setActiveState("note")}
                    className="flex justify-center items-center flex-col cursor-pointer"
                >
                    <img src={activeState === "note" ? noteActive : noteInactive} alt=""/>
                    <p
                        className={`${
                            activeState === "note" ? "text-[#FF6929]" : "text-[#667084]"
                        }  text-xs `}
                    >
                        Notes
                    </p>
                </button>}

                <button
                    onClick={() => setActiveState("people")}
                    className="flex justify-center relative items-center flex-col cursor-pointer"
                >
                    {!!requests.length && <span
                        className={"bg-[red] absolute top-[-10px] font-semibold right-[-6px] text-white text-[10px] font-grotesk py-[2px] px-[4px] justify-center text-center flex items-center rounded-full"}>
                        {String(requests.length).length === 1 ? `0${requests.length}` : `${requests.length}`}
                    </span>}

                    <img
                        src={activeState === "people" ? peopleActive : peopleInactive}
                        alt=""
                    />
                    <p
                        className={`${
                            activeState === "people" ? "text-[#FF6929]" : "text-[#667084]"
                        }  text-xs `}
                    >
                        People
                    </p>
                </button>
                {<button
                    onClick={() => setActiveState("question")}
                    className="flex justify-center items-center flex-col cursor-pointer"
                >
                    <img
                        src={
                            activeState === "question" ? questionInactive : questionInactive
                        }
                        alt=""
                    />
                    <p
                        className={`${
                            activeState === "question" ? "text-[#FF6929]" : "text-[#667084]"
                        }  text-xs `}
                    >
                        Questions
                    </p>
                </button>}

                {/*<button*/}
                {/*    onClick={() => setActiveState("poll")}*/}
                {/*    className="flex justify-center items-center flex-col cursor-pointer"*/}
                {/*>*/}
                {/*    <img*/}
                {/*        src={activeState === "poll" ? pollsActive : pollsInactive}*/}
                {/*        alt=""*/}
                {/*    />*/}
                {/*    <p*/}
                {/*        className={`${*/}
                {/*            activeState === "poll" ? "text-[#FF6929]" : "text-[#667084]"*/}
                {/*        }  text-xs `}*/}
                {/*    >*/}
                {/*        Polls*/}
                {/*    </p>*/}
                {/*</button>*/}

                <button
                    onClick={() => setActiveState("agenda")}
                    className="flex justify-center items-center flex-col cursor-pointer"
                >
                    <img
                        src={activeState === "agenda" ? pollsActive : pollsInactive}
                        alt=""
                    />
                    <p
                        className={`${
                            activeState === "agenda" ? "text-[#FF6929]" : "text-[#667084]"
                        }  text-xs `}
                    >
                        Agenda
                    </p>
                </button>
                <button
                    onClick={() => setActiveState("share")}
                    className="flex justify-center items-center flex-col cursor-pointer"
                >
                    <img
                        src={activeState === "share" ? shareActive : shareInactive}
                        alt=""
                    />
                    <p
                        className={`${
                            activeState === "share" ? "text-[#FF6929]" : "text-[#667084]"
                        }  text-xs `}
                    >
                        Share
                    </p>
                </button>
            </div>
            {/* Options selected  */}
            <div className=" border-t mt-4 py-4">
                {activeState === "note" && canShowNav && (
                    <div className="bg-[#F6F6F6] p-4 rounded-xl ">
                        <div className="text-[#545454] text-sm">
                            {activeSlide?.note ? <CopyToClipBoard text={activeSlide?.note}>
                                {activeSlide?.note}
                            </CopyToClipBoard> : <p className="text-center">
                                No note for the slide
                            </p>}
                        </div>
                    </div>
                )}
                {activeState === "people" && connected &&
                    <DesktopViewers eventDetails={eventDetails} isEvent={isEvent} connection={connection}
                                    presentationId={presentationId}
                                    users={users}
                                    requests={requests}/>}
                {activeState === 'share' && connected &&
                    <DesktopShare isEvent={isEvent} eventDetails={eventDetails}
                                  presentationDetails={presentationDetails} presentationId={presentationId}
                                  friendlyId={friendlyId}/>
                }
                {
                    activeState === "question" && connected &&
                    <div>

                        <DesktopQuestion eventDetails={eventDetails} isEvent={isEvent} eventId={eventId}
                                         presentationId={presentationId}
                                         connection={connection}/>
                    </div>
                }
                {
                    activeState === 'agenda' && connected && isEvent && eventDetails &&
                    <DesktopAgenda
                        presentationDetails={presentationDetails}
                        presentationDisplayDetails={presentationDisplayDetails} eventDetails={eventDetails}
                        activeDocumentId={activeDocumentId}
                        connection={connection}
                        presentationId={presentationId}
                        isEvent={isEvent}
                        upcomingAgenda={upcomingAgenda}
                    />
                }
            </div>
        </div>
    )
        ;
}



