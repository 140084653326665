import image1 from 'assets/image/bg-marketing-goals.png';
import ellipsis from 'assets/svg/ellipsis-horizontal-gray.svg';
import clock from 'assets/svg/timer-topDash-gray.svg';
import Loader from 'components/UI/Loader';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FetchedPoll, getPollTemplates } from 'store/PollCopy/pollCopySlice';
import { AppDispatch } from 'store/store';

interface Props {
  isLoading: boolean;
  polls: FetchedPoll[] | null;
}
export default function MyPolls({ isLoading, polls }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getPollTemplates());
  }, [dispatch]);
  return (
    <section>
      {isLoading ? (
        <Loader />
      ) : (
        <section className='grid grid-cols-3 gap-5'>
          {polls &&
            polls.map((poll) => (
              <div className=''>
                <div
                  className='rounded-md overflow-hidden border cursor-pointer h-[185px]'
                  onClick={() => {
                    navigate(`/poll-setup/${poll.rowKey}`);
                  }}
                >
                  <img
                    src={image1}
                    alt='background'
                    className='w-full object-cover'
                  />
                </div>
                <div className='mt-2'>
                  <div className='flex justify-between items-center'>
                    <p className='font-semibold'>{poll.title}</p>
                    <img
                      src={ellipsis}
                      alt='options'
                      className='cursor-pointer'
                    />
                  </div>
                  <div className='flex gap-x-1 items-center'>
                    <img src={clock} alt='date created' className='w-4' />
                    <p className='text-xs leading-none'>
                      {moment.utc(poll.launchDate).fromNow()}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </section>
      )}
    </section>
  );
}
