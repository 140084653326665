import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

// Assets
import chevronDown from 'assets/svg/chevron-down-black.svg';
import sectionIcon from 'assets/svg/section-rectangle-group.svg';
import imageTypeIcon from 'assets/svg/imagetype-icon-jpeg-png.svg';

// Slice
import { Tooltip } from 'antd';
import FolderChildren from './FolderChildren';
import FolderHeader from './FolderHeader';
import { ImageCache } from 'types/Users/SharedBriefcase/SharedBriefcaseTypes';

// Interface
interface PreviewDocument {
  sessionId: string;
  sessionType: number;
  documentId: string;
  title: string | null;
  note: string | null;
  position: number;
  url: string;
  type: string | null;
  deleted: boolean;
  id: string;
  folderId: string | null;
}

// Interface for each item in the main array
export interface PreviewPayload {
  id: string | null;
  title: string | null;
  docs: PreviewDocument[];
  subs: PreviewPayload[];
}

interface Props {
  doc: PreviewPayload;
  displayDocumentIndex: number;
  setDisplayDocumentIndex: Dispatch<SetStateAction<number>>;
  setSlideDocumentIndex: Dispatch<SetStateAction<number>>;
  flattedDisplayDocuments: PreviewDocument[];
  imageCache: MutableRefObject<ImageCache[]>;
  fetchedFlattenedDocIndex: number | null;
}

export default function Folder({
  doc,
  displayDocumentIndex,
  setDisplayDocumentIndex,
  flattedDisplayDocuments,
  setSlideDocumentIndex,
  imageCache,
  fetchedFlattenedDocIndex,
}: Props) {
  // State
  const [isOpen, setIsOpen] = useState(true);

  // const textRef = useRef<HTMLDivElement>(null);
  // const [isOverflow, setIsOverflow] = useState(false);

  // useEffect(() => {
  //   const checkOverflow = () => {
  //     if (textRef.current) {
  //       console.log('Scroll Width:', textRef.current.scrollWidth);
  //       console.log('Client Width:', textRef.current.clientWidth);
  //       setIsOverflow(
  //         textRef.current.scrollWidth > textRef.current.clientWidth
  //       );
  //     }
  //   };

  //   // Check overflow on component mount and on window resize
  //   checkOverflow();
  //   window.addEventListener('resize', checkOverflow);

  //   return () => {
  //     window.removeEventListener('resize', checkOverflow);
  //   };
  // }, []);

  return (
    <section className='border-b     w-full'>
      <section className=' relative h-fit     w-full'>
        {/* Folder Header */}
        <FolderHeader
          doc={doc}
          displayDocumentIndex={displayDocumentIndex}
          setDisplayDocumentIndex={setDisplayDocumentIndex}
          flattedDisplayDocuments={flattedDisplayDocuments}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />

        {flattedDisplayDocuments[displayDocumentIndex].folderId === doc.id && (
          <div className='bg-primary absolute top-0 -right-[6px] translate-x-[50%] w-1 h-full rounded-full'></div>
        )}
      </section>
      {/* Folder documents */}
      <section
        className={`py-2 twoK:px-10 eightK:px-10 ${!doc.id ? '' : 'px-3'}`}
      >
        {isOpen &&
          doc.docs.map((doc_) => (
            <FolderChildren
              doc={doc_}
              displayDocumentIndex={displayDocumentIndex}
              setDisplayDocumentIndex={setDisplayDocumentIndex}
              flattedDisplayDocuments={flattedDisplayDocuments}
              setSlideDocumentIndex={setSlideDocumentIndex}
              imageCache={imageCache}
              fetchedFlattenedDocIndex={fetchedFlattenedDocIndex}
            />
          ))}
      </section>
    </section>
  );
}
