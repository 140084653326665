import { useEffect, useRef, useState } from 'react';

// Assets
import bell from 'assets/svg/bell.svg';
import sendIcon from 'assets/svg/send-icon.svg';
import ChevronOrange from 'assets/svg/chevron-down-orange.svg';
import menu from 'assets/svg/hamburger.svg';
import ellipsisVertical from 'assets/svg/ellipsis-vertical.svg';
import play from 'assets/svg/play2.svg';
import calender from 'assets/svg/calendar-2.svg';
import eyeI from 'assets/svg/eye-2.svg';
import chevronDownWhite from 'assets/svg/chevron-down-white.svg';
import addCircle from 'assets/svg/add-circle.svg';
import arrowRight from 'assets/svg/arrowRight.svg';
import playBtn from 'assets/svg/playBTNgray.svg';
import calender2 from 'assets/svg/calendar-2.svg';

// components
import CreateSpace from 'views/Admin/Briefcase/Modals/CreateBriefcase';

// Libraries
import { Drawer, Dropdown, Menu, Modal, Tooltip } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ShareBriefcase from 'views/Admin/Briefcase/Modals/ShareBriefcase';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { shareNewPresentation } from 'store/Briefcase/briefcaseSlice';
import { toast } from 'sonner';

// Store
import dayjs from 'dayjs';
import DocumentPreviewComponent from './DocumentPreviewComponent';
// import { getDocument } from 'store/Workspace/workspaceSlice';

interface CreateSpaceModalRef {
  resetModal: () => void;
}

export default function Navbar() {
  const dispatch = useDispatch<AppDispatch>();

  // ref
  const createSpaceModal = useRef<CreateSpaceModalRef>(null);
  const briefcase = useSelector(
    (state: RootState) => state.briefcase.briefcase.data
  );
  const briefcaseDocument = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocument.data
  );
  const [presentationType, setPresentationType] = useState(0); // 0 for share and 1 for presentation

  //
  const navigate = useNavigate();
  const { spaceId, fileId } = useParams();
  // const { spaceId, fileId, documentId: workspaceDocumentId } = useParams();

  //state
  const [documentId, setDocumentId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openShareBriefcaseDrawer, setShareBriefCaseDrawer] = useState(false);
  const [schedulePresentation, setSchedulePresentation] =
    useState<boolean>(false);
  // const [friendlyId, setFriendlyId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (workspaceDocumentId) {
  //     dispatch(getBriefcaseDocument(workspaceDocumentId));
  //   }
  // }, [dispatch, workspaceDocumentId]);

  //functions
  const openInNewTab = (url: string) => {
    window.open(url, '_blank'); // Opens in a new tab
  };

  const openCreateSpaceModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    createSpaceModal.current?.resetModal();
  };

  const onClose = () => {
    setShareBriefCaseDrawer(false);
  };

  // Handles presenting briefcase now
  const handlePresentBriefcaseNow = async () => {
    // for formatting the presentation name
    const time = dayjs() as any;
    const presentationTimeName = dayjs.utc(time).format('YYYY-MM-DD');
    const presentationStartTime = dayjs
      .utc(time)
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    setIsLoading(true);
    const ShareLinkItemscopy = {
      title: `Presentation-${presentationTimeName}`,
      spaceId: spaceId ?? '',
      startTime: presentationStartTime,
      canDownload: 0,
      type: 1,
      openLink: false,
      emails: [],
      domains: [],
      passcode: '',
    };

    const { payload, type } = await dispatch(
      shareNewPresentation(ShareLinkItemscopy)
    );

    if (!type.includes('fulfilled')) {
      toast.error('Presentation was not created!');
      return;
    }

    if (payload) {
      // setFriendlyId(payload.friendlyId);
      let friendlyId = payload.friendlyId;
      setIsLoading(false);
      toast.success('Presentation created successfully');

      let presentationLink = `/presentation-details/${friendlyId}`;
      navigate(presentationLink);
    } else {
      setIsLoading(false);
      toast.error('Something went wrong');
    }
  };

  // birefcase file variables
  const items_ = [
    {
      key: '1',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => {
            if (fileId) {
              setDocumentId(fileId);
              setPresentationType(1);
              setSchedulePresentation(true);
              setShareBriefCaseDrawer(true);
            }
          }}
        >
          <img src={play} alt='rename file' className='w-4' />
          <p>Create presentation</p>
        </div>
      ),
    },

    {
      key: '2',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => {
            if (fileId) {
              setDocumentId(fileId);
              setPresentationType(0);
              setShareBriefCaseDrawer(true);
            }
          }}
        >
          <img src={calender} alt='metrics file' className='w-4' />
          <p>Share file</p>
        </div>
      ),
    },
  ];

  //variables
  const items_PresentBriefcase = [
    {
      key: '1',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => {
            // setPresentationType(1);
            handlePresentBriefcaseNow();
            // setSchedulePresentation(false);
          }}
        >
          <img src={playBtn} alt='rename file' className='w-4' />
          <p>Present now</p>
        </div>
      ),
    },

    {
      key: '2',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => {
            setPresentationType(1);
            setSchedulePresentation(true);
            setShareBriefCaseDrawer(true);
          }}
        >
          <img src={calender2} alt='metrics file' className='w-4' />
          <p>Schedule presentation</p>
        </div>
      ),
    },
  ];

  // const capitalizeInitials = (str: string): string => {
  //   if (!str) return '';
  //   return str
  //     .split(' ')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  //     .join(' ');
  // };
  const capitalizeInitials = (str: string): string => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  // const capitalizeFirstLetter = (str) => {
  //   if (!str) return '';
  //   return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  // };

  return (
    <>
      <nav className='fixed top-0  lg:left-[18%] left-0 z-30 lg:w-[82%] w-full h-20 bg-white pt-4'>
        <section className='w-[95%] mx-auto'>
          <div className='flex items-center justify-between  flex-col lg:flex-row gap-y-4'>
            <section className='flex items-center justify-between w-full lg:w-[40%] overflow-hidden'>
              <img src={menu} alt='open sidenav' className='block lg:hidden' />

              {/* <div className='leading-tight text-right lg:text-left justify-items-end justify-self-end lg:self-start'> */}
              <div className='leading-tight text-right lg:text-left justify-items-end lg:justify-items-start'>
                <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                  Briefcase
                </h1>
                {/* nav details or breadcrumb */}
                <>
                  {!spaceId && !fileId && (
                    <p className='lg:text-sm text-xs mt-[-3px]'>
                      Create a briefcase to manage your events and files
                    </p>
                  )}
                  {spaceId && !fileId && briefcase?.title && (
                    <div className='flex items-center mt-[-3px] cursor-pointer'>
                      <p
                        className='lg:text-sm text-xs'
                        onClick={() => {
                          navigate(`/admin/mybriefcase`);
                        }}
                      >
                        Briefcase
                      </p>
                      <img src={arrowRight} alt='next' className='w-3' />
                      <p
                        className='lg:text-sm text-xs'
                        onClick={() => {
                          navigate(`/admin/mybriefcase/details/${spaceId}`);
                        }}
                      >
                        {briefcase?.title}
                      </p>
                    </div>
                  )}

                  {spaceId &&
                    fileId &&
                    briefcase?.title &&
                    briefcaseDocument?.title && (
                      <div className='flex items-center mt-[-3px]'>
                        <p
                          className='lg:text-sm text-xs cursor-pointer'
                          onClick={() => {
                            navigate(`/admin/mybriefcase`);
                          }}
                        >
                          Briefcase
                        </p>
                        <img src={arrowRight} alt='next' className='w-3' />
                        <div
                          className='lg:text-sm text-xs cursor-pointer'
                          onClick={() => {
                            navigate(`/admin/mybriefcase/details/${spaceId}`);
                          }}
                        >
                          {briefcase?.title.length > 30 ? (
                            <Tooltip
                              title={
                                <p className='text-black capitalize'>
                                  {briefcase?.title}
                                </p>
                              }
                              color='white'
                              placement='top'
                            >
                              <p className=''>{`${capitalizeInitials(
                                briefcase?.title.slice(0, 30)
                              )}${
                                briefcase?.title.length > 30 ? '...' : ''
                              }`}</p>
                            </Tooltip>
                          ) : (
                            <p className=''>
                              {capitalizeInitials(briefcase?.title)}
                            </p>
                          )}
                        </div>
                        <img src={arrowRight} alt='next' className='w-3' />
                        {briefcaseDocument?.title.length > 30 ? (
                          <Tooltip
                            title={
                              <p className='text-black capitalize'>
                                {capitalizeInitials(briefcaseDocument?.title)}
                              </p>
                            }
                            color='white'
                            placement='top'
                          >
                            <p className=''>{`${capitalizeInitials(
                              briefcaseDocument?.title.slice(0, 30)
                            )}${
                              briefcaseDocument?.title.length > 30 ? '...' : ''
                            }`}</p>
                          </Tooltip>
                        ) : (
                          <p className=''>{briefcaseDocument?.title}</p>
                        )}
                      </div>
                    )}
                </>
              </div>
            </section>

            {/* All briefcases list NOTE this does not account for user without briefcase*/}
            {!spaceId && !fileId && (
              <section className='flex gap-x-2'>
                <div
                  className='bg-[#FF6929] px-2 py-2 text-white rounded-md text-sm flex gap-x-2 items-center cursor-pointer'
                  onClick={() => {
                    openCreateSpaceModal();
                  }}
                >
                  <img src={addCircle} alt='add new briefcase' />
                  <p>Create new briefcase</p>
                </div>
                <div className='flex items-center justify-between  flex-col lg:flex-row gap-y-4'>
                  <div className='px-2 py-2 border rounded-md cursor-pointer bg-gray20'>
                    <img src={bell} alt='notification' className='w-4' />
                  </div>
                </div>
              </section>
            )}

            {/* one single briefcase */}
            {spaceId && !fileId && (
              <div className='flex items-center flex-col lg:flex-row gap-y-4 gap-x-2 text-sm font-medium'>
                <div
                  className='h-8 flex items-center gap-x-2 px-5 rounded-md border border-[#AFAFAF] text-sm text-[#545454] cursor-pointer'
                  onClick={() => {
                    openInNewTab(`/briefcase-preview/${spaceId}`);
                    // navigate(`/briefcase-preview/${spaceId}`);
                  }}
                >
                  <p>Preview</p>
                  <img src={eyeI} alt='eye' className='w-4' />
                </div>
                {/* Share briefcase */}
                <div
                  className='px-3 border border-[#AFAFAF] rounded-md text-[#545454] flex gap-x-2 items-center h-8 cursor-pointer'
                  onClick={() => {
                    setPresentationType(0);
                    setShareBriefCaseDrawer(true);
                  }}
                >
                  <p>Share briefcase</p>
                  <img src={sendIcon} alt='share briefcase' />
                </div>

                {/* Present briefcase */}
                <section onClick={(e) => e.preventDefault()}>
                  <Dropdown
                    trigger={['click']}
                    dropdownRender={(menu) => (
                      <Menu>
                        {items_PresentBriefcase.map((item) => {
                          return (
                            <Menu.Item key={item.key}>{item.label}</Menu.Item>
                          );
                        })}
                      </Menu>
                    )}
                  >
                    <div className='px-3 text-[#FF6929] border border-[#D45722] rounded-md flex gap-x-2 items-center h-8 cursor-pointer'>
                      <p>Present briefcase</p>
                      <img src={ChevronOrange} alt='drop down' />
                    </div>
                  </Dropdown>
                </section>

                <div className='px-2 border rounded-md cursor-pointer bg-gray20 h-8 flex items-center'>
                  <img src={bell} alt='notification' className='w-4' />
                </div>
              </div>
            )}

            {/* briefcase file */}
            {spaceId && fileId && (
              <DocumentPreviewComponent
                documentId={fileId}
                setDocumentId={setDocumentId}
                setPresentationType={setPresentationType}
                setSchedulePresentation={setSchedulePresentation}
                setShareBriefCaseDrawer={setShareBriefCaseDrawer}
                openInNewTab={openInNewTab}
              />
            )}
          </div>
        </section>
      </nav>
      {/* Modal */}
      <Modal
        footer={null}
        centered={false}
        open={isModalOpen}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
      >
        <CreateSpace closeModal={handleCancel} ref={createSpaceModal} />
      </Modal>

      {briefcase?.id && (
        <Drawer
          placement='right'
          closable={false}
          onClose={onClose}
          open={openShareBriefcaseDrawer}
          width={468}
          destroyOnClose
        >
          <ShareBriefcase
            spaceId={briefcase.id}
            presentationType={presentationType}
            schedulePresentation={schedulePresentation}
            documentId={documentId}
          />
        </Drawer>
      )}
    </>
  );
}
