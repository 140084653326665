import onGoingPresentationIcon from "assets/svg/greyPresentationIcon.svg"
import rightBannerIcon from "assets/svg/rightBannerIcon.svg"
import {useNavigate} from "react-router-dom";
import {useState} from "react";

export const OnGoingAction = ({type, url}: { type: "Event" | "Presentation", url: string, }) => {
    const navigate = useNavigate()
    const [showAction, setShowAction] = useState(true)
    return <div className="min-h-[80px]  w-full ">
        {showAction &&
            <div onClick={() => navigate(url)}
                 className={`cursor-pointer ${type === "Event" ? "top-16" : "top-0"}  fixed top-0 z-50 p-3  w-full`}>
                <div
                    className="flex px-4 gap-2 bg-[#333333] opacity-90 w-full justify-between items-center p-2 rounded-lg">
                    <div onClick={() => setShowAction(false)}
                         className="flex items-center gap-4  w-full">
                        <div>
                            <img src={onGoingPresentationIcon} alt={""}/>
                        </div>
                        <div>
                            <p className="text-white text-lg">On going {type === "Event" ? "event" : "presentation"}</p>
                            <p className="text-white text-xs">You
                                have {type === "Event" ? "an event" : "a presentation"} going
                                on , click the banner to return to
                                {type === "Event" ? " event" : " presentation"}</p>
                        </div>
                    </div>
                    <div>
                        <img src={rightBannerIcon} alt={""}/>
                    </div>
                </div>
            </div>}
    </div>
}