import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState,} from "react";
import {useDispatch, useSelector} from "react-redux";

//assets
import wand from "assets/svg/wand.svg";
import oldVN from "assets/svg/Badge.svg";
import trash from "assets/svg/deleteVoice.svg";
import AIButton from "assets/svg/AIButton.svg";
import recordingImg from "assets/svg/recording.svg";
import orangeRecord from "assets/svg/orangeRecord.svg";

//slices
import {clearTranslatedAudio, getSlidesState, translateQuestion,} from "store/Slides/slidesSlice";

//utils
import {getConnectionId} from "utils/localStorageServices";

// signalR
import {ViewerAskQuestion} from "hooks/SignalR/Invokers/Viewer/ViewerAskQuestion";

//libraries
import {ClipLoader} from "react-spinners";
import {Skeleton} from "primereact/skeleton";
import {toast} from "sonner";

function AskQuestion(props, ref) {
    const {
        connection,
        slideId,
        closeModal,
        activeImage,
        slideNumber,
        presentationId,
        eventId,
        isEvent
    } = props;

    const connectionId = getConnectionId();
    const dispatch = useDispatch();
    const questionRef = useRef();

    useImperativeHandle(ref, () => ({
        resetModal() {
            setAudioUrl("");
            setQuestion("");
            setAudioFile(null);
            setIsRecording(false);
            setIsSummarized(false);
            setIsSummaryLoading(false);
            dispatch(clearTranslatedAudio());
            audioChunksRef.current.value = [];
            if (questionRef.current?.value) {
                questionRef.current.value = "";
            }
        },
    }));

    //useselector
    const {translatedAudio} = useSelector(getSlidesState);

    //usestate
    const [question, setQuestion] = useState("");
    const [isLoading, setisLoading] = useState(false);

    // audio recording
    const audioChunksRef = useRef([]);
    const mediaRecorderRef = useRef(null);
    const [audioUrl, setAudioUrl] = useState("");
    const [audioFile, setAudioFile] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isSummarized, setIsSummarized] = useState(false);
    const [isSummaryLoading, setIsSummaryLoading] = useState(false);
    const recordData = useRef([]);
    const mediaRecorder = useRef(null);

    //events
    connection.on(
        "EventResponse",
        (id, eventName, status, message, presenterConnectionId) => {
            // console.log(
            //   id,
            //   eventName,
            //   status,
            //   message,
            //   presenterConnectionId,
            //   "ask question"
            // );
        }
    );

    const submitQuestion = (e) => {
        e.preventDefault();

        let textQuestion = e.target.textQuestion?.value;
        setisLoading(true);

        // let finalQuestion = textQuestion;

        let finalQuestion;

        if (audioUrl === "") {
            finalQuestion = textQuestion;
        } else {
            if (isSummarized && translatedAudio.data) {
                finalQuestion = translatedAudio.data?.request;
            } else {
                finalQuestion = translatedAudio.data?.questionFull;
            }
        }


        if (!presentationId || !connectionId || !slideId || !finalQuestion) {
            console.log(
                connection,
                presentationId,
                connectionId,
                slideId,
                finalQuestion
            );
            toast.success("Some values are missing ");
            setisLoading(false)
            return;
        }

        ViewerAskQuestion(
            connection,
            presentationId,
            connectionId,
            slideId,
            finalQuestion,
            translatedAudio.data?.id,
            setisLoading,
            closeModal,
            isEvent,
            eventId
        );
    };

    const LGstyle = {
        background: "linear-gradient(159.93deg, #FF6929 13.38%, #993F18 80.83%)",
    };

    const recordStart = () => {
        setIsRecording(true);
        mediaRecorder.current.start();
    };
    const recordStop = () => {
        setIsRecording(false);

        mediaRecorder.current.stop();
    };

    const recordMicrophone = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices
                .getUserMedia({
                    audio: true,
                })
                .then((stream) => {
                    console.log(stream, "Stream Data here");
                    mediaRecorder.current = new MediaRecorder(stream);

                    mediaRecorder.current.ondataavailable = (e) => {
                        console.log(e.data, "E Data heree")
                        recordData.current.push(e.data);
                    };

                    console.log("Fixes", recordData.current)

                    mediaRecorder.current.onstop = (e) => {
                        const blob = new Blob(recordData.current, {type: mediaRecorder.current.mimeType});

                        setAudioFile(blob)

                        recordData.current = [];
                        const audioURL = window.URL.createObjectURL(blob);
                        console.log(audioURL, "Audio Url heree");
                        setAudioUrl(audioURL);
                    };
                })
                .catch((err) => {
                    toast.error(err);
                    console.error(`The following getUserMedia error occurred: ${err}`);
                });
        } else {
            toast.error("Get User media not supported on this browser");
        }
    };

    useEffect(() => {
        // Get access to the microphone
        // const getMicrophoneAccess = async () => {
        //   try {
        //     const stream = await navigator.mediaDevices.getUserMedia({
        //       audio: true,
        //     });
        //     mediaRecorderRef.current = new MediaRecorder(stream);

        //     mediaRecorderRef.current.ondataavailable = (event) => {
        //       audioChunksRef.current.push(event.data);
        //     };

        //     mediaRecorderRef.current.onstop = () => {
        //       const audioBlob = new Blob(audioChunksRef.current, {
        //         type: "audio/wav",
        //       });
        //       const audioUrl = URL.createObjectURL(audioBlob);
        //       setAudioUrl(audioUrl);
        //       const file = new File([audioBlob], "audio.wav", {
        //         type: "audio/wav",
        //       });
        //       setAudioFile(file);
        //       audioChunksRef.current = [];
        //     };
        //   } catch (err) {
        //     console.error("Error accessing microphone:", err);
        //   }
        // };

        // getMicrophoneAccess();

        // New method handling this
        recordMicrophone();
    }, []);

    const summmarizeText = () => {
        setIsSummaryLoading(true);
        setIsSummarized(true);
        setTimeout(() => {
            setIsSummaryLoading(false);
            setIsSummarized(true);
        }, [800]);
    };

    const startRecording = () => {
        setAudioUrl("");
        setIsRecording(true);
        audioChunksRef.current = [];
        mediaRecorderRef.current.start();
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorderRef.current.stop();
    };

    const deleteVoice = () => {
        setAudioUrl("");
        setIsSummarized(false);
        dispatch(clearTranslatedAudio());
        audioChunksRef.current = [];
    };

    const translateAudio = () => {
        if (!audioUrl) {
            toast.success("Upload a voice note");
            return;
        }
        const presentaConnectionId = localStorage.getItem("connectionId");
        const data = {
            presentationId,
            presentaConnectionId,
        };
        const formData = new FormData();
        console.log(audioFile, "Audio File Value hereee")
        formData.append("file", audioFile);
        dispatch(translateQuestion({audio: formData, data}));
    };
    return (
        <section className="">
            <form
                onSubmit={submitQuestion}
                className="lg:w-[93%] mx-auto my-4 font-grotesk"
            >
                <div>
                    <h1 className="text-base font-bold lg:text-2xl">
                        Question{" "}
                        <span className="mt-1 text-xs font-semibold">
              ( Slide {slideNumber + 1} )
            </span>
                    </h1>

                    <div className="mt-3">
                        <img
                            src={activeImage}
                            alt=""
                            className="lg:w-[12rem] lg:h-[8rem] w-[8rem] h-[5rem] rounded-lg lg:object-cover object-contain"
                        />
                    </div>
                </div>

                <section
                    className="flex w-full px-2 py-4 my-4 rounded-lg outline-none bg-orange10 text-medium lg:min-h-[15.5rem] min-h-[10rem] relative max-h-[16rem] overflow-y-scroll ">
                    <section className="w-11/12">
                        {!audioUrl && (
                            <textarea
                                name="textQuestion"
                                ref={questionRef}
                                onChange={(e) => setQuestion(e.target.value)}
                                placeholder={`${
                                    // recording
                                    isRecording ? "Listening...." : "Type your question"
                                }`}
                                className="w-full h-full pl-2 bg-transparent outline-none "
                                rows={5}
                            ></textarea>
                        )}

                        {audioUrl && (
                            <section>
                                <section className="flex items-center w-full lg:gap-x-4 gap-x-2">
                                    <audio
                                        controls={true}
                                        src={audioUrl}
                                        className="h-[2rem] w-full"
                                        onError={(e) => console.log(`audio error: ${e}`)}
                                    />
                                    <section className="flex gap-x-4">
                                        {!isSummarized ? (
                                            <div>
                                                {!translatedAudio.isLoading ? (
                                                    <img
                                                        src={AIButton}
                                                        alt="speech to text"
                                                        onClick={translateAudio}
                                                        className="cursor-pointer w-14"
                                                    />
                                                ) : (
                                                    <div
                                                        style={LGstyle}
                                                        className="flex items-center justify-center rounded-full w-7 h-7"
                                                    >
                                                        <ClipLoader color="white" size={12}/>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <img
                                                src={oldVN}
                                                alt="speech to text"
                                                onClick={() => setIsSummarized(false)}
                                                className="cursor-pointer "
                                            />
                                        )}

                                        <img
                                            src={trash}
                                            alt="speech to text"
                                            onClick={deleteVoice}
                                            className="cursor-pointer "
                                        />
                                    </section>
                                </section>

                                <section className="lg:min-h-[11.5rem] min-h-[8.5rem] relative ">
                                    {/* summarized AI response  */}
                                    <section className="pl-4 mt-4 text-sm font-medium  overflow-x-scroll h-[9rem]">
                                        {!isSummarized ? (
                                            <p>{translatedAudio.data?.questionFull}</p>
                                        ) : (
                                            <div>
                                                {isSummaryLoading ? (
                                                    <section className="w-full">
                                                        <Skeleton
                                                            width="100%"
                                                            height="12px"
                                                            className=""
                                                        ></Skeleton>
                                                        <Skeleton
                                                            width="100%"
                                                            height="12px"
                                                            className="my-2"
                                                        ></Skeleton>
                                                        <Skeleton
                                                            width="100%"
                                                            height="12px"
                                                            className=""
                                                        ></Skeleton>
                                                    </section>
                                                ) : (
                                                    <p>{translatedAudio.data?.request}</p>
                                                )}
                                            </div>
                                        )}
                                    </section>
                                    {/*  */}

                                    {/* summarize text  */}
                                    {translatedAudio.data?.questionFull?.length > 200 &&
                                        !isSummarized && (
                                            <section
                                                className="absolute bottom-0 flex-col items-center hidden px-4 py-2 mt-4 text-xs font-semibold lg:flex lg:flex-row rounded-xl bg-yellow100 gap-x-4">
                                                <p className="text-xs lg:text-sm">
                                                    Text limit exceeded, edit your text or summarize with
                                                    AI.
                                                </p>

                                                <div
                                                    onClick={summmarizeText}
                                                    className="flex items-center px-4 py-2 text-white bg-black rounded-full cursor-pointer lg:pr-8 gap-x-1"
                                                >
                                                    <span>Summarize</span>
                                                    <img src={wand} alt=""/>
                                                </div>
                                            </section>
                                        )}

                                    {/*  */}
                                </section>
                            </section>
                        )}
                    </section>

                    {!audioUrl && (
                        <div className="border-l h-[2rem] px-2">
                            <img
                                alt=""
                                className="w-[1.8rem] cursor-pointer"
                                src={isRecording ? recordingImg : orangeRecord}
                                onClick={isRecording ? recordStop : recordStart}
                            />
                        </div>
                    )}
                </section>
                <section className="block my-4 lg:hidden">
                    {/* summarize text  */}
                    {translatedAudio.data?.questionFull?.length > 200 &&
                        !isSummarized && (
                            <section
                                className="flex flex-col items-center px-4 py-1 text-xs font-semibold text-center lg:flex-row rounded-xl bg-yellow100 gap-x-4">
                                <p className="">
                                    Text limit exceeded, edit your text or summarize with AI.
                                </p>

                                <div
                                    onClick={summmarizeText}
                                    className="flex items-center px-4 py-2 text-white bg-black rounded-full cursor-pointer lg:pr-8 gap-x-1"
                                >
                                    <span>Summarize</span>
                                    <img src={wand} alt=""/>
                                </div>
                            </section>
                        )}
                </section>
                <button
                    type="submit"
                    disabled={
                        isRecording ||
                        (audioUrl && !translatedAudio.data) ||
                        (!question && !audioUrl)
                    }
                    className={`flex items-center justify-center w-full py-3 text-sm  rounded-lg cursor-pointer gap-x-2 ${
                        isRecording ||
                        (audioUrl && !translatedAudio.data) ||
                        (!question && !audioUrl)
                            ? "bg-gray300 text-gray40 font-semibold"
                            : " bg-primary text-white"
                    }`}
                >
                    {isLoading ? <ClipLoader size={15} color="white"/> : "  Submit"}
                </button>
            </form>
        </section>
    );
}

export default forwardRef(AskQuestion);
