import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

export const useHasPresentationStarted = (connection, presentationId, isEvent) => {
    const navigate = useNavigate();
    useEffect(() => {
        const handleEventResponse = (presentationId) => {
            toast.success("Presentation started");
            // console.log(event, id, 'evvv')
            navigate(isEvent ? `/viewer-mode-new/${presentationId}?isEvent=${isEvent}` : `/viewer-mode-new/${presentationId}`);
        };

        if (connection) {
            connection.on("PresentationStarted", handleEventResponse);
        }

        return () => {
            if (connection) {
                connection.off("PresentationStarted", handleEventResponse);
            }
        };
    }, [connection, presentationId, navigate]);
};
