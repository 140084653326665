import React, { useMemo, useState } from 'react';

import GroupSection from './GroupSection';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBriefcaseDocuments,
  getFolder,
  removeBriefcaseFile,
  setFolderDocuments,
  setFolders,
  updateDocuments,
  updateFoldersPositions,
  renameDocument,
} from 'store/Briefcase/briefcaseSlice';
import ConfirmationModal from 'components/UI/Modal/ConfirmationModal';
import { toast } from 'sonner';
import { BriefcaseGroupDocument } from 'interfaces/Briefcase';
import DropAreaSection from './DragAreaSection';
import RenameModal from 'components/UI/Modal/RenameModal';
import { Modal } from 'antd';
import Axios from 'lib/axiosInterceptor';

interface ImageCache {
  [id: string]: string;
}
interface ActiveCard {
  fromFolderId: string;
  fromIndex: number;
}
interface ActiveSection {
  // fromSectionId: string;
  fromPosition: number;
}

type DraggedItemType = 'section' | 'document' | 'null';

export default function MyDragnDrop({ spaceId }: { spaceId: string }) {
  //
  const dispatch = useDispatch<AppDispatch>();

  // Store
  const briefcaseFoldersDocuments = useSelector(
    (state: RootState) => state.briefcase.briefcaseFolders.documents
  );
  const briefcaseFoldersFolders = useSelector(
    (state: RootState) => state.briefcase.briefcaseFolders.folders
  );

  // State
  const [activeCard, setActiveCard] = useState<ActiveCard | null>(null);
  const [activeSection, setActiveSection] = useState<ActiveSection | null>(
    null
  );
  const [draggedItemType, setDraggedItemType] =
    useState<DraggedItemType>('null');
  const [confirmRemoveDocument, setConfirmRemoveDocument] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [confirmRenameDocument, setConfirmRenameDocument] = useState(false);
  const [isRenameLoading, setIsRenameLoading] = useState(false);

  console.log(draggedItemType);

  const [selectedDocument, setSelectedDocument] =
    useState<BriefcaseGroupDocument | null>(null);

  //usememo for memoizing the fetched url
  const imageCache = useMemo<ImageCache>(() => ({}), []);

  // Functions
  const onDropDocument = (toFolderId: string, toIndex: number) => {
    if (!activeCard) return;

    // This condition accomodates the extra drop location and fixes bug when dropping from top into the same section
    if (
      toIndex > activeCard.fromIndex &&
      toFolderId === activeCard.fromFolderId
    ) {
      toIndex = toIndex - 1;
    }

    // Create a deep copy of the current state to avoid mutating it directly
    const updatedBriefcaseGroups = [...briefcaseFoldersDocuments].map(
      (folder) => ({
        ...folder,
        documents: [...folder.documents],
      })
    );

    // Find the folder containing the document to move
    const folderOfDocToMove = updatedBriefcaseGroups.find(
      (folder) => folder.id === activeCard.fromFolderId
    );

    // Find the target folder where the document will be dropped
    const targetFolder = updatedBriefcaseGroups.find(
      (folder) => folder.id === toFolderId
    );

    if (!folderOfDocToMove || !targetFolder) return;

    // Remove the document from its current folder and index
    const [docToMove] = folderOfDocToMove.documents.splice(
      activeCard.fromIndex,
      1
    );

    // Insert the document into the target folder at the new index
    targetFolder.documents.splice(toIndex, 0, docToMove);

    // Update the state with the modified briefcase groups
    dispatch(setFolders(updatedBriefcaseGroups));

    let folderOfDocToMove_ = folderOfDocToMove.documents.map((doc, index) => {
      // handling the id of the documents without any group I set on the store
      let groupId;
      if (folderOfDocToMove.id === 'root_folder') {
        groupId = null;
      } else {
        groupId = folderOfDocToMove.id;
      }

      return {
        documentId: doc.documentId,
        groupId,
        position: index,
      };
    });

    let targetFolder_ = targetFolder.documents.map((doc, index) => {
      let groupId;
      if (targetFolder.id === 'root_folder') {
        groupId = null;
      } else {
        groupId = targetFolder.id;
      }

      return {
        documentId: doc.documentId,
        groupId,
        position: index,
      };
    });

    dispatch(updateDocuments({ spaceId, data: folderOfDocToMove_ }));
    dispatch(updateDocuments({ spaceId, data: targetFolder_ }));
    setDraggedItemType('null');
  };

  const onDropSection = (toPosition: number) => {
    console.log(
      `Dragged from position ${activeSection?.fromPosition} to position ${toPosition}`
    );

    if (!activeSection) return;

    if (toPosition < activeSection.fromPosition) {
      toPosition = toPosition + 1;
    }
    if (toPosition === 0) {
      toPosition = 1;
    }
    console.log(
      `Dragged from position ${activeSection?.fromPosition} to position ${toPosition}`
    );

    let updatedBriefcaseFolders = [...briefcaseFoldersFolders];

    // Remove the document from its current folder and index
    const [sectionToMove] = updatedBriefcaseFolders.splice(
      activeSection?.fromPosition,
      1
    );

    // Insert the document into the target folder at the new index
    updatedBriefcaseFolders.splice(toPosition, 0, sectionToMove);

    // Update positions
    updatedBriefcaseFolders = updatedBriefcaseFolders.map((folder, index) => {
      return { ...folder, position: index };
    });

    dispatch(setFolderDocuments(updatedBriefcaseFolders));

    // removing the root_folder
    let UpdatedFoldersToUpload = updatedBriefcaseFolders
      .slice(1)
      .map((folder, index) => {
        return {
          groupId: folder.id,
          position: index,
        };
      });

    dispatch(updateFoldersPositions({ spaceId, data: UpdatedFoldersToUpload }));
    console.log(updatedBriefcaseFolders);
    setDraggedItemType('null');
  };

  const closeConfirmRemove = () => {
    setConfirmRemoveDocument(false);
  };
  // const closeRenameDocumentModal = () => {
  //   setOpenRenameModal(false);
  // };

  const removeDocument = (documentId: string) => {
    setIsRemoveLoading(true);

    dispatch(removeBriefcaseFile({ spaceId, documentId })).then(({ type }) => {
      setIsRemoveLoading(false);
      if (type.includes('fulfilled')) {
        toast.success('Document removed successfully');
        if (selectedDocument && selectedDocument.groupId) {
          const groupId = selectedDocument.groupId;
          dispatch(getFolder({ spaceId, groupId }));
        } else {
          dispatch(getBriefcaseDocuments(spaceId));
        }
      }
    });
    closeConfirmRemove();
    setSelectedDocument(null);
    dispatch(getBriefcaseDocuments(spaceId)); //to update the briefcase files

    return;
  };

  const handleRemoveDocument = () => {
    if (selectedDocument) {
      removeDocument(selectedDocument.documentId);
    }
  };

  // const handleFileUploadCancel = () => {
  //   setIsFileUploadModalOpen(false);
  // };

  const [titleName, setTitleName] = useState('');

  const closeRenameModal = () => {
    setConfirmRenameDocument(false);
  };

  const handleRenameDocument = () => {
    setIsRenameLoading(true);
    console.log(selectedDocument);

    setIsRenameLoading(false);

    if (selectedDocument) {
      const { documentId, groupId } = selectedDocument;

      // const formData = new FormData();
      // formData.append('Title', titleName);
      // formData.append('DocumentId', documentId);

      const data = {
        spaceId,
        newTitle: titleName,
      };

      dispatch(renameDocument({ documentId, data })).then(({ type }) => {
        if (type.includes('fulfilled')) {
          toast.success('Document renamed successfully');

          if (groupId) {
            dispatch(getFolder({ spaceId, groupId }));
          } else {
            dispatch(getBriefcaseDocuments(spaceId));
          }

          // to be looked at
          // dispatch(getFolderFiles(mostActiveFolder?.id));
        }
        setIsRenameLoading(false);
        closeRenameModal();
      });
    }
  };

  const handleDownload = async (doc: BriefcaseGroupDocument) => {
    try {
      const match = doc.url.match(/\.([a-zA-Z0-9]+)$/);
      const fileExtension = match ? match[1] : null;
      const response = await Axios.get(`Document/Download/${doc.documentId}`, {
        responseType: 'arraybuffer',
      });

      console.log(response);

      if (response) {
        const url = URL.createObjectURL(new Blob([response.data]));
        console.log(url);
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.download = `${doc.title}.${fileExtension}`; // Set the filename (adjust the extension accordingly)
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className=''>
      <section className=''>
        {briefcaseFoldersFolders.map((folder, index) => (
          <React.Fragment key={index}>
            <GroupSection
              folderIndex={index}
              folder={folder}
              setActiveCard={setActiveCard}
              setActiveSection={setActiveSection}
              onDropDocument={onDropDocument}
              spaceId={spaceId}
              imageCache={imageCache}
              setConfirmRemoveDocument={setConfirmRemoveDocument}
              setSelectedDocument={setSelectedDocument}
              setDraggedItemType={setDraggedItemType}
              draggedItemType={draggedItemType}
              setConfirmRenameDocument={setConfirmRenameDocument}
              handleDownload={handleDownload}
            />
            <DropAreaSection
              onDropSection={() => onDropSection(index)}
              // dropAreaPosition={index + 1}
              dropAreaPosition={index}
              draggedItemType={draggedItemType}
            />
          </React.Fragment>
        ))}
      </section>

      {/* Modals */}
      <ConfirmationModal
        open={confirmRemoveDocument}
        isLoading={isRemoveLoading}
        onClose={closeConfirmRemove}
        func={handleRemoveDocument}
        text='Delete document'
        subText='Are you sure you want to delete this document?'
      />
      <Modal
        footer={null}
        centered={false}
        open={confirmRenameDocument}
        onCancel={closeRenameModal}
        style={{
          top: 20,
        }}
        destroyOnClose
        closeIcon={false}
      >
        <RenameModal
          closeModal={closeRenameModal}
          setTitleName={setTitleName}
          func={handleRenameDocument}
          isLoading={isRenameLoading}
          previousTitle={selectedDocument?.title ? selectedDocument?.title : ''}
          titleName={titleName}
          heading='Document title'
          subHeading='Rename your document'
          buttonText='Update title'
        />
      </Modal>
    </section>
  );
}
