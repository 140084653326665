import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import {
  getTeamInfo,
  getTeamMembers,
  getTeamsState,
} from 'store/Teams/teamsSlice';

export default function EventsLayout() {
  const dispatch = useDispatch<AppDispatch>();
  // const navigate = useNavigate();
  // //state & store
  // const { teamInfo } = useSelector(getTeamsState);
  // const briefcases = useSelector(
  //   (state: RootState) => state.briefcase.briefcases
  // );
  // const tab = useSelector((state: RootState) => state.briefcaseTab.tab);

  //useeffects
  useEffect(() => {
    dispatch(getTeamInfo()).then((response) => {
      console.log(response);
      if (!response.payload) {
        console.log('One');
      } else {
        console.log('two');
      }
    });
    dispatch(getTeamMembers());
  }, [dispatch]);

  return (
    <section className=''>
      {/* imitating the nav */}
      {/* <div className='h-20 w-full'></div> */}
      <section>
        {/* <Navbar /> */}
        <Outlet />
      </section>
    </section>
  );
}
