import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

//assets
import logo from "assets/svg/fullLogo.svg";
import lineBg from "assets/svg/lineBg.svg";

//libraries
import axios from "axios";
import {ClipLoader} from "react-spinners";

//utils
import {baseUrl} from "utils/baseUrl";
import {altLogins} from "./data";

//components
import Label from "components/UI/Label";
import AuthInput from "components/UI/Inputs/AuthInput";
import LoginCarousel from "components/Authentication/Login/Carousel";

//utils
import {toast} from "sonner";

export default function Login() {
    const navigate = useNavigate();

    //usestate
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [urlParams, setUrlParams] = useState({});

    useEffect(() => {
        const {email, password} = formData;
        if (email !== "" && password !== "") {
            setIsButtonActive(true);
        } else {
            setIsButtonActive(false);
        }
    }, [formData]);

    useEffect(() => {
        const getUrlParam = new URLSearchParams(window.location.search);
        setUrlParams({
            redirect: getUrlParam.get("redirect"),
            app: getUrlParam.get("app"),
            meetingId: getUrlParam.get("meetingId"),
        });
    }, []);

    //functions

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const loginFunc = (e) => {
        e.preventDefault();

        if (formData.email === "") {
            toast.error("Enter an email address");
            return;
        }

        if (formData.password === "") {
            toast.error("Enter password");
            return;
        }

        const data = {
            userName: formData.email,
            password: formData.password,
        };

        setIsLoading(true);
        setIsButtonActive(false);

        axios
            .post(`${baseUrl}/Account/login`, data)
            .then((response) => {
                localStorage.setItem("email", formData.email);

                localStorage.setItem("user", JSON.stringify(response.data));

                if (response.data?.code) {
                    localStorage.setItem("ref", response?.data?.code);
                    navigate("/otp");
                } else if (urlParams?.app == "zoom") {
                    console.log(urlParams.redirect, "Url Redirect");
                    console.log("Token", response.data.token);
                    const date = new Date();
                    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
                    let expires = "; Expires=" + date.toUTCString();

                    const cookieValue = `presenta-token=${response.data.token}; Path=/; domain=.thePresenta.com; SameSite=None; Secure`;
                    document.cookie = cookieValue;

                    alert(cookieValue);

                    window.location.href = `${urlParams?.redirect}`;
                } else {
                    navigate("/admin/mypresentations");

                }
            })
            .catch((err) => {
                let message = err?.response?.data?.message ?? err.message;
                toast.error(message);
            })
            .finally(() => {
                setIsLoading(false);
                setIsButtonActive(true);
            });
    };

    const openWindow = (name) => {
        let domain = window.location.host;
        // https://testapi.thepresenta.com/
        window.location.href = `${baseUrl}/login/${name}?origin=https://${domain}/login`;
    };

    const lineBgStyle = {
        background: `url(${lineBg}) no-repeat bottom`,
        // backgroundSize: "cover",
    };

    return (
        <section className="flex justify-between h-screen overflow-y-hidden font-grotesk ">
            {/* form */}
            <section style={lineBgStyle} className="lg:w-[40%] w-full  lg:py-4 py-10">
                <Link to="/">
                    <div className="mb-4">
                        <img src={logo} alt="Presenta Logo" className=" ml-5 lg:w-24"/>
                    </div>
                </Link>

                <section className="lg:mt-6 mt-[3%] w-[90%] mx-auto ">
                    <h1 className="font-bold lg:text-[2rem] text-2xl  text-left  ">
                        Login
                    </h1>

                    <section className="mt-4">
                        <form onSubmit={loginFunc} className="">
                            <div>
                                <Label text="Email address"/>
                                <AuthInput
                                    type="email"
                                    name="email"
                                    placeholder="example@presenta.com"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>

                            <div className="my-4">
                                <Label text="Password"/>
                                <AuthInput
                                    isPassword={true}
                                    placeholder="Enter your password"
                                    name="password"
                                    onChange={(e) => handleInputChange(e)}
                                />
                                <div className="text-right w-full">
                                    <Link
                                        to={"/forgot-password"}
                                        className="text-sm text-[#667085] font-semibold"
                                    >
                                        Forgot password
                                    </Link>
                                </div>
                            </div>

                            <button
                                // disabled={!isButtonActive}
                                className={`w-full py-3  text-sm text-white bg-black rounded-md ${
                                    isButtonActive
                                        ? "opacity-100"
                                        : "opacity-50 cursor-not-allowed"
                                }`}
                            >
                                {isLoading ? <ClipLoader color="white" size={12}/> : "Login"}
                            </button>
                        </form>

                        <div className="flex items-center justify-center mt-4 gap-x-2 ">
                            <p className="text-sm ">Don’t have an account?</p>

                            <Link to="/signup">
                                <div
                                    className="flex items-center px-2 py-1 text-xs border border-black rounded gap-x-2 ">
                                    <p className="font-semibold">Join Us</p>
                                    <i className="pi pi-arrow-right"></i>
                                </div>
                            </Link>
                        </div>
                    </section>

                    <div className="font-medium text-center lg:my-4 text-gray40 ">
                        <h3>OR</h3>
                    </div>

                    <section className="flex flex-col justify-center my-4 gap-y-2 gap-x-5 lg:mb-6">
                        {altLogins.map((el, index) => {
                            return (
                                <div
                                    onClick={() => openWindow(el.name)}
                                    key={index}
                                    className="flex items-center justify-center py-2 border-2 rounded-full cursor-pointer gap-x-4 "
                                >
                                    <div>
                                        <img
                                            src={el.img}
                                            alt={`${el.name} Logo`}
                                            className="w-5 lg:w-[1.5rem]"
                                        />
                                    </div>
                                    <div className="text-sm font-medium ">
                                        Sign in with {el.name}
                                    </div>
                                </div>
                            );
                        })}
                    </section>
                </section>
            </section>
            {/*  */}

            {/* carousel */}
            <section className="lg:w-[60%] hidden lg:block bg-orange10 h-screen px-10 ">
                <LoginCarousel/>
            </section>
            {/*  */}
        </section>
    );
}
