import {baseUrl} from "utils/baseUrl";

import axios from "axios";
import {getToken} from "utils/localStorageServices";
import {toast} from "sonner";

const instance = axios.create({
    baseURL: baseUrl,
    headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
    // timeout: 1000,
});

instance.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        if (getToken()) {
            config.headers["Authorization"] = `Bearer ${getToken() ?? ""}`;
        }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger

        // Do something with response data
        return response;
    },
    function (error) {
        let errorStatus = error?.response?.status;
        const errorList = error?.response?.data?.errors;
        let requestMsg =
            error?.response?.data?.message ??
            error.response?.data?.title ??
            error?.message;
        const isViewerMode = window.location.href?.includes("viewer-mode");

        if (
            !errorStatus &&
            requestMsg === "Network Error" &&
            !error?.config?.url?.includes("/Document/stream/")
        ) {
            toast.error("Network Error");

            return;
        }

        if (errorStatus === 401 && !isViewerMode) {
            toast.error("Session Expired");
            localStorage.clear();
            const currentUrl = window.location.href;
            localStorage.setItem("previousPath", window.location.pathname);
            if (currentUrl.toLowerCase().includes("external")) {
                const newTabUrl = `${window.location.origin}/login`;
                // Try opening the new tab
                const newTab = window.open(newTabUrl, "_blank");
                // Check if the pop-up was blocked
                if (newTab === null || typeof newTab === "undefined") {
                    // Redirect normally since the pop-up is blocked
                    window.location.href = "/login";
                }
            } else {
                window.location.href = "/login";
            }
            return Promise.reject(error);
        }
        // errorStatus === 404 ||
        if (isViewerMode && errorStatus === 401) {
            let routeOrigin = window.location.origin;
            let presentationId = window.location.href?.split("/")?.[4];
            localStorage.setItem("connectionId", "");

            toast.error(requestMsg);
            // toast.error(requestMsg);
            if (requestMsg) {
                setTimeout(() => {
                    // window.location.href = `${routeOrigin}/join-presentation/${presentationId}`;
                }, 1000);
            }
        }

        if (requestMsg === "One or more validation errors occurred.") {
            for (let error in errorList) {
                toast.error(errorList[error][0]);
                // toast.error(errorList[error][0]);
            }
        } else if (!error?.config?.url?.includes("/Document/stream/")) {
            toast.error(requestMsg);
            // toast.error(requestMsg);
        }

        return Promise.reject(error);
    }
);

export default instance;
