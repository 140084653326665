import { Dispatch, SetStateAction, useState } from 'react';
import emptyThumbnail from 'assets/image/empty-doc-thumbnail.png';
import addIcon from 'assets/svg/add-circle-gray.svg';
import reminder from 'assets/svg/alarm.svg';
import linkGray from 'assets/svg/link-gray.svg';

// Assets
import GroupRow from './GroupRow';

// Interface & types
import GroupHeader from './GroupHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/store';
import {
  AgendaItem,
  CreateEventResponsePayload,
  GetEventResponsePayload,
  PresentationDocument,
} from 'interfaces/Events/EventsInterfaces';
import { sendSessionReminder } from 'store/Events/EventsSlice';
import { Modal } from 'antd';
import UploadFiles from '../Modals/UploadFiles';
import InviteCoPresenters from '../Modals/InviteCoPresenters';
import { getEmail } from 'utils/localStorageServices';
import { getTeamsState } from 'store/Teams/teamsSlice';
import { toast } from 'sonner';
import copyLink from 'utils/copyLink';
import { SortableContext, useSortable } from '@dnd-kit/sortable';

interface ImageCache {
  [id: string]: string;
}

interface Props {
  section: AgendaItem;
  folderIndex: number;
  eventId: string;
  imageCache: ImageCache;
  setConfirmRemoveDocument: Dispatch<SetStateAction<boolean>>;
  setSelectedDocument: Dispatch<SetStateAction<PresentationDocument | null>>;
  setSelectedFolder: Dispatch<SetStateAction<AgendaItem | null>>;
  setConfirmRenameDocument: Dispatch<SetStateAction<boolean>>;
  handleDownload: (doc: PresentationDocument) => void;
  eventDetails: CreateEventResponsePayload;
}

export default function GroupSection({
  section,
  folderIndex,
  eventId,
  imageCache,
  setConfirmRemoveDocument,
  setSelectedDocument,
  setConfirmRenameDocument,
  handleDownload,
  eventDetails,
  setSelectedFolder,
}: Props) {
  //

  // States
  const [viewFolderDocuments, setViewFolderDocuments] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const { setNodeRef, attributes, listeners, transform } = useSortable({
    id: section.id,
    data: {
      type: 'Section',
      section,
    },
    //to disable drag and drop in edit mode
    // disabled: editMode,
  });

  const style = transform
    ? {
        transform: `translate(${transform.x}px, ${transform.y}px)`,
      }
    : undefined;

  return (
    <section ref={setNodeRef} style={style} className='mb-5 h-fit'>
      <GroupHeader
        section={section}
        folderIndex={folderIndex}
        setViewFolderDocuments={setViewFolderDocuments}
        viewFolderDocuments={viewFolderDocuments}
        eventId={eventId}
        eventDetails={eventDetails}
        attributes={attributes}
        listeners={listeners}
      />

      {viewFolderDocuments && (
        <section>
          {section.presentation &&
          section.presentation.doc &&
          section.presentation.doc.length ? (
            <SortableContext items={section.presentation.doc}>
              {section.presentation.doc.map((doc, index) => {
                return (
                  <GroupRow
                    key={index}
                    doc={doc}
                    section={section}
                    imageCache={imageCache}
                    eventId={eventId}
                    setConfirmRemoveDocument={setConfirmRemoveDocument}
                    setSelectedDocument={setSelectedDocument}
                    setSelectedFolder={setSelectedFolder}
                    setConfirmRenameDocument={setConfirmRenameDocument}
                  />
                );
              })}
            </SortableContext>
          ) : (
            <EmptyState
              setOpenUploadModal={setOpenUploadModal}
              agenda={section}
              eventDetails={eventDetails}
            />
          )}
        </section>
      )}
      <Modal
        footer={null}
        centered={false}
        open={openUploadModal}
        width={452}
        closeIcon={false}
        style={{
          top: 20,
        }}
        destroyOnClose
      >
        <UploadFiles
          agenda={section}
          setOpenUploadModal={setOpenUploadModal}
          setOpenInviteModal={setOpenInviteModal}
          presentationId={section.presentationId}
        />
      </Modal>

      <Modal
        footer={null}
        centered={false}
        open={openInviteModal}
        width={452}
        closeIcon={false}
        style={{
          top: 20,
        }}
        destroyOnClose
      >
        <InviteCoPresenters
          agenda={section}
          setOpenInviteModal={setOpenInviteModal}
          func={() => {}} //REmember to add this
        />
      </Modal>
    </section>
  );
}

const EmptyState = ({
  setOpenUploadModal,
  agenda,
  eventDetails,
}: {
  setOpenUploadModal: Dispatch<SetStateAction<boolean>>;
  agenda: AgendaItem;
  eventDetails: GetEventResponsePayload;
}) => {
  const hasAccess = agenda.collaborators.some((it) => it.email === getEmail());

  const { teamInfo } = useSelector(getTeamsState);
  const teamId = teamInfo?.data?.id;
  const dispatch = useDispatch<AppDispatch>();
  const handleSendSessionReminder = () => {
    dispatch(sendSessionReminder({ sessionId: agenda.id, teamId })).then(
      ({ type }) => {
        if (type.includes('fulfilled')) {
          toast.success('Reminder was sent');
        }
      }
    );
  };
  return (
    <section className='w-full flex justify-center bg-[#FBFBFB] py-5'>
      <section className='flex flex-col items-center gap-2'>
        <img src={emptyThumbnail} alt='' />

        <div className={``}>
          {eventDetails.role === 'EventOwner' ? (
            <div>
              {hasAccess ? (
                <p className='text-black font-semibold'>
                  You have a pending content to upload
                </p>
              ) : (
                <p className='text-black font-semibold'>
                  Content upload request sent
                </p>
              )}
            </div>
          ) : (
            <p className='text-black font-semibold'>
              You have a pending content to upload
            </p>
          )}
        </div>
        <div className={` ${!hasAccess && 'hidden'}`}>
          <button
            className={`h-8 px-3 rounded-lg bg-transparent border border-[#AFAFAF] py-1 text-sm text-[#545454] `}
            onClick={(e) => {
              e.preventDefault();
              setOpenUploadModal(true);
            }}
          >
            <div className={`flex gap-x-2 items-center`}>
              <img src={addIcon} alt='' />
              <p className='font-medium'>Upload content</p>
            </div>
          </button>
        </div>

        <div
          className={` ${
            agenda.role !== 'EventOwner' && 'hidden'
          } flex gap-x-2`}
        >
          <button
            className={`h-8 px-3 rounded-lg bg-transparent border border-[#AFAFAF] py-1 text-sm text-[#545454] `}
            onClick={(e) => {
              e.preventDefault();
              handleSendSessionReminder();
            }}
          >
            <div className={`flex gap-x-2 items-center`}>
              <p className='font-medium'>Send reminder</p>
              <img src={reminder} alt='' />
            </div>
          </button>
          <button
            className={`h-8 px-3 rounded-lg bg-transparent border border-[#AFAFAF] py-1 text-sm text-[#545454] `}
            onClick={(e) => {
              e.preventDefault();
              copyLink(`join-event/${eventDetails.id}`);
            }}
          >
            <div className={`flex gap-x-2 items-center`}>
              <p className='font-medium'>Copy link</p>
              <img src={linkGray} alt='' />
            </div>
          </button>
        </div>
      </section>
    </section>
  );
};
