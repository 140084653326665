import { toast } from "react-toastify";
import { getConnectionState } from "store/SignalR/signalRSlice";

export const GetUsersList = (connection, presentationId) => {
  const connectionState = getConnectionState();
  console.log("Inside connectin get User list");
  if (connectionState === "Connected") {
    connection.invoke("GetUsers", presentationId , false).catch((err) => {
      toast.error("An error occured while fetching the list of viewers");
      console.log(err);
    });
  }
};
