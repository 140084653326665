import BulkUpload from 'components/UI/BulkUpload/BulkUpload';
import { CButton } from 'components/UI/Button/Buttons';
import { AgendaItem } from 'interfaces/Events/EventsInterfaces';
import { Dispatch, SetStateAction, useState } from 'react';
import send from 'assets/svg/send-2.png';
import skip from 'assets/svg/skip.svg';
import closeIcon from 'assets/svg/close-icon2.svg';

export default function InviteCoPresenters({
  agenda,
  setOpenInviteModal,
  func,
}: {
  agenda: AgendaItem;
  setOpenInviteModal: Dispatch<SetStateAction<boolean>>;
  func: ({ email, emailList }: { email: string; emailList: string[] }) => void;
}) {
  const [email, setEmail] = useState('');
  const [emailList, setEmailList] = useState<string[]>([]);

  const handleRemoveEmail = (index: number) => {
    const updatedEmails = emailList.filter((_, i) => i !== index);
    setEmailList([...updatedEmails]);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value.trim();
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      if (email === ',') {
        setEmail('');
        return;
      }

      if (email) {
        if (emailList.includes(email)) {
          setEmail('');
          return;
        } else {
          setEmailList([...emailList, email]);
        }
      }
      setEmail('');
    }
  };

  const handleSkip = () => {
    setOpenInviteModal(false);
    setEmail('');
    setEmailList([]);
  };

  const handleSendInvite = () => {
    func({ email, emailList });
  };
  return (
    <section>
      <div className='flex justify-between items-center'>
        <h2 className='text-2xl font-semibold'>Invite co-presenters</h2>
        <img
          src={closeIcon}
          alt='close modal '
          className='w-12 cursor-pointer'
          onClick={() => handleSkip()}
        />
      </div>

      <BulkUpload
        item={email}
        itemList={emailList}
        handleKeyDown={handleKeyDown}
        removeItem={handleRemoveEmail}
        setItem={setEmail}
        inputType={'email'}
      />

      <div className='flex flex-col gap-3 mt-5'>
        <CButton
          text='Send invite'
          variant='dark'
          fullWidth
          icon={send}
          action={handleSendInvite}
        />
        <CButton
          text='Skip'
          variant='plain'
          fullWidth
          icon={skip}
          action={handleSkip}
        />
      </div>
    </section>
  );
}
