import { useEffect, useRef, useState } from 'react';
import { imageStream } from 'utils/imageStream';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { getSlides } from 'store/Briefcase/briefcaseSlice';
import useScreenWidth from 'hooks/UseScreenWidth';
import { ImageCache } from 'types/Users/SharedBriefcase/SharedBriefcaseTypes';

interface Props {
  flattedDisplayDocuments: any;
}

export default function usePreview({ flattedDisplayDocuments }: Props) {
  //
  const dispatch = useDispatch<AppDispatch>();
  const screenWidth = useScreenWidth();
  const minScreenWidth = 2000;
  const activeImgRef = useRef<HTMLImageElement | null>(null);
  // State
  const [activeImage, setActiveImage] = useState<string | null>(null);
  const [isSlides, setIsSlides] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [displayDocumentIndex, setDisplayDocumentIndex] = useState<number>(0);
  const [slideDocumentIndex, setSlideDocumentIndex] = useState<number>(0);
  const [isGridDisplayActive, setIsGridDisplayActive] = useState(false);
  const [isFullscreen, setIsFullScreen] = useState(false);
  const [fetchedFlattenedDocIndex, setFetchedFlattenedDocIndex] = useState<
    number | null
  >(null); //holds the max fetched document index
  const [slides, setSlides] = useState<string[]>([]);
  const imageCache = useRef<ImageCache[]>([]);
  const fullScreenContainerRef = useRef<HTMLElement | null>(null);

  // Loops through all the docs and fetch them

  const BATCH_SIZE = 1; // Number of documents/slides to fetch in a batch

  useEffect(() => {
    if (flattedDisplayDocuments?.length > 0) {
      const fetchBatch = async (
        startIndex: number,
        endIndex: number
      ): Promise<ImageCache[]> => {
        const batch = flattedDisplayDocuments.slice(startIndex, endIndex);
        const batchResults: ImageCache[] = [];

        for (let i = 0; i < batch.length; i++) {
          const doc = batch[i];
          if (doc?.url?.endsWith('.pptx') || doc?.url?.endsWith('.pdf')) {
            // Fetch slides for presentations
            const result = await dispatch(getSlides(doc.documentId));
            if (result.type.includes('fulfilled')) {
              const slidePromises = result.payload.slides.map(
                async (slide: { id: string }) => await imageStream(slide.id)
              );
              const slides = await Promise.all(slidePromises); // Fetch all slide URLs concurrently
              batchResults.push({ id: slides }); // Store as array of strings
            }
          } else {
            // Fetch single document images
            const url = await imageStream(doc.documentId);
            url && batchResults.push({ id: [url] }); // Store as single string
          }
        }

        return batchResults;
      };

      const fetchAllDocumentsIncrementally = async () => {
        const allResults: ImageCache[] = [];
        for (let i = 0; i < flattedDisplayDocuments.length; i += BATCH_SIZE) {
          const endIndex = Math.min(
            i + BATCH_SIZE,
            flattedDisplayDocuments.length
          );
          const batchResults = await fetchBatch(i, endIndex);
          allResults.push(...batchResults);

          imageCache.current.push(...batchResults);
          // console.log(`Fetched batch from ${i} to ${endIndex}`, batchResults);
          setFetchedFlattenedDocIndex(endIndex);
        }

        // console.log('All documents fetched:', allResults);
        imageCache.current = allResults;
      };

      fetchAllDocumentsIncrementally();
    }
  }, [flattedDisplayDocuments, dispatch]);

  //

  useEffect(() => {
    // setIsLoading(true);
    setActiveImage(null); //setting active image to null before handling the if statement.

    if (
      fetchedFlattenedDocIndex &&
      displayDocumentIndex <= fetchedFlattenedDocIndex - 1
    ) {
      if (
        flattedDisplayDocuments[displayDocumentIndex]?.url?.endsWith('.pptx') ||
        flattedDisplayDocuments[displayDocumentIndex]?.url?.endsWith('.pdf')
      ) {
        imageCache.current[displayDocumentIndex]?.id &&
          setSlides(imageCache.current[displayDocumentIndex]?.id);

        setActiveImage(imageCache.current[displayDocumentIndex]?.id[0]);
        setIsLoading(false);
        setIsSlides(true);
      } else {
        setActiveImage(imageCache.current[displayDocumentIndex]?.id[0]);
        setIsLoading(false);
        setIsSlides(false);
        setIsGridDisplayActive(false);
      }
    }
  }, [
    displayDocumentIndex,
    flattedDisplayDocuments,
    dispatch,
    slideDocumentIndex,
    fetchedFlattenedDocIndex,
  ]);

  // For setting active slide or document, handles getting the stream when the slideindex and document index changes
  useEffect(() => {
    if (imageCache.current.length && !isSlides) {
      imageCache.current[displayDocumentIndex]?.id &&
        setActiveImage(imageCache.current[displayDocumentIndex]?.id[0]);
    } else if (flattedDisplayDocuments && isSlides && slides.length > 0) {
      setActiveImage(
        imageCache.current[displayDocumentIndex]?.id[slideDocumentIndex]
      );
    }
  }, [
    flattedDisplayDocuments,
    displayDocumentIndex,
    isSlides,
    slides,
    slideDocumentIndex,
  ]);

  const [isOpen] = useState(false);

  const handleNextDoc = () => {
    if (displayDocumentIndex === flattedDisplayDocuments.length - 1) {
      return;
    } else {
      setDisplayDocumentIndex((prevIndex) => prevIndex + 1);
    }
  };
  const handlePrevDoc = () => {
    if (displayDocumentIndex === 0) {
      return;
    } else {
      setDisplayDocumentIndex((prevIndex) => prevIndex - 1);
    }
  };
  const handleNextSlideDoc = () => {
    // console.log(slides.length, 'Slides length');
    setSlideDocumentIndex((prevIndex) =>
      Math.min(
        prevIndex + 1,
        imageCache.current[displayDocumentIndex]?.id.length - 1
      )
    );
  };

  const handlePrevSlideDoc = () => {
    setSlideDocumentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  // Assuming activeImgRef is defined as a ref for an HTML element
  const enterFullscreen = () => {
    const elem = document.documentElement; // Entire document (browser fullscreen)
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((elem as any)?.mozRequestFullScreen) {
      // Firefox
      (elem as any).mozRequestFullScreen();
    } else if ((elem as any)?.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      (elem as any).webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      // Firefox
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      // Chrome, Safari, and Opera
      (document as any).webkitExitFullscreen();
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    // Ensuring this only run only when the there is slides
    if (slides.length === 0) {
      return;
    }

    if (e.key === 'ArrowLeft') {
      handlePrevSlideDoc();
    } else if (e.key === 'ArrowRight') {
      handleNextSlideDoc();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [slides]); //ensures to reattach eventListener when slide changes

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    // Cleanup listeners on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      enterFullscreen();
      setIsFullScreen(true);
    } else {
      exitFullscreen();
    }
  };

  const handleGridDisplay = () => {
    setIsGridDisplayActive(!isGridDisplayActive);
  };

  const itemRefs = useRef<(HTMLElement | null)[]>([]); // Store references to all items

  useEffect(() => {
    const currentItem = itemRefs.current[slideDocumentIndex];

    if (currentItem) {
      currentItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center', // Center alignment for better view
      });
    }
  }, [slideDocumentIndex, itemRefs]);

  return {
    screenWidth,
    minScreenWidth,
    activeImgRef,
    activeImage,
    isLoading,
    isFullscreen,
    isOpen,
    handleNextDoc,
    handlePrevDoc,
    handleFullscreen,
    handleGridDisplay,
    itemRefs,
    displayDocumentIndex,
    setDisplayDocumentIndex,
    imageCache,
    setSlideDocumentIndex,
    fetchedFlattenedDocIndex,
    flattedDisplayDocuments,
    slides,
    slideDocumentIndex,
    isGridDisplayActive,
    setIsGridDisplayActive,
    isSlides,
    handlePrevSlideDoc,
    handleNextSlideDoc,
    fullScreenContainerRef,
  };
}
