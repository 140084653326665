import backArrow from 'assets/svg/arrow-left-outline.svg';
import ellipsisCircle from 'assets/svg/ellipsis-horizontal-circle.svg';
import playBtn from 'assets/svg/play.svg';
import { Poll } from 'interfaces/PollSetup';
import { useNavigate } from 'react-router-dom';

interface Props {
  handleCreatePoll: () => void;
  poll: Poll;
  handleUpdateTitle: ({ newTitle }: { newTitle: string }) => void;
}

export default function Navbar({
  handleCreatePoll,
  poll,
  handleUpdateTitle,
}: Props) {
  const navigate = useNavigate();

  return (
    <nav className='fixed top-0 left-0 w-full h-[80px] bg-white border-b z-50'>
      <section className='flex justify-between items-center h-full'>
        <section className='h-full py-2 flex'>
          <div
            className='w-20 flex items-center justify-center h-full border-r cursor-pointer'
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={backArrow} alt='arrow back' />
          </div>
          <div className='w-[200px] flex flex-col justify-center h-full border-r  pl-5'>
            <p className='text-sm'>Team workspace</p>
            <p className='font-semibold truncate'>{poll.title ?? 'My poll'}</p>
          </div>
        </section>
        <section className='h-full py-2 flex items-center'>
          <div className='h-[37px] p-1 bg-[#EEEEEE] flex gap-x-5 rounded-md'>
            <div className='flex gap-x-2 bg-white p-2 items-center rounded-md'>
              <p className='font-semibold'>Questions</p>
              <div className='bg-primary h-[25px] w-[25px] flex items-center justify-center rounded-md text-white'>
                <p className='text-xs'>20</p>
              </div>
            </div>
            <div className='flex gap-x-2 items-center'>
              <p>Result</p>
              <div className='bg-[#E2E2E2] h-[25px] w-[25px] flex items-center justify-center rounded-md text-[#333333]'>
                <p className='text-xs'>20</p>
              </div>
            </div>
          </div>
        </section>
        <section className='flex gap-x-3 pr-5 items-center'>
          <div
            onClick={handleCreatePoll}
            className='border-2 rounded-md border-primary flex gapx-4 px-4 h-7 items-center cursor-pointer'
          >
            <img src={playBtn} alt='play botton' />
            <p className='text-sm text-[#ED6E33]'>Preview</p>
          </div>
          <div className='px-2 border rounded-md cursor-pointer bg-gray20 h-8 flex items-center'>
            <img src={ellipsisCircle} alt='more options' className='w-4' />
          </div>
        </section>
      </section>
    </nav>
  );
}
