import { Dispatch, SetStateAction } from 'react';

// Assets
import dragNdropIcon from 'assets/svg/drag-drop-icon.svg';
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import eyeI from 'assets/svg/eye-2.svg';
import trashDarkRed from 'assets/svg/trash-dark-red.svg';
import rename from 'assets/svg/rename.svg';

// Libraries
import { Dropdown, Menu, Tooltip } from 'antd';

// Utils
import FileThumbnailCache from 'components/Admin/BriefCase/FileThumbnailCache';
import {
  AgendaItem,
  PresentationDocument,
} from 'interfaces/Events/EventsInterfaces';
import { useSortable } from '@dnd-kit/sortable';
interface ImageCache {
  [id: string]: string;
}

interface Props {
  doc: PresentationDocument;
  imageCache: ImageCache;
  eventId: string;
  setConfirmRemoveDocument: Dispatch<SetStateAction<boolean>>;
  setSelectedDocument?: Dispatch<SetStateAction<PresentationDocument | null>>;
  setConfirmRenameDocument: Dispatch<SetStateAction<boolean>>;
  setSelectedFolder: Dispatch<SetStateAction<AgendaItem | null>>;
  section?: AgendaItem;
}

export default function GroupRow({
  doc: document,
  imageCache,
  eventId,
  setConfirmRemoveDocument,
  setSelectedDocument,
  setConfirmRenameDocument,
  setSelectedFolder,
  section,
}: Props) {
  const openInNewTab = (url: string) => {
    window.open(url, '_blank'); // Opens in a new tab
  };

  const items = [
    {
      key: '1',
      action: 'rename',
      label: (
        <div
          className='flex gap-x-2 pl-2 pr-5'
          onClick={() => {
            setSelectedDocument && setSelectedDocument(document);
            setConfirmRenameDocument(true);
          }}
        >
          <img src={rename} alt='rename file' className='w-5' />
          <p>Rename document</p>
        </div>
      ),
    },

    {
      key: '2',
      action: 'preview',
      label: (
        <div
          className='flex gap-x-2 pl-2 pr-5'
          onClick={() => {
            openInNewTab(`/events/preview/${eventId}/${document.documentId}`);
          }}
        >
          <img src={eyeI} alt='share file' className='w-5' />
          <p>Preview</p>
        </div>
      ),
    },

    {
      key: '3',
      action: 'delete',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl-2 pr-5'
          onClick={() => {
            setSelectedDocument && setSelectedDocument(document);
            section && setSelectedFolder(section);
            setConfirmRemoveDocument(true);
          }}
        >
          <img src={trashDarkRed} alt='delete file' className='w-5' />
          <p className='text-[#AE372A]'>Delete</p>
        </div>
      ),
    },
  ];

  const { setNodeRef, attributes, listeners, transform } = useSortable({
    id: document.id,
    data: {
      type: 'Row',
      document,
      presentationId: section?.presentationId ?? null,
    },
    //to disable drag and drop in edit mode
    // disabled: editMode,
  });

  const style = transform
    ? {
        transform: `translate(${transform.x}px, ${transform.y}px)`,
      }
    : undefined;

  return (
    <article
      className={`py-3 border-b`}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
    >
      {/* Rows */}
      <section className={`h-[36px] flex text-sm opacity-100`}>
        {/* table data 1  */}
        <section className={` w-[5%] flex h-full items-center justify-center`}>
          <div className='cursor-grab w-12 flex items-center pl-4'>
            <img
              src={dragNdropIcon}
              alt='drag'
              className='w-4 pointer-events-none'
            />
          </div>
        </section>
        {/* table 2 */}
        <section className={`w-[81%]`}>
          <section className='w-[400px] cursor-pointer h-full flex items-center pl-5'>
            <section className='flex items-center gap-x-4'>
              <div className='text-white text-xs rounded-lg overflow-hidden w-[36px] h-full flex items-center justify-center'>
                <FileThumbnailCache
                  imageCache={imageCache}
                  file={document}
                  height={36}
                  width={36}
                  imgTagheight={36}
                  imgTagwidth={36}
                />
              </div>

              {document.title.length > 50 ? (
                <Tooltip
                  title={
                    <p className='text-black capitalize'>{document.title}</p>
                  }
                  color='white'
                  placement='top'
                >
                  <p className='text-sm'>{`${document.title.slice(0, 50)}${
                    document.title.length > 50 ? '...' : ''
                  }`}</p>
                </Tooltip>
              ) : (
                <p className='text-sm'>{document.title}</p>
              )}
            </section>
          </section>
        </section>
        {/* table 3 */}
        <section className={`w-[14%] pr-10`}>
          <div className='w-[100px flex justify-end items-center cursor-pointer h-full'>
            <Dropdown
              trigger={['click']}
              dropdownRender={(menu) => (
                <Menu>
                  {items.map((item) => {
                    return <Menu.Item key={item.key}>{item.label}</Menu.Item>;
                  })}
                </Menu>
              )}
            >
              <div
                className='cursor-pointer py-4'
                onClick={(e) => e.stopPropagation()}
              >
                <img src={ellipsis} alt='options' />
              </div>
            </Dropdown>
          </div>
        </section>
      </section>
    </article>
  );
}
