import WebsiteLayout from 'components/Website/Layout/WebsiteLayout';
import { Route, Routes } from 'react-router-dom';
import AboutUs from 'views/Website/About';
import Home from 'views/Website/Home';
import Pricing from 'views/Website/Pricing';
import Support from 'views/Website/Support';

const landingPageRoutes = [
  {
    path: '/',
    element: (
      <Routes>
        <Route
          path='/'
          element={
            <WebsiteLayout>
              <Home />
            </WebsiteLayout>
          }
        />
      </Routes>
    ),
  },

  {
    path: '/about-us',
    element: (
      <WebsiteLayout>
        <AboutUs />
      </WebsiteLayout>
    ),
  },

  {
    path: '/pricing',
    element: (
      <WebsiteLayout>
        <Pricing />
      </WebsiteLayout>
    ),
  },

  {
    path: '/support',
    element: (
      <WebsiteLayout>
        <Support />
      </WebsiteLayout>
    ),
  },
  {
    path: '/about-us',
    element: (
      <WebsiteLayout>
        <AboutUs />
      </WebsiteLayout>
    ),
  },

  {
    path: '/pricing',
    element: (
      <WebsiteLayout>
        <Pricing />
      </WebsiteLayout>
    ),
  },

  {
    path: '/support',
    element: (
      <WebsiteLayout>
        <Support />
      </WebsiteLayout>
    ),
  },
];

export default landingPageRoutes;
