import {useEffect} from "react";
import {useNavigate} from "react-router-dom";


export const useIsPresentationAccessGiven = (connection, presentationId , isEvent) => {
    const navigate = useNavigate();
    useEffect(() => {
        const handleEventResponse = () => {
            navigate(isEvent ? `/viewer-mode-new/${presentationId}?isEvent=${isEvent}` : `/viewer-mode-new/${presentationId}`);
        };

        if (connection) {
            connection.on("AccessGranted", handleEventResponse);
        }

        return () => {
            if (connection) {
                connection.off("AccessGranted", handleEventResponse);
            }
        };
    }, [connection, presentationId, navigate]);
};
