import {toast} from "sonner";

export const RemoveGuest = (connection, presentationId, email, status, isEvent) => {
    if (connection) {
        console.log(presentationId, email, status, isEvent, "REmove User Payload")
        connection
            .invoke("RemoveUser", presentationId, email, status, isEvent)
            .catch((err) => {
                toast.error(err);
                console.log(err);
            });
    }
};
