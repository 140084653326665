import ProtectedRoute from 'routes/ProtectedRoute';
import PollSetup from 'views/Admin/PollSetup';

const pollsRoutes = [
  {
    path: 'poll-setup/:pollId',
    element: (
      <ProtectedRoute>
        <PollSetup />
      </ProtectedRoute>
    ),
  },

  {
    path: 'poll-setup',
    element: (
      <ProtectedRoute>
        <PollSetup />
      </ProtectedRoute>
    ),
  },
];

export default pollsRoutes;
