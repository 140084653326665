import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';

export default function Contact() {
  return (
    <section className='h-full my-10  [ grid grid-cols-1 lg:grid-cols-2 items-center p-10  justify-center ] [ lg:w-[80%] w-[90%] mx-auto ] [ lg:flex-row flex-col ]  [ lg:gap-x-6 gap-y-4 ]'>
      <section className='h-full'>
        <ContactInfo />
      </section>
      <section className='lg:w-[50% w-full h-full'>
        <ContactForm />
      </section>
    </section>
  );
}
