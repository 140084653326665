import {
  AddPresentationRequestPayload,
  AgendaItem,
} from 'interfaces/Events/EventsInterfaces';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addPresentation, getEvent } from 'store/Events/EventsSlice';
import { getTeamsState } from 'store/Teams/teamsSlice';
import closeIcon from 'assets/svg/close-icon2.svg';
import { AppDispatch } from 'store/store';
import eventThumbnail from 'assets/svg/eventThumbnail.svg';
import circle_i from 'assets/svg/circle_i-black.svg';
import UploadDocuments from 'views/Admin/Briefcase/Modals/UploadDocuments';
import dayjs from 'dayjs';
import addSquare from 'assets/svg/add-square.svg';
import { Modal } from 'antd';
import AddFile from 'views/Admin/Briefcase/Modals/AddFile';

export default function UploadFiles({
  agenda,
  setOpenUploadModal,
  setOpenInviteModal,
  presentationId,
}: {
  agenda: AgendaItem;
  setOpenUploadModal: Dispatch<SetStateAction<boolean>>;
  setOpenInviteModal: Dispatch<SetStateAction<boolean>>;
  presentationId: string | null;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [disableBTN, setDisableBTN] = useState(true);
  const { teamInfo } = useSelector(getTeamsState);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);

  const { eventId } = useParams();

  useEffect(() => {
    if ((title.length > 0 && description.length > 0) || presentationId) {
      setDisableBTN(false);
    } else {
      setDisableBTN(true);
    }

    // if (presentationId) {
    //   setDisableBTN(false);
    // }
  }, [title, description, presentationId]);

  const handleUploadFiles = async (
    uploadDocuments: (presentationId?: string) => void
  ) => {
    const data: AddPresentationRequestPayload = {
      title,
      description,
      eventId: agenda.eventId,
      sessionId: agenda.id,
    };

    dispatch(addPresentation(data)).then(async ({ type, payload }) => {
      if (type.includes('fulfilled')) {
        await uploadDocuments(payload.id);
        setOpenUploadModal(false);
        const teamId = teamInfo.data?.id;
        if (teamId && eventId) {
          dispatch(getEvent({ eventId, teamId }));
        }
        // Move to top to open invite co-presenter modal before fetching all the events
        setOpenInviteModal(true);
      }
    });
  };

  const handleFileUploadCancel = () => {
    setIsFileUploadModalOpen(false);
  };
  return (
    <section>
      <div className='flex justify-between items-center'>
        <h2 className='text-2xl font-semibold'>Upload your files</h2>
        <img
          src={closeIcon}
          alt='close modal '
          className='w-12 cursor-pointer'
          onClick={() => setOpenUploadModal(false)}
        />
      </div>

      <div className='mt-5 bg-[#FAFAFA] rounded-xl p-2'>
        <div className='rounded-full w-fit flex gap-x-2 px-2 items-center border border-[#D3D3D3] bg-[#F6F6F6]'>
          <img src={eventThumbnail} alt='' />
          <p className='font-medium'>{agenda.title}</p>
        </div>
        <p className='font-medium mt-1'>
          {dayjs(agenda.time).format('dddd, MMMM D, YYYY h:mm A')}
        </p>
      </div>

      <form>
        {!presentationId && (
          <div>
            <div className='flex flex-col gap-y-1 mt-5'>
              <label htmlFor='space' className='font-normal text-gray700'>
                Presentation Title
              </label>
              <input
                type='text'
                name='title'
                required
                value={title || ''}
                placeholder='e.g for external board members'
                className='py-3 pl-4 text-sm rounded-lg outline-none bg-orange10'
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
            <div className='flex flex-col gap-y-1 mt-5'>
              <label htmlFor='space' className='font-normal text-gray700'>
                Description
              </label>
              <textarea
                // type='text'
                name='title'
                // required
                value={description || ''}
                placeholder='e.g for external board members'
                className='py-3 pl-4 text-sm rounded-lg outline-none bg-orange10 h-[86px] resize-none'
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>

            <div className='h-[50px] w-full rounded-xl bg-[#EFF3FE] flex items-center gap-x-3 px-3 mt-5 text-black'>
              <img src={circle_i} alt='important!' className='w-6' />
              <p className='font-medium text-sm'>
                Published event will send emails to presenters if any
              </p>
            </div>
          </div>
        )}

        <button
          // disabled={multiFiles.length < 1 || isLoading || disableBTN}
          onClick={(e) => {
            e.preventDefault();
            console.log('Click');
            setIsFileUploadModalOpen(true);
            // eventFunc && eventFunc(submitUpload);
          }}
          disabled={disableBTN}
          className={`flex items-center justify-center w-full py-3 text-sm text-white rounded-lg cursor-pointer  gap-x-2 mt-5 ${
            disableBTN ? 'bg-gray-400' : 'bg-primary'
          }`}
        >
          <img src={addSquare} alt='upload slide' className='w-6' />
          <p>Upload content</p>
        </button>

        {/* <UploadDocuments
          closeModal={() => {}}
          fromEvent={true}
          eventFunc={handleUploadFiles}
          disableBTN={disableBTN}
        /> */}
      </form>

      <Modal
        open={isFileUploadModalOpen}
        onCancel={handleFileUploadCancel}
        footer={null}
        width={452}
        centered
        destroyOnClose={true}
        closable={false}
      >
        <AddFile
          closeAddFileModal={handleFileUploadCancel}
          fromEvent={true}
          eventFunc={handleUploadFiles}
          presentationId={agenda.presentationId ?? ''}
          // spaceId={spaceId}
          // groupId={folder.id}
        />
      </Modal>
    </section>
  );
}
