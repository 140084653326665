import React, {useEffect} from "react";

//libraries
import {toast} from "sonner";
import {ClipLoader} from "react-spinners";
import {getConnectionId, getUserDetails} from "utils/localStorageServices";

export default function JoinForm({
                                     triggerLoading,
                                     connection,
                                     presentationId,
                                     setNextStage,
                                     setIsLoading,
                                     isLoading,
                                     isEvent
                                 }) {


    const joinPresentationSinceAuthorised = (name, email) => {
        triggerLoading();
        if (connection) {
            if (isEvent) {
                connection
                    .invoke(
                        isEvent ? "RequestEventAccess" : "RequestAccess",
                        presentationId,
                        1,
                        email,
                        name,
                        getConnectionId() ? getConnectionId() : null,
                        isEvent ? presentationId : null
                    )
                    .catch((err) => {
                        setIsLoading(false);
                        console.log(err);
                        toast.error(
                            "An unexpected error occurred invoking 'RequestAccess' on the server"
                        );
                    });
            } else {
                console.log("Got called here alone")
                connection
                    .invoke(
                        "RequestAccess",
                        presentationId,
                        1,
                        email,
                        name,
                        getConnectionId() ? getConnectionId() : null,
                    )
                    .catch((err) => {
                        setIsLoading(false);
                        console.log(err);
                        toast.error(
                            "An unexpected error occurred invoking 'RequestAccess' on the server"
                        );
                    });
            }
        }
    };
    const joinPresentation = (e) => {
        e.preventDefault();
        let name = e.target.name.value;

        let email = e.target.email.value;

        if (!name) {
            toast.error("Enter your fullname");
            return;
        }

        if (!email) {
            toast.error("Enter your email");
            return;
        }

        triggerLoading();

        if (connection) {

            if (isEvent) {
                connection
                    .invoke(
                        "RequestEventAccess",
                        presentationId,
                        1,
                        email,
                        name,
                        getConnectionId() ? getConnectionId() : null,
                        isEvent ? presentationId : null
                    )
                    .catch((err) => {
                        setIsLoading(false);
                        console.log(err);
                        toast.error(
                            "An unexpected error occurred invoking 'RequestAccess' on the server"
                        );
                    });
            } else {
                console.log("I Also got triggered 3")
                console.log("RequestAccess",
                    presentationId,
                    1,
                    email,
                    name,
                    getConnectionId() ? getConnectionId() : null,)
                connection
                    .invoke(
                        "RequestAccess",
                        presentationId,
                        1,
                        email,
                        name,
                        getConnectionId() ? getConnectionId() : null,
                    )
                    .catch((err) => {
                        setIsLoading(false);
                        console.log(err);
                        toast.error(
                            "An unexpected error occurred invoking 'RequestAccess' on the server"
                        );
                    });
            }
        }
    };

    useEffect(() => {

        const userDetails = getUserDetails()
        if (userDetails?.fullName && userDetails?.email) {
            joinPresentationSinceAuthorised(userDetails.fullName, userDetails.email)
        }
    }, []);

    return (
        <section>
            <div className="mt-4 text-white">
                <h3 className="text-2xl"> Join Presentation </h3>
                <p className="my-1 text-sm">
                    Hi there, to join the presentation kindly fill in the form below{" "}
                </p>
            </div>

            <form onSubmit={joinPresentation} className="my-6 text-white">
                <div className="flex flex-col">
                    <label className="mb-2 text-sm"> Fullname</label>
                    <input
                        type="text"
                        placeholder="Enter your full name"
                        className="rounded-md pl-4 bg-transparent py-2 outline-none [ placeholder:text-white placeholder:text-sm ] [ backdrop-blur-lg bg-gradient-to-b from-[#F6F3EF1F]/10 to-[#F6F3EF1F]/10 ]"
                        name="name"
                    />
                </div>

                <div className="flex flex-col mt-6">
                    <label className="mb-2 text-sm"> Email address</label>
                    <input
                        type="email"
                        placeholder="example@presenta.com"
                        className="rounded-md pl-4 bg-transparent py-2 outline-none [ placeholder:text-white placeholder:text-sm ] [ backdrop-blur-lg bg-gradient-to-b from-[#F6F3EF1F]/10 to-[#F6F3EF1F]/10 ]"
                        name="email"
                    />
                </div>

                <button className="bg-[#0E0E0E] text-white w-full py-2 mt-4 rounded-md ">
                    {" "}
                    {isLoading ? (
                        <ClipLoader color="white" size={12}/>
                    ) : (
                        "Join presentation"
                    )}
                </button>
            </form>
        </section>
    );
}
