import SectionParent from 'components/Admin/Events/Section';
import { useParams } from 'react-router-dom';

export default function AllAgendas() {
  const { eventId } = useParams();
  return (
    <section>
      <section className='mt-2'>
        <div className='h-9 w-full rounded-lg bg-[#F6F6F6] grid grid-cols-2 px-5 mb-3'>
          <div className='flex items-center'>
            <p>Sessions</p>
          </div>
          <div className='flex items-center'>
            <p>Actions</p>
          </div>
        </div>

        {/* Sections */}
        {eventId && <SectionParent eventId={eventId} />}
      </section>
    </section>
  );
}
