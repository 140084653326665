import {CButton} from "../../Button/Buttons";
import questionIcon from "assets/svg/questionIcon.svg"

export const ViewerMenuHeader = ({text, sideText, questionType, handleQuestion}: {
    text: string,
    sideText?: string,
    questionType?: boolean,
    handleQuestion?: (val: boolean) => void
}) => {
    return <div className={"flex items-center p-4 border-b justify-between"}>
        <div className="flex items-center gap-2">
            <p className="text-[#101828] text-base font-semibold">
                {text}
            </p>
            {sideText && <span className="bg-[#EEEEEE] text-[#545454] font-semibold p-2 rounded-lg text-xs">
                {sideText}
            </span>}

        </div>
        {questionType &&
            <CButton action={() => handleQuestion && handleQuestion(true)} text={"Question"} variant={"dark"}
                     icon={questionIcon}
                     iconPosition={"left"}/>
                     }

    </div>
}