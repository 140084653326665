import axios from 'axios';
import Axios from 'lib/axiosInterceptor';
import { baseUrl } from 'utils/baseUrl';

//========================> GET
export const _getBriefcases = (teamId) => {
  return Axios.get(`/Spaces?teamId=${teamId}`);
};

export const _getBriefcase = (spaceId, teamId) => {
  return Axios.get(`/Spaces/Get/${spaceId}?teamId=${teamId}`);
};

export const _getDocument = (documentId) => {
  return Axios.get(`/Document/${documentId}`);
};
export const _getBriefcaseDocuments = (spaceId) => {
  return Axios.get(`/Spaces/Documents/${spaceId}`);
};

export const _getBriefcaseShareHistory = (spaceId) => {
  return Axios.get(`/Report/Spaces/History/${spaceId}?type=${0}`);
};

export const _getBriefcasePresentationHistory = (spaceId) => {
  return Axios.get(`/Report/Spaces/History/${spaceId}?type=${1}`);
};

export const _getDocumentVisits = (spaceId, documentId) => {
  return Axios.get(`/Report/Document/Visits/${documentId}?spaceId=${spaceId}`);
};

export const _getDocumentAnalytics = (spaceId, documentId) => {
  return Axios.get(
    `/Report/Document/Analytics/${documentId}?spaceId=${spaceId}`
  );
};
export const _getDocumentUsage = (spaceId, documentId) => {
  return Axios.get(`/Report/Document/Usage/${documentId}?spaceId=${spaceId}`);
};
export const _getDocumentPerformanceSummary = (spaceId, documentId) => {
  return Axios.get(
    `/Report/Document/PerformanceSummary/${documentId}?spaceId=${spaceId}`
  );
};

export const _getFolders = (spaceId) => {
  return Axios.get(`/Spaces/Groups/${spaceId}`);
};
export const _getFolder = (spaceId, groupId) => {
  return Axios.get(`/Spaces/Groups/${spaceId}/${groupId}`);
};

export const _getPreviewDocuments = (spaceId) => {
  return Axios.get(`/Spaces/Documents/${spaceId}?preview=true`);
};

export const _getSlides = (documentId) => {
  return Axios.get(`/Document/${documentId}`);
};

export const _getPresentationDetails = (presentaionId) => {
  return axios.get(`${baseUrl}/Presentation/${presentaionId}`);
};

export const _getPresentationDetailsAuth = (presentaionId) => {
  return Axios.get(`/Presentation/${presentaionId}`);
};

export const _getPresentationGuests = (presentaionId) => {
  return Axios.get(`/Presentation/Guests/${presentaionId}`);
};

// export const _getUserImage = (userId) => {
//   return Axios.get(`/Account/UserImage/${userId}`, {
//     responseType: 'blob',
//   });
// };

// export const _downloadDocument = (documentId) => {
//   return Axios.get(`Document/Download/${documentId}`, {
//     responseType: 'arraybuffer',
//   });
// };
// export const _getDocumentDetails = (documentId) => {
//   return Axios.get(`/Document/${documentId}`);
// };

//====================================> POST
export const _createBriefcase = (data) => {
  return Axios.post('/Spaces/Create', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const _updateBriefcase = (data) => {
  return Axios.post('/Spaces/Update', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const _addBriefcaseFile = (spaceId, data) => {
  return Axios.post(`/Spaces/Documents/Add/${spaceId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const _removeBriefcaseFile = (spaceId, documentId) => {
  return Axios.post(
    `/Spaces/Documents/Remove/${spaceId}?documentId=${documentId}`
  );
};

export const _setBriefcaseDocumentPermission = (spaceId, data) => {
  return Axios.post(`/Spaces/Documents/SetPermission/${spaceId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const _removeBriefcase = (spaceId, archive) => {
  return Axios.post(`/Spaces/Delete/${spaceId}?archive=${archive}`, {});
};

export const _createFolder = (spaceId, title, parentId) => {
  return Axios.post(`/Spaces/Groups/Create/`, { spaceId, title, parentId });
};

export const _updateFolder = (spaceId, groupId, title, parentId, position) => {
  return Axios.post(`/Spaces/Groups/Update/${groupId}`, {
    spaceId,
    title,
    parentId,
    position,
  });
};

export const _deleteFolder = (spaceId, groupId) => {
  return Axios.post(`/Spaces/Groups/Delete/${groupId}?spaceId=${spaceId}`);
};

export const _shareNewBriefcasePresentation = (data) => {
  return Axios.post(`Presentation/New`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const _addPresentationDocuments = (data) => {
  return Axios.post(`/Presentation/AddDocument/Multiple`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const _updateDocuments = (spaceId, data) => {
  return Axios.post(`/Spaces/Documents/Update/${spaceId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const _updateFoldersPositions = (spaceId, data) => {
  return Axios.post(
    `/Spaces/Groups/UpdatePositions/?spaceId=${spaceId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const _addMember = (spaceId, memberId) => {
  return Axios.post(`/Spaces/AddMember/${spaceId}?memberId=${memberId}`);
};
export const _removeMember = (spaceId, memberId) => {
  return Axios.post(`/Spaces/RemoveMember/${spaceId}?memberId=${memberId}`);
};

export const _updatePresentation = (presentaionId, data) => {
  return Axios.post(`/Presentation/Update/${presentaionId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const _addGuests = (presentaionId, data) => {
  return Axios.post(`/Presentation/AddGuests/${presentaionId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const _removeGuests = (presentaionId, data) => {
  return Axios.post(`/Presentation/RemoveGuests/${presentaionId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const _renameDocument = (documentId, data) => {
  return Axios.post(`/Spaces/Documents/Rename/${documentId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
