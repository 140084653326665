import React, {useEffect, useRef} from "react";
import {AgendaInterface, EventsInterface, SlideInterface} from "interfaces/interfaces-data";
import bannerPlaceholder from "assets/svg/eventWaiting.svg"
import conferenceSmall from "assets/svg/conferenceSmall.svg";
import {Col, Row} from "antd";

export const DesktopPrentationScreen = ({
                                            loading,
                                            activeSlide,
                                            gridModeActive,
                                            handleGridModeActive,
                                            slides,
                                            handleChangeSlide,
                                            activeSlideIndex,
                                            isEvent,
                                            isIdle,
                                            upcomingAgenda,
                                            eventDetails,
                                            eventPresentationsEnded

                                        }: {
    loading: boolean;
    activeSlide?: SlideInterface;
    gridModeActive: boolean;
    handleGridModeActive: (val: boolean) => void
    slides?: SlideInterface[]
    handleChangeSlide: (item: number) => void
    activeSlideIndex: number
    isEvent: boolean
    isIdle: boolean
    upcomingAgenda?: AgendaInterface
    eventDetails?: EventsInterface,
    eventPresentationsEnded: boolean

}) => {
    const elementRef = useRef<(HTMLDivElement | null)[]>([]);
    useEffect(() => {
        if (gridModeActive) {
            elementRef.current[activeSlideIndex]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center', // Center alignment for better view
            });
        }
    }, [gridModeActive]);
    return (
        <>
            <div
                className="w-full bg-[#F6F6F6] mt-2 mb-4 overflow-hidden rounded-xl p-4 flex-grow  min-h-[400px] h-[400px] flex items-center justify-center">
                {loading ? (
                    <div className="h-full w-full bg-[#F6F6F6] "></div>
                ) : (
                    <>{((isEvent && isIdle && upcomingAgenda) || eventPresentationsEnded) ?
                        <div className={"h-full w-full "}>
                            {!eventPresentationsEnded && <div
                                className="bg-[#E0DDD9] py-2 flex rounded-2xl justify-around text-[#FF6929] italic text-2xl font-grotesk gap-2">
                                <p className="relative">
                                    <span className="absolute -left-2">*</span>
                                    <span>Coming Up</span>
                                    <span className="absolute -right-2">*</span>

                                </p><p className="relative">
                                <span className="absolute -left-2">*</span>
                                <span>Coming Up</span>
                                <span className="absolute -right-2">*</span>

                            </p><p className="relative">
                                <span className="absolute -left-2">*</span>
                                <span>Coming Up</span>
                                <span className="absolute -right-2">*</span>
                            </p>
                            </div>}


                            <Row justify={"center"} align={"middle"}
                                 className={"flex py-8 items-center h-full "}>
                                <Col xs={8}>
                                    <img src={bannerPlaceholder} alt={""}/>
                                </Col>
                                <Col xs={16} className="bg-white p-4 min-h-[80%] h-[80%] rounded-lg">

                                    <p className="text-[#A53D1F] mb-3 text-4xl font-grotesk font-semibold">{eventPresentationsEnded ? "End of Event" : upcomingAgenda?.title}</p>
                                    <div className="flex  h-[80%] flex-col justify-between">

                                        <div className="bg-[#FAFAFA] border p-4 rounded-lg border-[#EBF1FF] rounded-lg">
                                            <p className="italic text-sm text-[#1D1E1F]">
                                                {eventDetails?.description}
                                            </p>
                                        </div>
                                        <div className="flex gap-2 flex-wrap gap-2">
                                            {eventPresentationsEnded ? "Thank you for participating" : upcomingAgenda?.collaborators?.length ? upcomingAgenda?.collaborators.map(item => {
                                                return <span
                                                    key={item.id}
                                                    className="bg-[#EEEEEE] mb-2 border border-[#D3D3D3] rounded-full py-1 flex items-center gap-2 px-4">
                                                <img src={conferenceSmall} alt={""}/>
                                                <p className={"text-xs"}>
                                                    {item.fullName}
                                                </p>
                                            </span>
                                            }) : <div> {eventPresentationsEnded ? "" : "No Collaborator Found"}</div>

                                            }

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div> : gridModeActive ?
                            <div
                                className='hide-scrollbar grid grid-cols-5 w-full max-h-full gap-3 px-5 overflow-y-auto'>
                                {slides?.length && slides.map((item, index, key) => {
                                    return <div
                                        ref={(el) => elementRef.current[index] = el}
                                        onClick={() => {
                                            handleChangeSlide(index)
                                            handleGridModeActive(false)
                                        }} className={"flex flex-col justify-center"}>
                                        <div
                                            className={`${activeSlide?.id === item.id ? "border-2 border-[#FF6929]" : ""} overflow-hidden  h-fit w-full rounded-lg cursor-pointer`}
                                            key={item.id}>
                                            <img src={item.imgUrl} alt={""}/>
                                        </div>
                                        <div className='flex w-full justify-center mt-2'>
                                            <div
                                                className={`rounded-full h-4 w-4 flex items-center justify-center ${
                                                    activeSlide?.id === item.id
                                                        ? ' bg-[#ED6E33] text-white'
                                                        : 'bg-gray-300 text-gray-600'
                                                } `}
                                            >
                                                <p className='text-xs'>{index + 1}</p>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                            : <img
                                className="min-w-full max-w-full  w-full h-full overflow-hidden rounded-2xl object-contain"
                                src={activeSlide?.imgUrl}
                                alt=""
                            />
                    }


                    </>
                )}
            </div>
        </>
    )
        ;
};
