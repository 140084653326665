import { useEffect, useMemo, useState } from 'react';
import GroupSection from './GroupSection';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from 'components/UI/Modal/ConfirmationModal';
import RenameModal from 'components/UI/Modal/RenameModal';
import { Modal } from 'antd';
import Axios from 'lib/axiosInterceptor';
import {
  AgendaItem,
  PresentationDocument,
} from 'interfaces/Events/EventsInterfaces';
import {
  getEvent,
  removePresentationDocument,
  updateDocument,
  updatePresentationDocumentPosition,
  updateSessionPosition,
} from 'store/Events/EventsSlice';
import { getTeamsState } from 'store/Teams/teamsSlice';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';

interface ImageCache {
  [id: string]: string;
}

export default function SectionParent({ eventId }: { eventId: string }) {
  //
  const dispatch = useDispatch<AppDispatch>();
  const { teamInfo } = useSelector(getTeamsState);
  const teamId = teamInfo?.data?.id;

  // Store
  const eventDetails = useSelector(
    (state: RootState) => state.events.event.data
  );
  const [confirmRemoveDocument, setConfirmRemoveDocument] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [confirmRenameDocument, setConfirmRenameDocument] = useState(false);
  const [isRenameLoading, setIsRenameLoading] = useState(false);

  const [selectedDocument, setSelectedDocument] =
    useState<PresentationDocument | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<AgendaItem | null>(null);
  const [activeSession, setActiveSession] = useState<AgendaItem | null>(null);

  const [agendaList, setAgendaList] = useState<AgendaItem[]>([]);
  const [activeRow, setActiveRow] = useState<PresentationDocument | null>(null);

  useEffect(() => {
    if (eventDetails && eventDetails.agenda) {
      setAgendaList(eventDetails.agenda);
    }
  }, [eventDetails]);

  //usememo for memoizing the fetched thumbnail
  const imageCache = useMemo<ImageCache>(() => ({}), []);

  const closeConfirmRemove = () => {
    setConfirmRemoveDocument(false);
  };

  const removeDocument = ({
    presentationId,
    documentId,
  }: {
    presentationId: string;
    documentId: string;
  }) => {
    setIsRemoveLoading(true);
    if (selectedDocument) {
      dispatch(
        removePresentationDocument({
          presentationId,
          documentId,
        })
      ).then(() => {
        setIsRemoveLoading(false);
        setConfirmRemoveDocument(false);
        dispatch(getEvent({ eventId, teamId }));
      });
    }
  };

  const handleRemoveDocument = () => {
    if (selectedFolder?.presentationId && selectedDocument?.documentId) {
      removeDocument({
        presentationId: selectedFolder?.presentationId,
        documentId: selectedDocument?.documentId,
      });
    }
  };

  const [titleName, setTitleName] = useState('');

  const closeRenameModal = () => {
    setConfirmRenameDocument(false);
  };

  const handleRenameDocument = () => {
    setIsRenameLoading(true);

    setIsRenameLoading(false);

    if (selectedDocument) {
      const { documentId } = selectedDocument;
      const data = new FormData();
      data.append('Title', titleName);
      data.append('DocumentId', documentId);
      data.append('FolderId', JSON.stringify(null));

      dispatch(updateDocument(data)).then(({ type }) => {
        if (type.includes('fulfilled')) {
          dispatch(getEvent({ eventId, teamId }));
        }
        setIsRenameLoading(false);
        closeRenameModal();
      });
    }
  };

  const handleDownload = async (doc: PresentationDocument) => {
    try {
      const match = doc.url.match(/\.([a-zA-Z0-9]+)$/);
      const fileExtension = match ? match[1] : null;
      const response = await Axios.get(`Document/Download/${doc.documentId}`, {
        responseType: 'arraybuffer',
      });

      if (response) {
        const url = URL.createObjectURL(new Blob([response.data]));
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.download = `${doc.title}.${fileExtension}`; // Set the filename (adjust the extension accordingly)
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    } catch (error) {}
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );
  function onDragStart(event: DragStartEvent) {
    if (event.active.data.current?.type === 'Section') {
      setActiveSession(event.active.data.current?.section); //this would set the active column - nice
      return;
    }
    if (event.active.data.current?.type === 'Row') {
      setActiveRow(event.active.data.current?.document); //this would set the active column - nice
      return;
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setActiveSession(null);
    setActiveRow(null);
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    const isActiveSection = active.data.current?.type === 'Section';
    const isOverSection = over.data.current?.type === 'Section';

    if (activeId === overId) return;

    if (isActiveSection && isOverSection) {
      setAgendaList((agendas) => {
        const activeRowIndex = agendas.findIndex((col) => col.id === activeId);
        const overRowIndex = agendas.findIndex((col) => col.id === overId);

        const rearrangedSession = arrayMove(
          agendas,
          activeRowIndex,
          overRowIndex
        ).map((agend, idx) => {
          return {
            sessionId: agend.id,
            position: idx,
          };
        });
        eventId &&
          dispatch(updateSessionPosition({ eventId, data: rearrangedSession }));

        return arrayMove(agendas, activeRowIndex, overRowIndex); // the swaps the activeCOlumnIndex with the overColumnIndex and returns a new array.
      });
    }

    // drag and drop in same session
    const isActiveRow = active.data.current?.type === 'Row';
    const isOverRow = over.data.current?.type === 'Row';
    const isActiveRowPresentationId = active.data.current?.presentationId;
    const isOverRowPresentationId = over.data.current?.presentationId;

    if (
      isActiveRow &&
      isOverRow &&
      isActiveRowPresentationId === isOverRowPresentationId
    ) {
      setAgendaList((agendas) =>
        agendas.map((agenda) => {
          // Check for the matching presentationId
          if (
            agenda.presentationId === active.data.current?.presentationId &&
            agenda.presentation &&
            Array.isArray(agenda.presentation.doc)
          ) {
            // Find indices of active and over rows
            const activeRowIndex = agenda.presentation.doc.findIndex(
              (col) => col.id === activeId
            );
            const overRowIndex = agenda.presentation.doc.findIndex(
              (col) => col.id === overId
            );

            // Ensure indices are valid
            if (activeRowIndex === -1 || overRowIndex === -1) {
              return agenda; // Return the unchanged agenda if indices are invalid
            }

            // Move the items within the doc array
            const newDoc = arrayMove(
              agenda.presentation.doc,
              activeRowIndex,
              overRowIndex
            );

            // // Updating request
            let requestPayload = newDoc.map((doc, index) => {
              return {
                documentId: doc.documentId,
                position: index,
                slides: [],
              };
            });

            if (requestPayload) {
              dispatch(
                updatePresentationDocumentPosition({
                  presentationId: active.data.current?.presentationId ?? '',
                  data: requestPayload,
                })
              );
            }

            // Return the updated agenda
            return {
              ...agenda,
              presentation: {
                ...agenda.presentation,
                doc: newDoc, // Update the doc array
              },
            };
          }

          // Return agenda unchanged if conditions do not match
          return agenda;
        })
      );
    }
  }

  return (
    <section className=''>
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        {eventDetails && agendaList && (
          <SortableContext
            items={agendaList}
            disabled={eventDetails.role === 'EventOwner' ? false : true}
          >
            {agendaList.map((section, index) => (
              <GroupSection
                folderIndex={index}
                section={section}
                eventId={eventId}
                imageCache={imageCache}
                setConfirmRemoveDocument={setConfirmRemoveDocument}
                setSelectedDocument={setSelectedDocument}
                setConfirmRenameDocument={setConfirmRenameDocument}
                handleDownload={handleDownload}
                eventDetails={eventDetails}
                setSelectedFolder={setSelectedFolder}
              />
            ))}
          </SortableContext>
        )}
      </DndContext>

      {/* Modals */}
      <ConfirmationModal
        open={confirmRemoveDocument}
        isLoading={isRemoveLoading}
        onClose={closeConfirmRemove}
        func={handleRemoveDocument}
        text='Delete document'
        subText='Are you sure you want to delete this document?'
      />
      <Modal
        footer={null}
        centered={false}
        open={confirmRenameDocument}
        onCancel={closeRenameModal}
        style={{
          top: 20,
        }}
        destroyOnClose
        closeIcon={false}
      >
        <RenameModal
          closeModal={closeRenameModal}
          setTitleName={setTitleName}
          func={handleRenameDocument}
          isLoading={isRenameLoading}
          previousTitle={selectedDocument?.title ? selectedDocument?.title : ''}
          titleName={titleName}
          heading='Document title'
          subHeading='Rename your document'
          buttonText='Update title'
        />
      </Modal>
    </section>
  );
}
