import Navbar from './Navbar';

import { Outlet } from 'react-router-dom';

export default function PollsLayout() {
  return (
    <section className='relative'>
      <section>
        <Navbar />
        <section className='mt-20'>
          <Outlet />
        </section>
      </section>
    </section>
  );
}
