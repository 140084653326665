import {CopyToClipBoard} from "../../wrapper/CopyToClipBoard";
import {CopyWrapper} from "../../wrapper/copy-wrapper";
import React from "react";
import {CopyActionWrapper} from "../../wrapper/copy-action-wrapper";
import {ViewerMenuWrapper} from "./ViewerMenuWrapper";
import {ViewerMenuHeader} from "./ViewMenuHeader";
import {ViewerInnerWrapper} from "./ViewerInnerWrapper";

export const DesktopViewerShare = ({friendlyId, isEvent}: { friendlyId: string, isEvent: boolean }) => {
    return <>
        <ViewerMenuWrapper>
            <ViewerMenuHeader text={"Share"}/>
            <ViewerInnerWrapper>
                <CopyToClipBoard
                    text={isEvent ? `${window.location.origin}/join-presentation/${friendlyId}?isEvent=true` : `${window.location.origin}/join-presentation/${friendlyId}`}>
                    <CopyWrapper
                        text={isEvent ? `${window.location.origin}/join-presentation/${friendlyId}?isEvent=true` : `${window.location.origin}/join-presentation/${friendlyId}`} />
                </CopyToClipBoard>
                <CopyActionWrapper
                    text={isEvent ? `${window.location.origin}/join-presentation/${friendlyId}?isEvent=true` : `${window.location.origin}/join-presentation/${friendlyId}`}/>
            </ViewerInnerWrapper>
        </ViewerMenuWrapper>

    </>
}