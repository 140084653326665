import {ReactNode} from "react";
import {Modal} from "antd";
import modalSideIcon from "assets/svg/modalSideIcon.svg"
import cancelIcon from "assets/svg/cancelIcon.svg"

export const ModalWrapper = ({children, open, onCancel}: {
    children: ReactNode,
    open: boolean,
    onCancel: () => void
}) => {
    return (
        <Modal
            open={open}
            onCancel={onCancel}
            destroyOnClose={true}
            footer={null}
            closeIcon={false}
        >
            <div className="flex items-center justify-between">
                <span>
                    <img src={modalSideIcon} alt={""}/>
                </span>
                <button onClick={onCancel}>
                    <img src={cancelIcon} alt={"Cancel Icon"}/>
                </button>
            </div>
            <div className="mt-3">
                {children}
            </div>
        </Modal>
    )
}